<h1 mat-dialog-title>
    {{title}}
  </h1>
  
<div mat-dialog-content>
    <p>{{message}}</p>
</div>

<div mat-dialog-actions align="end">
    <div class="px-2">
        <a href="javascript:void(0)" (click)="onDismiss()">Cancel</a>
    </div>
    <button mat-raised-button type="button" [color]="'primary'" class="mx-2" (click)="onConfirm()">Confirm</button>
</div>