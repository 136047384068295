import { Routes } from '@angular/router';
import { OrderListComponent } from './order-list/order-list.component';
import { OrderDetailsComponent } from './order-details/order-details.component';
import { OrderDetailFormComponent } from './order-detail-form/order-detail-form.component';
import { OrderRejectionComponent } from './order-rejection/order-rejection.component';
import { OrderSubmissionComponent } from './order-submission/order-submission.component';
import { MsalGuard } from '@azure/msal-angular';
import { OrderLineDetailsComponent } from './order-details/order-line-details/order-line-details.component';
import { AskQuestionComponent } from './ask-question/ask-question.component';

export const orderRoutes: Routes = [
  {
    path: 'orders',
    canActivate: [MsalGuard],
    data: { breadcrumb: 'My Orders' },
    children: [
      {
        path: '',
        component: OrderListComponent,
      },
      {
        path: 'order-details/:id',
        data: { breadcrumb: 'Order Detail' },
        children: [
          {
            path: '',
            component: OrderDetailsComponent,
          },
          {
            path: 'ask',
            data: { breadcrumb: 'Ask Question' },
            component: AskQuestionComponent
          },
          {
            path: 'order-line-details/:lineId',
            data: { breadcrumb: 'Order Line Detail' },
            children: [
              {
                path: '',
                component: OrderLineDetailsComponent,
              },
              {
                path: 'ask',
                data: { breadcrumb: 'Ask Question' },
                component: AskQuestionComponent
              }
            ]
          }
        ]
      },
      {
        path: 'order-detail-form',
        component: OrderDetailFormComponent
      },
      {
        path: 'order-rejection',
        component: OrderRejectionComponent
      },
      {
        path: 'order-submission',
        component: OrderSubmissionComponent
      }
    ],
  },
];
