import { getState, patchState, signalStore, withComputed, withHooks, withMethods, withState } from "@ngrx/signals";
import { computed, effect, inject } from "@angular/core";
import { IItemTypeGroup } from "../../../../core/models/IItemTypeGroup";
import { ItemtypeGroupService } from "../../../../core/services/itemtype-group.service";
import { LocalStorageService } from "../../../../core/services/local-storage.service";
import { forkJoin, Observable } from 'rxjs';
import { IItemTypeGroupCategory } from "../../../../core/models/IItemTypeGroupCategory";
import { RegionService } from "../../../../core/services/region.service";
import { IRegion } from "../../../../core/models/IRegion";

const itemTypeGroupStoreKey = 'tarifftel_itemtype_groups';
const itemTypeGroupTimestampKey = 'tarifftel_itemtype_groups_timestamp';
const CACHE_DURATION = 60 * 60 * 1000; // 1 hour in milliseconds

type ItemTypeGroupFilter = 'all' | 'active' | 'inactive';

type ItemTypeGroupState = {
  itemTypeGroups: IItemTypeGroup[];
  itemTypeGroupCategories: IItemTypeGroupCategory[];
  regions: IRegion[];
  filter: ItemTypeGroupFilter;
  searchTerm: string;
  selectedList: 'itemtypegroups' | 'groupcategories' | 'materials' | 'constructions';
  selectedItemTypeGroupId: number | null;
  selectedRegionId: number | null;
  selectedItemTypeGroupCategoryId: number | null;
  loading: boolean; // Add loading state
};

const initialState: ItemTypeGroupState = {
  itemTypeGroups: [],
  itemTypeGroupCategories: [],
  regions: [],
  filter: 'all',
  searchTerm: '',
  selectedList: 'itemtypegroups',
  selectedItemTypeGroupId: null,
  selectedRegionId: null,
  selectedItemTypeGroupCategoryId: null,
  loading: true
};

export const ItemTypeGroupStore = signalStore(
  { providedIn: 'root' },
  withState(initialState),
  withComputed(({ itemTypeGroups, filter, searchTerm }) => ({
    activeRegions: computed(() =>
      itemTypeGroups().filter((itemTypeGroupItem) => itemTypeGroupItem.active)
    ),
    filteredItemTypeGroups: computed(() => {
      let filtered = itemTypeGroups();

      // Apply Filter
      switch (filter()) {
        case 'active':
          filtered = filtered.filter(group => group.active);
          break;
        case 'inactive':
          filtered = filtered.filter(group => !group.active);
          break;
        default:
          // 'all' - no additional filtering
          break;
      }

      // Apply Search
      const term = searchTerm().toLowerCase().trim();
      if (term) {
        filtered = filtered.filter(itemTypeGroup =>
          itemTypeGroup.description.toLowerCase().includes(term)
        );
      }

      return filtered;
    })
  })),
  withMethods((store) => {
    const regionService = inject(RegionService);
    const itemTypeGroupService = inject(ItemtypeGroupService);
    const localStorageService = inject(LocalStorageService);

    return {
      loadData() {
        const dataFromStorage = localStorageService.getItem(itemTypeGroupStoreKey) || {};
        const timestamp = parseInt(localStorageService.getItem(itemTypeGroupTimestampKey) || '0', 10);
        const now = Date.now();

        patchState(store, { loading: true });

        if (Object.keys(dataFromStorage).length > 0 && (now - timestamp) < CACHE_DURATION) {
          patchState(store, dataFromStorage);
          patchState(store, { loading: false });

          // Ensure the first item type group is selected after loading from cache
          if (getState(store).selectedItemTypeGroupId === null) {
            this.selectFirstItemTypeGroup();
          }

          if (getState(store).selectedRegionId === null && getState(store).regions.length > 0) {
            this.setSelectedRegionId(getState(store).regions[0].id);
          }
        } else {
          forkJoin({
            itemTypeGroups: itemTypeGroupService.getAllItemTypeGroups(),
            itemTypeGroupCategories: itemTypeGroupService.getItemTypeGroupCategories(),
            regions: regionService.getActiveRegions(),
          }).subscribe({
            next: ({ itemTypeGroups, itemTypeGroupCategories, regions }) => {
              patchState(store, { itemTypeGroups, itemTypeGroupCategories, regions });
              localStorageService.setItem(itemTypeGroupStoreKey, JSON.stringify({ itemTypeGroups, itemTypeGroupCategories, regions }));
              localStorageService.setItem(itemTypeGroupTimestampKey, now.toString());
              patchState(store, { loading: false });

              // Select the first item type group if no group is selected
              if (getState(store).selectedItemTypeGroupId === null) {
                this.selectFirstItemTypeGroup();
              }

              if (getState(store).selectedRegionId === null && regions.length > 0) {
                this.setSelectedRegionId(regions[0].id);
              }
            },
            error: (err) => {
              console.error('Failed to load materials, categories, regions, and Item Type Groups', err);
              patchState(store, { loading: false });
            }
          });
        }
      },

      loadItemTypeGroups() {
        // Retrieve from local storage and parse the JSON string into an array
        patchState(store, { loading: true }); // Set loading to true initially
        const itemTypeGroupsFromStorage = JSON.parse(localStorageService.getItem(itemTypeGroupStoreKey) || '[]');
        const timestamp = parseInt(localStorageService.getItem(itemTypeGroupTimestampKey) || '0', 10);
        const now = Date.now();

        if (itemTypeGroupsFromStorage.length > 0 && (now - timestamp) < CACHE_DURATION) {
          patchState(store, { itemTypeGroups: itemTypeGroupsFromStorage });
        } else {
          // Fetch from backend
          itemTypeGroupService.getAllItemTypeGroups().subscribe({
            next: (itemTypeGroups) => {
              patchState(store, { itemTypeGroups });
              localStorageService.setItem(itemTypeGroupStoreKey, JSON.stringify(itemTypeGroups));
              localStorageService.setItem(itemTypeGroupTimestampKey, now.toString());
            },
            error: (error) => {
              console.error('Failed to load Item Type Groups', error);
            }
          });
        }
      },

      getItemTypeGroupCategories(): Observable<IItemTypeGroupCategory[]> {
        return new Observable((observer) => {
          itemTypeGroupService.getItemTypeGroupCategories().subscribe({
            next: (categories) => {
              patchState(store, { itemTypeGroupCategories: categories });
              observer.next(categories);
              observer.complete();
            },
            error: (error) => {
              console.error('Failed to load item type group categories', error);
              observer.error(error);
            }
          });
        });
      },

      setSelectedItemTypeGroupId(id: number | null) {
        patchState(store, { selectedItemTypeGroupId: id });
      },

      setSelectedRegionId(id: number | null) {
        patchState(store, { selectedRegionId: id });
      },

      setSelectedItemTypeGroupCategoryId(id: number | null) {
        patchState(store, { selectedItemTypeGroupCategoryId: id });
      },

      changeFilter(filter: ItemTypeGroupFilter) {
        patchState(store, { filter });
      },

      toggleActiveItemTypeGroup(id: number) {
        patchState(store, {
          itemTypeGroups: store.itemTypeGroups().map(itemTypeGroup => {
            if (itemTypeGroup.id === id) {
              return { ...itemTypeGroup, active: !itemTypeGroup.active };
            }
            return itemTypeGroup;
          })
        });
      },

      updateItemTypeGroupStatus(itemTypeGroup: IItemTypeGroup): Observable<any> {
        return itemTypeGroupService.toggleItemTypeGroupStatus(itemTypeGroup.id, itemTypeGroup.active);
      },

      createItemTypeGroup(itemTypeGroup: IItemTypeGroup): Observable<IItemTypeGroup> {
        return itemTypeGroupService.createItemTypeGroup(itemTypeGroup.description);
      },

      updateItemTypeGroup(itemTypeGroup: IItemTypeGroup): Observable<IItemTypeGroup> {
        return itemTypeGroupService.updateItemTypeGroup(itemTypeGroup);
      },

      setSearchTerm(term: string) {
        patchState(store, { searchTerm: term });
      },

       // Add new item directly to the state
       addItemTypeGroup(newItem: IItemTypeGroup) {
        patchState(store, {
          itemTypeGroups: [...store.itemTypeGroups(), newItem]
            .sort((a, b) => a.description.localeCompare(b.description))
        });
      },

      showTable(selectedList: 'itemtypegroups' | 'groupcategories' | 'materials' | 'constructions') {
        patchState(store, { selectedList });
      },

      // Update the item in the state
      patchItemTypeGroup(updatedItem: IItemTypeGroup) {
        patchState(store, {
          itemTypeGroups: store.itemTypeGroups().map(item =>
            item.id === updatedItem.id ? updatedItem : item
          )
        });
      },

      // Methods for item type group categories
      createCategory(category: IItemTypeGroupCategory): Observable<IItemTypeGroupCategory> {
        return itemTypeGroupService.createItemTypeGroupCategory(category);
      },

      updateCategory(category: IItemTypeGroupCategory): Observable<IItemTypeGroupCategory> {
        return itemTypeGroupService.updateItemTypeGroupCategory(category);
      },

      deleteCategory(categoryId: number): Observable<void> {
        return itemTypeGroupService.deleteItemTypeGroupCategory(categoryId);
      },

      addItemTypeGroupCategory(newCategory: IItemTypeGroupCategory) {
        patchState(store, {
          itemTypeGroupCategories: [...store.itemTypeGroupCategories(), newCategory].sort((a, b) => a.description.localeCompare(b.description))
        });
      },

      patchCategory(updatedCategory: IItemTypeGroupCategory) {
        patchState(store, {
          itemTypeGroupCategories: store.itemTypeGroupCategories().map(cat => cat.id === updatedCategory.id ? updatedCategory : cat)
        });
      },

      selectFirstItemTypeGroup() {
        const { itemTypeGroups } = getState(store);
        if (itemTypeGroups.length > 0) {
          this.setSelectedItemTypeGroupId(itemTypeGroups[0].id);
        }
      },

      clearCache() {
        localStorageService.removeItem(itemTypeGroupStoreKey);
        localStorageService.removeItem(itemTypeGroupTimestampKey);
        patchState(store, { itemTypeGroups: [] }); // Optionally, clear itemTypeGroups in the store
      }
    };
  }),
  withHooks({
    onInit(store) {
      store.loadData()
    },
  })
);
