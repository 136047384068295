import { IAggridTopToolbarBtn } from "./IAggridTopToolbarBtn";


export enum AggridTopToolbarBtnsEnum {
    Add,
    AddUser,
    DeleteUser,
    Edit,
    Delete,
    Refresh
  }

export class AggridTopToolbarBtn implements IAggridTopToolbarBtn {
  
    constructor(
        public btnEnumVal: AggridTopToolbarBtnsEnum,
        public classes: string,
        public title: string,
        public disabled: boolean,
        public color: string
    ) {}
    
}