import { Component } from '@angular/core';

@Component({
  selector: 'app-order-rejection',
  standalone: true,
  imports: [],
  templateUrl: './order-rejection.component.html',
  styleUrl: './order-rejection.component.scss'
})
export class OrderRejectionComponent {

}
