import { Component, effect, ElementRef, inject, Input, ViewChild } from '@angular/core';
import { UserFriendlyHttpErrorMessageDialogComponent } from '../user-friendly-http-error-message-dialog/user-friendly-http-error-message-dialog.component';
import { ConfirmModalComponent } from '../../../../core/components/confirm-modal/confirm-modal.component';
import { CommonModule } from '@angular/common';
import { MatCard, MatCardContent, MatCardHeader, MatCardTitle } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { RouterModule } from '@angular/router';
import { ButtonWithMatSpinnerComponent } from '../button-with-mat-spinner/button-with-mat-spinner.component';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { FormsModule } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ExcelFileAttachBulkEntityLoadService } from '../../../../core/services/excel-file-attach-bulk-entity-load.service';
import { RegionStore } from '../../../item-type-management/regions/store/regions.store';
import { ToastrService } from 'ngx-toastr';
import { VendorStore } from '../store/vendors.store';

@Component({
  selector: 'app-excel-file-attach-bulk-entity-load',
  standalone: true,
  imports: [
    CommonModule,
    ConfirmModalComponent,
    MatCard,
    MatCardContent,
    MatCardHeader,
    MatCardTitle,
    MatDividerModule,
    MatProgressSpinnerModule,
    RouterModule,
    ButtonWithMatSpinnerComponent,
    MatButtonModule,
    MatInputModule,
    MatListModule,
    FormsModule
  ],
  templateUrl: './excel-file-attach-bulk-entity-load.component.html',
  styleUrl: './excel-file-attach-bulk-entity-load.component.scss'
})
export class ExcelFileAttachBulkEntityLoadComponent {
  @Input() validFileExtensions:string[]|undefined;
  @Input() acceptedFileTypes:string[]|undefined;
  @Input() fileUploadendpointSuffix:string|undefined;
  @Input() sampleFilePath:string|undefined;
  @Input() sampleFileLinkName:string|undefined;
  @Input() viewAllRecordsLinkPath:string|undefined;
  @Input() sampleFileMandatoryFields:string[]|undefined;
  @Input() sampleFileNonMandatoryFieldsGuidelines:string[]|undefined;
  @Input() vendorMode:boolean = false;
  @Input() vendorUserMode:boolean = false;
  vendorIdForVendorUserMode:boolean = false;
  selectedFile: any = null;
  showUploadLoadingSpinner = false;
  showGuidelinesLoadingSpinner = false;
  uploadCompleteValidationNoRejection = false;
  uploadCompleteValidationRejection = false;
  rejectionTextAreaTextContent = "";
  @ViewChild('fileInput') fileSelectionInput: ElementRef | undefined;
  fileInputSelectValue = ""
  regionStore = inject(RegionStore);
  validationRejectionFileLocalDownloadHref = "";
  vendorStore = inject(VendorStore);

  constructor(private toastrService:ToastrService,private fileUploadService:ExcelFileAttachBulkEntityLoadService,private dialog:MatDialog) {
    effect(()=> {
      this.showGuidelinesLoadingSpinner = this.fileUploadService.guidelinesRegionLoadingSignal();
    })
  }

  ngOnInit(): void {

    if(this.vendorMode) {
      this.fileUploadService.guidelinesRegionLoadingSignal.set(true);
      this.regionStore.loadRegions(this.fileUploadService.guidelinesRegionLoadingSignal);
    }

  }

  getModeEntityString() {
    if(this.vendorMode) {
      return "vendor"
    }
    else if(this.vendorUserMode) {
      return "vendor user"
    }
    else{
      return "";
    }
  }

  getAttachFileToBeginButtonLabel() {
    if(this.uploadCompleteValidationNoRejection) {
      return "Select Another Excel File";
    }
    else if(this.uploadCompleteValidationRejection) {
      return "Select Amended Excel File";
    }
    else {
      return "Select Excel File";
    }
  }

  getFileTypesForInputAcceptedAttribute() {
    return this.acceptedFileTypes?.join(";");
  }

  onFileSelected(event: any) {
      const fileToUpload = event.target.files[0];

      if (this.isInvalidFileExtension(fileToUpload)) {
          this.showInvalidFileExtensionDialog();
      } else {
          this.handleFileUpload(fileToUpload);
      }
  }

  private isInvalidFileExtension(file: File): boolean|undefined {
      return this.validFileExtensions && !this.fileUploadService.checkFileExtensionFromInputTypeFile(file, this.validFileExtensions);
  }

  private showInvalidFileExtensionDialog(): void {
      const fileValidationMessage = `The selected file has an invalid file extension. Please check the file extension is one of the following '${this.validFileExtensions?.join(",")}'.`;

      const dialogRef = this.dialog.open(ConfirmModalComponent, {
          data: {
              title: "",
              message: fileValidationMessage
          }
      });

      dialogRef.afterClosed().subscribe();
  }

  private handleFileUpload(fileToUpload: File): void {
      this.showUploadLoadingSpinner = true;
      this.rejectionTextAreaTextContent = "";
      this.uploadCompleteValidationNoRejection = false;
      this.uploadCompleteValidationRejection = false;

      if (this.fileUploadendpointSuffix) {
          this.fileUploadService.uploadFileViaPost(fileToUpload, this.fileUploadendpointSuffix).subscribe({
              next: (response: any) => this.onUploadSuccess(),
              error: (errorResponse: any) => this.onUploadError(errorResponse)
          });
      }
  }

  private onUploadSuccess(): void {
      if (this.vendorMode || this.vendorUserMode) {
          this.vendorStore.clearCache();
      }

      this.fileInputSelectValue = '';
      this.showUploadLoadingSpinner = false;
      this.uploadCompleteValidationNoRejection = true;
      this.toastrService.success(`Data from ${this.getModeEntityString()} excel file successfully Loaded!`);
  }

  private onUploadError(errorResponse: any): void {
      if (errorResponse.status === 422 && errorResponse.error) {
          this.handleValidationError(errorResponse.error);
      } else {
          this.handleGeneralError();
      }

      this.fileInputSelectValue = '';
      this.showUploadLoadingSpinner = false;
      this.uploadCompleteValidationRejection = true;
  }

  private handleValidationError(error: string): void {
      this.rejectionTextAreaTextContent = error;
      this.validationRejectionFileLocalDownloadHref = "data:text/plain;charset=utf-8," + encodeURIComponent(this.rejectionTextAreaTextContent);
  }

  private handleGeneralError(): void {
      this.rejectionTextAreaTextContent = "";
      this.dialog.open(UserFriendlyHttpErrorMessageDialogComponent);
  }


}
