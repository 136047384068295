<h2 mat-dialog-title>{{ editMode  ? 'Edit Default Size' : 'Add Default Size' }}</h2>
<hr />
<form [formGroup]="defaultSizeForm" (ngSubmit)="onSave()">
  <mat-dialog-content>
    <div class="form-group">
      <mat-form-field appearance="fill" class="full-width">
        <mat-label>Size Category</mat-label>
        <mat-select formControlName="sizeCategoryId">
          <mat-option *ngFor="let category of store.filteredCategoriesForDialog()" [value]="category.id">
            {{ category.categoryName }}
          </mat-option>
        </mat-select>
        <!-- Hint for guidance -->
        <mat-hint align="start">
          Select the category that this size belongs to.
        </mat-hint>
      </mat-form-field>

      <mat-form-field appearance="fill" class="full-width">
        <mat-label>Size Code</mat-label>
        <input matInput formControlName="sizeCode"  placeholder="Enter Size Code" maxlength="300" />
        <mat-hint align="end">{{ defaultSizeForm.get('sizeCode')?.value?.length || 0 }}/300</mat-hint>

          <!-- Hint for guidance -->
        <mat-hint align="start">
            Defines a Default size code within the selected category, Group and Region.
        </mat-hint>

        <!-- Required error message -->
        <mat-error *ngIf="defaultSizeForm.get('sizeCode')?.hasError('required')">
          Size Code is required
        </mat-error>

        <mat-error *ngIf="defaultSizeForm.get('sizeCode')?.hasError('maxlength')">
          Size Code cannot exceed 300 characters
        </mat-error>

      </mat-form-field>
    </div>
  </mat-dialog-content>
  <hr />
  <div class="action-buttons">
    <mat-dialog-actions>
      <button mat-flat-button color="warn" type="button" *ngIf="editMode" (click)="onDelete()">
        Delete
      </button>
    </mat-dialog-actions>
    <mat-dialog-actions>
      <button mat-button type="button" (click)="onCancel()">Cancel</button>
      <button mat-flat-button color="primary" type="submit" [disabled]="defaultSizeForm.invalid || isSaving">
        {{ editMode ? 'Update' : 'Create' }}
      </button>
    </mat-dialog-actions>
</div>
</form>




