import { Component } from '@angular/core';
import { SecondaryNavbarComponent } from '@tarifftel/components-library';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { CommonModule } from '@angular/common';
import { ClientListComponent } from './client-list/client-list.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-clients',
  standalone: true,
  imports: [CommonModule, SecondaryNavbarComponent, TabsModule, ClientListComponent, TranslateModule],
  templateUrl: './clients.component.html',
  styleUrl: './clients.component.scss'
})
export class ClientsComponent {

}
