import { CommonModule, NgFor } from '@angular/common';
import { Component, inject } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UiSwitchModule } from 'ngx-ui-switch';
import { ItemTypeGroupStore } from '../store/itemtype-group.store';
import { classificationStore } from '../../item-type-mgmt/classification-dialog/store/classifierstore';
import { MaterialCategoryStore } from '../../material-mgmt/store/materialstore';
import { AttributeListingStore } from '../../attribute-values-mgmt/store/attributelistingstore';
import { ConstructionCategoryStore } from '../../construction-mgmt/store/constructionstore';
import { DefaultSizeStore } from '../../size-mgmt/store/sizecategorystore';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import { debounceTime, Subject } from 'rxjs';
import { IItemTypeGroup } from '../../../../core/models/IItemTypeGroup';
import { ItemTypeGroupDialogComponent } from '../item-type-group-dialog/item-type-group-dialog.component';

@Component({
  selector: 'app-item-type-group-list',
  standalone: true,
  imports: [
    CommonModule,
    NgFor,
    FormsModule,
    UiSwitchModule
  ],
  providers: [
    {
      provide: UiSwitchModule,
      useFactory: () => UiSwitchModule.forRoot({
        size: 'medium',
        color: 'rgb(0, 189, 99)',
        switchColor: '#3f4d97',
        defaultBgColor: '#cfe2ff',
        defaultBoColor: '#476EFF',
        checkedLabel: 'on',
        uncheckedLabel: 'off'
      })
    }
  ],
  templateUrl: './item-type-group-list.component.html',
  styleUrl: './item-type-group-list.component.scss'
})
export class ItemTypeGroupListComponent {
  store = inject(ItemTypeGroupStore);
  classifierStore = inject(classificationStore);
  materialCategoryStore = inject(MaterialCategoryStore);
  attributelistingStore = inject(AttributeListingStore);
  constructionStore = inject(ConstructionCategoryStore);
  defaultSizeStore = inject(DefaultSizeStore);
  toastrService = inject(ToastrService);
  dialog = inject(MatDialog);

  private searchSubject = new Subject<string>();
  searchTerm: string = '';

  ngOnInit() {
    this.store.loadData();
    this.searchSubject.pipe(
      debounceTime(300) // Adjust the debounce time as needed
    ).subscribe((term) => {
      this.store.setSearchTerm(term);
    });
  }

  onToggleActive(itemTypeGroup: IItemTypeGroup) {
    // Optimistically toggle the Item Type Groups active status in the store
    const newStatus = !itemTypeGroup.active; // Explicitly toggle active status
    this.store.toggleActiveItemTypeGroup(itemTypeGroup.id);

    // Call the service with the new status
    this.store.updateItemTypeGroup({ ...itemTypeGroup, active: newStatus }).subscribe({
      next: () => {
        this.clearSystemStoreCaches();
        this.updateSystemStores();
        this.toastrService.success('Item Type Group updated successfully', 'Success');
      },
      error: () => {
        this.toastrService.error('Failed to update Item Type Group', 'Error');
        // Revert the change in the store
        this.store.toggleActiveItemTypeGroup(itemTypeGroup.id);
      }
    });
  }

  clearSystemStoreCaches() {
    this.materialCategoryStore.clearCache();
    this.constructionStore.clearCache();
    this.defaultSizeStore.clearCache();
    this.attributelistingStore.clearCache();
    this.classifierStore.clearCache();
  }

  updateSystemStores() {
    this.materialCategoryStore.loadData();
    this.constructionStore.loadData();
    this.defaultSizeStore.loadData();
    this.attributelistingStore.loadData();
    this.classifierStore.loadData();
  }

  forceRefresh() {
    this.store.clearCache();
    this.store.loadData();
    this.toastrService.info('Data has been refreshed', 'Refreshed');
  }

  onSearchTermChange(term: string) {
    this.searchSubject.next(term);
  }

  // New method to reset filters and search
  resetFilters() {
    this.searchTerm = ''; // Clear the search input
    this.store.setSearchTerm(''); // Explicitly update the store's searchTerm
    this.toastrService.info('Filters have been reset', 'Reset'); // Optional: Notify the user
  }

  openDialog(editMode: boolean, itemTypeGroup?: IItemTypeGroup) {
    const dialogRef = this.dialog.open(ItemTypeGroupDialogComponent, {
      width: '600px',
      data: {
        itemTypeGroup: itemTypeGroup ?? { id: 0, description: '', clarifyingInformation: '', active: true },
        editMode
      }
    });

    dialogRef.afterClosed().subscribe((result: IItemTypeGroup) => {
      if (result) {
        if (editMode) {
          this.store.patchItemTypeGroup(result); // Update the item in the store
        } else {
          this.store.addItemTypeGroup(result); // Add the new item to the store
        }

        // this.clearSystemStoreCaches();
        // this.updateSystemStores();
      }
    });
  }


  createItemTypeGroup() {
    this.openDialog(false);
  }

  editItemTypeGroup(itemTypeGroup: IItemTypeGroup) {
    this.openDialog(true, itemTypeGroup);
  }
}
