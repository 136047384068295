import { Routes } from '@angular/router';
import { InboxDirectoryComponent } from '../inbox/inbox-directory/inbox-directory.component';
import { MsalGuard } from '@azure/msal-angular';

export const inboxRoutes: Routes = [
  {
    path: 'inbox',
    component: InboxDirectoryComponent,
    canActivate: [MsalGuard],
  },
];
