import { inject, Injectable, OnInit, signal } from '@angular/core';
import { forkJoin, Observable, ObservableInput, tap } from 'rxjs';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { environment } from '../../../environments/environment';
import { Vendor } from '../models/vendor.model';
import { VendorUser } from '../models/vendor-user.model';
import { IVendor } from '../models/IVendor';
import { IVendorUser } from '../models/IVendorUser';
import { ToastrService } from 'ngx-toastr';
import { UserFriendlyHttpErrorMessageDialogComponent } from '../../features/settings/vendor-management/user-friendly-http-error-message-dialog/user-friendly-http-error-message-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class VendorManagementService {
  baseUrl = environment.apiUrl;

  vendorDetailUpdateModeSignal = signal<boolean>(false);

  vendorAddUpdateLoadingSignal = signal<boolean>(false);
  vendorAddUpdateCloseDialogSignal = signal<boolean>(false);

  vendorUserAddUpdateLoadingSignal = signal<boolean>(false);
  vendorUserAddUpdateCloseDialogSignal = signal<boolean>(false);



  constructor(private toastrService:ToastrService,private httpClient:HttpClient,private dialog:MatDialog) {

  }

  getVendors(): Observable<any> {
    return this.httpClient.get(this.baseUrl + "/api/Vendor/GetAllVendors");
  }

  addVendor(vendor:Vendor) {
      return this.httpClient.post(this.baseUrl + '/api/vendor/CreateVendor',vendor)
  }

  addVendorUser(vendor:Vendor,vendorUser:VendorUser) {
    return this.httpClient.post(this.baseUrl + '/api/vendorUser',vendorUser);
  }

  updateVendorUser(vendorUserId:number,vendorUser:VendorUser) {

    return this.httpClient.put<Vendor>(this.baseUrl + `/api/VendorUser/${vendorUserId}`,vendorUser);

  }

  updateVendor(id:number,newVendor:Vendor) {
    return this.httpClient.put<Vendor>(this.baseUrl + `/api/Vendor/UpdateVendor/${id}`,newVendor);
  }

  deleteVendors(vendorsToDelete:Vendor[]) {

    let vendorIdsToDelete = vendorsToDelete.map(v => v.id);
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }), body: vendorIdsToDelete
    };

    return this.httpClient.delete(this.baseUrl + '/api/vendor/deleteMultiple',httpOptions)

  }

  deleteVendorUsers(vendor:Vendor,vendorUsersToDelete:VendorUser[]) {

    let vendorIdsToDelete = vendorUsersToDelete.map(v => v.id);
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }), body: vendorIdsToDelete
    };

    return this.httpClient.delete(this.baseUrl + '/api/vendorUser/deleteMultiple',httpOptions);
  }

  bulkUpdateVendorsStatus(vendorsToChgStatus:Vendor[],activateVendor:boolean) {

      let vendorIdsToUpdateStatus = vendorsToChgStatus.map(v => v.id);

      return this.httpClient.put(this.baseUrl + '/api/vendor/updateVendorStatus',{
        Ids:vendorIdsToUpdateStatus,
        status:activateVendor
      })
  }

  bulkUpdateVendorUsersActive(vendorUsersToChgStatus:VendorUser[],activateVendorUser:boolean) {
    let vendorUserIdsToUpdateStatus = vendorUsersToChgStatus.map(v => v.id);
    return this.httpClient.put(this.baseUrl + '/api/vendorUser/updateVendorUserActive',{
      ids:vendorUserIdsToUpdateStatus,
      active:activateVendorUser
    })
  }







}
