import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { IConstruction } from '../models/IConstruction';
import { Observable, tap } from 'rxjs';
import { IConstructionCategory } from '../models/IConstructionCategory';

@Injectable({
  providedIn: 'root'
})
export class ConstructionService {

  clientUrl = environment.apiUrl;

  constructor(private http: HttpClient) { }

  getConstructions(): Observable<IConstruction[]> {
    return this.http.get<IConstruction[]>(`${this.clientUrl}/api/construction`);
  }

  getConstructionCategories(): Observable<IConstructionCategory[]> {
    return this.http.get<IConstructionCategory[]>(this.clientUrl + "/api/constructioncategory");
  }

  // Create a new construction
  createConstruction(construction: IConstruction): Observable<IConstruction> {
    return this.http.post<IConstruction>(`${this.clientUrl}/api/construction`, construction, {
      headers: { 'Content-Type': 'application/json' }
    });
  }


  // Update an existing construction
  updateConstruction(construction: IConstruction): Observable<IConstruction> {
    return this.http.put<IConstruction>(`${this.clientUrl}/api/construction/${construction.id}`, construction, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }

  deleteConstruction(constructionId: number): Observable<void> {
    return this.http.delete<void>(`${this.clientUrl}/api/construction/${constructionId}`);
  }

 // New method for creating a construction category
 createCategory(category: IConstructionCategory): Observable<IConstructionCategory> {
    return this.http.post<IConstructionCategory>(`${this.clientUrl}/api/constructioncategory`, category, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }

  updateCategory(category: IConstructionCategory): Observable<IConstructionCategory> {
    return this.http.put<IConstructionCategory>(`${this.clientUrl}/api/constructioncategory/${category.id}`, category, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }

  deleteCategory(categoryId: number): Observable<void> {
    return this.http.delete<void>(`${this.clientUrl}/api/constructioncategory/${categoryId}`);
  }
}
