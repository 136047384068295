
<div class="row my-2">
  <div class="col-12 d-flex justify-content-end align-items-center gap-2">
    <!-- Loading Spinner -->
    <div *ngIf="showOrderGridLoading" class="spinner-border text-primary" role="status">
      <span class="visually-hidden">Uploading...</span>
    </div>

    <!-- Hidden File Input -->
    <input type="file" #fileInput (change)="onFileSelected($event)" style="display: none;" accept=".xlsx" />

    <!-- Upload Orders Button -->
    <button type="button" class="btn btn-primary me-2" (click)="fileInput.click()" [disabled]="showOrderGridLoading">
      Upload Orders Via Excel
    </button>

    <!-- Clear Filters Button -->
    <button type="button" class="btn btn-secondary" (click)="clearFilters()" [disabled]="showOrderGridLoading">
      Clear Filters
    </button>
  </div>
</div>
<ag-grid-angular
[attr.style]="'height:' + gridHeight() + 'px;width:  100%;'"
[columnDefs]="columnDefs"
[defaultColDef]="defaultColDef"
[rowData]="rowData"
[sideBar]="sideBarDef()"
[class]="themeClass"
[rowSelection]="'multiple'"
[animateRows]="true"
[pagination]="true"
[paginationPageSize]="20"
[cacheBlockSize]="20"
[rowModelType]="'serverSide'"
[getRowId]="getRowId"
[enableRangeSelection]="true"
[enableAdvancedFilter]="true"
[includeHiddenColumnsInAdvancedFilter]="true"
[paginationPageSizeSelector]="false"
[getContextMenuItems]="getContextMenuItems"
(gridReady)="onGridReady($event)"
></ag-grid-angular>
