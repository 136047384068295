import { CommonModule } from '@angular/common';
import { Component, effect, inject, OnInit, signal } from '@angular/core';
import { AggridManageVendorUsersComponent } from './aggrid-manage-vendor-users/aggrid-manage-vendor-users.component';
import { MatCardModule } from '@angular/material/card';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { MatTabsModule } from '@angular/material/tabs';
import { VendorFormComponent } from "../vendor-form/vendor-form.component";
import { UserFriendlyHttpErrorMessageDialogComponent } from '../user-friendly-http-error-message-dialog/user-friendly-http-error-message-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { IVendor } from '../../../../core/models/IVendor';
import { VendorManagementService } from '../../../../core/services/vendor-management.service';
import { FormGroup } from '@angular/forms';
import { ConfirmModalComponent } from '../../../../core/components/confirm-modal/confirm-modal.component';
import { CanComponentWithUnSavedFormChangesDeactivate } from '../../../../core/guards/can-deactivate-confirm-discard-unsaved-changes.guard';
import { BreadcrumbComponent } from "../../../../core/components/breadcrumb/breadcrumb.component";
import { VendorStore } from '../store/vendors.store';
import { RegionStore } from '../../../item-type-management/regions/store/regions.store';

@Component({
  selector: 'app-vendor-detail',
  standalone: true,
  imports: [
    CommonModule,
    AggridManageVendorUsersComponent,
    MatCardModule,
    MatTabsModule,
    VendorFormComponent,
    BreadcrumbComponent
],
  templateUrl: './vendor-detail.component.html',
  styleUrl: './vendor-detail.component.scss'
})
export class VendorDetailComponent implements OnInit,CanComponentWithUnSavedFormChangesDeactivate {
  title:string = "Vendor Detail"
  vendor:IVendor | undefined;
  vendorId:number|undefined;
  showVendorLoadingSpinner = signal<boolean>(true);
  updateVendorMode:boolean = false;
  vendorDetailForm:FormGroup | undefined
  vendorStore = inject(VendorStore);
  regionStore = inject(RegionStore);
  
  constructor(private dialog:MatDialog,private route: ActivatedRoute,private vendorManagementService:VendorManagementService) {
    effect(() => {
      this.updateVendorMode = this.vendorManagementService.vendorDetailUpdateModeSignal();
    })
  }

  ngOnInit() {
    this.showVendorLoadingSpinner.set(true);
    this.parseCreateOrEditVendorModeQueryStringParam();
    this.parseIdQueryStringParam();
    
  }

  parseCreateOrEditVendorModeQueryStringParam() {
    const createOrEditVendorModeStrQueryParam:string | null = this.route.snapshot.paramMap.get('createOrEditVendorMode');
    if(createOrEditVendorModeStrQueryParam) {
      (createOrEditVendorModeStrQueryParam.toUpperCase() == "CREATE") ? this.vendorManagementService.vendorDetailUpdateModeSignal.set(false):this.vendorManagementService.vendorDetailUpdateModeSignal.set(true);
     
    }

    this.updateVendorMode = this.vendorManagementService.vendorDetailUpdateModeSignal();
    
  }

  parseIdQueryStringParam() {
    const vendorIdStrQueryParam:string | null = this.route.snapshot.paramMap.get('id');

    if(this.updateVendorMode && vendorIdStrQueryParam) {
      this.vendorId = +vendorIdStrQueryParam
      this.loadVendors(false);
    }
    else {
      this.showVendorLoadingSpinner.set(false);
    }  
  }

  onVendorFormChangeEvent(vendorForm:any) {
    this.vendorDetailForm = vendorForm;
  }

  canDeactivate() {
    if(this.vendorDetailForm && this.vendorDetailForm?.dirty) {
      const dialogRef = this.dialog.open(ConfirmModalComponent, {
        data: {
          title:"",
          message:"Changes to the vendor form have not been saved. Do you want to continue and discard the current changes?"
        }
      });
  
      return dialogRef.afterClosed();
    }
    else {
      return true;
    }
  
  }

  refreshVendorStore() {
    this.showVendorLoadingSpinner.set(true);
    this.loadVendors(true);
  }

  refreshRegionStore() {
    this.showVendorLoadingSpinner.set(true);
    this.regionStore.clearCache();
    this.regionStore.loadRegions(this.showVendorLoadingSpinner);
  }

  loadVendors(loadFromRequestNotStateOverride:boolean) {
    this.vendorStore.loadVendors(loadFromRequestNotStateOverride).subscribe({
      next: (vendors) => {
        if(this.vendorId) {
          this.vendor = this.vendorStore.getVendorById(this.vendorId);
          this.showVendorLoadingSpinner.set(false);
        }
      },
      error: (error) => {
        console.error('Failed to get vendors', error);
        this.dialog.open(UserFriendlyHttpErrorMessageDialogComponent);
      }
    });
  }
}
