import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatListModule } from '@angular/material/list';
import { IAggridFurtherOptionsToolPanelParams } from './IAggridFurtherOptionsToolPanelParams';
import { ConfirmModalComponent } from '../../../../core/components/confirm-modal/confirm-modal.component';
import { AggridFurtherOptions } from './AggridFurtherOptions';

@Component({
  selector: 'app-aggrid-further-options-tool-panel',
  standalone: true,
  imports: [
    CommonModule,
    MatListModule
  ],
  templateUrl: './aggrid-further-options-tool-panel.component.html',
  styleUrl: './aggrid-further-options-tool-panel.component.scss'
})
export class AggridFurtherOptionsToolPanelComponent {

  params:IAggridFurtherOptionsToolPanelParams | undefined;
  constructor(public dialog: MatDialog) {

  }
  refresh(params: IAggridFurtherOptionsToolPanelParams): boolean | void {
    this.params = params;
    return true;
  }
  getState?() {
  }

  agInit(params: IAggridFurtherOptionsToolPanelParams): void {
    this.params = params;
    this.params.api.addEventListener(
      'modelUpdated',() => {
        if(this.params) {
          this.params = this.params;
        }
      }
    );
  }

  optionEvent(optionSelected:string) {
      switch(optionSelected) {
        case AggridFurtherOptions.COPYMENUITEMNAME: {
          this.params?.api.copySelectedRowsToClipboard();
          break;
        }
        case AggridFurtherOptions.COPYWITHHEADERSMENUITEMNAME: {
          this.params?.api.copySelectedRowsToClipboard({
            includeHeaders: true,
            includeGroupHeaders: true
          });
          break;
        }
        case AggridFurtherOptions.EXCELEXPORTSELECTEDROWSMENUITEMNAME:{

          if(this.params) {
            this.params.excelExportParams.onlySelected = true;
            if(this.chkRowsAreSelectedForBulks("",`Please select atleast one row to export selected rows to an excel file.`)) {
              this.params?.api.exportDataAsExcel(this.params.excelExportParams);
            }
          }

          break;
        }
        case AggridFurtherOptions.EXCELEXPORTMENUITEMNAME: {

          if(this.params) {
            this.params.excelExportParams.onlySelected = false;
            this.params?.api.exportDataAsExcel(this.params.excelExportParams);
          }


          break;
        }
        case AggridFurtherOptions.ACTIVATEVENDORSCONTEXTMENUITEMNAME: {
          this.bulkChgStatusOfVendor(true);
          break;
        }
        case AggridFurtherOptions.DEACTIVATEVENDORSCONTEXTMENUITEMNAME: {
          this.bulkChgStatusOfVendor(false);
          break;
        }
        case AggridFurtherOptions.ACTIVATEVENDORUSERSCONTEXTMENUITEMNAME: {
          this.bulkChgActiveOfVendorUser(true);
          break;
        }
        case AggridFurtherOptions.DEACTIVATEVENDORUSERSCONTEXTMENUITEMNAME: {
          this.bulkChgActiveOfVendorUser(false);
          break;
        }
        default: {
          break;
        }
      }
  }

  chkRowsAreSelectedForBulks(confirmModalTitle:string,confirmModalMsg:string) {
    if(this.params) {
      let selectedVendorRows = this.params.api.getSelectedRows();
      if(selectedVendorRows.length < 1) {
          this.dialog.open(ConfirmModalComponent, {
            data: {
              title:confirmModalTitle,
              message:confirmModalMsg
            }
          });
          return false;
      }
      else {
        return true;
      }
    }
    return false;
  }

  bulkChgStatusOfVendor(activate:boolean) {
    if(this.chkRowsAreSelectedForBulks("",`Please select atleast one vendor to bulk ${activate ? 'activate':'deactivate'} selected vendors.`)&& this.params) {
      const dialogRef = this.dialog.open(ConfirmModalComponent, {
        data: {
          title:"",
          message:`Are you sure you would like proceed to ${activate ? 'activate':'deactivate'} selected vendors?`
        }
      });

      dialogRef.afterClosed().subscribe(confirmButtonSelected => {
        if(confirmButtonSelected) {
          this.params?.api.setGridOption("loading", true);
          if((this.params?.api as any).furtherOptionsToolPanelBulkUpdateVendorsStatus != undefined) {
            (this.params?.api as any).furtherOptionsToolPanelBulkUpdateVendorsStatus.next(activate);
          }
        }
      });
    }
  }

  bulkChgActiveOfVendorUser(activate:boolean) {
    if(this.chkRowsAreSelectedForBulks("",`Please select atleast one vendor user to bulk ${activate ? 'activate':'deactivate'} selected vendor users.`)&& this.params) {
      const dialogRef = this.dialog.open(ConfirmModalComponent, {
        data: {
          title:"",
          message:`Are you sure you would like proceed to ${activate ? 'activate':'deactivate'} selected vendor users?`
        }
      });

      dialogRef.afterClosed().subscribe(confirmButtonSelected => {
        if(confirmButtonSelected) {
          this.params?.api.setGridOption("loading", true);
          if((this.params?.api as any).furtherOptionsToolPanelBulkUpdateVendorUsersStatus != undefined) {
            (this.params?.api as any).furtherOptionsToolPanelBulkUpdateVendorUsersStatus.next(activate);
          }
        }
      });
    }
  }



}
