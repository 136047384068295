import { Routes } from '@angular/router';
import { RegionsComponent } from './regions/regions.component';
import { MsalGuard } from '@azure/msal-angular';
import { ItemTypeManagementComponent } from './item-type-management.component';

export const itemTypeRoutes: Routes = [
  {
    path: 'item-type-management',
    component: ItemTypeManagementComponent,
    canActivate: [MsalGuard],
    // children: [
    //   {
    //     path: 'regions',
    //     component: RegionsComponent
    //   },
    // ],
  },
];
