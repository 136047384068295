import { Component, inject, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ISizeCategory } from '../../../../core/models/ISizeCategory';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { IDefaultSize } from '../../../../core/models/IDefaultSize';
import { MatDialogModule } from '@angular/material/dialog';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { CommonModule } from '@angular/common';
import { DefaultSizeStore } from '../store/sizecategorystore';

@Component({
  selector: 'app-default-size-dialog',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatOptionModule,
    MatButtonModule,
    MatDialogModule,
    MatSelectModule,
    CommonModule
  ],
  templateUrl: './default-size-dialog.component.html',
  styleUrls: ['./default-size-dialog.component.scss']
})
export class DefaultSizeDialogComponent {
  defaultSizeForm: FormGroup;
  editMode: boolean;
  isSaving = false;

  // Inject the store to access filteredCategories and other necessary data
  store = inject(DefaultSizeStore);
  toastrService = inject(ToastrService);

  constructor(
    public dialogRef: MatDialogRef<DefaultSizeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { defaultsize: IDefaultSize; editMode: boolean },
    private fb: FormBuilder
  ) {
    this.editMode = data.editMode;

    // Initialize the form with default size data, ensuring sizeCategoryId is included
    this.defaultSizeForm = this.fb.group({
      sizeCategoryId: [data.defaultsize.sizeCategoryId, [Validators.required]],
      sizeCode: [data.defaultsize.sizeCode, [Validators.required, Validators.maxLength(300)]],
    });
  }

  onSave(): void {
    if (this.defaultSizeForm.valid) {
      const payload: IDefaultSize = {
        id: this.editMode ? this.data.defaultsize.id : undefined,
        ...this.defaultSizeForm.value,
        regionId: this.store.selectedRegionId(),
        itemTypeGroupId: this.store.selectedItemTypeGroupId(),
      };

      if (this.store.isDuplicateSizeCode(payload)) {
        this.toastrService.error('Size code already exists');
        this.isSaving = false;
      } else if (this.editMode) {
        this.store.updateSize(payload as IDefaultSize).subscribe({
          next: () => {
            this.dialogRef.close(payload);
          },
          error: (error) => {
            if (error.status === 500) {
              this.toastrService.error('Failed to update size', 'Error');
            } else {
              this.toastrService.error(error.error, 'Error');
            }
            this.isSaving = false;
          }
        });
      } else {
        this.store.createSize(payload as IDefaultSize).subscribe({
          next: (newSize) => {
            this.dialogRef.close(newSize);
          },
          error: (error) => {
            if (error.status === 500) {
              this.toastrService.error('Failed to create size', 'Error');
            } else {
              this.toastrService.error(error.error, 'Error');
            }
            this.isSaving = false;
          }
        });
      }
    }
  }

  onDelete(): void {
    const defaultSize = this.data.defaultsize;

    if (defaultSize.id) {
      this.store.deleteSize(defaultSize.id).subscribe({
        next: () => {
          this.toastrService.success('Size deleted successfully', 'Success');
          this.store.removeSizeFromStore(defaultSize.id);
          this.dialogRef.close(defaultSize);
        },
        error: (error) => {
          if (error.status === 500) {
            this.toastrService.error('Failed to delete size', 'Error');
          } else {
            this.toastrService.error(error.error, 'Error');
          }
        }
      });
    }
  }

  onCancel(): void {
    this.dialogRef.close();
  }
}
