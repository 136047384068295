<div class="container">
  <h6 *ngIf="store.selectedAttributeName() !== 'No Attribute Selected'; else noAttribute">
    Values for {{ store.selectedAttributeName() }}
  </h6>
  <ng-template #noAttribute>
    <h6>No Attribute Selected</h6>
  </ng-template>
  <div class="d-flex">
    <button
      type="button"
      class="btn btn-primary btn-sm me-2"
      (click)="createAttributeValue()"
      [disabled]="!store.selectedItemTypeAttributeId()"
      aria-disabled="true">
      Create Attribute Value
    </button>
    <button
      *ngIf="store.selectedItemTypeAttributeId() && store.selectedAttributeValueId()"
      type="button"
      class="btn btn-warning btn-sm"
      (click)="editAttributeValue()">
      Edit Attribute Value
    </button>
  </div>
  <br />

  <!-- Alert when no attribute values exist -->
  <div *ngIf="store.attributeValues().length === 0" class="alert alert-warning" role="alert">
    No Values exist.
  </div>

  <!-- Attribute Value List -->
  <div *ngIf="store.attributeValues().length > 0" class="itemtypeattributes">
    <ul class="list-group">
      <li
        *ngFor="let value of store.attributeValues()"
        class="list-group-item d-flex justify-content-between align-items-center"
        [ngClass]="{ 'active': value.id === store.selectedAttributeValueId() }"
        [attr.aria-selected]="value.id === store.selectedAttributeValueId()"
        (click)="selectAttributeValue(value.id)">
        <span class="list-group-item-text">{{ value.valueName }}</span>
        <span *ngIf="value.attributeValueAliases?.length !== 0" class="badge bg-success">Has Information</span>
        <span
          class="text-danger"
          (click)="removeAttributeValue(store.selectedItemTypeAttributeId() ?? 0, value.id); $event.stopPropagation()"
        >
            ×
        </span>
      </li>
    </ul>
  </div>
</div>
