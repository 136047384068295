import { NgFor, NgIf } from '@angular/common';
import { Component, inject } from '@angular/core';
import { DefaultSizeStore } from '../store/sizecategorystore';
import { ISizeCategory } from '../../../../core/models/ISizeCategory';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import { SizeCategoryDialogComponent } from '../size-category-dialog/size-category-dialog.component';

@Component({
  selector: 'app-size-category-list',
  standalone: true,
  imports: [NgIf, NgFor],
  templateUrl: './size-category-list.component.html',
  styleUrl: './size-category-list.component.scss'
})
export class SizeCategoryListComponent {

  store = inject(DefaultSizeStore);
  toastrService = inject(ToastrService);
  dialog = inject(MatDialog);

  // Open the dialog to create or edit size category
  openSizeCategoryDialog(editMode: boolean, sizeCategory?: ISizeCategory) {
    const dialogRef = this.dialog.open(SizeCategoryDialogComponent, {
      width: '600px',
      data: {
        sizeCategory: sizeCategory ?? { id: 0, categoryName: '', itemTypeGroupId: this.store.selectedItemTypeGroupId },
        editMode
      }
    });

    dialogRef.afterClosed().subscribe((result: ISizeCategory) => {
      if (result) {
        if (editMode) {
          this.store.patchCategory(result);
        } else {
          this.store.addCategory(result);
        }
      }

      // Refresh the list after adding or patching
      this.store.loadData();
    });
  }

  createSizeCategory() {
    this.openSizeCategoryDialog(false);
  }

  editSizeCategoryDescription(sizeCategory: ISizeCategory) {
    this.openSizeCategoryDialog(true, sizeCategory);
  }

}
