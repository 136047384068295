
<ng-container *ngIf="reactiveFormGroup && reactiveFormControlName && inputElementType" [formGroup]="reactiveFormGroup">
    <ng-container *ngIf="inputElementType.toUpperCase() == 'TEXT'||inputElementType.toUpperCase() == 'TEXTAREA'">
        <div *ngIf="inputElementReadOnly" class="my-2">
            <label>{{inputElementLabel}}</label>
            <div *ngIf="reactiveFormGroup.controls[reactiveFormControlName].value" class="view-only-form-item-word-wrap">{{reactiveFormGroup.controls[reactiveFormControlName].value}}</div>
            <div *ngIf="!reactiveFormGroup.controls[reactiveFormControlName].value">(not set)</div>
        </div>
        <mat-form-field *ngIf="!inputElementReadOnly" appearance="outline" class="w-100">
            <mat-label>{{inputElementLabel}}</mat-label>
            <input *ngIf="inputElementType.toUpperCase() == 'TEXT'" matInput formControlName="{{reactiveFormControlName}}" (change)="onInputTextChangeEvent()"/>
            <textarea *ngIf="inputElementType.toUpperCase() == 'TEXTAREA'" matInput formControlName="{{reactiveFormControlName}}" (change)="onInputTextChangeEvent()"></textarea>
            <mat-error>
                <ng-container *ngTemplateOutlet="validationErrorMsgTemplate">
                </ng-container>
            </mat-error>
        </mat-form-field>
    </ng-container>
    <ng-container *ngIf="inputElementType.toUpperCase() == 'CHECKBOX'">
        <mat-checkbox formControlName="{{reactiveFormControlName}}" [disabled]="inputElementReadOnly" >{{inputElementLabel}}</mat-checkbox>
    </ng-container>
    <ng-container *ngIf="inputElementType.toUpperCase() == 'SLIDETOGGLE'">
        <mat-slide-toggle [disabled]="inputElementReadOnly" [checked]="reactiveFormGroup.controls[reactiveFormControlName].value" (click)="changeSlideToggleControlValue(reactiveFormControlName)">{{inputElementLabel}}</mat-slide-toggle>
    </ng-container>
    <ng-container *ngIf="inputElementType.toUpperCase() == 'DATE'">
        <div *ngIf="inputElementReadOnly" class="my-2">
            <label>{{inputElementLabel}}</label>
            <div *ngIf="reactiveFormGroup.controls[reactiveFormControlName].value" class="view-only-form-item-word-wrap">{{reactiveFormGroup.controls[reactiveFormControlName].value|date:DATETIMEFORMAT}}</div>
            <div *ngIf="!reactiveFormGroup.controls[reactiveFormControlName].value">(not set)</div>
        </div>
        <mat-form-field *ngIf="!inputElementReadOnly" appearance="outline" class="w-100">
            <mat-label>{{inputElementLabel}}</mat-label>
            <input matInput [matDatepicker]="picker" formControlName="{{reactiveFormControlName}}">
            <mat-hint>MM/DD/YYYY</mat-hint>
            <mat-datepicker-toggle matIconSuffix [for]="picker" >
                <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
            <mat-error>
                <ng-container *ngTemplateOutlet="validationErrorMsgTemplate">
                </ng-container>
            </mat-error>
        </mat-form-field>
    </ng-container>
    <ng-container *ngIf="inputElementType.toUpperCase() == 'SELECT' && inputElementSelectItemLabelPropertyName">
        <div *ngIf="inputElementReadOnly" class="my-2">
            <label>{{inputElementLabel}}</label>
            <div *ngIf="reactiveFormGroup.controls[reactiveFormControlName].value && reactiveFormGroup.controls[reactiveFormControlName].value[inputElementSelectItemLabelPropertyName]" class="view-only-form-item-word-wrap">{{reactiveFormGroup.controls[reactiveFormControlName].value[inputElementSelectItemLabelPropertyName]}}</div>
            <div *ngIf="!reactiveFormGroup.controls[reactiveFormControlName].value">(not set)</div>
        </div>
        <mat-form-field *ngIf="!inputElementReadOnly && inputElementSelectCompareWithFunc" appearance="outline" class="w-100">
            <mat-label>{{inputElementLabel}}</mat-label>
            <mat-select placeholder="Select {{inputElementLabel}}" formControlName="{{reactiveFormControlName}}" [compareWith]="inputElementSelectCompareWithFunc">
                <mat-option *ngFor="let selectItem of inputElementSelectItems" [value]="selectItem">{{selectItem[inputElementSelectItemLabelPropertyName]}}</mat-option>
            </mat-select>
            <mat-error>
                <ng-container *ngTemplateOutlet="validationErrorMsgTemplate">
                </ng-container>
            </mat-error>
        </mat-form-field>
    </ng-container>
    <ng-template #validationErrorMsgTemplate>
        <ng-container *ngIf="reactiveFormGroup.controls[reactiveFormControlName].errors?.['required']">
            Please enter a {{inputElementLabel}}.
        </ng-container>
        <ng-container *ngIf="reactiveFormGroup.controls[reactiveFormControlName].errors?.['maxlength']">
            Please enter a {{inputElementLabel}} that does not exceed {{reactiveFormGroup.controls[reactiveFormControlName].errors?.maxlength.requiredLength}} characters.
        </ng-container>
        <ng-container *ngIf="reactiveFormGroup.controls[reactiveFormControlName].errors?.['pattern']">
            Please enter a valid phone number.
        </ng-container>
        <ng-container *ngIf="reactiveFormGroup.controls[reactiveFormControlName].errors?.['email']">
            Please enter a valid email address.
        </ng-container>
    </ng-template>
   
 
</ng-container>

