<div class="d-flex flex-column-reverse h-100 overflow-y-auto">
    @for (message of messages(); track message.id) {
    <div class="my-1 w-max-90" [class]="alertContainerClasses(message)">
        <div class="alert mb-0" [class]="alertClasses(message)">
            <h6 class="alert-heading">{{ message.sender }} <small>- {{ message.senderRole }}</small></h6>
            <p class="mb-0">
                {{ message.text }}
            </p>
            <div class="d-flex">
                <small class="ms-auto">{{ message.sentAt | date:'shortTime' }}</small>
            </div>
        </div>
    </div>
    @if (showMessageDate($index)) {
        <span class="badge rounded-pill text-bg-dark mx-auto mt-1">
            {{ message.sentAt | date:'mediumDate' }}
        </span>
    }
}
</div>