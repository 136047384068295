import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, inject } from '@angular/core';
import { SecondaryNavbarComponent } from '@tarifftel/components-library';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-size-management',
  standalone: true,
  imports: [CommonModule, SecondaryNavbarComponent],
  templateUrl: './size-management.component.html',
  styleUrl: './size-management.component.scss'
})
export class SizeManagementComponent {
  readonly MATERIALS: 'materials' = 'materials';
  readonly MATERIALCATEGORIES: 'materialcategories' = 'materialcategories';

  // selectedItem: 'materials' | 'materialcategories' = this.MATERIALS; // Default to 'materials'
  // searchTerm: string = '';

  // cdr = inject(ChangeDetectorRef);
  // store = inject(MaterialCategoryStore);
  // toastrService = inject(ToastrService);

  // private searchSubject = new Subject<string>();

  // ngOnInit() {
  //   this.store.clearCache();
  //   this.store.loadData();

  //   // Only set initial state for `selectedItemTypeGroupId` if the list is 'materials'
  //   if (this.store.selectedList() === this.MATERIALS && this.store.selectedItemTypeGroupId() === null) {
  //     this.store.selectFirstItemTypeGroup();
  //   }

  //   this.store.setSearchTerm(this.searchTerm);

  //   this.cdr.detectChanges();
  // }

  // onSearchTermChange(term: string) {
  //   this.searchTerm = term;
  //   this.searchSubject.next(term);
  // }

  // resetFilters() {
  //   this.searchTerm = '';
  //   this.store.setSearchTerm(''); // Update the store's search term
  //   this.toastrService.info('Filters have been reset', 'Reset');
  // }

  // forceRefresh() {
  //   this.store.clearCache();
  //   this.store.loadData();
  //   this.toastrService.info('Data has been refreshed', 'Refreshed');
  // }

  // // Updated method to switch between materials and material categories
  // showTable(item: 'materials' | 'materialcategories') {
  //   this.store.showTable(item);

  //   // Only select the first Item Type Group when switching to 'materials'
  //   if (item === this.MATERIALS && this.store.selectedItemTypeGroupId() === null) {
  //     this.store.selectFirstItemTypeGroup();
  //   }
  // }
}
