import { patchState, signalStore, withComputed, withHooks, withMethods, withState } from "@ngrx/signals";
import { computed, inject } from "@angular/core";
import { forkJoin, of, switchMap, map, Observable, catchError } from "rxjs";
import { IItemType } from "../../../../../core/models/IItemType";
import { IRegion } from "../../../../../core/models/IRegion";
import { ItemtypeGroupService } from "../../../../../core/services/itemtype-group.service";
import { LocalStorageService } from "../../../../../core/services/local-storage.service";
import { RegionService } from "../../../../../core/services/region.service";
import { IMaterial } from "../../../../../core/models/IMaterial";
import { IItemAttribute } from "../../../../../core/models/IItemAttribute";
import { IAttributeValue } from "../../../../../core/models/IAttributeValue";
import { MaterialService } from "../../../../../core/services/material.service";
import { ConstructionService } from "../../../../../core/services/construction.service";
import { IItemTypeGroup } from "../../../../../core/models/IItemTypeGroup";
import { IItemTypeAttributeValue } from "../../../../../core/models/IItemTypeAttributeValue";
import { ItemTypeAttributeService } from "../../../../../core/services/item-type-attribute.service";
import { ItemTypeService } from "../../../../../core/services/itemtype.service";
import { IConstruction } from "../../../../../core/models/IConstruction";
import { OrderService } from "../../../../../core/services/order.service";
import { TariffBookService, TariffData } from "../../../../../core/services/tariffbook.service";

const classifierStoreKey = 'tarifftel_classifier';
const classifierTimestampKey = 'tarifftel_classifier_timestamp';
const CACHE_DURATION = 60 * 60 * 1000; // 1 hour in milliseconds

type ClassifierState = {
  materials: IMaterial[];
  constructions: IConstruction[];
  regions: IRegion[];
  countryOfOrigins: IRegion[];
  attributes: IItemAttribute[];
  attributeValues: IAttributeValue[];
  itemTypeAttributeValues: IItemTypeAttributeValue[];
  itemTypes: IItemType[];
  itemTypeGroups: IItemTypeGroup[];
  originOfMaterials: IRegion[];
  originOfManufacture: IRegion[];
  selectedRegionId: number | null;
  selectedConstructionCategoryId: number | null;
  selectedItemTypeGroupId: number | null;
  selectedItemTypeAttributeId: number | null;
  selectedAttributeValueId: number | null;
  filteredMaterials: IMaterial[];
  allAttributeValuesMap: Record<number, IAttributeValue[]>;
  selectedValuesMap: Record<number, number | null>;
  loading: boolean;
  selectedDescription: string;
  selectedItemTypeId: number | any;
  selectedTariffCode: string;
  ukTariffData: TariffData | null;
  usTariffData: TariffData | null;
  euTariffData: TariffData | null;
};

const initialState: ClassifierState = {
  materials: [],
  constructions: [],
  regions: [],
  countryOfOrigins: [],
  attributes: [],
  attributeValues: [],
  itemTypeAttributeValues: [],
  itemTypes: [],
  itemTypeGroups: [],
  originOfMaterials: [],
  originOfManufacture: [],
  selectedRegionId: null,
  selectedConstructionCategoryId: null,
  selectedItemTypeGroupId: null,
  selectedItemTypeAttributeId: null,
  selectedAttributeValueId: null,
  filteredMaterials: [],
  allAttributeValuesMap: {},
  selectedValuesMap: {},
  loading: true,
  selectedDescription: '',
  selectedItemTypeId: null,
  selectedTariffCode: '',
  ukTariffData: null,
  usTariffData: null,
  euTariffData: null
};

export const classificationStore = signalStore(
  { providedIn: 'root' },
  withState(initialState),

  withComputed(
    ({
      itemTypes,
      itemTypeAttributeValues,
      attributes,
      allAttributeValuesMap,
      selectedItemTypeAttributeId,
      selectedValuesMap,
      selectedDescription,
      itemTypeGroups,
      selectedItemTypeGroupId,
      selectedItemTypeId,
      selectedTariffCode
    }) => {
      const filterItemTypes = (
        types: IItemType[],
        itav: IItemTypeAttributeValue[],
        selectedMap: Record<number, number | null>,
        descFilter: string
      ) => {
        let intermediateTypes = types;
        if (descFilter) {
          intermediateTypes = intermediateTypes.filter(t =>
            t.description.toLowerCase().includes(descFilter)
          );
        }

        const filters = Object.entries(selectedMap)
          .filter(([_, value]) => value !== null)
          .map(([attrId, value]) => ({
            attributeId: Number(attrId),
            valueId: value
          }));

        if (filters.length === 0) {
          return intermediateTypes;
        }

        return intermediateTypes.filter(it => {
          // Ensure the ItemType has at least one attribute configured
          const hasConfiguredAttributes = itav.some(attr => attr.itemTypeId === it.id);
          if (!hasConfiguredAttributes) return false;
      
          // First condition: Check if there exists a chosen attribute that does NOT match an existing attribute for the item type
          const missingChosenAttribute = filters.some(chosen =>
            !itav.some(attr =>
              attr.itemTypeId === it.id &&
              attr.itemAttributeId === chosen.attributeId &&
              attr.attributeValueId === chosen.valueId
            ) && itav.some(attr =>
              attr.itemTypeId === it.id &&
              attr.itemAttributeId === chosen.attributeId
            )
          );
      
          // Second condition: Check if there exists an item attribute that has no corresponding chosen attribute
          const unmatchedItemAttribute = itav.some(attr =>
            attr.itemTypeId === it.id &&
            !filters.some(chosen =>
              chosen.attributeId === attr.itemAttributeId &&
              chosen.valueId === attr.attributeValueId
            )
          );

          return !missingChosenAttribute || !unmatchedItemAttribute;
        });
      };

      const filteredItemTypes = computed(() => {
        const types = itemTypes();
        const itav = itemTypeAttributeValues();
        const selectedMap = selectedValuesMap();
        const descFilter = selectedDescription().trim().toLowerCase();

        return filterItemTypes(types, itav, selectedMap, descFilter);
      });

      const visibleAttributes = computed(() => {
        const types = itemTypes();
        const itav = itemTypeAttributeValues();
        const selectedMap = selectedValuesMap();
        const descFilter = selectedDescription().trim().toLowerCase();

        const filteredTypes = filterItemTypes(types, itav, selectedMap, descFilter);

        const attributeUsageCount: Record<number, number> = {};

        filteredTypes.forEach((itemType) => {
          itav.forEach((itavEntry) => {
            if (itavEntry.itemTypeId === itemType.id) {
              if (!attributeUsageCount[itavEntry.itemAttributeId]) {
                attributeUsageCount[itavEntry.itemAttributeId] = 0;
              }
              attributeUsageCount[itavEntry.itemAttributeId]++;
            }
          });
        });

        return attributes()
          .filter((attr) => {
            return filteredTypes.some((itemType) =>
              itav.some(
                (itavEntry) =>
                  itavEntry.itemTypeId === itemType.id &&
                  itavEntry.itemAttributeId === attr.id
              )
            );
          })
          .sort((a, b) => {
            const aCompleted = selectedMap[a.id] ? 1 : 0;
            const bCompleted = selectedMap[b.id] ? 1 : 0;
            if (aCompleted !== bCompleted) {
              return bCompleted - aCompleted; // show completed steps on top
            }
            return (attributeUsageCount[b.id] || 0) - (attributeUsageCount[a.id] || 0);
          });
      });

      const completedSteps = computed(() => {
        const selectedMap = selectedValuesMap();
        return visibleAttributes().map(attr => ({
          attributeId: attr.id,
          completed: selectedMap[attr.id] !== null
        }));
      });

      const visibleAttributesWithStep = computed(() => {
        const visibleAttrs = visibleAttributes();
        return visibleAttrs.map((attr, index) => ({
          ...attr,
          stepLabel: `Step ${index + 1} - ${attr.attributeName}`,
        }));
      });

      const visibleValuesForSelectedAttribute = computed(() => {
        const currentAttrId = selectedItemTypeAttributeId();
        if (currentAttrId == null) {
          return [];
        }

        const allValues = allAttributeValuesMap()[currentAttrId] || [];
        const itav = itemTypeAttributeValues();
        const currentlyFilteredTypes = filteredItemTypes();

        const validValueIds = new Set<number>();
        for (const t of currentlyFilteredTypes) {
          for (const entry of itav) {
            if (entry.itemTypeId === t.id && entry.itemAttributeId === currentAttrId) {
              validValueIds.add(entry.attributeValueId);
            }
          }
        }
        // return allValues.filter(v => validValueIds.has(v.id));
        return allValues
        .filter(v => validValueIds.has(v.id))
        .map(v => ({
          ...v,
          hasClarifyingInfo: !!v.description?.trim() // Assuming 'description' is used as clarifying info
        }));
      });

      const isFiltered = computed(() => {
        const map = selectedValuesMap();
        return Object.values(map).some(val => val !== null);
      });

      const selectedClarifyingInformation = computed(() => {
        const selectedGroupId = selectedItemTypeGroupId();
        const groups = itemTypeGroups();
        const selectedGroup = groups.find(group => group.id === selectedGroupId);
        return selectedGroup?.clarifyingInformation || '';
      });

      const isItemTypeSelected = computed(() => selectedItemTypeId() !== null);


      return {
        filteredItemTypes,
        visibleAttributes,
        visibleAttributesWithStep,
        visibleValuesForSelectedAttribute,
        isFiltered,
        isItemTypeSelected,
        completedSteps,
        selectedClarifyingInformation, // Exposed here
      };
    }
  ),


  withMethods((store) => {
    const itemTypeAttributeService = inject(ItemTypeAttributeService);
    const itemTypeGroupService = inject(ItemtypeGroupService);
    const itemTypeService = inject(ItemTypeService);
    const materialService = inject(MaterialService);
    const constructionService = inject(ConstructionService);
    const regionService = inject(RegionService);
    const purchaseOrderService = inject(OrderService);
    const localStorageService = inject(LocalStorageService);
    const tariffService = inject(TariffBookService)

    return {
      loadData() {
        patchState(store, { loading: true });

        const dataFromStorage = localStorageService.getItem(classifierStoreKey);
        const timestamp = parseInt(localStorageService.getItem(classifierTimestampKey) || '0', 10);
        const now = Date.now();

        // Check if data exists and is valid
        const cachedData = dataFromStorage ? JSON.parse(dataFromStorage) : {};
        const hasValidCache = cachedData.itemTypeGroups && cachedData.itemTypeGroups.length > 0;
        const cacheExpired = now - timestamp >= CACHE_DURATION;

        if (hasValidCache && !cacheExpired) {
          patchState(store, { ...cachedData, loading: false });
        } else {
          forkJoin({
            itemTypeGroups: itemTypeGroupService.getActiveItemTypeGroups(),
            constructions: constructionService.getConstructions(),
            regions: regionService.getActiveRegions(),
            countryOfOrigins: regionService.getRegions(),
            materials: materialService.getMaterials()
          }).subscribe({
            next: ({ constructions, regions, itemTypeGroups, materials, countryOfOrigins }) => {
              // If API returned empty, ensure local storage is cleared
              if (!itemTypeGroups.length || !regions.length) {
                localStorageService.removeItem(classifierStoreKey);
                localStorageService.removeItem(classifierTimestampKey);
              }

              patchState(store, {
                constructions,
                regions,
                countryOfOrigins,
                itemTypeGroups,
                materials,
                loading: false
              });

              localStorageService.setItem(classifierStoreKey, JSON.stringify({
                itemTypeGroups, regions, constructions, materials, countryOfOrigins
              }));
              localStorageService.setItem(classifierTimestampKey, now.toString());
            },
            error: (err) => {
              patchState(store, { loading: false });
            }
          });
        }
      },

      fetchTariffData(tariffCode: string, regionId: number) {
        // console.log('Fetching Tariff Data for:', tariffCode, 'Region ID:', regionId);

        patchState(store, { loading: true });

        let fetchObservable: Observable<TariffData>;
        let stateField: keyof ClassifierState;

        switch (regionId) {
          case 159: // UK
            fetchObservable = tariffService.getUKTariffCodeData(tariffCode);
            stateField = 'ukTariffData';
            break;
          // case 2: // US (160)
          //   fetchObservable = tariffService.getUSTariffCodeData(tariffCode);
          //   stateField = 'usTariffData';
          //   break;
          case 45: // EU
            fetchObservable = tariffService.getEUTariffCodeData(tariffCode);
            stateField = 'euTariffData';
            break;
          default:
            console.warn('Unsupported region ID:', regionId);
            patchState(store, { loading: false });
            return; // Exit early if unsupported region
        }

        fetchObservable.subscribe({
          next: (data) => {
            console.log(`Tariff Data received for region ${regionId}:`, data);
            patchState(store, { [stateField]: data, loading: false });
          },
          error: (error) => {
            console.error('Error fetching tariff data:', error);
            patchState(store, { [stateField]: null, loading: false });
          }
        });
      },

      loadAttributesWithValues(itemTypeGroupId: number) {
        patchState(store, { loading: true });

        itemTypeAttributeService
          .getAttributesByItemTypeGroupId(itemTypeGroupId)
          .pipe(
            switchMap((fetchedAttributes) => {
              if (!fetchedAttributes.length) {
                return of({ fetchedAttributes, combined: [] });
              }
              const requests = fetchedAttributes.map(attr =>
                itemTypeAttributeService
                  .getItemTypeAttributeValuesByItemTypeAttributeId(attr.id)
                  .pipe(map(vals => ({ attr, vals })))
              );
              return forkJoin(requests).pipe(
                map(combinedResults => ({ fetchedAttributes, combined: combinedResults }))
              );
            })
          )
          .subscribe({
            next: ({ fetchedAttributes, combined }) => {
              const usageMap: Record<number, Set<number>> = {};
              const allMappings = store.itemTypeAttributeValues();
              for (const mapping of allMappings) {
                const attrId = mapping.itemAttributeId;
                if (!usageMap[attrId]) {
                  usageMap[attrId] = new Set<number>();
                }
                usageMap[attrId].add(mapping.itemTypeId);
              }

              combined.forEach(item => {
                const attrId = item.attr.id;
                const usageCount = usageMap[attrId]?.size || 0;
                (item as any).usageCount = usageCount;
              });

              combined.sort((a, b) => {
                const diff = (b as any).usageCount - (a as any).usageCount;
                if (diff !== 0) return diff;
                return a.attr.attributeName.localeCompare(b.attr.attributeName);
              });

              const sortedAttributes = combined.map(item => item.attr);
              const valueMap: Record<number, IAttributeValue[]> = {};
              combined.forEach(({ attr, vals }) => {
                valueMap[attr.id] = vals;
              });

              const newSelectedValuesMap: Record<number, number | null> = {};
              sortedAttributes.forEach(attr => {
                newSelectedValuesMap[attr.id] = null;
              });

              patchState(store, {
                attributes: sortedAttributes,
                allAttributeValuesMap: valueMap,
                selectedValuesMap: newSelectedValuesMap,
                loading: false
              });

              if (combined.length > 0) {
                const first = combined[0];
                this.setSelectedAttributeId(first.attr.id);
                patchState(store, { attributeValues: first.vals });
                this.setSelectedAttributeValueId(null);
              } else {
                this.setSelectedAttributeId(null);
                patchState(store, { attributeValues: [] });
              }
            },
            error: (error) => {
              console.error('Failed to load attributes + values', error);
              patchState(store, { loading: false });
            }
          });
      },

      loadItemTypesAndMappings(itemTypeGroupId: number, regionId: number) {
        patchState(store, { loading: true });

        forkJoin([
          itemTypeService.getItemTypesByItemTypeGroupIdAndRegionId(itemTypeGroupId, regionId),
          itemTypeAttributeService.getItemTypeAttributeValuesByItemTypeGroupId(itemTypeGroupId)
        ]).subscribe({
          next: ([itemTypes, mapping]) => {
            itemTypes.sort((a, b) => a.description.localeCompare(b.description));

            patchState(store, {
              itemTypes,
              itemTypeAttributeValues: mapping,
              loading: false
            });
          },
          error: (err) => {
            patchState(store, { loading: false });
          }
        });
      },

      handleGroupAndRegionSelect(itemTypeGroupId: number, regionId: number) {
        if (!store.itemTypeGroups().length || !store.regions().length) {
          this.clearCache();
          this.loadData();
          return;
        }

        patchState(store, {
          selectedItemTypeGroupId: itemTypeGroupId,
          selectedRegionId: regionId
        });

        this.setSelectedAttributeId(null);
        this.setSelectedAttributeValueId(null);
        this.loadAttributesWithValues(itemTypeGroupId);
        if (itemTypeGroupId && regionId) {
          this.loadItemTypesAndMappings(itemTypeGroupId, regionId);
        }
      },

      selectAttributeAndLoadValues(attributeId: number) {
        this.setSelectedAttributeId(attributeId);
        const allValuesMap = store.allAttributeValuesMap();
        const vals = allValuesMap[attributeId] || [];
        patchState(store, { attributeValues: vals });
        const chosenValue = store.selectedValuesMap()[attributeId] ?? null;
        this.setSelectedAttributeValueId(chosenValue);
      },

      setSelectedAttributeValueId(id: number | null) {
        patchState(store, { selectedAttributeValueId: id });
        const currentAttrId = store.selectedItemTypeAttributeId();
        if (currentAttrId !== null) {
          const oldMap = store.selectedValuesMap();
          patchState(store, {
            selectedValuesMap: {
              ...oldMap,
              [currentAttrId]: id
            }
          });
        }
      },

      setSelectedAttributeId(id: number | null) {
        patchState(store, { selectedItemTypeAttributeId: id });
      },

      setSelectedDescription(description: string) {
        patchState(store, { selectedDescription: description });
      },

      setSelectedItemType(itemTypeId: number | null) {
        patchState(store, { selectedItemTypeId: itemTypeId });
      },

      setSelectedTariffCode(tariffCode: string) {
        patchState(store, { selectedTariffCode: tariffCode });
      },

      resetAttributeSelections() {
        const newMap: Record<number, number | null> = {};
        const allAttrs = store.attributes();
        allAttrs.forEach(attr => {
          newMap[attr.id] = null;
        });

        patchState(store, {
          selectedValuesMap: newMap,
          selectedItemTypeAttributeId: null,
          selectedAttributeValueId: null
        });

        if (allAttrs.length > 0) {
          const firstAttr = allAttrs[0];
          this.setSelectedAttributeId(firstAttr.id);
          patchState(store, {
            attributeValues: store.allAttributeValuesMap()[firstAttr.id] || []
          });
          this.setSelectedAttributeValueId(null);
        }
      },

      clearCache() {
        localStorageService.removeItem(classifierStoreKey);
        localStorageService.removeItem(classifierTimestampKey);
        patchState(store, {
          itemTypeGroups: [],
          materials: [],
          regions: [],
          attributes: [],
          attributeValues: [],
          itemTypeAttributeValues: [],
          itemTypes: [],
          originOfMaterials: [],
          originOfManufacture: [],
          selectedRegionId: null,
          selectedConstructionCategoryId: null,
          selectedItemTypeGroupId: null,
          selectedItemTypeAttributeId: null,
          selectedAttributeValueId: null,
          filteredMaterials: [],
          allAttributeValuesMap: {},
          selectedValuesMap: {},
          loading: false
        });
      },
      submitClassification(payload: {
          tariffCode: string;
          productCode: string;
          itemTypeId: number;
          orderLineId: number;
          description: string;
          basic_duty_rate: string | null;
          validity_start_date: string;
          vatRate: string;
        }): Observable<boolean> {

        patchState(store, { loading: true });

        return purchaseOrderService.submitClassification(payload).pipe(
          map(() => {
            patchState(store, { loading: false });
            return true;
          }),
          catchError((error: any) => {
            console.error("Error submitting classification", error);
            patchState(store, { loading: false });
            return of(false);
          })
        );
      }
    };
  }),
  withHooks({
    onInit(store) {
      store.loadData();
    }
  })
);
