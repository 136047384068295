import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'dateAgo',
    standalone: true // Declare as standalone if using standalone components
})
export class DateAgoPipe implements PipeTransform {
    transform(value: string): string {
        if (value) {
            const seconds = Math.floor((+new Date() - +new Date(value)) / 1000);
            if (seconds < 29) // less than 30 seconds ago will show as 'Just now'
                return 'Just now';

            const intervals = [
                { key: 'year', value: 31536000 },
                { key: 'month', value: 2592000 },
                { key: 'week', value: 604800 },
                { key: 'day', value: 86400 },
                { key: 'hour', value: 3600 },
                { key: 'minute', value: 60 },
                { key: 'second', value: 1 }
            ];
            let counter;
            
            for (let i = 0; i < intervals.length; i++) {
              let v = intervals[i].value;
              counter = Math.floor(seconds / v);
              if (counter > 0) {
                let ago = counter === 1 ? ' ago' : 's ago';// singular vs plural                
                return counter + ' ' + intervals[i].key + ago;
              }
            }
            return 'error'; // should never get here
        }
        return value;
    }
}