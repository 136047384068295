import { Component } from '@angular/core';
import { ExcelFileAttachBulkEntityLoadComponent } from '../excel-file-attach-bulk-entity-load/excel-file-attach-bulk-entity-load.component';
import { BreadcrumbComponent } from "../../../../core/components/breadcrumb/breadcrumb.component";

@Component({
  selector: 'app-bulk-add-vendors-attach-file',
  standalone: true,
  imports: [
    ExcelFileAttachBulkEntityLoadComponent,
    BreadcrumbComponent
],
  templateUrl: './bulk-add-vendors-attach-file.component.html',
  styleUrl: './bulk-add-vendors-attach-file.component.scss'
})
export class BulkAddVendorsAttachFileComponent {
  readonly VALIDFILEEXTENSIONS:string[] = [".xlsx"]; 
  readonly ACCEPTEDFILETYPES:string[] = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
  readonly VENDORENDPOINTSUFFIX:string = 'vendor/CreateVendorsFromExcelFile'
  readonly SAMPLEFILEPATH:string = "../../../assets/BulkLoadVendorsSampleFile.xlsx";
  readonly SAMPLEFILELINKNAME:string = "Vendor Sample File";
  readonly VIEWALLRECORDSLINKPATH:string = '/settings/vendor-management';
  readonly SAMPLEFILEMANDATORYFIELDS:string[] = ["Email","Company Name","Region","Active (true or false value)","Vendor Identifier"] 
  readonly SAMPLEFILENONMANDATORYFIELDS:string[] = [
    "Administrator Name",
    "Phone Number (The formatting of the excel cell for the Phone Number field must be set to text. This formatting is set in the sample file)",
    "Street Address",
    "City",
    "State",
    "Postal Code",
    "Country"
  ] 
}
