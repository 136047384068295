
<app-breadcrumb></app-breadcrumb>
<app-excel-file-attach-bulk-entity-load
    [validFileExtensions]="VALIDFILEEXTENSIONS"
    [acceptedFileTypes]="ACCEPTEDFILETYPES"
    [fileUploadendpointSuffix]="VENDORENDPOINTSUFFIX"
    [sampleFilePath]="SAMPLEFILEPATH"
    [sampleFileLinkName]="SAMPLEFILELINKNAME"
    [viewAllRecordsLinkPath]="VIEWALLRECORDSLINKPATH"
    [sampleFileMandatoryFields]="SAMPLEFILEMANDATORYFIELDS"
    [sampleFileNonMandatoryFieldsGuidelines]="SAMPLEFILENONMANDATORYFIELDS"
    [vendorMode]="true"
></app-excel-file-attach-bulk-entity-load>

