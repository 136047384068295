<hr />
<!-- Search and Filters Section -->
<div class="d-flex justify-content-between align-items-center mb-3">
  <!-- Search Field -->
  <div class="col-lg-6">
    <input
      type="text"
      class="form-control"
      placeholder="Search by Section, Chapter or Heading"
      [(ngModel)]="searchTerm"
      (ngModelChange)="onSearchTermChange($event)"
    />
  </div>

  <!-- Filters and Buttons -->
  <div class="col-lg-6 text-end">
    <div class="btn-group me-2" role="group" aria-label="Filter Buttons">
      <button
        [ngClass]="store.filter() === 'all' ? 'btn btn-primary' : 'btn btn-outline-primary'"
        (click)="store.changeFilter('all')"
        aria-label="Filter to show all"
      >
        All
      </button>
      <button
        [ngClass]="store.filter() === 'active' ? 'btn btn-primary' : 'btn btn-outline-primary'"
        (click)="store.changeFilter('active')"
        aria-label="Filter to show all active"
      >
        Active
      </button>
      <button
        [ngClass]="store.filter() === 'inactive' ? 'btn btn-primary' : 'btn btn-outline-primary'"
        (click)="store.changeFilter('inactive')"
        aria-label="Filter to show all inactive"
      >
        Not Active
      </button>
    </div>
    <!-- Reset Button -->
    <button class="btn btn-warning me-2" (click)="resetFilters()" aria-label="Reset filters and search">
      Reset
    </button>
    <!-- Refresh Button -->
    <button class="btn btn-secondary" (click)="forceRefresh()" aria-label="Refresh data">
      Refresh Data
    </button>
  </div>
</div>
<hr />

<!-- Left Column: Menu for selecting sections, chapters, or headings -->
<div class="grid-container">
  <div class="left-column">
    <div class="container-fluid">
      <div class="itemtypeattributes">
        <div>
          <h6>Select an Option Below:</h6>
          <ul class="list-group">
            <li
              class="list-group-item d-flex justify-content-between align-items-center"
              [ngClass]="{ 'active': selectedItem === SECTIONS }"
              (click)="showTable(SECTIONS)"
            >
              Sections
            </li>
            <li
              class="list-group-item d-flex justify-content-between align-items-center"
              [ngClass]="{ 'active': selectedItem === CHAPTERS }"
              (click)="showTable(CHAPTERS)"
            >
              Chapters
            </li>
            <li
              class="list-group-item d-flex justify-content-between align-items-center"
              [ngClass]="{ 'active': selectedItem === HEADINGS }"
              (click)="showTable(HEADINGS)"
            >
              Headings
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <!-- Right Column: Conditionally display the correct component -->
  <div class="right-column">
    <!-- Show Section List Component -->
    <app-section-list *ngIf="selectedItem === SECTIONS"></app-section-list>

    <!-- Show Chapter List Component -->
    <app-chapter-list *ngIf="selectedItem === CHAPTERS"></app-chapter-list>

    <!-- Show Heading List Component -->
    <app-heading-list *ngIf="selectedItem === HEADINGS"></app-heading-list>
  </div>
</div>
