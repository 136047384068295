import { Routes } from '@angular/router';
import { TenantSelectionComponent } from './tenant-selection.component';
import { MsalGuard } from '@azure/msal-angular';

export const tenantSelectionRoute: Routes = [
  {
    path: 'tenant-selection',
    component: TenantSelectionComponent,
    canActivate: [MsalGuard],
  },
];
