import { CommonModule, Location } from '@angular/common';
import { Component, effect, ElementRef, EventEmitter, HostListener, inject, Input, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ReactiveFormControlInputComponent } from "../reactive-form-control-input/reactive-form-control-input.component";
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatCardActions, MatCardModule } from '@angular/material/card';
import { ButtonWithMatSpinnerComponent } from "../button-with-mat-spinner/button-with-mat-spinner.component";
import { MsalService } from '@azure/msal-angular';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSlideToggle, MatSlideToggleModule } from '@angular/material/slide-toggle';
import { UserFriendlyHttpErrorMessageDialogComponent } from '../user-friendly-http-error-message-dialog/user-friendly-http-error-message-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { HttpErrorResponse } from '@angular/common/http';
import { Vendor } from '../../../../core/models/vendor.model';
import { VendorManagementService } from '../../../../core/services/vendor-management.service';
import { VendorUser } from '../../../../core/models/vendor-user.model';
import { RegionStore } from '../../../item-type-management/regions/store/regions.store';
import { ToastrModule, ToastrService } from 'ngx-toastr';
import { ConfirmModalComponent } from '../../../../core/components/confirm-modal/confirm-modal.component';
import { VendorStore } from '../store/vendors.store';
import { GridApi } from 'ag-grid-enterprise';

@Component({
  selector: 'app-vendor-form',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormControlInputComponent,
    MatDividerModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatCardActions,
    ButtonWithMatSpinnerComponent,
    MatCardModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatSlideToggleModule
],
  templateUrl: './vendor-form.component.html',
  styleUrl: './vendor-form.component.scss'
})
export class VendorFormComponent {
  vendorDetailForm:FormGroup = new FormGroup({
    companyName: new FormControl(null,[Validators.required]),
    administratorName: new FormControl(null,),
    email: new FormControl(null,[Validators.required, Validators.email]),
    phoneNumber: new FormControl(null,[Validators.pattern(/^\+?\d{7,15}$/)]),
    streetAddress: new FormControl(null,[]),
    vendorIdentifier: new FormControl(null,[Validators.required]),
    city: new FormControl(null,[]),
    state: new FormControl(null,[]),
    postalCode: new FormControl(null,[]),
    country: new FormControl(null,[]),
    region: new FormControl(null,[Validators.required]),
    status: new FormControl(true)
  });
  @Output() vendorDetailFormChange = new EventEmitter();
  updateVendorMode:boolean = false; // if false its in addVendorMode
  updateVendorReadOnlyMode:boolean = false;
  @Input() vendorToEdit:Vendor|undefined
  @Output() vendorToEditChange = new EventEmitter();
  @Input() gridApi:GridApi|undefined
  @Input() showCancelButton:boolean = true;
  @Input() showMainFormCentralLoadingSpinner:boolean = true;
  showSaveLoadingSpinner:boolean = false;
  title:string = "Add Vendor"
  @Output() cancelButtonSelected = new EventEmitter();
  selectedTabIndex = 0;
  readonly NUMBEROFTABS = 2;
  showValidationRejectionAlertMessage:boolean = false;
  validationRejectionAlertMessage:string | undefined;
  regionStore = inject(RegionStore);
  vendorStore = inject(VendorStore)

  constructor(private toastrService:ToastrService,private dialog:MatDialog,private location:Location,private authService:MsalService,private vendorManagementService:VendorManagementService) {
    effect(() => {
      this.showSaveLoadingSpinner = this.vendorManagementService.vendorAddUpdateLoadingSignal();
    });


    this.vendorDetailForm.valueChanges.subscribe(change=>
    {
      this.vendorDetailFormChange.emit(this.vendorDetailForm);
    }

    )

  }

  vendorFormInit() {
    this.setVendorUpdateMode();
    this.initFormValues();
    this.setTitle();

  }

  ngOnInit() {
    this.vendorManagementService.vendorAddUpdateLoadingSignal.set(true);
    this.vendorFormInit();
    this.regionStore.loadRegions(this.vendorManagementService.vendorAddUpdateLoadingSignal);
  }


  ngOnChanges() {
    this.vendorFormInit();
  }

  setVendorUpdateMode() {
    this.updateVendorMode = this.vendorManagementService.vendorDetailUpdateModeSignal();

    if(this.updateVendorMode) {
      this.updateVendorReadOnlyMode = true;
    }
  }

  setTitle() {
    this.title = (this.updateVendorMode) ? "Edit Vendor":"Add Vendor"
  }

  setVendorDetailFormGroup(vendorDetailForm:FormGroup) {
    this.vendorDetailForm = vendorDetailForm;
  }

  initFormValues() {
    if(this.updateVendorMode) {
      Object.keys(this.vendorDetailForm.controls).forEach(key => {
        if(this.vendorToEdit) {
          this.vendorDetailForm.controls[key].setValue(this.vendorToEdit[key as keyof Vendor]);
        }
      });
    }
  }

  submitVendorDetailForm() {
    if(this.vendorDetailForm.dirty && this.vendorDetailForm.valid) {
      this.vendorManagementService.vendorAddUpdateLoadingSignal.set(true);
      this.gridApi?.setGridOption("loading",true);
      let mappedVendorObj = this.mapVendorFormValsToVendorObj();

      if(this.updateVendorMode && this.vendorToEdit && this.vendorToEdit.id && mappedVendorObj) {
        this.submitUpdateVendor(this.vendorToEdit.id,mappedVendorObj);
      }
      else if (mappedVendorObj) {
        this.submitAddVendor(mappedVendorObj);
      }
    }

  }

  submitAddVendor(mappedVendorObj:Vendor) {
    this.vendorStore.addVendor(mappedVendorObj).subscribe({
      next:(vendorResponse:any) => {
        if(vendorResponse) {
          this.vendorToEdit = vendorResponse;
          this.vendorToEditChange.emit(vendorResponse);
          this.location.replaceState(`settings/vendor-management/vendor-detail/edit/${vendorResponse.id}`);
          this.vendorDetailForm.markAsPristine();
          this.vendorManagementService.vendorDetailUpdateModeSignal.set(true);
          this.vendorManagementService.vendorAddUpdateLoadingSignal.set(false);
          this.vendorManagementService.vendorAddUpdateCloseDialogSignal.set(true);
          this.showValidationRejectionAlertMessage = false;
          this.vendorFormInit();
          this.toastrService.success("Vendor Successfully Added!");
        }
      },
      error:(errorResponse:HttpErrorResponse) => {
        if(errorResponse.status == 422 && errorResponse.error) {
          //validation error
          this.showValidationRejectionAlertMessage = true;
          this.validationRejectionAlertMessage = errorResponse.error
        }
        else {
          this.showValidationRejectionAlertMessage = false;
          this.dialog.open(UserFriendlyHttpErrorMessageDialogComponent);
        }
        this.vendorManagementService.vendorAddUpdateLoadingSignal.set(false);
        this.vendorManagementService.vendorAddUpdateCloseDialogSignal.set(true);
      }
    });
  }

  submitUpdateVendor(id:number,mappedVendorObj:Vendor) {

    this.vendorStore.updateVendor(id,mappedVendorObj).subscribe({
      next:(vendorResponse:any) => {
        if(vendorResponse) {
          this.vendorToEdit = vendorResponse;
          this.vendorToEditChange.emit(vendorResponse);
          this.vendorDetailForm.markAsPristine();
          this.vendorManagementService.vendorAddUpdateLoadingSignal.set(false);
          this.vendorManagementService.vendorAddUpdateCloseDialogSignal.set(true);
          this.showValidationRejectionAlertMessage = false;
          this.vendorFormInit();
          this.toastrService.success("Vendor Successfully Updated!");
        }
      },
      error:(errorResponse:HttpErrorResponse) => {
        if(errorResponse.status == 422 && errorResponse.error) {
          //validation error
          this.showValidationRejectionAlertMessage = true;
          this.validationRejectionAlertMessage = errorResponse.error;
        }
        else {
          this.showValidationRejectionAlertMessage = false;
          this.dialog.open(UserFriendlyHttpErrorMessageDialogComponent);
        }

        this.vendorManagementService.vendorAddUpdateLoadingSignal.set(false);
        this.vendorManagementService.vendorAddUpdateCloseDialogSignal.set(true);
      }
    });
  }

  mapVendorFormValsToVendorObj() {
    let newVendor:Vendor|undefined;
    let userGuid:string|undefined = this.authService.instance.getActiveAccount()?.localAccountId;
    let submittedDateNow = new Date(Date.now());

    if(this.updateVendorMode && this.vendorToEdit && userGuid) {
      newVendor = new Vendor(
        this.vendorToEdit.id,// overidden in the api
        this.vendorToEdit.createdAt,// overidden in the api
        submittedDateNow,// overidden in the api
        this.vendorToEdit.createdBy,// overidden in the api
        userGuid as any,// overidden in the api
        this.vendorToEdit.isDeleted,// overidden in the api
        this.vendorDetailForm.controls['companyName'].value,
        this.vendorDetailForm.controls['email'].value,
        this.vendorToEdit?.vendorUsers,
        this.vendorDetailForm.controls['status'].value,
        this.vendorDetailForm.controls['region'].value.id,
        this.vendorDetailForm.controls['vendorIdentifier'].value,
        this.vendorDetailForm.controls['administratorName'].value,
        this.vendorDetailForm.controls['phoneNumber'].value,
        this.vendorDetailForm.controls['streetAddress'].value,
        this.vendorDetailForm.controls['city'].value,
        this.vendorDetailForm.controls['state'].value,
        this.vendorDetailForm.controls['postalCode'].value,
        this.vendorDetailForm.controls['country'].value,
        this.vendorToEdit.lastOrderDate,


      )
    }
    else if(userGuid) {
      newVendor = new Vendor(
        0, // overidden in the api
        submittedDateNow, // overidden in the api
        submittedDateNow,// overidden in the api
        userGuid as any,// overidden in the api
        userGuid as any,// overidden in the api
        false,// overidden in the api
        this.vendorDetailForm.controls['companyName'].value,
        this.vendorDetailForm.controls['email'].value,
        new Array<VendorUser>(),
        this.vendorDetailForm.controls['status'].value,
        this.vendorDetailForm.controls['region'].value.id,
        this.vendorDetailForm.controls['vendorIdentifier'].value,
        this.vendorDetailForm.controls['administratorName'].value,
        this.vendorDetailForm.controls['phoneNumber'].value,
        this.vendorDetailForm.controls['streetAddress'].value,
        this.vendorDetailForm.controls['city'].value,
        this.vendorDetailForm.controls['state'].value,
        this.vendorDetailForm.controls['postalCode'].value,
        this.vendorDetailForm.controls['country'].value,
      );
    }
    return newVendor;
  }

  cancelBtnClickEvent() {
    this.vendorDetailForm.reset();
    this.vendorFormInit();
    this.cancelButtonSelected.emit();
  }

  regionComparisonFunction = function( option:any, value:any ) : boolean {
    if(option != null && value != null) {
      return option.id === value.id;
    }
    else {
      return false;
    }

  }

  onTabBackButtonClicked() {
    if(this.selectedTabIndex > 0) {
      this.selectedTabIndex = this.selectedTabIndex - 1;
    }
  }

  onTabNextButtonClicked() {
    if(this.selectedTabIndex < this.NUMBEROFTABS-1) {
      this.selectedTabIndex = this.selectedTabIndex + 1;
    }

  }

  changeActiveTab(selectedTabPill: any){
    this.selectedTabIndex = selectedTabPill.currentTarget.attributes['data-index'].value;
  }

  editFormSlideToggleChange(slideToggleElement:MatSlideToggle) {
    if(this.vendorDetailForm.dirty) {
      const dialogRef = this.dialog.open(ConfirmModalComponent, {
        data: {
          title:"",
          message:"Changes to the vendor form have not been saved. Do you want to continue and discard the current changes?"
        }
      });

      dialogRef.afterClosed().subscribe(proceedWithReadOnlyMode => {
        if(proceedWithReadOnlyMode) {
          this.updateVendorReadOnlyMode = true;
          slideToggleElement.checked = !this.updateVendorReadOnlyMode;
          this.vendorDetailForm.reset();
          this.vendorFormInit();
        }
        else {
          this.updateVendorReadOnlyMode = false;
          slideToggleElement.checked = !this.updateVendorReadOnlyMode;
        }
      });
    }
    else {
      this.updateVendorReadOnlyMode = !this.updateVendorReadOnlyMode;
      slideToggleElement.checked = !this.updateVendorReadOnlyMode;
    }
  }

}
