<div class="d-flex justify-content-between align-items-center mb-3 mt-2">
  <div class="col-lg-6">
    <input
      type="text"
      class="form-control"
      placeholder="Search by Group Category Description"
      [(ngModel)]="searchTerm"
      (ngModelChange)="onSearchTermChange($event)"
    />
  </div>

  <div class="col-lg-6 text-end">
    <!-- <div class="btn-group me-2" role="group" aria-label="Filter Buttons">
      <button
        [ngClass]="store.filter() === 'all' ? 'btn btn-primary' : 'btn btn-outline-primary'"
        (click)="store.changeFilter('all')"
        aria-label="Filter to show all regions"
      >
        All
      </button>
      <button
        [ngClass]="store.filter() === 'active' ? 'btn btn-primary' : 'btn btn-outline-primary'"
        (click)="store.changeFilter('active')"
        aria-label="Filter to show active regions"
      >
        Active
      </button>
      <button
        [ngClass]="store.filter() === 'inactive' ? 'btn btn-primary' : 'btn btn-outline-primary'"
        (click)="store.changeFilter('inactive')"
        aria-label="Filter to show inactive regions"
      >
        Not Active
      </button>
    </div> -->

    <button class="btn btn-primary me-2" (click)="createItemTypeGroup()" aria-label="Create Category">
      Create
    </button>

    <button class="btn btn-warning me-2" (click)="resetFilters()" aria-label="Reset filters and search">
      Reset
    </button>

    <button class="btn btn-secondary" (click)="forceRefresh()" aria-label="Refresh data">
      Refresh Data
    </button>
  </div>

</div>
<hr />
<div class="container">
  <div class="item-type-tab-set">
    <div>
      <table class="table table-bordered table-striped" style="width: 100%">
        <thead>
          <tr>
            <th scope="col" style="width: 45%">Group Category Description (Click to Edit)</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td><span class="clickable-description">Accessories</span></td>
          </tr>
          <tr>
            <td><span class="clickable-description">Bed Linen</span></td>
          </tr>
          <tr>
            <td><span class="clickable-description">Bedspreads</span></td>
          </tr>
          <tr>
            <td><span class="clickable-description">Blankets</span></td>
          </tr>
          <tr>
            <td><span class="clickable-description">Carpets</span></td>
          </tr>
          <!-- <tr *ngFor="let itemTypeGroup of store.filteredItemTypeGroups()">
            <td>
              <div class="d-flex justify-content-between align-items-center">
                <span class="clickable-description" (click)="editItemTypeGroup(itemTypeGroup)">
                  {{ itemTypeGroup.description }}
                </span>
                <span
                  *ngIf="itemTypeGroup.clarifyingInformation"
                  class="badge bg-danger text-white"
                  style="padding: 0.25em 0.5em; font-size: 0.9em;"
                >
                  Clarifying Information
                </span>
              </div>
            </td>
            <td style="text-align: center">{{ itemTypeGroup.managedAtSize ? 'Yes' : 'No' }}</td>
            <td style="text-align: center">{{ itemTypeGroup.requiresConstructionMgmt ? 'Yes' : 'No' }}</td>
            <td style="text-align: center">{{ itemTypeGroup.requiresMaterialMgmt ? 'Yes' : 'No' }}</td>
          </tr> -->
        </tbody>
      </table>

    </div>
  </div>
</div>
