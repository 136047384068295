import { CommonModule } from '@angular/common';
import { NgFor } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { UiSwitchModule } from 'ngx-ui-switch';
import { RegionStore } from './store/regions.store';
import { ToastrService } from 'ngx-toastr';
import { debounceTime } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { FormsModule } from '@angular/forms';
import { IRegion } from '../../../core/models/IRegion';
import { ConstructionCategoryStore } from '../construction-mgmt/store/constructionstore';
import { MaterialCategoryStore } from '../material-mgmt/store/materialstore';
import { DefaultSizeStore } from '../size-mgmt/store/sizecategorystore';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { MatTabsModule } from '@angular/material/tabs';

@Component({
  selector: 'app-regions',
  standalone: true,
  imports: [CommonModule, NgFor, UiSwitchModule, FormsModule, MatTabsModule, TabsModule,], // Added CommonModule and FormsModule
  templateUrl: './regions.component.html',
  styleUrls: ['./regions.component.scss'],
  providers: [
    {
      provide: UiSwitchModule,
      useFactory: () => UiSwitchModule.forRoot({
        size: 'medium',
        color: 'rgb(0, 189, 99)',
        switchColor: '#3f4d97',
        defaultBgColor: '#cfe2ff',
        defaultBoColor: '#476EFF',
        checkedLabel: 'on',
        uncheckedLabel: 'off'
      })
    }
  ]
})
export class RegionsComponent implements OnInit  {

  store = inject(RegionStore);
  materialCategoryStore = inject(MaterialCategoryStore);
  constructionStore = inject(ConstructionCategoryStore);
  defaultSizeStore = inject(DefaultSizeStore);
  toastrService = inject(ToastrService);

  // Subject for search input with debounce
  private searchSubject = new Subject<string>();
  searchTerm: string = '';

  ngOnInit() {
    this.store.loadRegions();

    // Subscribe to search input changes with debounce
    this.searchSubject.pipe(
      debounceTime(300) // Adjust the debounce time as needed
    ).subscribe((term) => {
      this.store.setSearchTerm(term);
    });
  }

  onToggleActive(region: IRegion) {
    // Optimistically toggle the region's active status in the store
    const newStatus = !region.active; // Explicitly toggle active status
    this.store.toggleActiveRegion(region.id);

    // Call the service with the new status
    this.store.updateRegionStatus({ ...region, active: newStatus }).subscribe({
      next: () => {
        this.clearSystemStoreCaches();
        this.updateSystemStores();
        this.toastrService.success('Region updated successfully', 'Success');
      },
      error: () => {
        this.toastrService.error('Failed to update region', 'Error');
        // Revert the change in the store
        this.store.toggleActiveRegion(region.id);
      }
    });
  }

  clearSystemStoreCaches() {
    this.materialCategoryStore.clearCache();
    this.constructionStore.clearCache();
    this.defaultSizeStore.clearCache();
  }

  updateSystemStores() {
    this.materialCategoryStore.loadData();
    this.constructionStore.loadData();
    this.defaultSizeStore.loadData();
  }

  forceRefresh() {
    this.store.clearCache();
    this.store.loadRegions();
    this.toastrService.info('Data has been refreshed', 'Refreshed');
  }

  onSearchTermChange(term: string) {
    this.searchSubject.next(term);
  }

  // New method to reset filters and search
  resetFilters() {
    this.searchTerm = ''; // Clear the search input
    this.store.setSearchTerm(''); // Explicitly update the store's searchTerm
    this.store.changeFilter('all'); // Reset the filter to 'all'
    this.toastrService.info('Filters have been reset', 'Reset'); // Optional: Notify the user
  }
}
