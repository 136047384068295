<app-breadcrumb></app-breadcrumb>

@if (loading) {
  <div class="d-flex justify-content-center align-items-center my-5">
    <mat-spinner></mat-spinner>
  </div>
}
@else {
  <div class="container p-4">
    <h3 class="mb-4">Ask a Question about Order {{ orderNumber }} <small *ngIf="orderLineCode">Line {{ orderLineCode }}</small></h3>
    <form [formGroup]="questionForm" (ngSubmit)="onSubmit()">
      <section class="w-75 mb-3">
        <div class="row mb-1">
          <div class="col">
            <mat-label>To whom would you like to ask this question?*</mat-label>            
          </div>
        </div>

        <div class="row">
          <div class="col">
            <mat-button-toggle-group formControlName="scope" aria-label="Question scope">
              <mat-button-toggle value="customer">
                <mat-icon>
                  factory
                </mat-icon>
                Supplier
              </mat-button-toggle>
              <mat-button-toggle value="master">
                <img width="26" height="26" src="/assets/tarifftel-small.png">
                Customs Team
              </mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
      </section>
      
      <mat-form-field class="w-75 mb-2">
          <mat-label>Question Subject</mat-label>
          <input required matInput type="text" formControlName="subject"
            placeholder="Concise summary of the question, should end in '?'." />
            @if (questionForm.controls.subject.hasError('required')) {
              <mat-error>Question Subject is required.</mat-error>
            }
            @if (questionForm.controls.subject.hasError('minlength')) {
              <mat-error>Question Subject must be at least 4 characters long.</mat-error>
            }
            @if (questionForm.controls.subject.hasError('maxlength')) {
              <mat-error>Question Subject cannot be longer than 50 characters.</mat-error>
            }
      </mat-form-field>
  
      <mat-form-field class="w-75 mb-2">
        <mat-label>Category</mat-label>
        <mat-select required formControlName="category" placeholder="Select a category">
          <mat-option [value]="null" disabled>Select a category</mat-option>
          @if (categories) {
            @for (category of categories; track category.key) {
              <mat-option [value]="category.key">{{ category.value }}</mat-option>
            }
          }
        </mat-select>
        @if (questionForm.controls.category.hasError('required')) {
          <mat-error>This field is required</mat-error>
        }
      </mat-form-field>
  
      <mat-form-field class="w-75">
          <mat-label>Additional Information</mat-label>
          <textarea matInput
            cdkTextareaAutosize
            formControlName="body"
            cdkAutosizeMinRows="3"
            cdkAutosizeMaxRows="10"
            placeholder="You can add any extra information that's required here, or you can leave this blank"></textarea>
            @if (questionForm.controls.body.hasError('maxlength')) {
              <mat-error>Question Subject cannot be longer than 1500 characters.</mat-error>
            }
      </mat-form-field>
  
      <div>
        <button mat-fab extended type="submit" [disabled]="!questionForm.valid">
          <mat-icon>send</mat-icon>
          Ask
        </button>
      </div>      
    </form>
  </div>
}