import { Component, inject, input } from '@angular/core';
import { InboxItemListComponent } from '../inbox-item-list/inbox-item-list.component';
import { InboxReadingPaneComponent } from '../inbox-reading-pane/inbox-reading-pane.component';
import { InboxItemsFilterComponent } from "../inbox-items-filter/inbox-items-filter.component";
import { InboxStore } from '../store/inbox-store';
import { IInboxItem } from '../../../core/models/IInboxItem';
import { MatIcon } from '@angular/material/icon';
import { SecondaryNavbarComponent } from '@tarifftel/components-library';

@Component({
  selector: 'app-inbox-directory',
  standalone: true,
  providers: [InboxStore],
  imports: [InboxItemListComponent, InboxReadingPaneComponent, InboxItemsFilterComponent, MatIcon, SecondaryNavbarComponent],
  templateUrl: './inbox-directory.component.html',
  styleUrl: './inbox-directory.component.scss'
})
export class InboxDirectoryComponent {
  readonly store = inject(InboxStore);
  orderIdFilter = input<number>();
  orderLineIdFilter = input<number>();

  itemSelected(item: IInboxItem): boolean {
    return this.store.readingPane
      && this.store.readingPane()?.tenantId === item.tenantId
      && this.store.readingPane()?.threadId === item.threadId;
  }
}
