import { getState, patchState, signalStore, withComputed, withHooks, withMethods, withState } from "@ngrx/signals";
import { computed, inject } from "@angular/core";
import { ItemtypeGroupService } from "../../../../core/services/itemtype-group.service";
import { LocalStorageService } from "../../../../core/services/local-storage.service";
import { forkJoin, map, Observable, tap } from "rxjs";
import { ItemTypeAttributeService } from "../../../../core/services/item-type-attribute.service";
import { IItemAttribute } from "../../../../core/models/IItemAttribute";
import { IAttributeValue } from "../../../../core/models/IAttributeValue";
import { IAttributeValueImage } from "../../../../core/models/IAttributeValueImage";
import { IItemTypeGroup } from "../../../../core/models/IItemTypeGroup";
import { ISubAttributeValue } from "../../../../core/models/ISubAttributeValue";
import { ISubAttribute } from "../../../../core/models/ISubAttribute";
import { IItemType } from "../../../../core/models/IItemType";
import { ItemTypeService } from "../../../../core/services/itemtype.service";

const attributelistingStoreKey = 'tarifftel_attribute_list_data';
const attributelistingStoreTimestampKey = 'tarifftel_attribute_list_data_timestamp';
const CACHE_DURATION = 60 * 60 * 1000; // 1 hour

type Filter = 'all' | 'active' | 'inactive';

type TariffTelAttributeListState = {
  itemTypeGroups: IItemTypeGroup[];
  itemTypes: IItemType[];
  attributes: IItemAttribute[];
  attributeValues: IAttributeValue[];
  searchTerm: string;
  selectedList: 'typesattributevalues' | 'sharedattributes' | 'itemtypes';
  selectedItemTypeGroupId: number | null;
  selectedItemTypeAttributeId: number | null;
  selectedAttributeValueId: number | null;
  loading: boolean;
};

const initialState: TariffTelAttributeListState = {
  itemTypeGroups: [],
  itemTypes: [],
  attributes: [],
  attributeValues: [],
  searchTerm: '',
  selectedList: 'typesattributevalues',
  selectedItemTypeGroupId: null,
  selectedItemTypeAttributeId: null,
  selectedAttributeValueId: null,
  loading: true,
};

export const AttributeListingStore = signalStore(
  { providedIn: 'root' },
  withState(initialState),
  withComputed(({ itemTypeGroups, itemTypes, attributes, attributeValues, selectedList, selectedItemTypeGroupId, selectedItemTypeAttributeId, selectedAttributeValueId }) => ({
    filteredList: computed(() => {
      let filtered: any[] = [];

      if (selectedList() === 'typesattributevalues') {
        filtered = attributes();

        if (selectedItemTypeGroupId()) {
          filtered = filtered.filter(item => item.itemTypeGroupId === selectedItemTypeGroupId());
        }
      }
      // No need to handle shared attributes if no specific filtering is applied
      return filtered;
    }),

    selectedItemTypeGroup: computed(() => {
      return itemTypeGroups().find(group => group.id === selectedItemTypeGroupId());
    }),

    selectedItemTypeGroupName: computed(() => {
      const selectedGroup = itemTypeGroups().find(group => group.id === selectedItemTypeGroupId());
      return selectedGroup ? selectedGroup.description : 'N/A';
    }),

    selectedAttributeName: computed(() => {
      const selectedAttribute = attributes().find(attribute => attribute.id === selectedItemTypeAttributeId());
      return selectedAttribute ? selectedAttribute.attributeName : 'No Attribute Selected';
    }),

    selectedAttributeValueName: computed(() => {
      const selectedAttributeValue = attributeValues().find(attributevalue => attributevalue.id === selectedAttributeValueId());
      return selectedAttributeValue ? selectedAttributeValue.valueName : 'No Attribute Value Selected';
    })
  })),
  withMethods((store) => {
    const itemTypeAttributeService = inject(ItemTypeAttributeService);
    const itemTypeGroupService = inject(ItemtypeGroupService);
    const itemTypeService = inject(ItemTypeService);
    const localStorageService = inject(LocalStorageService);

    return {
      loadData() {
        const dataFromStorage = localStorageService.getItem(attributelistingStoreKey) || {};
        const timestamp = parseInt(localStorageService.getItem(attributelistingStoreTimestampKey) || '0', 10);
        const now = Date.now();

        patchState(store, { loading: true });

        if (Object.keys(dataFromStorage).length > 0 && (now - timestamp) < CACHE_DURATION) {
          patchState(store, dataFromStorage);
          patchState(store, { loading: false });

          // Ensure the first item type group is selected after loading from cache
          if (getState(store).selectedItemTypeGroupId === null) {
            this.selectFirstItemTypeGroup();
          }
        } else {
          forkJoin({
            itemTypeGroups: itemTypeGroupService.getActiveItemTypeGroups(),
          }).subscribe({
            next: ({ itemTypeGroups }) => {
              patchState(store, { itemTypeGroups });
              localStorageService.setItem(attributelistingStoreKey, JSON.stringify({ itemTypeGroups }));
              localStorageService.setItem(attributelistingStoreTimestampKey, now.toString());
              patchState(store, { loading: false });

              // Select the first item type group if no group is selected
              if (getState(store).selectedItemTypeGroupId === null) {
                this.selectFirstItemTypeGroup();
              }
            },
            error: (err) => {
              console.error('Failed to load Item Type Groups', err);
              patchState(store, { loading: false });
            }
          });
        }
      },

      loadSubAttributes(attributeValueId: number) {
        itemTypeAttributeService.getItemTypeSubAttributesByAttributeValueId(attributeValueId).subscribe({
          next: (subAttributes) => {
            const updatedValues = getState(store).attributeValues.map((value) => {
              if (value.id === attributeValueId) {
                value.subAttributes = subAttributes;
              }
              return value;
            });
            patchState(store, { attributeValues: updatedValues });
          },
          error: (error) => console.error('Failed to load sub attributes', error),
        });
      },

      addSubAttribute(attributeValueId: number, subAttribute: ISubAttribute) {
        const updatedValues = getState(store).attributeValues.map((value) => {
          if (value.id === attributeValueId) {
            value.subAttributes = [...(value.subAttributes || []), { ...subAttribute, isActive: false }];
          }
          return value;
        });
        patchState(store, { attributeValues: updatedValues });
      },

      addSubAttributeValue(subAttributeId: number, subAttributeValue: ISubAttributeValue) {
        const updatedValues = getState(store).attributeValues.map((value) => {
          value.subAttributes = value.subAttributes?.map((subAttr) => {
            if (subAttr.id === subAttributeId) {
              subAttr.subAttributeValues = [...(subAttr.subAttributeValues || []), subAttributeValue];
            }
            return subAttr;
          });
          return value;
        });
        patchState(store, { attributeValues: updatedValues });
      },

      removeSubAttribute(attributeValueId: number, subAttributeId: number) {
        const updatedValues = getState(store).attributeValues.map((value) => {
          if (value.id === attributeValueId) {
            value.subAttributes = value.subAttributes?.filter((subAttr) => subAttr.id !== subAttributeId);
          }
          return value;
        });
        patchState(store, { attributeValues: updatedValues });
      },

      removeSubAttributeValue(subAttributeId: number, subAttributeValueId: number) {
        const updatedValues = getState(store).attributeValues.map((value) => {
          value.subAttributes = value.subAttributes?.map((subAttr) => {
            if (subAttr.id === subAttributeId) {
              subAttr.subAttributeValues = subAttr.subAttributeValues?.filter((val) => val.id !== subAttributeValueId);
            }
            return subAttr;
          });
          return value;
        });
        patchState(store, { attributeValues: updatedValues });
      },

      addAlias(attributeValueId: number, alias: string) {
        const attributeValues = getState(store).attributeValues.map(attr => {
          if (attr.id === attributeValueId) {
            attr.attributeValueAliases = [...(attr.attributeValueAliases || []), { id: 0, alias, attributeValueId }];
          }
          return attr;
        });
        patchState(store, { attributeValues });
      },

      removeAlias(attributeValueId: number, aliasId: number) {
        const attributeValues = getState(store).attributeValues.map(attr => {
          if (attr.id === attributeValueId) {
            attr.attributeValueAliases = attr.attributeValueAliases?.filter(alias => alias.id !== aliasId);
          }
          return attr;
        });
        patchState(store, { attributeValues });
      },

      updateImage(attributeValueId: number, image: IAttributeValueImage) {
        const attributeValues = getState(store).attributeValues.map(attr => {
          if (attr.id === attributeValueId) {
            attr.attributeValueImages = [...(attr.attributeValueImages || []), image];
          }
          return attr;
        });
        patchState(store, { attributeValues });
      },

      uploadImage(attributeValueId: number, file: File): Observable<string> {
        const formData = new FormData();
        formData.append('imageFile', file);

        return itemTypeAttributeService.uploadImage(formData).pipe(
          tap((imageUrl) => {
            // Update the store state with the new image
            this.updateImage(attributeValueId, {
              id: 0, // Assuming the image is new and has no ID yet
              imageUrl,
              attributeValueId,
              description: '' // Optional, can be filled later
            });

            console.log(`Image uploaded and added to state: ${imageUrl}`);
          })
        );
      },

      selectFirstItemTypeGroup() {
        const { itemTypeGroups } = getState(store);
        if (itemTypeGroups.length > 0) {
          const firstGroupId = itemTypeGroups[0].id;
          this.setSelectedItemTypeGroupId(firstGroupId);
          this.loadAttributes(firstGroupId); // Load attributes when the first group is selected
        }
      },

      selectAttributeAndLoadValues(attributeId: number) {
        this.setSelectedAttributeId(attributeId);
        this.loadAttributeValues(attributeId); // Load values for the selected attribute
      },

      loadAttributes(itemTypeGroupId: number) {
        patchState(store, { loading: true });

        itemTypeAttributeService.getAttributesByItemTypeGroupId(itemTypeGroupId).subscribe({
          next: (attributes) => {
            // Sort attributes alphabetically by attributeName
            attributes.sort((a, b) => a.attributeName.localeCompare(b.attributeName));

            patchState(store, { attributes, loading: false });

            if (attributes.length > 0) {
              // Select the first attribute and load its values
              const firstAttribute = attributes[0];
              this.setSelectedAttributeId(firstAttribute.id);
              this.loadAttributeValues(firstAttribute.id);
            } else {
              // Clear selection if no attributes exist
              this.setSelectedAttributeId(null);
              patchState(store, { attributeValues: [] }); // Clear attribute values as well
            }
          },
          error: (error) => {
            console.error("Failed to load attributes", error);
            patchState(store, { loading: false });
          }
        });
      },


      loadAttributeValues(attributeId: number) {
        itemTypeAttributeService.getItemTypeAttributeValuesByItemTypeAttributeId(attributeId).subscribe({
          next: (attributeValues) => {
            patchState(store, { attributeValues });

            if (attributeValues.length > 0) {
              this.setSelectedAttributeValueId(attributeValues[0].id);
            } else {
              this.setSelectedAttributeValueId(null);
            }
          },
          error: (error) => {
            console.error("Failed to load attribute values", error);
          }
        });
      },

      loadItemTypes(itemTypeGroupId: number) {
        patchState(store, { loading: true });

        itemTypeService.getItemTypesByItemTypeGroupId(itemTypeGroupId).subscribe({
          next: (itemTypes) => {
            // Sort item types alphabetically by description
            itemTypes.sort((a, b) => a.description.localeCompare(b.description));
            patchState(store, { itemTypes, loading: false });
          },
          error: (error) => {
            console.error("Failed to load attributes", error);
            patchState(store, { loading: false });
          }
        });
      },

      removeAttributeValue(attributeId: number, attributeValueId: number) {
        const updatedAttributeValues = getState(store).attributeValues.filter(
          val => val.id !== attributeValueId
        );
        patchState(store, { attributeValues: updatedAttributeValues });
        itemTypeAttributeService.deleteAttributeValue(attributeId, attributeValueId).subscribe();
      },

      removeAttribute(attributeId: number) {
        const updatedAttribute = getState(store).attributes.filter(
          val => val.id !== attributeId
        );
        patchState(store, { attributes: updatedAttribute });
        itemTypeAttributeService.deleteAttribute(attributeId).subscribe();
      },

      setSelectedItemTypeGroupId(id: number | null) {
        patchState(store, { selectedItemTypeGroupId: id });
      },

      setSelectedAttributeId(id: number | null) {
        patchState(store, { selectedItemTypeAttributeId: id });
      },

      setSelectedAttributeValueId(id: number | null) {
        patchState(store, { selectedAttributeValueId: id });
      },

      showTable(selectedList: 'typesattributevalues' | 'sharedattributes' | 'itemtypes') {
        patchState(store, { selectedList });

        // Ensure first item type group is selected only if switching to 'Attributes/Values' and not already set
        if (selectedList === 'typesattributevalues' && getState(store).selectedItemTypeGroupId === null) {
          this.selectFirstItemTypeGroup();
        }
      },

      setSearchTerm(term: string) {
        patchState(store, { searchTerm: term });
      },

      createSharedAttribute(attribute: IItemAttribute): Observable<IItemAttribute> {
        return itemTypeAttributeService.createSharedAttribute(attribute);
      },

      updateSharedAttribute(attribute: IItemAttribute): Observable<IItemAttribute> {
        return itemTypeAttributeService.updateSharedAttribute(attribute);
      },

      isDuplicateAttribute(attribute: IItemAttribute): boolean {
        return getState(store).attributes.some(
          (attr) =>
            attr.attributeName.toLowerCase() ===
              attribute.attributeName.toLowerCase() &&
            attr.itemTypeGroupId === attribute.itemTypeGroupId &&
            attr.id !== attribute.id
        );
      },

      createAttribute(attribute: IItemAttribute): Observable<IItemAttribute> {
        return itemTypeAttributeService.createAttribute(attribute);
      },

      updateAttribute(attribute: IItemAttribute): Observable<IItemAttribute> {
        return itemTypeAttributeService.updateAttribute(attribute);
      },

      addAttribute(newItem: IItemAttribute) {
        patchState(store, {
          attributes: [...store.attributes(), newItem]
            .sort((a, b) => a.description.localeCompare(b.description))
        });
      },

      patchAttribute(updatedItem: IItemAttribute) {
        patchState(store, {
          attributes: store.attributes().map(item =>
            item.id === updatedItem.id ? updatedItem : item
          )
        });
      },

      isDuplicateAttributeValue(attributeValue: IAttributeValue): boolean {
        return getState(store).attributeValues.some(
          (val) =>
            val.valueName.toLowerCase() === attributeValue.valueName.toLowerCase() &&
            val.itemAttributeId === attributeValue.itemAttributeId &&
            val.id !== attributeValue.id
        );
      },

      createAttributeValue(attributeValue: IAttributeValue, imageFile?: File): Observable<IAttributeValue> {
        return itemTypeAttributeService.createAttributeValue(attributeValue, imageFile).pipe(
          tap((newAttributeValue) => this.addAttributeValue(newAttributeValue))
        );
      },


      updateAttributeValue(attributeValue: IAttributeValue, imageFile?: File): Observable<IAttributeValue | null> {
        return itemTypeAttributeService.updateAttributeValue(attributeValue, imageFile).pipe(
          map((updatedAttributeValue) => updatedAttributeValue ?? attributeValue), // Replace null with original attributeValue
          tap((finalAttributeValue) => this.patchAttributeValue(finalAttributeValue))
        );
      },



      addAttributeValue(newAttributeValue: IAttributeValue) {
        patchState(store, {
          attributeValues: [...store.attributeValues(), newAttributeValue].sort((a, b) => a.valueName.localeCompare(b.valueName))
        });
      },

      patchAttributeValue(updatedItem: IAttributeValue) {
        patchState(store, {
          attributeValues: store.attributeValues().map(item =>
            item.id === updatedItem.id ? updatedItem : item
          )
        });
      },

      patchCategory(updatedCategory: IAttributeValue) {
        patchState(store, {
          attributeValues: store.attributeValues().map(cat => cat.id === updatedCategory.id ? updatedCategory : cat)
        });
      },

      clearCache() {
        localStorageService.removeItem(attributelistingStoreKey);
        localStorageService.removeItem(attributelistingStoreTimestampKey);
        patchState(store, initialState);
      },

      handleGroupClick(group: IItemTypeGroup) {
        this.setSelectedItemTypeGroupId(group.id);
        this.setSelectedAttributeValueId(null); // Clear selected attribute value
        this.setSelectedAttributeId(null); // Clear selected attribute
        this.loadAttributes(group.id); // Load attributes for the new group
      },
    };
  }),
  withHooks({
    onInit(store) {
      store.loadData();
    },
  })
);
