
export class AggridFurtherOptions {
  
    static readonly ACTIVATEVENDORUSERSCONTEXTMENUITEMNAME = 'Activate Selected Vendor Users';
    static readonly DEACTIVATEVENDORUSERSCONTEXTMENUITEMNAME = 'Deactivate Selected Vendor Users';
    static readonly ACTIVATEVENDORSCONTEXTMENUITEMNAME = 'Activate Selected Vendors';
    static readonly DEACTIVATEVENDORSCONTEXTMENUITEMNAME = 'Deactivate Selected Vendors';
    static readonly EXCELEXPORTSELECTEDROWSMENUITEMNAME = 'Export Selected Rows To Excel';
    static readonly EXCELEXPORTMENUITEMNAME = 'Export Grid To Excel';
    static readonly COPYMENUITEMNAME = 'Copy';
    static readonly COPYWITHHEADERSMENUITEMNAME = 'Copy with Headers';
    
}