<!-- dialog-wrapper.component.html (example) -->
<div class="dialog-wrapper">
  <h2 mat-dialog-title>
    Create Item Type(s) for {{ store.itemTypeGroup()?.description }}:
  </h2>
  <hr />

  <mat-dialog-content class="dialog-content">
    <mat-tab-group [selectedIndex]="selectedIndex" (selectedIndexChange)="selectedIndexChange($event)">
      <!-- REGIONS TAB -->
      <mat-tab label="Regions">
        <br />
        <div *ngIf="!store.loading()">
          <mat-radio-group [value]="store.selectedRegionId()">
            <mat-radio-button
              *ngFor="let region of store.regions()"
              [value]="region"
              class="radio-item"
            >
              <span
                [class]="region.class"
                style="font-size: 20px; padding-top:2px"
              ></span>
              <span style="padding-left: 5px; padding-top:2px">
                {{ region.description }}
              </span>
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </mat-tab>

      <!-- ATTRIBUTES & VALUES TAB -->
      <mat-tab label="Attributes & Values">
        <br />
        <div class="row" *ngIf="!store.loading()">
          <div class="col">
            <h6>Attributes</h6>

            <!-- If no attributes exist -->
            <div
              *ngIf="store.attributes().length === 0"
              class="alert alert-warning"
              role="alert"
            >
              No Attributes Exist
            </div>

            <!-- If attributes exist -->
            <div *ngIf="store.attributes().length > 0" class="itemtypeattributes">
              <ul class="list-group">
                <li
                  *ngFor="let attr of store.attributes()"
                  class="list-group-item d-flex justify-content-between align-items-center"
                  [class.active]="attr === store.selectedAttribute()"
                  (click)="loadAttributeValues(attr)"
                >
                  {{ attr.attributeName }}
                </li>
              </ul>
            </div>
          </div>

          <div class="col">
            <h6>Attribute Values</h6>

            <!-- If no values exist -->
            <div
              *ngIf="store.attributeValues().length === 0"
              class="alert alert-warning"
              role="alert"
            >
              No Values Exist
            </div>

            <!-- If values exist -->
            <div *ngIf="store.attributeValues().length > 0" class="itemtypeattributes">
              <ul class="list-group">
                <li
                  *ngFor="let val of store.attributeValues()"
                  class="list-group-item"
                  (dblclick)="doubleClickValue(val)"
                >
                  {{ val.valueName }}
                </li>
              </ul>
            </div>
          </div>
        </div>
        <hr />
        <div>
          <h5>Item Type String</h5>
          <p>{{ store.selectedValuesString() }}</p>
          <button mat-raised-button color="primary" (click)="addItemType()">
            Add Item Type
          </button>
        </div>
      </mat-tab>

      <!-- REVIEW/FINISH TAB -->
      <mat-tab label="Review/Finish">
        <br />
        <div>
          <h5>Generated Item Types</h5>
          <!-- Scrollable container for item types -->
          <div class="itemtypes-table-wrapper">
            <ul>
              <li *ngFor="let itemType of store.generatedItemTypes()">
                {{ itemType }}
              </li>
            </ul>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button mat-button (click)="previousStep()" [disabled]="selectedIndex === 0">
      Previous
    </button>
    <button mat-button (click)="nextStep()" [disabled]="isLastStep()">
      Next
    </button>
    <button mat-button *ngIf="isLastStep()" (click)="finish()">
      Finish
    </button>
    <button mat-button (click)="close()">
      Cancel
    </button>
  </mat-dialog-actions>
</div>
