<lib-secondary-navbar
[title]="'sidebar.clientmanagement' | translate"
></lib-secondary-navbar>

<div class="containerdiv">
  <tabset type="pills">
    <tab heading="Clients">
       <app-client-list></app-client-list>
    </tab>
  </tabset>
</div>
