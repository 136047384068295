import { patchState, signalStore, withComputed, withHooks, withMethods, withState } from "@ngrx/signals";
import { tapResponse } from '@ngrx/operators';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { computed, inject } from "@angular/core";
import { IInboxItem } from "../../../core/models/IInboxItem";
import { IInboxItemsFilter } from "../../../core/models/IInboxItemsFilter";
import { IInboxReadingPane } from "../../../core/models/IInboxReadingPane";
import { debounceTime, distinctUntilChanged, pipe, switchMap, tap } from "rxjs";
import { InboxItemsService } from "../../../core/services/inbox-items.service";
import { LocalStorageService } from "../../../core/services/local-storage.service";
import { InboxReadingPaneService } from "../../../core/services/inbox-reading-pane.service";
import { AppSettingsService } from "@tarifftel/components-library";

const storeKey = 'tarifftel_inbox_data';
const storeTimestampKey = storeKey + '_timestamp';
const CACHE_DURATION = 2 * 1 * 1000; // 2 seconds

type InboxState = {
  itemsLoading: boolean;
  paneLoading: boolean;
  readingPane: IInboxReadingPane | null;
  items: IInboxItem[];
  filter: IInboxItemsFilter;
  empty: boolean;
};

const initialState: InboxState = {
  items: [],
  readingPane: null,
  itemsLoading: true,
  paneLoading: false,
  filter: { query: '', unresolvedOnly: false, unreadOnly: false },
  empty: false,
};

export const InboxStore = signalStore(
  withState(initialState),
  withComputed(({ items, filter }) => ({
    sortedItems: computed(() => {
      return items()
        .filter(item => !filter().unreadOnly || item.unread)
        .filter(item => !filter().unresolvedOnly || item.unresolved)
        .toSorted((a, b) => -1 * a.sentAt.localeCompare(b.sentAt));
    })
  })),
  withMethods((
    store,
    itemsService = inject(InboxItemsService),
    readingPaneService = inject(InboxReadingPaneService),
    localStorageService = inject(LocalStorageService),
    appSettingsService = inject(AppSettingsService),
  ) => ({
    updateQuery(query: string): void {
      patchState(store, (state) => ({ filter: { ...state.filter, query } }));
      this.loadByQuery(query);
    },
    updateOnlyFilters(filters: Partial<IInboxItemsFilter>): void {
      patchState(store, (state) => ({ filter: { ...state.filter, ...filters } }));
    },
    loadAll(): void {
      const cachedState = localStorageService.getItem(storeKey) || {};
      const timestamp = parseInt(localStorageService.getItem(storeTimestampKey) || '0', 10);
      const now = Date.now();

      patchState(store, { itemsLoading: true });

      if (Object.keys(cachedState).length > 0 && (now - timestamp) < CACHE_DURATION) {
        patchState(store, { ...cachedState, itemsLoading: false, empty: cachedState.items.length === 0 });
      }
      else {
        itemsService.getAll().subscribe({
          next: (items) => {
            localStorageService.setItem(storeKey, JSON.stringify({ items }));
            localStorageService.setItem(storeTimestampKey, now.toString());
            patchState(store, { items, empty: items.length === 0 });
          },
          // error: (error: HttpErrorResponse) => console.error(error),
          complete: () => patchState(store, { itemsLoading: false }),
        });
      }
    },
    loadByQuery: rxMethod<string>(
      pipe(
        debounceTime(300),
        distinctUntilChanged(),
        tap(() => patchState(store, { itemsLoading: true })),
        switchMap((query) => {
          return itemsService.getByQuery(query).pipe(
            tapResponse({
              next: (items) => patchState(store, { items }),
              error: console.error,
              finalize: () => patchState(store, { itemsLoading: false })
            })
          );
        })
      )
    ),
    clearCache() {
      localStorageService.removeItem(storeKey);
      localStorageService.removeItem(storeTimestampKey);
    },
    selectItem(item: IInboxItem): void {
      var items = store.items();
      for (let i of items) {
        let selected = i.tenantId === item.tenantId && i.threadId === item.threadId;
        i.selected = selected;
        if (selected) {
          if (i.unread) {
            appSettingsService.setMessagesCount(appSettingsService.messagesCount() - 1);
          }

          i.unread = false;
        }
      }

      patchState(store, { items, paneLoading: true });

      readingPaneService.get(item).subscribe({
        next: readingPane => patchState(store, { readingPane }),
        complete: () => patchState(store, { paneLoading: false })
      });
    },
  })),
  withHooks({
    onInit(store) {
      console.log('withHooks onInit');
      store.loadAll();
    },
  })
);
