import { Injectable, signal } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Observable, tap } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { UserFriendlyHttpErrorMessageDialogComponent } from '../../features/settings/vendor-management/user-friendly-http-error-message-dialog/user-friendly-http-error-message-dialog.component';
import { IOrder } from '../models/IOrder';
import { IServerSideGetRowsRequest } from 'ag-grid-enterprise';
import { IOrderV2 } from '../models/IOrderV2';

@Injectable({
  providedIn: 'root'
})
export class OrderService {
  baseUrl = environment.apiUrl;
  ordersSignal = signal<IOrder[]|null>(null);
  readonly NUMBEROFORDERSTOTAKE = 50

  constructor(private toastrService:ToastrService,private httpClient:HttpClient,private dialog:MatDialog) { }

  /*
  // high number of records in the database so use this function to take a subset of the top/latest records
  getOrders(): Observable<any> {
    return this.httpClient.get(this.baseUrl + `PurchaseOrder/GetTopLatest/${this.NUMBEROFORDERSTOTAKE}`).pipe(
      tap({
        next:(orders:any) => this.ordersSignal.set(orders)
      })
    );
  }
    */

  getOrders(): Observable<any> {
    return this.httpClient.get(this.baseUrl + `/api/PurchaseOrder`).pipe(
      tap({
        next:(orders:any) => this.ordersSignal.set(orders)
      })
    );
  }

  getOrdersAggridPagination(aggridServerSideGetRowRequest:IServerSideGetRowsRequest): Observable<any> {
    return this.httpClient.post(this.baseUrl + `/api/PurchaseOrder/GetPurchaseOrdersAggridPaginationV2`,aggridServerSideGetRowRequest);
  }


  getOrderById(id:number) {
    return this.httpClient.get(this.baseUrl + `/api/PurchaseOrder/GetByIdV2/${id}`);
  }

  getOrderLineById(id:number) {
    return this.httpClient.get(this.baseUrl + `/api/PurchaseOrder/GetOrderLineByIdV2/${id}`);
  }

  updateOrderStatus(orderLineId: number, newStatus: string): Observable<any> {
    const url = `${this.baseUrl}/api/PurchaseOrdersAPI/UpdateOrderStatus`;
    const payload = {
      orderLineId: orderLineId,
      status: newStatus,
    };

    return this.httpClient.post(url, payload).pipe(
      tap({
        next: () => {
          this.toastrService.success('Order status updated successfully');
        },
        error: (error) => {
          this.toastrService.error('Failed to update order status');
          console.error('Error updating order status:', error);
        },
      })
    );
  }

  uploadOrdersViaExcel(file: File): Observable<any> {
    const formData = new FormData();
    formData.append('file', file);

    return this.httpClient.post(`${this.baseUrl}/api/PurchaseOrder/upload-orders`, formData);
  }

  submitClassification(payload: {
      tariffCode: string;
      productCode: string;
      itemTypeId: number;
      orderLineId: number;
      description: string;
      basic_duty_rate: string | null;
      validity_start_date: string;
      vatRate: string;
    }): Observable<any> {
    return this.httpClient.post<any>(`${this.baseUrl}/api/PurchaseOrder/SubmitClassification`, payload);
  }
}
