import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { SecondaryNavbarComponent} from '@tarifftel/components-library';
import { ISettingsCard } from './ISettingsCard';
import { BreadcrumbComponent } from "../../core/components/breadcrumb/breadcrumb.component";
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-settings',
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatCardModule,
    SecondaryNavbarComponent,
    BreadcrumbComponent,
    TranslateModule
],
  templateUrl: './settings.component.html',
  styleUrl: './settings.component.scss'
})
export class SettingsComponent implements OnInit {

  currentRouteTitle = 'Settings';  // default title

  settingOptions: ISettingsCard[] = [
    { settingsOptionTitle: "Vendor Management", settingsIcon: "fa-solid fa-truck-field fa-lg" },
    { settingsOptionTitle: "Size Management", settingsIcon: "fa-solid fa-weight-hanging fa-lg" },
    { settingsOptionTitle: "Department Management", settingsIcon: "fa-solid fa-building fa-lg" }
  ];

  constructor(
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.route.url.subscribe(url => {
      const lastSegment = url[url.length - 1]?.path;
      if (lastSegment === 'vendor-management' || lastSegment === 'size-management') {
        this.currentRouteTitle = lastSegment.replace('-', ' ').replace(/\b\w/g, (l) => l.toUpperCase());
      } else {
        this.currentRouteTitle = 'Settings';
      }
    });
  }

  settingsTileSelected(settingOptionSelected: string) {
    if (settingOptionSelected === "Vendor Management") {
      this.router.navigate(['/settings/vendor-management']);
    } else if (settingOptionSelected === "Size Management") {
      this.router.navigate(['/settings/size-management']);
    } else if (settingOptionSelected === "Department Management") {
      this.router.navigate(['/settings/department-management']);
    }
  }

  navigateToSettings() {
    this.router.navigate(['/settings']);
  }
  // @Output() brandingBtnEvent = new EventEmitter();
  // settingOptions:ISettingsCard[] = [
  //   {
  //     settingsOptionTitle:"Vendor Management",
  //     settingsIcon:"fa-solid fa-truck-field fa-lg"
  //   },
  //   {
  //     settingsOptionTitle:"Size Management",
  //     settingsIcon:"fa-solid fa-weight-hanging fa-lg"
  //   },
  //   {
  //     settingsOptionTitle:"Department Management",
  //     settingsIcon:"fa-solid fa-building fa-lg"
  //   }
  // ];
  // appSettings = inject(AppSettingsService)

  // constructor(public dialog: MatDialog,private router: Router) {}

  // settingsTileSelected(settingOptionSelected:string) {
  //   if (settingOptionSelected.toUpperCase() == this.settingOptions[0].settingsOptionTitle.toUpperCase()) {
  //     this.router.navigate(['/settings/vendor-management']);
  //   }
  //   else if (settingOptionSelected.toUpperCase() == this.settingOptions[1].settingsOptionTitle.toUpperCase()) {
  //     this.router.navigate(['/settings/size-management']);
  //   }
  // }
}
