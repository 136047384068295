import { Component} from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { CommonModule } from '@angular/common';
import { AggridVendorManagementComponent } from "./aggrid-vendor-management/aggrid-vendor-management.component";
import { SecondaryNavbarComponent } from '@tarifftel/components-library';
import { BreadcrumbComponent } from "../../../core/components/breadcrumb/breadcrumb.component";

@Component({
    selector: 'app-vendor-management-menu',
    standalone: true,
    templateUrl: './vendor-management.component.html',
    styleUrl: './vendor-management.component.scss',
    imports: [
    CommonModule,
    MatCardModule,
    AggridVendorManagementComponent,
    SecondaryNavbarComponent,
    BreadcrumbComponent
]
})
export class VendorManagementComponent {
  
}
