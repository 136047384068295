<div class="container-fluid">
  <div style="display: flex; justify-content: space-between; align-items: center;">
    <h6>Default Sizes for '{{ store.selectedItemTypeGroup()?.description }}' in '{{ store.selectedRegion()?.description }}':</h6>
    <button class="btn btn-primary" (click)="createDefaultSize()" >Create Default Size</button>
  </div>

  <!-- Use filteredSizes for default sizes only -->
  <div *ngIf="store.filteredSizes().length; else noSizes">
    <table class="table table-bordered table-striped mt-2" style="width: 100%">
      <thead>
        <tr>
          <th style="width: 30%;">Size Code (click to edit)</th>
          <th style="width: 70%;">Category Name</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let size of store.filteredSizes()">
          <td>
            <span class="clickable-description" (click)="editDefaultSize(size)">
              {{ size.sizeCode.length > 25 ? size.sizeCode.slice(0, 25) + '...' : size.sizeCode }}
            </span>
          </td>
          <td>{{ size.sizeCategoryName }}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <ng-template #noSizes>
    <div class="alert alert-warning mt-3" role="alert">
      No sizes found for '{{ store.selectedItemTypeGroup()?.description }}' in '{{ store.selectedRegion()?.description }}'.
    </div>
  </ng-template>
</div>


