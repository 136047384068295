import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IItemTypeGroup } from '../models/IItemTypeGroup';
import { IItemTypeGroupCategory } from '../models/IItemTypeGroupCategory';

@Injectable({
  providedIn: 'root'
})
export class ItemtypeGroupService {

  clientUrl = environment.apiUrl;

  constructor(private http: HttpClient) { }

  getAllItemTypeGroups(): Observable<IItemTypeGroup[]> {
    return this.http.get<IItemTypeGroup[]>(this.clientUrl + "/api/itemtypegroup");
  }

  getItemTypeGroupCategories(): Observable<IItemTypeGroupCategory[]> {
    return this.http.get<IItemTypeGroupCategory[]>(this.clientUrl + "/api/itemtypegroupcategory");
  }

  getActiveItemTypeGroups(): Observable<IItemTypeGroup[]> {
    return this.http.get<IItemTypeGroup[]>(this.clientUrl + "/api/itemtypegroup/active");
  }

  getActiveConstructionItemTypeGroups(): Observable<IItemTypeGroup[]> {
    return this.http.get<IItemTypeGroup[]>(this.clientUrl + "/api/itemtypegroup/activeconstruction");
  }

  getActiveMaterialItemTypeGroups(): Observable<IItemTypeGroup[]> {
    return this.http.get<IItemTypeGroup[]>(this.clientUrl + "/api/itemtypegroup/activematerial");
  }

  getManagedAtSizeItemTypeGroups(): Observable<IItemTypeGroup[]> {
    return this.http.get<IItemTypeGroup[]>(this.clientUrl + "/api/itemtypegroup/managedatsize");
  }

  // Updated to use PUT and the correct endpoint
  toggleItemTypeGroupStatus(id: number, isActive: boolean): Observable<any> {
    return this.http.put(`${this.clientUrl}/api/itemtypegroup/${id}/active`, isActive, {
        headers: {
            'Content-Type': 'application/json'
        }
    });
  }

   // Create a new item type group
   createItemTypeGroup(description: string, clarifyingInformation?: string): Observable<IItemTypeGroup> {
    // Normalize clarifyingInformation to null if it is undefined or empty
    const payload = {
      description,
      clarifyingInformation: clarifyingInformation ? clarifyingInformation : null
    };

    return this.http.post<IItemTypeGroup>(`${this.clientUrl}/api/itemtypegroup`, payload, {
      headers: { 'Content-Type': 'application/json' }
    });
  }

  // Update an existing item type group
  updateItemTypeGroup(itemTypeGroup: IItemTypeGroup): Observable<IItemTypeGroup> {
    return this.http.put<IItemTypeGroup>(`${this.clientUrl}/api/itemtypegroup/${itemTypeGroup.id}`, itemTypeGroup, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }

    // New method for creating an Item Type Group category
    createItemTypeGroupCategory(category: IItemTypeGroupCategory): Observable<IItemTypeGroupCategory> {
      return this.http.post<IItemTypeGroupCategory>(`${this.clientUrl}/api/itemtypegroupcategory`, category, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
    }

    updateItemTypeGroupCategory(category: IItemTypeGroupCategory): Observable<IItemTypeGroupCategory> {
      return this.http.put<IItemTypeGroupCategory>(`${this.clientUrl}/api/itemtypegroupcategory/${category.id}`, category, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
    }

    deleteItemTypeGroupCategory(categoryId: number): Observable<void> {
      return this.http.delete<void>(`${this.clientUrl}/api/itemtypegroupcategory/${categoryId}`);
    }
}
