<hr />
<div class="container-fluid">
  <!-- <div class="search-container">
    <div class="d-flex justify-content-end align-items-center mb-3 mt-2">
      <button class="btn btn-warning me-2" (click)="resetFilters()" aria-label="Reset filters and search">Reset</button>
      <button class="btn btn-secondary" (click)="forceRefresh()" aria-label="Refresh data">Refresh Data</button>
    </div>
  </div> -->
<!--
  <hr /> -->
  <div class="grid-container">
    <div class="left-column">
      <h6>Select an Option Below:</h6>
      <ul class="list-group">
        <li
          class="list-group-item d-flex justify-content-between align-items-center"
          [ngClass]="{ 'active': store.selectedList() === 'itemtypegroups' }"
          (click)="showTable('itemtypegroups')"
        >
          Item Type Groups
        </li>
        <li
          class="list-group-item d-flex justify-content-between align-items-center"
          [ngClass]="{ 'active': store.selectedList() === 'groupcategories' }"
          (click)="showTable('groupcategories')"
        >
          Group Categories
        </li>
        <li
          class="list-group-item d-flex justify-content-between align-items-center"
          [ngClass]="{ 'active': store.selectedList() === 'materials' }"
          (click)="showTable('materials')"
        >
          Materials
        </li>
        <li
          class="list-group-item d-flex justify-content-between align-items-center"
          [ngClass]="{ 'active': store.selectedList() === 'constructions' }"
          (click)="showTable('constructions')"
        >
          Constructions
        </li>
      </ul>

      <hr />

      <div *ngIf="store.selectedList() === 'materials' || store.selectedList() == 'constructions' || store.selectedList() === 'groupcategories'">
        <h6>Item Type Group(s):</h6>
        <div class="scrollable-item-group-list">
          <ul class="list-group">
            <li
              class="list-group-item d-flex justify-content-between align-items-center"

            >
              Bags and Accessories
            </li>
            <li
              class="list-group-item d-flex justify-content-between align-items-center"
              [ngClass]="'active'"
            >
              Household Textiles
          </li>
            <!-- <li
              class="list-group-item d-flex justify-content-between align-items-center"
              *ngFor="let group of store.itemTypeGroups()"
              [ngClass]="{ 'active': group.id === store.selectedItemTypeGroupId() }"
              (click)="store.handleGroupClick(group)"
            >
              {{ group.description }}
            </li> -->
          </ul>
        </div>
      </div>

      <div *ngIf="store.selectedList() === 'materials' || store.selectedList() === 'constructions'">
        <hr />
        <h6>Active Region:</h6>
        <div class="scrollable-item-group-list">
          <ul class="list-group">
            <li
              class="list-group-item d-flex justify-content-between align-items-center"
              [ngClass]="'active'"
            >
              United States of America
              <span [class]="'fi fi-us'" style="font-size:23px"></span>
            </li>
            <!-- <li
              class="list-group-item d-flex justify-content-between align-items-center"
              *ngFor="let region of store.regions()"
              [ngClass]="{ 'active': region.id === store.selectedRegionId() }"
              (click)="store.handleRegionClick(region)"
            >
              {{ region.description }}
              <span [class]="region.class" style="font-size: 23px"></span>
            </li> -->
          </ul>
        </div>
      </div>

    </div>
    <div class="right-column">
        <div *ngIf="store.selectedList() === 'itemtypegroups'">
          <app-item-type-group-list></app-item-type-group-list>
        </div>
        <div *ngIf="store.selectedList() === 'groupcategories'">
          <app-group-categories-list></app-group-categories-list>
        </div>
        <div *ngIf="store.selectedList() === 'materials'">
          <app-material-conditions-list></app-material-conditions-list>
        </div>
        <div *ngIf="store.selectedList() === 'constructions'">
          <app-group-constructions-list></app-group-constructions-list>
        </div>

    </div>

</div>

















