import { Component, EventEmitter, inject, Output } from '@angular/core';
import { AttributeListingStore } from '../store/attributelistingstore';
import { NgFor, NgIf } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { ItemTypeDialogComponent } from '../item-type-dialog/item-type-dialog.component';

@Component({
  selector: 'app-item-type-list',
  standalone: true,
  imports: [NgIf, NgFor],
  templateUrl: './item-type-list.component.html',
  styleUrl: './item-type-list.component.scss'
})
export class ItemTypeListComponent {

 store = inject(AttributeListingStore);
 dialog = inject(MatDialog);
 @Output() generatedItemTypesEvent = new EventEmitter<string[]>();

 ngOnInit() {
    const groupId = this.store.selectedItemTypeGroupId();
    if (groupId !== null) {
      console.log('Loading item types for group: ' + groupId);
      this.store.loadItemTypes(groupId);
    }
  }

 createItemType() {
    this.openWizard();
  }

  openWizard() {
    // 1) Grab the currently active Item Type Group from existing store
    const selectedGroup = this.store.selectedItemTypeGroup();

    // 2) Open the new dialog; pass in the group as data
    const dialogRef = this.dialog.open(ItemTypeDialogComponent, {
      width: '800px',
      height: '650px',
      data: {
        itemTypeGroup: selectedGroup
      }
    });

    // 3) Handle the dialog closing
    dialogRef.afterClosed().subscribe(result => {
        // If you need to do something on close, do it here !
    });
  }
}
