<lib-secondary-navbar
[title]="'sidebar.settings' | translate"
  (titleClick)="navigateToSettings()"
></lib-secondary-navbar>
<app-breadcrumb></app-breadcrumb>
<div class="settings-cards-container" *ngIf="currentRouteTitle === 'Settings'">
  <mat-card
    class="settings-card"
    appearance="outlined"
    *ngFor="let settingOption of settingOptions"
    (click)="settingsTileSelected(settingOption.settingsOptionTitle)"
  >
    <mat-card-content class="settings-card-content">
      <div class="row mt-3">
        <span class="{{ settingOption.settingsIcon }}"></span>
      </div>
      <div class="row mt-4">
        <h5>{{ settingOption.settingsOptionTitle }}</h5>
      </div>
    </mat-card-content>
  </mat-card>
</div>
