import { getState, patchState, signalStore, withComputed, withHooks, withMethods, withState } from "@ngrx/signals";
import { IRegion } from "../../../../core/models/IRegion";
import { computed, inject } from "@angular/core";
import { LocalStorageService } from "../../../../core/services/local-storage.service";
import { forkJoin, Observable } from 'rxjs';
import { IMaterial } from "../../../../core/models/IMaterial";
import { IMaterialCategory } from "../../../../core/models/IMaterialCategory";
import { RegionService } from "../../../../core/services/region.service";
import { ItemtypeGroupService } from "../../../../core/services/itemtype-group.service";
import { MaterialService } from "../../../../core/services/material.service";
import { IItemTypeGroup } from "../../../../core/models/IItemTypeGroup";

const materialcategoryStoreKey = 'tarifftel_material_category_list_data';
const materialcategoryStoreTimestampKey = 'tarifftel_material_category_list_data_timestamp';
const CACHE_DURATION = 60 * 60 * 1000; // 1 hour

type Filter = 'all' | 'active' | 'inactive';

type TariffTelMaterialListState = {
  materials: IMaterial[];
  materialcategories: IMaterialCategory[];
  regions: IRegion[];
  itemTypeGroups: IItemTypeGroup[];
  filter: Filter;
  searchTerm: string;
  selectedList: 'materials' | 'materialcategories';
  selectedItemTypeGroupId: number | null;
  selectedRegionId: number | null;
  selectedMaterialCategoryId: number | null;
  loading: boolean;
};

const initialState: TariffTelMaterialListState = {
  materials: [],
  materialcategories: [],
  regions: [],
  itemTypeGroups: [],
  filter: 'all',
  searchTerm: '',
  selectedList: 'materials',
  selectedItemTypeGroupId: null,
  selectedRegionId: null,
  selectedMaterialCategoryId: null,
  loading: true,
};

export const MaterialCategoryStore = signalStore(
  { providedIn: 'root' },
  withState(initialState),
  withComputed(({ materials, materialcategories, selectedList, selectedRegionId, selectedItemTypeGroupId, regions, itemTypeGroups }) => ({
    filteredList: computed(() => {
      let filtered: any[] = [];

      if (selectedList() === 'materials') {
        filtered = materials();

        // Apply filters for materials only
        if (selectedRegionId()) {
          filtered = filtered.filter(item => item.regionId === selectedRegionId());
        }

        if (selectedItemTypeGroupId()) {
          filtered = filtered.filter(item => item.itemTypeGroupId === selectedItemTypeGroupId());
        }

      } else {
        // For material categories, no need to apply these filters
        filtered = materialcategories();
      }

      return filtered;
    }),

    selectedRegion: computed(() => {
      return regions().find(region => region.id === selectedRegionId());
    }),

    selectedItemTypeGroup: computed(() => {
      return itemTypeGroups().find(group => group.id === selectedItemTypeGroupId());
    })
  })),
  withMethods((store) => {
    const regionService = inject(RegionService);
    const materialService = inject(MaterialService);
    const itemTypeGroupService = inject(ItemtypeGroupService);
    const localStorageService = inject(LocalStorageService);

    return {
      loadData() {
        const dataFromStorage = localStorageService.getItem(materialcategoryStoreKey) || {};
        const timestamp = parseInt(localStorageService.getItem(materialcategoryStoreTimestampKey) || '0', 10);
        const now = Date.now();

        patchState(store, { loading: true });

        if (Object.keys(dataFromStorage).length > 0 && (now - timestamp) < CACHE_DURATION) {
          patchState(store, dataFromStorage);
          patchState(store, { loading: false });

          // Ensure the first item type group is selected after loading from cache
          if (getState(store).selectedItemTypeGroupId === null) {
            this.selectFirstItemTypeGroup();
          }

          if (getState(store).selectedRegionId === null && getState(store).regions.length > 0) {
            this.setSelectedRegionId(getState(store).regions[0].id);
          }
        } else {
          forkJoin({
            materials: materialService.getMaterials(),
            materialcategories: materialService.getMaterialCategories(),
            regions: regionService.getActiveRegions(),
            itemTypeGroups: itemTypeGroupService.getActiveMaterialItemTypeGroups(),
          }).subscribe({
            next: ({ materials, materialcategories, regions, itemTypeGroups }) => {
              patchState(store, { materials, materialcategories, regions, itemTypeGroups });
              localStorageService.setItem(materialcategoryStoreKey, JSON.stringify({ materials, materialcategories, regions, itemTypeGroups }));
              localStorageService.setItem(materialcategoryStoreTimestampKey, now.toString());
              patchState(store, { loading: false });

              // Select the first item type group if no group is selected
              if (getState(store).selectedItemTypeGroupId === null) {
                this.selectFirstItemTypeGroup();
              }

              if (getState(store).selectedRegionId === null && regions.length > 0) {
                this.setSelectedRegionId(regions[0].id);
              }
            },
            error: (err) => {
              console.error('Failed to load materials, categories, regions, and Item Type Groups', err);
              patchState(store, { loading: false });
            }
          });
        }
      },

      getMaterialCategories(): Observable<IMaterialCategory[]> {
        return new Observable((observer) => {
          materialService.getMaterialCategories().subscribe({
            next: (categories) => {
              patchState(store, { materialcategories: categories });
              observer.next(categories);
              observer.complete();
            },
            error: (error) => {
              console.error('Failed to load material categories', error);
              observer.error(error);
            }
          });
        });
      },

      setSelectedItemTypeGroupId(id: number | null) {
        patchState(store, { selectedItemTypeGroupId: id });
      },

      setSelectedRegionId(id: number | null) {
        patchState(store, { selectedRegionId: id });
      },

      setSelectedMaterialCategoryId(id: number | null) {
        patchState(store, { selectedMaterialCategoryId: id });
      },

      showTable(selectedList: 'materials' | 'materialcategories') {
        patchState(store, { selectedList });

        // Ensure first item type group is selected only if switching to 'materials' and not already set
        if (selectedList === 'materials' && getState(store).selectedItemTypeGroupId === null) {
          this.selectFirstItemTypeGroup();
        }
      },

      setSearchTerm(term: string) {
        patchState(store, { searchTerm: term });
      },

      isDuplicateDescription(newMaterial: IMaterial): boolean {
        const materials = getState(store).materials;
        return materials.some(material => 
          material.description.toLowerCase() === newMaterial.description.toLowerCase()
          && material.itemTypeGroupId === newMaterial.itemTypeGroupId
          && material.regionId === newMaterial.regionId
          && material.id !== newMaterial.id
        );
      },
  
      createMaterial(material: IMaterial): Observable<IMaterial> {
        // Pass the full material object to the service
        return materialService.createMaterial(material);
      },

      updateMaterial(material: IMaterial): Observable<IMaterial> {
        return materialService.updateMaterial(material);
      },

      deleteMaterial(materialId: number): Observable<void> {
        return materialService.deleteMaterial(materialId);
      },

      // Add new item directly to the state
      addMaterial(newItem: IMaterial) {
        // Get the description of the selected category to display immediately
        const selectedCategory = getState(store).materialcategories.find(
          category => category.id === newItem.materialCategoryId
        );

        if (!selectedCategory) {
          throw new Error('Selected category is undefined');
        }

        // Set the materialCategoryName for immediate display
        const materialWithCategoryName = {
          ...newItem,
          materialCategoryName: selectedCategory.description
        };

        patchState(store, {
          materials: [...store.materials(), materialWithCategoryName].sort((a, b) => a.description.localeCompare(b.description))
        });
      },

      // Update the item in the state
      patchMaterial(updatedItem: IMaterial) {
        patchState(store, {
          materials: store.materials().map(item =>
            item.id === updatedItem.id ? updatedItem : item
          )
        });
      },

      removeMaterialFromStore(materialId: number) {
        patchState(store, {
          materials: getState(store).materials.filter(material => material.id !== materialId),
        });
      },

      isDuplicateCategory(description: string, categoryid?: number): boolean {
        const categories = getState(store).materialcategories;
        return categories.some(category => 
          category.description.toLowerCase() === description.toLowerCase() && category.id !== categoryid
        );
      },

      // Methods for material categories
      createCategory(category: IMaterialCategory): Observable<IMaterialCategory> {
        return materialService.createCategory(category);
      },

      updateCategory(category: IMaterialCategory): Observable<IMaterialCategory> {
        return materialService.updateCategory(category);
      },

      deleteCategory(categoryId: number): Observable<void> {
        return materialService.deleteCategory(categoryId);
      },

      addCategory(newCategory: IMaterialCategory) {
        patchState(store, {
          materialcategories: [...store.materialcategories(), newCategory].sort((a, b) => a.description.localeCompare(b.description))
        });
      },

      patchCategory(updatedCategory: IMaterialCategory) {
        patchState(store, {
          materialcategories: store.materialcategories().map(cat => cat.id === updatedCategory.id ? updatedCategory : cat)
        });
      },

      removeCategoryFromStore(categoryId: number) {
        patchState(store, {
          materialcategories: getState(store).materialcategories.filter(cat => cat.id !== categoryId),
          materials: getState(store).materials.filter(material => material.materialCategoryId !== categoryId),
        });
      },

      clearCache() {
        localStorageService.removeItem(materialcategoryStoreKey);
        localStorageService.removeItem(materialcategoryStoreTimestampKey);
        patchState(store, initialState);
      },

      selectFirstItemTypeGroup() {
        const { itemTypeGroups } = getState(store);
        if (itemTypeGroups.length > 0) {
          this.setSelectedItemTypeGroupId(itemTypeGroups[0].id);
        }
      },

      // Define the `handleGroupClick` method to set the selected item type group
      handleGroupClick(group: IItemTypeGroup) {
        this.setSelectedItemTypeGroupId(group.id);
      },

      // Define the `regionsWithConstructionsForGroup` method to get regions with constructions
      regionsWithMaterialsForGroup(groupId: number) {
        const { materials, regions } = getState(store);
        const regionsWithMaterials = new Set<number>();

        // Check for constructions matching the groupId and add the corresponding regionId to the set
        materials.forEach(material => {
          if (material.itemTypeGroupId === groupId) {
            regionsWithMaterials.add(material.regionId);
          }
        });

        // Map the regionIds to actual Region objects
        return regions.filter(region => regionsWithMaterials.has(region.id));
      },
    };
  }),
  withHooks({
    onInit(store) {
      store.loadData();
    },
  })
);

