import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IDepartment } from '../models/IDepartment';

@Injectable({
  providedIn: 'root'
})
export class DepartmentService {

  clientUrl = environment.apiUrl;

  constructor(private http: HttpClient) { }

  getAllDepartments(): Observable<IDepartment[]> {
    return this.http.get<IDepartment[]>(this.clientUrl + "/api/department");
  }

  getActiveDepartments(): Observable<IDepartment[]> {
    return this.http.get<IDepartment[]>(this.clientUrl + "/api/department/active");
  }

  // Updated to use PUT and the correct endpoint
  toggleDepartmentStatus(id: number, isActive: boolean): Observable<any> {
    return this.http.put(`${this.clientUrl}/api/department/${id}/active`, isActive, {
        headers: {
            'Content-Type': 'application/json'
        }
    });
  }

   // Create a new department
   createDepartment(description: string, departmentcode?: string): Observable<IDepartment> {
    const payload = {
      description,
      code: departmentcode ? departmentcode : null
    };

    return this.http.post<IDepartment>(`${this.clientUrl}/api/department`, payload, {
      headers: { 'Content-Type': 'application/json' }
    });
  }

  // Update an existing department
  updateDepartment(department: IDepartment): Observable<IDepartment> {
    return this.http.put<IDepartment>(`${this.clientUrl}/api/department/${department.id}`, department, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }
}
