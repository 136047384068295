<div class="container-fluid">
  <div class="row mb-3">
    <div class="col-12 col-md">
      <!-- Dynamic heading based on the selected type -->
      <h6 class="mb-3 mb-md-0">TariffTel Codes for ...</h6>
    </div>
    <div class="col-12 col-md-auto text-md-end">
      <!-- Container for buttons -->
      <div class="d-flex flex-wrap justify-content-md-end justify-content-start">
        <button class="btn btn-warning me-2 mb-2" aria-label="Create Temporary Code">
          Create Temporary Code
        </button>
      </div>
    </div>
  </div>
  <hr />
