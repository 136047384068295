import { Component, input } from '@angular/core';
import { IInboxReadingPane } from '../../../core/models/IInboxReadingPane';
import { RouterLink } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { InboxMessageListComponent } from '../inbox-message-list/inbox-message-list.component';

@Component({
  selector: 'app-inbox-reading-pane',
  standalone: true,
  imports: [RouterLink, MatButtonModule, MatIconModule, MatMenuModule, InboxMessageListComponent],
  templateUrl: './inbox-reading-pane.component.html',
  styleUrl: './inbox-reading-pane.component.scss'
})
export class InboxReadingPaneComponent {
  pane = input.required<IInboxReadingPane | null>();
  loading = input.required<boolean>();
}
