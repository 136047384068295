<!-- <div class="alert alert-primary mt-2 mb-2" role="alert">
  The Regions listed have their own Tariff Book. Select Active to Manage Item Types for selected region.
</div> -->
<hr />
<!-- Search and Filters Section -->
<div class="d-flex justify-content-between align-items-center mb-3">
  <!-- Search Field -->
  <div class="col-lg-6">
    <input
      type="text"
      class="form-control"
      placeholder="Search by Country Code or Description"
      [(ngModel)]="searchTerm"
      (ngModelChange)="onSearchTermChange($event)"
    />
  </div>

  <!-- Filters and Buttons -->
  <div class="col-lg-6 text-end">
    <div class="btn-group me-2" role="group" aria-label="Filter Buttons">
      <button
        [ngClass]="store.filter() === 'all' ? 'btn btn-primary' : 'btn btn-outline-primary'"
        (click)="store.changeFilter('all')"
        aria-label="Filter to show all regions"
      >
        All
      </button>
      <button
        [ngClass]="store.filter() === 'active' ? 'btn btn-primary' : 'btn btn-outline-primary'"
        (click)="store.changeFilter('active')"
        aria-label="Filter to show active regions"
      >
        Active
      </button>
      <button
        [ngClass]="store.filter() === 'inactive' ? 'btn btn-primary' : 'btn btn-outline-primary'"
        (click)="store.changeFilter('inactive')"
        aria-label="Filter to show inactive regions"
      >
        Not Active
      </button>
    </div>
    <!-- Reset Button -->
    <button class="btn btn-warning me-2" (click)="resetFilters()" aria-label="Reset filters and search">
      Reset
    </button>
    <!-- Refresh Button -->
    <button class="btn btn-secondary" (click)="forceRefresh()" aria-label="Refresh data">
      Refresh Data
    </button>
  </div>
</div>

<div class="container">
  <div class="item-type-tab-set">
    <div>
      <table class="table table-bordered table-striped" style="width: 100%">
        <thead>
          <tr>
            <th scope="col" style="width: 5%" class="text-center">Flag</th>
            <th scope="col" style="width: 8%" class="text-center">Country Code</th>
            <th scope="col" style="width: 60%">Description</th>
            <th scope="col" style="width: 5%" class="text-center">Active</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let region of store.filteredRegions()">
            <td scope="row" class="text-center">
              <span [class]="region.class" style="font-size: 23px"></span>
            </td>
            <td class="text-center">{{ region.countryCode }}</td>
            <td>{{ region.description }}</td>
            <td style="text-align: center">
              <ui-switch
                [checked]="region.active"
                (change)="onToggleActive(region)"
              >
              </ui-switch>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
