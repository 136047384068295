
import { Component, effect, EventEmitter, inject, Inject, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { ButtonWithMatSpinnerComponent } from "../../button-with-mat-spinner/button-with-mat-spinner.component";
import { CommonModule, TitleCasePipe } from '@angular/common';
import { MsalService } from '@azure/msal-angular';
import { MatDividerModule } from '@angular/material/divider';
import { Guid } from 'guid-typescript';
import { MatButtonModule } from '@angular/material/button';
import { MatTabsModule } from '@angular/material/tabs';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ReactiveFormControlInputComponent } from '../../reactive-form-control-input/reactive-form-control-input.component';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { HttpErrorResponse } from '@angular/common/http';
import { ConfirmModalComponent } from '../../../../../core/components/confirm-modal/confirm-modal.component';
import { UserFriendlyHttpErrorMessageDialogComponent } from '../../user-friendly-http-error-message-dialog/user-friendly-http-error-message-dialog.component';
import { Vendor } from '../../../../../core/models/vendor.model';
import { VendorUser } from '../../../../../core/models/vendor-user.model';
import { IVendorUser } from '../../../../../core/models/IVendorUser';
import { IVendor } from '../../../../../core/models/IVendor';
import { VendorManagementService } from '../../../../../core/services/vendor-management.service';
import { ToastrService } from 'ngx-toastr';
import { VendorStore } from '../../store/vendors.store';
import { GridApi } from 'ag-grid-enterprise';

@Component({
  selector: 'app-vendor-add-user-form',
  standalone: true,
  imports: [
    CommonModule,
    MatCardModule,
    MatInputModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormControlInputComponent,
    ButtonWithMatSpinnerComponent,
    MatDividerModule,
    MatTabsModule,
    MatProgressSpinnerModule,
    MatSlideToggle
],
  templateUrl: './vendor-user-form.component.html',
  styleUrl: './vendor-user-form.component.scss',
  providers:[
    TitleCasePipe
  ]
})
export class VendorUserFormComponent {
  title:string = "Add Vendor User"

  userDetailForm:FormGroup = new FormGroup({
    firstName: new FormControl(null,[Validators.required,Validators.maxLength(64)]),
    lastName: new FormControl(null,[Validators.required,Validators.maxLength(64)]),
    email:new FormControl(null,[Validators.required,Validators.email]),
    active: new FormControl(true),
    readOnlyPermission: new FormControl(false),
  });
  @Output() vendorUserDetailFormChange = new EventEmitter();
  @Input() editMode:boolean = false;
  @Input() vendorToEdit:Vendor|undefined
  @Input() vendorUserToEdit:VendorUser|undefined
  @Input() gridApi:GridApi|undefined
  @Input() showCancelButton:boolean = true;
  @Input() showMainFormCentralLoadingSpinner:boolean = true;
  @Output() cancelButtonSelected = new EventEmitter();
  showSaveLoadingSpinner:boolean = false;
  @Input() updateVendorUserMode:boolean = false; // if false its in addVendorUserMode
  updateVendorUserReadOnlyMode:boolean = false;
  showValidationRejectionAlertMessage:boolean = false;
  validationRejectionAlertMessage:string | undefined;
  vendorStore = inject(VendorStore)

  constructor(private toastrService:ToastrService,private dialog:MatDialog,private authService:MsalService,private titleCasePipe: TitleCasePipe,private vendorManagementService:VendorManagementService) {

    effect(() => {
      this.showSaveLoadingSpinner = this.vendorManagementService.vendorUserAddUpdateLoadingSignal();
    });

    this.userDetailForm.valueChanges.subscribe(change=>
    {
      this.vendorUserDetailFormChange.emit(this.userDetailForm);
    }
    )
  }

  ngOnInit() {
    this.vendorUserFormInit();
  }

  vendorUserFormInit() {
    this.setTitle();
    this.initFormValues();
    this.setVendorUserUpdateMode();
  }


  ngOnChanges() {
    this.initFormValues();
    this.setVendorUserUpdateMode();
  }

  setVendorUserUpdateMode() {
    if(this.updateVendorUserMode) {
      this.updateVendorUserReadOnlyMode = true;
    }
  }

  cancelBtnClickEvent() {
    this.userDetailForm.reset();
    this.vendorUserFormInit();
    this.cancelButtonSelected.emit();
  }

  setTitle() {
    this.title = (this.editMode) ? "Edit Vendor User":"Add Vendor User"
  }

  initFormValues() {
    if(this.editMode) {
      Object.keys(this.userDetailForm.controls).forEach(key => {
        if(this.vendorUserToEdit) {
          this.userDetailForm.controls[key].setValue(this.vendorUserToEdit[key as keyof VendorUser]);
        }
      });
    }
  }

  setUserDetailFormGroup(userDetailForm:FormGroup) {
    this.userDetailForm = userDetailForm;
  }

  submitUserDetailForm() {

    if(this.userDetailForm.dirty && this.userDetailForm.valid) {
      this.gridApi?.setGridOption("loading",true);
      this.vendorManagementService.vendorUserAddUpdateLoadingSignal.set(true);
      let mappedVendorUserObj = this.mapVendorUserFormValsToVendorUserObj();
      if(this.editMode && this.vendorToEdit && mappedVendorUserObj && this.vendorUserToEdit?.id) {
        this.submitUpdateVendorUser(this.vendorToEdit,this.vendorUserToEdit.id,mappedVendorUserObj)
      }
      else {
        if(this.vendorToEdit && mappedVendorUserObj) {
          this.submitAddVendorUser(this.vendorToEdit,mappedVendorUserObj)
        }
      }
    }

  }

  submitUpdateVendorUser(vendorToEdit:IVendor,vendorUserToEditId:number,mappedVendorUserObj:VendorUser) {
    this.vendorStore.updateVendorUser(vendorToEdit,vendorUserToEditId,mappedVendorUserObj).subscribe({
      next:(vendorUserResponse:any) => {
        this.userDetailForm.markAsPristine();
        this.updateVendorUserMode = true;
        this.vendorUserToEdit = vendorUserResponse;
        this.vendorUserFormInit();
        this.vendorManagementService.vendorUserAddUpdateLoadingSignal.set(false);
        this.vendorManagementService.vendorUserAddUpdateCloseDialogSignal.set(true);
        this.toastrService.success("Vendor User Successfully Updated!");
      },
      error:(error:any) => {
        this.dialog.open(UserFriendlyHttpErrorMessageDialogComponent);
        this.vendorManagementService.vendorUserAddUpdateLoadingSignal.set(false);
        this.vendorManagementService.vendorUserAddUpdateCloseDialogSignal.set(true);
      }
    });
  }

  submitAddVendorUser(vendorForVendorUser:Vendor,vendorUserToAdd:VendorUser) {
    this.vendorStore.addVendorUser(vendorForVendorUser,vendorUserToAdd).subscribe({
      next:(vendorUserResponse:any) => {
       this.userDetailForm.markAsPristine();
        this.updateVendorUserMode = true;
        this.vendorUserToEdit = vendorUserResponse;
        this.vendorUserFormInit();
        this.vendorManagementService.vendorUserAddUpdateLoadingSignal.set(false);
        this.vendorManagementService.vendorUserAddUpdateCloseDialogSignal.set(true);
        this.showValidationRejectionAlertMessage = false;
        this.toastrService.success("Vendor User Successfully Added!");
      },
      error:(errorResponse:HttpErrorResponse) => {
        if(errorResponse.status == 422 && errorResponse.error) {
          //validation error
          this.showValidationRejectionAlertMessage = true;
          this.validationRejectionAlertMessage = errorResponse.error;
          this.gridApi?.hideOverlay();
          this.vendorManagementService.vendorUserAddUpdateLoadingSignal.set(false);
        }
        else {
          this.showValidationRejectionAlertMessage = false;
          this.dialog.open(UserFriendlyHttpErrorMessageDialogComponent);
          this.gridApi?.hideOverlay();
          this.vendorManagementService.vendorUserAddUpdateLoadingSignal.set(false);
          this.vendorManagementService.vendorUserAddUpdateCloseDialogSignal.set(true);
        }


      }
    });
  }

  mapVendorUserFormValsToVendorUserObj() {
    let newVendorUser:VendorUser|undefined
    let userGuid:string|undefined = this.authService.instance.getActiveAccount()?.localAccountId;
    let submittedDateNow = new Date(Date.now());
    let displayName = `${this.userDetailForm.controls['firstName'].value} ${this.userDetailForm.controls['lastName'].value}`;
    let emptyEntraGuid = Guid.createEmpty();

    if(this.editMode && this.vendorUserToEdit && userGuid) {
      newVendorUser = new VendorUser(
        this.vendorUserToEdit.id,// overidden in the api
        this.vendorUserToEdit.createdAt,// overidden in the api
        submittedDateNow,// overidden in the api
        this.vendorUserToEdit.createdBy,// overidden in the api
        userGuid as any,// overidden in the api
        this.vendorUserToEdit.isDeleted,// overidden in the api
        this.userDetailForm.controls['firstName'].value,
        this.userDetailForm.controls['lastName'].value,
        this.userDetailForm.controls['email'].value,
        this.userDetailForm.controls['active'].value,
        this.userDetailForm.controls['readOnlyPermission'].value,
        this.vendorUserToEdit.vendorId,
        this.vendorUserToEdit.entraId,
        displayName
      )
    }
    else if(this.vendorToEdit && this.vendorToEdit?.id && userGuid) {
      newVendorUser = new VendorUser(
        0, // overidden in the api
        submittedDateNow, // overidden in the api
        submittedDateNow,// overidden in the api
        userGuid as any,// overidden in the api
        userGuid as any,// overidden in the api
        false,// overidden in the api
        this.userDetailForm.controls['firstName'].value,
        this.userDetailForm.controls['lastName'].value,
        this.userDetailForm.controls['email'].value,
        this.userDetailForm.controls['active'].value,
        this.userDetailForm.controls['readOnlyPermission'].value,
        this.vendorToEdit.id,
        emptyEntraGuid.toString() as any, // entra id overidden in the api
        displayName
      )
    }
    return newVendorUser;
  }

  convertPropNameToTitleCaseWithSpaces(propName:string):string {
    return this.titleCasePipe.transform(propName.replace(/([a-z])([A-Z])/g, '$1 $2'));
  }

  editFormSlideToggleChange(slideToggleElement:MatSlideToggle) {
    if(this.userDetailForm.dirty) {
      const dialogRef = this.dialog.open(ConfirmModalComponent, {
        data: {
          title:"",
          message:"Changes to the vendor user form have not been saved. Do you want to continue and discard the current changes?"
        }
      });

      dialogRef.afterClosed().subscribe(proceedWithReadOnlyMode => {
        if(proceedWithReadOnlyMode) {
          this.updateVendorUserReadOnlyMode = true;
          slideToggleElement.checked = !this.updateVendorUserReadOnlyMode;
          this.userDetailForm.reset();
          this.vendorUserFormInit();
        }
        else {
          this.updateVendorUserReadOnlyMode = false;
          slideToggleElement.checked = !this.updateVendorUserReadOnlyMode;
        }
      });
    }
    else {
      this.updateVendorUserReadOnlyMode = !this.updateVendorUserReadOnlyMode;
      slideToggleElement.checked = !this.updateVendorUserReadOnlyMode;
    }
  }
}
