import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { IInboxItem } from '../models/IInboxItem';
import { IInboxReadingPane } from '../models/IInboxReadingPane';

@Injectable({
  providedIn: 'root'
})
export class InboxReadingPaneService {

  clientUrl = environment.apiUrl;

  constructor(private http: HttpClient) { }

  get(item: IInboxItem): Observable<IInboxReadingPane> {
    const tenant = item.tenantId ?? 'master';
    return this.http.get<IInboxReadingPane>(`${this.clientUrl}/api/inbox/read/${tenant}/${item.threadId}`);
  }
}
