@if (orderIdFilter() === undefined) {
<lib-secondary-navbar
    [title]="'Inbox'"
></lib-secondary-navbar>
}

@if (store.empty()) {
<div class="d-flex">
    <div class="my-5 mx-auto alert alert-secondary d-flex" role="alert">
        <mat-icon class="me-2">
            info
        </mat-icon>
        Your inbox is empty
    </div>
</div>
}
@else {
<div class="container-fluid">
    <div id="inbox" class="row" [class.short]="orderIdFilter() !== undefined">
        <div class="col-5 h-100">
            <div class="d-flex flex-column h-100">
                <app-inbox-items-filter (onQueryChange)="store.updateQuery($event)"
                    (onOnlyCheckboxesChange)="store.updateOnlyFilters($event)">
                </app-inbox-items-filter>

                @if (store.itemsLoading()) {
                <div class="d-flex justify-content-center align-items-center">
                    <div class="spinner-border my-5" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>
                }
                @else {
                <app-inbox-item-list class="flex-grow-1 overflow-y-auto" [items]="store.sortedItems()"
                    (selectItem)="store.selectItem($event)">
                </app-inbox-item-list>
                }
            </div>
        </div>
        <div class="col-7 h-100">
            <app-inbox-reading-pane [pane]="store.readingPane()" [loading]="store.paneLoading()">
            </app-inbox-reading-pane>
        </div>
    </div>
</div>
}