<h2 mat-dialog-title>{{ editMode ? 'Edit Item Type Group' : 'Create Item Type Group' }}</h2>
<hr />
<form [formGroup]="itemTypeGroupForm" (ngSubmit)="onSave()">
  <mat-dialog-content>
    <!-- <mat-tab-group>
      <mat-tab label="Group Details"> -->
        <div class="tab-content form-group">
          <!-- Description Field -->
          <mat-form-field appearance="fill" class="full-width">
            <mat-label>Description</mat-label>
            <input matInput formControlName="description" placeholder="Enter a short and clear description" />

            <!-- Hint to guide users -->
            <mat-hint align="start">Used to filter and classify item types for product categorisation.</mat-hint>

            <!-- Character count -->
            <mat-hint align="end">
              {{ itemTypeGroupForm.get('description')?.value?.length || 0 }}/300
            </mat-hint>

            <!-- Validation errors -->
            <mat-error *ngIf="itemTypeGroupForm.get('description')?.hasError('required')">
              Description is required.
            </mat-error>
            <mat-error *ngIf="itemTypeGroupForm.get('description')?.hasError('maxlength')">
              Description cannot exceed 300 characters.
            </mat-error>
          </mat-form-field>

          <!-- Clarifying Information Field -->
          <mat-form-field appearance="fill" class="full-width">
            <mat-label>Clarifying Information</mat-label>
            <textarea
              matInput
              formControlName="clarifyingInformation"
              placeholder="Provide advisory notes on classification considerations"
              maxlength="1000"
              rows="5"
              style="resize: none;"
            ></textarea>

            <!-- Hint to guide users -->
            <mat-hint align="start">
              Provides classification guidance on whether a product fits within this category.
            </mat-hint>

            <!-- Character count -->
            <mat-hint align="end">
              {{ itemTypeGroupForm.get('clarifyingInformation')?.value?.length || 0 }}/1000
            </mat-hint>
          </mat-form-field>

          <!-- Managed at Size Checkbox -->
          <mat-checkbox formControlName="managedAtSize">
            Requires Size Management
            <mat-icon matTooltip="Indicates whether this group is size-related and available in Size Management." class="help-icon">
              help_outline
            </mat-icon>
          </mat-checkbox>

          <!-- Requires Construction Mgmt -->
          <mat-checkbox formControlName="requiresConstructionMgmt">
            Requires Construction Management
            <mat-icon matTooltip="Specifies if this group must manage constructions and categories in the Construction section." class="help-icon">
              help_outline
            </mat-icon>
          </mat-checkbox>

          <!-- Requires Material Mgmt -->
          <mat-checkbox formControlName="requiresMaterialMgmt">
            Requires Material Management
            <mat-icon matTooltip="Specifies if this group must manage materials and categories in the Materials section." class="help-icon">
              help_outline
            </mat-icon>
          </mat-checkbox>

        </div>
      <!-- </mat-tab>
      <mat-tab label="Group Categories">
        <div class="tab-content">
          <div class="input-group mb-3">
            <input
              type="text"
              class="form-control"
              placeholder="Add a group category"
              formControlName="newGroupCategory"
            />
            <button
              type="button"
              class="btn btn-primary"
              (click)="addGroupCategory()"
              [disabled]="itemTypeGroupForm.get('newGroupCategory')?.value?.trim()"
            >
              Add Group Category
            </button>
            <div *ngIf="groupCategories.length > 0">
              <table class="table table-bordered table-striped">
                <thead>
                  <tr>
                    <th>Group Category</th>
                    <th style="text-align: center">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let groupCategory of groupCategories.controls; let i = index">
                    <td><input matInput [formControl]="groupCategory" class="form-control"></td>
                    <td style="text-align: center">
                        <button
                          type="button"
                          class="btn btn-danger btn-sm"
                          (click)="removeGroupCategory(i)"
                        >
                        Remove
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div *ngIf="groupCategories.length === 0" class="alert alert-warning mt-2">No Categories added</div>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="Materials">
        <div class="tab-content">
          <div class="input-group mb-3">
            <input
              type="text"
              class="form-control"
              placeholder="Add a Material Condition"
              formControlName="newGroupCategory"
            />
            <button
              type="button"
              class="btn btn-primary"
              (click)="addGroupCategory()"
              [disabled]="itemTypeGroupForm.get('newGroupCategory')?.value?.trim()"
            >
              Add Group Category
            </button>
            <div *ngIf="groupCategories.length > 0">
              <table class="table table-bordered table-striped">
                <thead>
                  <tr>
                    <th>Group Category</th>
                    <th style="text-align: center">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let groupCategory of groupCategories.controls; let i = index">
                    <td><input matInput [formControl]="groupCategory" class="form-control"></td>
                    <td style="text-align: center">
                        <button
                          type="button"
                          class="btn btn-danger btn-sm"
                          (click)="removeGroupCategory(i)"
                        >
                        Remove
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div *ngIf="groupCategories.length === 0" class="alert alert-warning mt-2">No Categories added</div>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="Constructions">
        <div class="tab-content">

        </div>
      </mat-tab>
    </mat-tab-group> -->
  </mat-dialog-content>

  <hr />
  <mat-dialog-actions align="end">
    <button mat-button type="button" (click)="onCancel()">Cancel</button>
    <button mat-flat-button color="primary" type="submit" [disabled]="itemTypeGroupForm.invalid || isSaving">
      {{ editMode ? 'Update' : 'Create' }}
    </button>
  </mat-dialog-actions>
</form>
