import { CommonModule, NgFor, NgIf } from '@angular/common';
import { Component, inject, Inject } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatOptionModule } from '@angular/material/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { IConstructionCategory } from '../../../../core/models/IConstructionCategory';
import { ToastrService } from 'ngx-toastr';
import { ConstructionCategoryStore } from '../store/constructionstore';
import { ConfirmModalComponent } from '../../../../core/components/confirm-modal/confirm-modal.component';

@Component({
  selector: 'app-construction-category-dialog',
  standalone: true,
  imports: [ReactiveFormsModule,
    MatFormFieldModule,
    MatOptionModule,
    MatInputModule,
    MatSelectModule,
    MatCheckboxModule,
    MatButtonModule,
    MatDialogModule,
    CommonModule,
    NgIf],
  templateUrl: './construction-category-dialog.component.html',
  styleUrl: './construction-category-dialog.component.scss'
})
export class ConstructionCategoryDialogComponent {

  constructionCategoryForm: FormGroup;
  editMode: boolean;
  categories: IConstructionCategory[] = [];
  isSaving = false;

  store = inject(ConstructionCategoryStore);
  toastrService = inject(ToastrService);
  dialog = inject(MatDialog);

  constructor(
    public dialogRef: MatDialogRef<ConstructionCategoryDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { constructionCategory: IConstructionCategory; editMode: boolean },
    private fb: FormBuilder
  ) {
    this.editMode = data.editMode;

    // Initialize form with construction category data
    this.constructionCategoryForm = this.fb.group({
      description: [data.constructionCategory.description, [Validators.required, Validators.maxLength(750)]],
    });
  }

  onSave(): void {
    if (this.constructionCategoryForm.valid && !this.isSaving) {
      const updatedConstructionCategory: IConstructionCategory = {
        ...this.data.constructionCategory,
        ...this.constructionCategoryForm.value,
      };

      if (this.store.isDuplicateCategory(updatedConstructionCategory.description, updatedConstructionCategory.id)) {
        this.toastrService.error('Material Category Description already exists.');
        this.isSaving = false;
      } else if (this.editMode) {
        // Update construction category
        this.store.updateCategory(updatedConstructionCategory).subscribe({
          next: () => {
            this.toastrService.success('Construction Category updated successfully', 'Success');
            this.dialogRef.close(updatedConstructionCategory);
            this.isSaving = false;
          },
          error: (error) => {
            if (error.status === 500) {
              this.toastrService.error('Failed to update construction category', 'Error');
            } else {
              this.toastrService.error(error.error, 'Error');
            }
            this.isSaving = false;
          }
        });
      } else {
        // Create new construction category
        this.store.createCategory(updatedConstructionCategory).subscribe({
          next: (newConstructionCategory) => {
            this.toastrService.success('Construction Category created successfully', 'Success');
            this.dialogRef.close(newConstructionCategory);
            this.isSaving = false;
          },
          error: (error) => {
            if (error.status === 500) {
              this.toastrService.error('Failed to create construction category', 'Error');
            } else {
              this.toastrService.error(error.error, 'Error');
            }
            this.isSaving = false;
          }
        });
      }
    }
  }

  onDelete(): void {
    const constructionCategory = this.data.constructionCategory;

    if (constructionCategory.id) {
      this.store.deleteCategory(constructionCategory.id).subscribe({
        next: () => {
          this.toastrService.success('Construction category deleted successfully', 'Success');
          this.store.removeCategoryFromStore(constructionCategory.id);
          this.dialogRef.close(constructionCategory);
        },
        error: (error) => {
          if (error.status === 500) {
            this.toastrService.error('Failed to delete construction category', 'Error');
          } else {
            this.toastrService.error(error.error, 'Error');
          }
        }
      });
    }
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  openConfirmDeleteModal() {
    const entityName = 'Construction Category';
    const dialogRef = this.dialog.open(ConfirmModalComponent, {
          data: {
            title: `Are you sure you want to delete this ${ entityName }?`,
            message: `All information associated to this ${ entityName } will be permanently deleted.
              <span class="text-danger">This operation cannot be undone.</span>`
          },
          width: '400px',
        });
    
        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            this.onDelete();
          }
        });
  }
}
