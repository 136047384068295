import { getState, patchState, signalStore, withComputed, withHooks, withMethods, withState } from '@ngrx/signals';
import { IRegion } from '../../../../core/models/IRegion';
import { computed, effect, inject, WritableSignal } from '@angular/core';
import { RegionService } from '../../../../core/services/region.service';
import { LocalStorageService } from '../../../../core/services/local-storage.service';
import { MatDialog } from '@angular/material/dialog';
import { UserFriendlyHttpErrorMessageDialogComponent } from '../../../settings/vendor-management/user-friendly-http-error-message-dialog/user-friendly-http-error-message-dialog.component';

const regionStoreKey = 'tarifftel_regions';
const regionStoreTimestampKey = 'tarifftel_regions_timestamp';
const CACHE_DURATION = 60 * 60 * 1000; // 1 hour in milliseconds

type RegionFilter = 'all' | 'active' | 'inactive';

type RegionState = {
  regions: IRegion[];
  filter: RegionFilter;
  searchTerm: string;
}

const initialState: RegionState = {
  regions: [],
  filter: 'all',
  searchTerm: ''
}

export const RegionStore = signalStore(
  { providedIn: 'root'},
  withState(initialState),
  withComputed(({ regions, filter, searchTerm }) => ({
    activeRegions: computed(() =>
      regions().filter((regionItem) => regionItem.active)
    ),
    filteredRegions: computed(() => {
      let filtered = regions();

      // Apply Filter
      switch (filter()) {
        case 'active':
          filtered = filtered.filter(region => region.active);
          break;
        case 'inactive':
          filtered = filtered.filter(region => !region.active);
          break;
        default:
          // 'all' - no additional filtering
          break;
      }

      // Apply Search
      const term = searchTerm().toLowerCase().trim();
      if (term) {
        filtered = filtered.filter(region =>
          region.countryCode.toLowerCase().includes(term) ||
          region.description.toLowerCase().includes(term)
        );
      }

      return filtered;
    })
  })),
  withMethods((store) => {
    const regionService = inject(RegionService);
    const localStorageService = inject(LocalStorageService);
    const matDialogService = inject(MatDialog)

    return {
      // Initialize regions from the service or local storage
      loadRegions(vendorLoadingSignal?:WritableSignal<boolean>) {
        const regionsFromStorage = localStorageService.getItem(regionStoreKey) || [];
        const timestamp = parseInt(localStorageService.getItem(regionStoreTimestampKey) || '0', 10);
        const now = Date.now();

        if (regionsFromStorage.length > 0 && (now - timestamp) < CACHE_DURATION) {
          patchState(store, { regions: regionsFromStorage });

          if(vendorLoadingSignal) {
            vendorLoadingSignal.set(false);
          }
        } else {

          // Fetch from backend
          regionService.getRegions().subscribe({
            next: (regions) => {
              patchState(store, { regions });
              localStorageService.setItem(regionStoreTimestampKey, now.toString());

              if(vendorLoadingSignal) {
                vendorLoadingSignal.set(false);
              }
            },
            error: (error) => {
              console.error('Failed to load regions', error);
              if(vendorLoadingSignal) {
                vendorLoadingSignal.set(false);
                matDialogService.open(UserFriendlyHttpErrorMessageDialogComponent);
              }
            }
          });
        }
      },

      changeFilter(filter: RegionFilter) {
        patchState(store, { filter });
      },

      toggleActiveRegion(id: number) {
        patchState(store, {
          regions: store.regions().map(region => {
            if (region.id === id) {
              return {
                ...region,
                active: !region.active
              }
            }
            return region;
          })
        });
      },

      updateRegionStatus(region: IRegion) {
        return regionService.toggleRegionStatus(region.id, region.active);
      },

      // New method to set search term
      setSearchTerm(term: string) {
        patchState(store, { searchTerm: term });
      },

      // New method to clear cache
      clearCache() {
        localStorageService.removeItem(regionStoreKey);
        localStorageService.removeItem(regionStoreTimestampKey);
        patchState(store, { regions: [] }); // Optionally, clear regions in the store
      }
    };
  }),
  withHooks({
    onInit(store) {
      const localStorageService = inject(LocalStorageService);
      store.loadRegions();

      effect(() => {
        const state = getState(store);
        const now = Date.now();
        localStorageService.setItem(
          regionStoreKey,
          state.regions
        );
        localStorageService.setItem(
          regionStoreTimestampKey,
          now.toString()
        );
      });
    },
  })
);
