import { Component, inject, OnInit, ViewChild } from '@angular/core';
import { NotificationStore } from './store/notification.store';
import { MatMenu, MatMenuItem, MatMenuTrigger } from '@angular/material/menu';
import { DatePipe, NgFor } from '@angular/common';
import { INotification } from '../../core/models/INotification';
import { MatIcon } from '@angular/material/icon';
import { MatDivider } from '@angular/material/divider';
import { MatSlideToggle } from '@angular/material/slide-toggle';

@Component({
  selector: 'app-notification',
  standalone: true,
  imports: [MatMenu, MatMenuItem, DatePipe, NgFor, MatIcon, MatDivider, MatSlideToggle],
  templateUrl: './notification.component.html',
  styleUrl: './notification.component.scss',
  exportAs: 'menuInOtherComponent'
})
export class NotificationComponent {
  @ViewChild('notificationMenu', { static: true }) menu!: MatMenu
  @ViewChild(MatMenuTrigger) trigger!: MatMenuTrigger

  notifications: INotification[] = [];
  unreadNotifications: INotification[] = [];
  filterUnread = false;

  readonly notificationStore = inject(NotificationStore);

  markAsRead = (notificationId: number) => this.notificationStore.markAsRead(notificationId);

  getNotifications = () => this.filterUnread ? this.notificationStore.unreadNotifications() : this.notificationStore.notifications();

  toggleFilterUnread = () => this.filterUnread = !this.filterUnread;
}
