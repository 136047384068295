import { CommonModule, NgIf } from '@angular/common';
import { Component, inject, Inject, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatOptionModule } from '@angular/material/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { ISizeCategory } from '../../../../core/models/ISizeCategory';
import { DefaultSizeStore } from '../store/sizecategorystore';
import { ToastrService } from 'ngx-toastr';
import { ConfirmModalComponent } from '../../../../core/components/confirm-modal/confirm-modal.component';

@Component({
  selector: 'app-size-category-dialog',
  standalone: true,
  imports: [ReactiveFormsModule,
    MatFormFieldModule,
    MatOptionModule,
    MatInputModule,
    MatSelectModule,
    MatCheckboxModule,
    MatButtonModule,
    MatDialogModule,
    CommonModule,
    NgIf],
  templateUrl: './size-category-dialog.component.html',
  styleUrl: './size-category-dialog.component.scss'
})
export class SizeCategoryDialogComponent {

  sizeCategoryForm: FormGroup;
  editMode: boolean;
  categories: ISizeCategory[] = [];
  isSaving = false;

  store = inject(DefaultSizeStore);
  toastrService = inject(ToastrService);
  dialog = inject(MatDialog);

  constructor(
    public dialogRef: MatDialogRef<SizeCategoryDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { sizeCategory: ISizeCategory; editMode: boolean },
    private fb: FormBuilder
  ) {
    this.editMode = data.editMode;

    // Initialize form with size category data
    this.sizeCategoryForm = this.fb.group({
      categoryName: [data.sizeCategory.categoryName, [Validators.required, Validators.maxLength(200)]],
    });
  }

  onSave(): void {
    if (this.sizeCategoryForm.valid) {
      const itemTypeGroupId = this.store.selectedItemTypeGroupId(); // Unwrap the signal by calling it

      // Only include required fields in the payload
      const updatedSizeCategory: ISizeCategory = {
        ...this.data.sizeCategory,
        ...this.sizeCategoryForm.value,
        itemTypeGroupId
      };

      if (this.store.isDuplicateCategory(updatedSizeCategory)) {
        this.toastrService.error('Material Category Description already exists.');
        this.isSaving = false;
      } else if (this.editMode && !this.isSaving) {
        this.store.updateCategory(updatedSizeCategory as ISizeCategory).subscribe({
          next: () => {
            this.toastrService.success('Size Category updated successfully', 'Success');
            this.dialogRef.close(updatedSizeCategory);
            this.isSaving = false;
          },
          error: (error) => {
            if (error.status === 500) {
              this.toastrService.error('Failed to update size category', 'Error');
            } else {
              this.toastrService.error(error.error, 'Error');
            }
            this.isSaving = false;
          }
        });
      } else {
        this.store.createCategory(updatedSizeCategory as ISizeCategory).subscribe({
          next: (newCategory) => {
            this.toastrService.success('Size Category created successfully', 'Success');
            this.dialogRef.close(newCategory);
            this.isSaving = false;
          },
          error: (error) => {
            if (error.status === 500) {
              this.toastrService.error('Failed to create size category', 'Error');
            } else {
              this.toastrService.error(error.error, 'Error');
            }
            this.isSaving = false;
          }
        });
      }
    }
  }

  onDelete(): void {
    const sizeCategory = this.data.sizeCategory;

    if (sizeCategory.id) {
      this.store.deleteCategory(sizeCategory.id).subscribe({
        next: () => {
          this.toastrService.success('Size Category deleted successfully', 'Success');
          this.store.removeCategoryFromStore(sizeCategory.id);
          this.dialogRef.close(sizeCategory);
        },
        error: (error) => {
          if (error.status === 500) {
            this.toastrService.error('Failed to delete size category', 'Error');
          } else {
            this.toastrService.error(error.error, 'Error');
          }
        }
      });
    }
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  openConfirmDeleteModal() {
    const entityName = 'Size Category';
    const dialogRef = this.dialog.open(ConfirmModalComponent, {
          data: {
            title: `Are you sure you want to delete this ${ entityName }?`,
            message: `All information associated to this ${ entityName } will be permanently deleted.
              <span class="text-danger">This operation cannot be undone.</span>`
          },
          width: '400px',
        });
    
        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            this.onDelete();
          }
        });
  }
}
