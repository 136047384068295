import { Component, inject, OnInit } from '@angular/core';
import { AttributeListingStore } from '../store/attributelistingstore';
import { CommonModule, NgFor, NgIf } from '@angular/common';
import { AttributeValueDialogComponent } from '../attribute-value-dialog/attribute-value-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { take } from 'rxjs';
import { IAttributeValue } from '../../../../core/models/IAttributeValue';
import { ConfirmModalComponent } from '../../../../core/components/confirm-modal/confirm-modal.component';
import { MatLabel } from '@angular/material/form-field';

@Component({
  selector: 'app-attribute-value-list',
  standalone: true,
  imports: [CommonModule, NgIf, NgFor, MatLabel],
  templateUrl: './attribute-value-list.component.html',
  styleUrl: './attribute-value-list.component.scss'
})
export class AttributeValueListComponent implements OnInit {

  store = inject(AttributeListingStore);
  dialog = inject(MatDialog);

  // Access the selected attribute name from the store's computed signal
  selectedAttributeValueName = this.store.selectedAttributeValueName;
  selectedItemAttributeId = this.store.selectedItemTypeAttributeId;

  ngOnInit() {
  }

  selectAttributeValue(valueId: number) {
    this.store.setSelectedAttributeValueId(valueId);
  }

  createAttributeValue() {
    this.openDialog(false);
  }

  editAttributeValue() {
    const selectedAttributeValue = this.store.attributeValues().find(
      (attr) => attr.id === this.store.selectedAttributeValueId()
    );

    if (selectedAttributeValue) {
      this.openDialog(true, selectedAttributeValue);
    }
  }

  removeAttributeValue(attributeId: number, attributeValueId: number): void {
    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      data: {
        title: 'Confirm Deletion',
        message: 'Are you sure you want to delete this attribute value?'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const selectedAttributeValue = this.store.attributeValues().find(
          (attr) => attr.id === attributeValueId
        );
        if (selectedAttributeValue) {
          this.store.removeAttributeValue(attributeId, attributeValueId);
        }
      }
    });
  }

  openDialog(editMode: boolean, attribute?: IAttributeValue) {
    const itemTypeAttributeId = this.store.selectedItemTypeAttributeId();

    const dialogRef = this.dialog.open(AttributeValueDialogComponent, {
      width: '900px',
      data: {
        attributeValue: attribute ?? {
          id: 0, // New attribute
          valueName: '',
          description: '',
          itemAttributeId: itemTypeAttributeId,
          attributeValueAliases: [], // Default to empty array if undefined
          subAttributes: [], // Include subAttributes for new attributes
        },
        editMode,
      },
    });

    dialogRef.afterClosed().subscribe((result: IAttributeValue) => {
      if (result) {
        if (editMode) {
          this.store.patchAttributeValue(result); // Update the store for existing attribute
        } else {
          const alreadyExists = this.store.attributeValues().some(attr => attr.id === result.id);
          if (!alreadyExists) {
            this.store.addAttributeValue(result); // Add to the store for new attribute
          }
        }
      }
    });
  }
}
