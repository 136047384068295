import { Component, effect, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { VendorUserFormComponent } from "../vendor-user-form/vendor-user-form.component";
import { CommonModule } from '@angular/common';
import { Vendor } from '../../../../../core/models/vendor.model';
import { VendorUser } from '../../../../../core/models/vendor-user.model';
import { VendorManagementService } from '../../../../../core/services/vendor-management.service';
import { GridApi } from 'ag-grid-enterprise';

@Component({
  selector: 'app-vendor-user-form-dialog',
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    VendorUserFormComponent],
  templateUrl: './vendor-user-form-dialog.component.html',
  styleUrl: './vendor-user-form-dialog.component.scss'
})
export class VendorUserFormDialogComponent {
  editMode:boolean | undefined
  gridApi:GridApi
  vendorToEdit:Vendor|undefined
  vendorUserToEdit:VendorUser|undefined

  constructor(private dialog: MatDialogRef<VendorUserFormDialogComponent>,@Inject(MAT_DIALOG_DATA) private data: {editMode: boolean,vendorUserRow?:VendorUser,vendorRow?:Vendor,gridApi:GridApi},private vendorManagementService:VendorManagementService) {
    this.editMode = data.editMode;
    this.vendorToEdit = data.vendorRow;
    this.vendorUserToEdit = data.vendorUserRow;
    this.gridApi = data.gridApi;
    this.vendorManagementService.vendorUserAddUpdateCloseDialogSignal.set(false);

    effect(() => {
      if(this.vendorManagementService.vendorUserAddUpdateCloseDialogSignal()) {
        this.dialog.close();
      }

    });
  }

  closeModal() {
    this.dialog.close();
  }
}
