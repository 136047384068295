<div class="d-flex justify-content-between align-items-center mb-3 mt-2">
  <br />
  <div class="col-lg-8">
    <input
      type="text"
      class="form-control"
      placeholder="Search by Code or Description"
      [(ngModel)]="searchTerm"
      (ngModelChange)="onSearchTermChange($event)"
    />
  </div>

  <div class="col-lg-4 text-end">
    <button class="btn btn-primary me-2" (click)="createItemTypeGroup()" aria-label="Create Category">
      Create
    </button>

    <button class="btn btn-warning me-2" (click)="resetFilters()" aria-label="Reset filters and search">
      Reset
    </button>

    <button class="btn btn-secondary" (click)="forceRefresh()" aria-label="Refresh data">
      Refresh Data
    </button>
  </div>

</div>
<hr />
<div class="container">
  <div>
    <span [class]="'fi fi-us'" style="font-size:23px"></span>
    <span style="margin-left: 10px;">United States of America, Antiques and Art group condition(s):</span>
  </div>
  <div class="item-type-tab-set">
    <div>
      <table class="table table-bordered mt-2" style="width: 100%">
        <thead>
          <tr>
            <th scope="col" style="width: 45%">Material</th>
            <th scope="col" style="width: 45%">Category</th>
            <th scole="col" style="width: 10%; text-align:center">Select</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <div class="d-flex justify-content-between align-items-center">
                Acetate
                <!-- <span
                  class="badge rounded-pill bg-warning text-dark"
                  style="padding: 0.25em 0.5em; font-size: 0.9em;"
                  >
                    Clarifying Information
                </span> -->
              </div>
            </td>
            <td>MMF</td>
            <td style="text-align:center">
              <span class="clickable-description">Select</span></td>
          </tr>
          <tr>
            <td>
              <div class="d-flex justify-content-between align-items-center">
                Acrylic - Solid Plastic
                <!-- <span
                  class="badge rounded-pill bg-warning text-dark"
                  style="padding: 0.25em 0.5em; font-size: 0.9em;"
                  >
                    Clarifying Information
                </span> -->
              </div>
            </td>
            <td>Plastic</td>
            <td style="text-align:center">
              <span class="clickable-description">Select</span></td>
          </tr>
          <tr>
            <td>
              <div class="d-flex justify-content-between align-items-center">
                Acrylic - Textile
                <!-- <span
                  class="badge rounded-pill bg-warning text-dark"
                  style="padding: 0.25em 0.5em; font-size: 0.9em;"
                  >
                    Clarifying Information
                </span> -->
              </div>
            </td>
            <td>MMF</td>
            <td style="text-align:center">
              <span class="clickable-description">Select</span></td>
          </tr>
          <!-- <tr *ngFor="let itemTypeGroup of store.filteredItemTypeGroups()">
            <td>
              <div class="d-flex justify-content-between align-items-center">
                <span class="clickable-description" (click)="editItemTypeGroup(itemTypeGroup)">
                  {{ itemTypeGroup.description }}
                </span>
                <span
                  *ngIf="itemTypeGroup.clarifyingInformation"
                  class="badge bg-danger text-white"
                  style="padding: 0.25em 0.5em; font-size: 0.9em;"
                >
                  Clarifying Information
                </span>
              </div>
            </td>
            <td style="text-align: center">{{ itemTypeGroup.managedAtSize ? 'Yes' : 'No' }}</td>
            <td style="text-align: center">{{ itemTypeGroup.requiresConstructionMgmt ? 'Yes' : 'No' }}</td>
            <td style="text-align: center">{{ itemTypeGroup.requiresMaterialMgmt ? 'Yes' : 'No' }}</td>
          </tr> -->
        </tbody>
      </table>

    </div>
  </div>
</div>
