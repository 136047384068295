import { NgFor, NgIf } from '@angular/common';
import { Component, inject, Inject } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatOptionModule } from '@angular/material/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { IMaterialCategory } from '../../../../core/models/IMaterialCategory';
import { IMaterial } from '../../../../core/models/IMaterial';
import { ToastrService } from 'ngx-toastr';
import { MaterialCategoryStore } from '../store/materialstore';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-material-dialog',
  standalone: true,
  imports: [ReactiveFormsModule,
    MatFormFieldModule,
    MatOptionModule,
    MatInputModule,
    MatSelectModule,
    MatCheckboxModule,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    MatTooltipModule,
    NgFor,
    NgIf],
  templateUrl: './material-dialog.component.html',
  styleUrl: './material-dialog.component.scss'
})
export class MaterialDialogComponent {
  materialForm: FormGroup;
  editMode: boolean;
  categories: IMaterialCategory[] = [];
  isSaving = false;

  store = inject(MaterialCategoryStore);
  toastrService = inject(ToastrService);

  constructor(
    public dialogRef: MatDialogRef<MaterialDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { material: IMaterial; editMode: boolean },
    private fb: FormBuilder
  ) {
    this.editMode = data.editMode;

    // Initialize form with material data
    this.materialForm = this.fb.group({
      description: [data.material.description, [Validators.required, Validators.maxLength(750)]],
      clarifyingInformation: [data.material.clarifyingInformation, [Validators.maxLength(2000)]],
      category: [data.material.materialCategoryId, [Validators.required]],
      manMade: [data.material.manMade || false],
      metallizedYarn: [data.material.metallizedYarn || false],
      includeInCustomsDescription: [data.material.includeInCustomsDescription || false]
    });

    // Load categories for the dropdown
    this.loadCategories();
  }

  // Method to load categories, fetched from the service
  loadCategories(): void {
    this.store.getMaterialCategories().subscribe({
      next: (categories) => {
        this.categories = categories;
      },
      error: () => {
        this.toastrService.error('Failed to load categories', 'Error');
      }
    });
  }

  onSave(): void {
    if (this.materialForm.valid && !this.isSaving) {
      this.isSaving = true;

      const selectedCategory = this.categories.find(
        category => category.id === this.materialForm.value.category
      );

      const updatedMaterial: IMaterial = {
        ...this.data.material,
        ...this.materialForm.value,
        materialCategoryId: this.materialForm.value.category || null,
        materialCategoryName: selectedCategory ? selectedCategory.description : null,
        regionId: this.data.material.regionId,
        itemTypeGroupId: this.data.material.itemTypeGroupId,
        itemTypeGroup: this.data.material.itemTypeGroup
      };

      if (this.store.isDuplicateDescription(updatedMaterial)) {
        this.toastrService.error('Material Description already exists.');
        this.isSaving = false;
      } else if (this.editMode) {
        // Update material
        this.store.updateMaterial(updatedMaterial).subscribe({
          next: () => {
            this.toastrService.success('Material updated successfully', 'Success');
            this.dialogRef.close(updatedMaterial);
            this.isSaving = false;
          },
          error: (error) => {
            if (error.status === 500) {
              this.toastrService.error('Failed to update material', 'Error');
            } else {
              this.toastrService.error(error.error, 'Error');
            }
            this.isSaving = false;
          }
        });
      } else {
        // Create new material
        this.store.createMaterial(updatedMaterial).subscribe({
          next: (newMaterial) => {
            this.toastrService.success('Material created successfully', 'Success');
            this.dialogRef.close(newMaterial);
            this.isSaving = false;
          },
          error: (error) => {
            if (error.status === 500) {
              this.toastrService.error('Failed to create material', 'Error');
            } else {
              this.toastrService.error(error.error, 'Error');
            }
            this.isSaving = false;
          }
        });
      }
    }
  }

  onDelete(): void {
    const material = this.data.material;

    if (material.id) {
      this.store.deleteMaterial(material.id).subscribe({
        next: () => {
          this.toastrService.success('Material deleted successfully', 'Success');
          this.store.removeMaterialFromStore(material.id);
          this.dialogRef.close(material);
        },
        error: (error) => {
          if (error.status === 500) {
            this.toastrService.error('Failed to delete material', 'Error');
          } else {
            this.toastrService.error(error.error, 'Error');
          }
        }
      });
    }
  }

  onCancel(): void {
    this.dialogRef.close();
  }
}
