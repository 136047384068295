import { CommonModule, NgFor, NgIf } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, inject, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { Router } from '@angular/router';

@Component({
  selector: 'app-demo-classifier',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatButtonModule,
    MatRadioModule,
    MatIcon,
    MatInputModule,
    MatOptionModule,
    MatSelectModule,
    NgFor,
    NgIf
  ],
  templateUrl: './demo-classifier.component.html',
  styleUrl: './demo-classifier.component.scss'
})
export class DemoClassifierComponent {
  classificationForm: FormGroup;
  classificationResult: any = null;
  steps: any[] = [];
  selectedIndex = 0;
  selectedValues: { [key: string]: string } = {};
  currentImageUrl: string | null = null;
  isClassifying = false;

  router = inject(Router)

  // Stage 2-specific state
  leftList: any[] = [];
  rightListOptions: { value: string; imageUrl: string | null }[] = [];
  additionalInfo: any = {};
  selectedLeftItem: string | null = null;

  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<DemoClassifierComponent>,
    private http: HttpClient,
    @Inject(MAT_DIALOG_DATA) public data: { orderLine: any; headerTitle: string }
  ) {
    this.classificationForm = this.fb.group({});
  }

  ngOnInit() {
    this.http.get('/assets/test-config.json').subscribe((config: any) => {
      this.steps = config.stages;
      this.initializeForm();
      this.initializeStage3(); // Initialize Stage 3-specific data
    });
  }

  navigateToAskQuestion() {
    console.log(this.data.orderLine);
    this.router.navigate(['/orders/order-details', this.data.orderLine.orderId, 'order-line-details', this.data.orderLine.id, 'ask']);
    this.close();
  }

  initializeForm() {
    this.steps.forEach(stage => {
      stage.fields?.forEach((field: any) => {
        this.classificationForm.addControl(
          field.key,
          this.fb.control('', Validators.required) // No default value
        );
      });
    });

    // Add fields explicitly for dynamic selections in Stage 3
    this.classificationForm.addControl('productGroup', this.fb.control('', Validators.required));
    this.classificationForm.addControl('materials', this.fb.control('', Validators.required));
    this.classificationForm.addControl('productDetails', this.fb.control('', Validators.required));
    this.classificationForm.addControl('consignedFrom', this.fb.control('', Validators.required));
    this.classificationForm.addControl('aircraftType', this.fb.control('', Validators.required));
    this.classificationForm.addControl('additionalproduct', this.fb.control('', Validators.required));
  }

  initializeStage3() {
    const stage3 = this.steps.find(stage => stage.id === 'stage3');
    if (stage3) {
      this.leftList = stage3.lists[0].items;
      this.additionalInfo = stage3.additionalInfo;

      // Automatically select the first property type if available
      if (this.leftList.length > 0) {
        this.selectLeftItem(this.leftList[0].key);
      }

      // Add selectedRightItem to the form group
      this.classificationForm.addControl('selectedRightItem', this.fb.control('', Validators.required)); // No default value
    }
  }

  calculateClassification() {
    const formValues = this.classificationForm.value;

    const stage4 = this.steps.find(stage => stage.id === 'stage4');
    const classifications = stage4?.classifications || [];

    // Normalize the comparison to handle missing fields
    this.classificationResult = classifications.find((classification: any) => {
      return Object.keys(classification.conditions).every(key => {
        const expected = classification.conditions[key];
        const actual = formValues[key] || ''; // No default value
        return expected === actual;
      });
    })?.result || null;

    if (!this.classificationResult) {
      console.error('No matching classification found. Form Values:', formValues);
    }
  }

  selectLeftItem(key: string) {
    this.selectedLeftItem = key;

    const stage3 = this.steps.find(stage => stage.id === 'stage3');
    if (stage3) {
      const dependencies = stage3.lists[1].dependencies;
      this.rightListOptions = dependencies[key] || [];
    }

    const previousSelection = this.selectedValues[key] || '';
    this.classificationForm.get('selectedRightItem')?.setValue(previousSelection);

    // Update image for the selected value if applicable
    const selectedOption = this.rightListOptions.find(option => option.value === previousSelection);
    this.currentImageUrl = selectedOption?.imageUrl || null;
  }

  selectRightItem(optionValue: string) {
    if (this.selectedLeftItem) {
      const selectedOption = this.rightListOptions.find(option => option.value === optionValue);

      // Assign the value to the correct form control based on the selected left item
      this.classificationForm.get(this.selectedLeftItem)?.setValue(optionValue);

      this.selectedValues[this.selectedLeftItem] = optionValue; // Save the selection
      this.classificationForm.get('selectedRightItem')?.setValue(optionValue);

      // Update the image (if applicable)
      this.currentImageUrl = selectedOption?.imageUrl || null;
    }
  }

  getFieldOptions(fieldKey: string): string[] {
    const stage = this.steps.find(s => s.id === `stage${this.selectedIndex + 1}`);
    const field = stage?.fields.find((f: any) => f.key === fieldKey);
    return field?.options || [];
  }

  get selectedLeftLabel(): string {
    const selectedItem = this.leftList.find(item => item.key === this.selectedLeftItem);
    return selectedItem ? selectedItem.label : '';
  }

  onSubmit() {
    console.log('Form Submitted:', this.classificationForm.value);
    this.dialogRef.close(this.classificationForm.value); // Close dialog and pass data
  }

  close() {
    this.dialogRef.close(); // Close the dialog without passing data
  }

  finish(): void {
    // You can also pass any additional data if needed
    this.dialogRef.close(true); // Send confirmation back to the parent
  }

  previousStep() {
    if (this.selectedIndex > 0) {
      this.selectedIndex--;
    }
  }

  nextStep() {
    if (this.selectedIndex < this.steps.length - 1) {
      this.selectedIndex++;
      if (this.selectedIndex === 3) { // Assuming Stage 4 is the Classification stage
        this.showLoadingScreen();
      }
    }
  }

  private showLoadingScreen() {
    this.isClassifying = true;
    const randomDelay = Math.floor(Math.random() * 3000) + 4000; // Random delay between 4 and 7 seconds
    setTimeout(() => {
      this.calculateClassification();
      this.isClassifying = false;
    }, randomDelay);
  }
}

interface FormField {
  label: string;
  type: string;
  key: string;
  options?: string[];
  computed?: boolean;
  dependencies?: { [key: string]: string[] };
}
