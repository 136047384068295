import { NgFor, NgIf } from '@angular/common';
import { Component, inject } from '@angular/core';
import { DefaultSizeStore } from '../store/sizecategorystore';
import { IDefaultSize } from '../../../../core/models/IDefaultSize';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import { DefaultSizeDialogComponent } from '../default-size-dialog/default-size-dialog.component';

@Component({
  selector: 'app-size-list',
  standalone: true,
  imports: [NgIf, NgFor],
  templateUrl: './size-list.component.html',
  styleUrl: './size-list.component.scss'
})
export class SizeListComponent {

  store = inject(DefaultSizeStore);
  toastrService = inject(ToastrService);
  dialog = inject(MatDialog);

  // Open the dialog to create or edit default size
  openDefaultSizeDialog(editMode: boolean, defaultsize?: IDefaultSize) {
    const regionId = this.store.selectedRegionId();
    const itemTypeGroupId = this.store.selectedItemTypeGroupId();

    const dialogRef = this.dialog.open(DefaultSizeDialogComponent, {
      width: '600px',
      data: {
        defaultsize: defaultsize ?? { id: 0, sizeCode: '', sizeCategoryId: null, regionId, itemTypeGroupId },
        editMode
      }
    });

    dialogRef.afterClosed().subscribe((result: IDefaultSize) => {
      if (result) {
        if (editMode) {
          this.store.patchSize(result);
        } else {
          this.store.addSize(result);
        }
      }
    });
  }

  createDefaultSize() {
    this.openDefaultSizeDialog(false);
  }

  editDefaultSize(defaultsize: IDefaultSize) {
    this.openDefaultSizeDialog(true, defaultsize);
  }
}


