import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { SecondaryNavbarComponent } from '@tarifftel/components-library';

@Component({
  selector: 'app-onboarding',
  standalone: true,
  imports: [MatButtonModule, SecondaryNavbarComponent],
  templateUrl: './onboarding.component.html',
  styleUrl: './onboarding.component.scss'
})
export class OnboardingComponent {
  onSubmit() {
    
  }
}
