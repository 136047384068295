import { CommonModule, NgFor, NgIf } from '@angular/common';
import { Component, Inject, inject } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatOptionModule } from '@angular/material/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { MatRadioModule } from '@angular/material/radio';
import { IItemTypeGroup } from '../../../../core/models/IItemTypeGroup';
import { IItemAttribute } from '../../../../core/models/IItemAttribute';
import { ItemTypeDialogStore } from '../store/itemtypedialogstore';
import { IAttributeValue } from '../../../../core/models/IAttributeValue';

@Component({
  selector: 'app-item-type-dialog',
  standalone: true,
  imports: [ReactiveFormsModule,
    CommonModule,
    MatFormFieldModule,
    MatOptionModule,
    MatInputModule,
    MatSelectModule,
    MatCheckboxModule,
    MatRadioModule,
    MatIconModule,
    MatButtonModule,
    MatDialogModule,
    MatTooltipModule,
    MatTabsModule,
    TabsModule,
    FormsModule,
    NgFor,
    NgIf],
  templateUrl: './item-type-dialog.component.html',
  styleUrl: './item-type-dialog.component.scss'
})
export class ItemTypeDialogComponent {

  // Tab index for mat-tab-group
  selectedIndex = 0;

  // Inject the new store
  store = inject(ItemTypeDialogStore);

  constructor(
    public dialogRef: MatDialogRef<ItemTypeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { itemTypeGroup: IItemTypeGroup },
  ) {}

  ngOnInit(): void {
    // 1) Set the item type group in the store
    if (this.data?.itemTypeGroup) {
      this.store.setItemTypeGroup(this.data.itemTypeGroup);
      // 2) Load attributes for that group
      this.store.loadAttributes();
    }

    // The store already loads regions on init (store's onInit hook)
  }

  // Tab Navigation
  selectedIndexChange(index: number) {
    this.selectedIndex = index;
  }

  previousStep() {
    this.selectedIndex = Math.max(0, this.selectedIndex - 1);
  }

  nextStep() {
    this.selectedIndex = Math.min(2, this.selectedIndex + 1);
  }

  // Region Selection
  selectRegion(regionId: number) {
    this.store.selectRegion(regionId);
  }

  // Attribute Selection
  loadAttributeValues(attribute: IItemAttribute) {
    this.store.loadAttributeValues(attribute.id);
  }

  // Single-click to highlight, double-click to add to the store's string
  doubleClickValue(value: IAttributeValue) {
    this.store.addValueToSelection(value.valueName);
  }

  // Add the built string to a "generated item types" array
  addItemType() {
    this.store.addItemTypeToGeneratedList();
  }

  // Finish the Wizard
  finish() {
    // Pass the finished Item Types back to the parent
    const finalItemTypes = this.store.generatedItemTypes();
    this.dialogRef.close({ generatedItemTypes: finalItemTypes });
  }

  close() {
    this.dialogRef.close();
  }

  // Just for the template to know if we are at the last tab
  isLastStep(): boolean {
    return this.selectedIndex === 2;
  }
}
