import { getState, patchState, signalStore, withComputed, withHooks, withMethods, withState } from "@ngrx/signals";
import { computed, effect, inject } from "@angular/core";
import { Observable } from 'rxjs';
import { LocalStorageService } from "../../../core/services/local-storage.service";
import { IClientAccessDto } from "../../../core/models/IClientAccessDto";
import { ClientService } from "../../../core/services/client.service";
import { IClient } from "../../../core/models/IClient";

const clientStoreKey = 'tarifftel_clients';
const clientTimestampKey = 'tarifftel_clients_timestamp';
const CACHE_DURATION = 60 * 60 * 1000; // 1 hour in milliseconds

type ClientFilter = 'all' | 'active' | 'inactive';

type ClientState = {
  clients: IClient[];
  filter: ClientFilter;
  searchTerm: string;
};

const initialState: ClientState = {
  clients: [],
  filter: 'all',
  searchTerm: ''
};

export const ClientStore = signalStore(
  { providedIn: 'root' },
  withState(initialState),
  withComputed(({ clients, filter, searchTerm }) => ({
    activeRegions: computed(() =>
      clients().filter((clientItem) => clientItem.active)
    ),
    filteredClients: computed(() => {
      let filtered = clients();

      // Apply Filter
      switch (filter()) {
        case 'active':
          filtered = filtered.filter(client => client.active);
          break;
        case 'inactive':
          filtered = filtered.filter(client => !client.active);
          break;
        default:
          // 'all' - no additional filtering
          break;
      }

      // Apply Search
      const term = searchTerm().toLowerCase().trim();
      if (term) {
        filtered = filtered.filter(client =>
          client.clientName.toLowerCase().includes(term)
        );
      }

      return filtered;
    })
  })),
  withMethods((store) => {
    const clientService = inject(ClientService);
    const localStorageService = inject(LocalStorageService);

    return {
      loadClients() {
        // Retrieve from local storage and parse the JSON string into an array
        const clientsFromStorage = JSON.parse(localStorageService.getItem(clientStoreKey) || '[]');
        const timestamp = parseInt(localStorageService.getItem(clientTimestampKey) || '0', 10);
        const now = Date.now();

        if (clientsFromStorage.length > 0 && (now - timestamp) < CACHE_DURATION) {
          patchState(store, { clients: clientsFromStorage });
          clientService.getClients().subscribe({
            next: (clients) => {
              patchState(store, { clients });
              localStorageService.setItem(clientStoreKey, JSON.stringify(clients));
              localStorageService.setItem(clientTimestampKey, now.toString());
            },
            error: (error) => {
              console.error('Failed to load Clients', error);
            }
          });
        } else {
          // Fetch from backend
          clientService.getClients().subscribe({
            next: (clients) => {
              patchState(store, { clients });
              localStorageService.setItem(clientStoreKey, JSON.stringify(clients));
              localStorageService.setItem(clientTimestampKey, now.toString());
            },
            error: (error) => {
              console.error('Failed to load Clients', error);
            }
          });
        }
      },

      toggleActiveClient(id: number) {
        const client = store.clients().find(c => c.id === id);
        if (client) {
          clientService.toggleClientStatus(id, !client.active).subscribe({
            next: () => {
              patchState(store, {
                clients: store.clients().map(c =>
                  c.id === id ? { ...c, active: !c.active } : c
                ),
              });
            },
            error: (error) => console.error('Failed to toggle client status', error),
          });
        }
      },

      changeFilter(filter: ClientFilter) {
        patchState(store, { filter });
      },

      updateClientStatus(client: IClient): Observable<any> {
        return clientService.toggleClientStatus(client.id, client.active);
      },

      createClient(newClient: IClient): Observable<IClient> {
        return clientService.createClient(newClient);
      },

      updateClient(updatedClient: IClient): Observable<IClient> {
        return clientService.updateClient(updatedClient);
      },

      setSearchTerm(term: string) {
        patchState(store, { searchTerm: term });
      },

       // Add new item directly to the state
       addClient(newItem: IClient) {
        patchState(store, {
          clients: [...store.clients(), newItem]
            .sort((a, b) => a.clientName.localeCompare(b.clientName))
        });
      },

      patchClient(updatedClient: IClient) {
        patchState(store, {
          clients: store.clients().map(client =>
            client.id === updatedClient.id ? updatedClient : client
          )
        });
      },

      loadClientAccessSettings(clientId: number): Observable<IClientAccessDto> {
        return clientService.getClientAccessSettings(clientId);
      },

      updateClientAccessSettings(clientId: number, accessSettings: IClientAccessDto): Observable<any> {
        return clientService.updateClientAccessSettings(clientId, accessSettings);
      },

      clearCache() {
        localStorageService.removeItem(clientStoreKey);
        localStorageService.removeItem(clientTimestampKey);
        patchState(store, { clients: [] }); // Optionally, clear clients in the store
      }
    };
  }),
  withHooks({
    onInit(store) {
      const localStorageService = inject(LocalStorageService);
      store.loadClients();

      effect(() => {
        const state = getState(store);
        const now = Date.now();
        // Save the state as a string in local storage
        localStorageService.setItem(clientStoreKey, JSON.stringify(state.clients));
        localStorageService.setItem(clientTimestampKey, now.toString());
      });
    },
  })
);
