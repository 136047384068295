import { NgIf } from '@angular/common';
import { Component, Inject, inject } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { DepartmentStore } from '../store/departmentstore';
import { ToastrService } from 'ngx-toastr';
import { IDepartment } from '../../../../core/models/IDepartment';

@Component({
  selector: 'app-department-dialog',
  standalone: true,
  imports: [ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSlideToggleModule,
    MatButtonModule,
    MatDialogModule,
    MatCheckboxModule,
    NgIf],
  templateUrl: './department-dialog.component.html',
  styleUrl: './department-dialog.component.scss'
})
export class DepartmentDialogComponent {

  departmentForm: FormGroup;
  editMode: boolean;
  isSaving = false;

  store = inject(DepartmentStore);
  toastrService = inject(ToastrService);

  constructor(
    public dialogRef: MatDialogRef<DepartmentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { department: IDepartment; editMode: boolean },
    private fb: FormBuilder
  ) {
    this.editMode = data.editMode;

    this.departmentForm = this.fb.group({
      description: [data.department.description, [Validators.required, Validators.maxLength(500)]],
      code: [data.department.code, [Validators.maxLength(50)]]
    });
  }

  onSave(): void {
    if (this.departmentForm.valid && !this.isSaving) {
      this.isSaving = true;

      const updatedDepartment: IDepartment = {
        ...this.data.department,
        ...this.departmentForm.value
      };

      if (this.editMode) {
        // Update mode
        this.store.updateDepartment(updatedDepartment).subscribe({
          next: () => {
            this.toastrService.success('Department updated successfully', 'Success');
            this.dialogRef.close(updatedDepartment); // Close dialog and return updated item
            this.isSaving = false;
          },
          error: () => {
            this.toastrService.error('Failed to update Department', 'Error');
            this.isSaving = false;
          }
        });
      } else {
        // Create mode
        this.store.createDepartment(updatedDepartment).subscribe({
          next: (newItem) => {
            this.toastrService.success('Department created successfully', 'Success');
            this.dialogRef.close(newItem); // Close dialog and return new item
            this.isSaving = false;
          },
          error: () => {
            this.toastrService.error('Failed to create Department', 'Error');
            this.isSaving = false;
          }
        });
      }
    }
  }


  onCancel(): void {
    this.dialogRef.close();
  }
}

