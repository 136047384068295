import { Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { clientRoutes } from './features/clients/client.routes';
import { dashboardRoutes } from './features/dashboard/dashboard-routes';
import { itemTypeRoutes } from './features/item-type-management/item-type.routes';
import { inboxRoutes } from './features/inbox/inbox.routes';
// import { notificationRoutes } from './features/notification/notification.routes';
import { orderRoutes } from './features/orders/order.routes';
import { tenantSelectionRoute } from './features/tenant-selection/tenant-selection.routes';
import { reportRoutes } from './features/reports/report-routes';
import { settingsRoutes } from './features/settings/settings.routes';
import { OnboardingComponent } from './features/unauth/onboarding/onboarding.component';
import { LoggedOutComponent } from './features/unauth/logged-out/logged-out.component';
import { LoginFailedComponent } from './features/unauth/login-failed/login-failed.component';
import { ErrorComponent } from './features/unauth/error/error.component';

export const routes: Routes = [
  {
    path: '',
    loadComponent: () => import('./features/dashboard/dashboard.component').then(m => m.DashboardComponent),
    canActivate: [MsalGuard],
  },
  {
    path: 'logged-out',
    component: LoggedOutComponent,
  },
  {
    path: 'login-failed',
    component: LoginFailedComponent,
  },
  {
    path: 'consent-error',
    component: ErrorComponent,
  },
  {
    path: 'onboarding',
    component: OnboardingComponent,
    canActivate: [MsalGuard],
  },
  ...clientRoutes,
  ...dashboardRoutes,
  ...itemTypeRoutes,
  ...inboxRoutes,
  // ...notificationRoutes,
  ...orderRoutes,
  ...reportRoutes,
  ...tenantSelectionRoute,
  ...settingsRoutes
];
