<div style="display: flex; justify-content: space-between; align-items: center;">
  <h6>Size Categories for '{{ store.selectedItemTypeGroup()?.description }}':</h6>
  <!-- Create/update buttons hidden for now as Size Categories will not be updated by people on this screen(it should just be Babies,
   Children, Adults and needs to be this for classification). Can delete properly once this is 100% confirmed -->
  <button class="btn btn-primary" (click)="createSizeCategory()" disabled hidden>Create Category</button>
</div>

<!-- Use filteredCategories for size categories only -->
<div *ngIf="store.filteredCategories().length; else noCategories">
  <table class="table table-bordered table-striped mt-2" style="width: 100%">
    <thead>
      <tr>
        <th style="width: 100%;">Category Name</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let category of store.filteredCategories()">
        <td class="d-flex justify-content-between align-items-center">
          <!-- <span class="clickable-description" (click)="editSizeCategoryDescription(category)"></span> -->
            {{ category.categoryName.length > 100 ? category.categoryName.slice(0, 100) + '...' : category.categoryName }}
          <!-- </span> -->
        </td>
      </tr>
    </tbody>
  </table>
</div>

<ng-template #noCategories>
  <div class="alert alert-warning mt-3" role="alert">
    No size categories found.
  </div>
</ng-template>
