import { Component, effect, inject, signal, ViewChild, WritableSignal } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatDrawer } from '@angular/material/sidenav';
import { AppSettingsService, FooterComponent, ISideBarMenuItem, PrimaryNavbarComponent, SidebarComponent } from '@tarifftel/components-library';
import { AuthService } from './core/services/auth.service';
import { environment } from '../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { NotificationComponent } from './features/notification/notification.component';
import { UnreadCountersService } from './core/services/unread-counters.service';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterModule, MatSidenavModule, SidebarComponent, PrimaryNavbarComponent, FooterComponent, NotificationComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {

  translateService = inject(TranslateService);
  authService = inject(AuthService);
  appSettings = inject(AppSettingsService);

  @ViewChild(NotificationComponent) notificationComponent!: NotificationComponent;

  ngOnInit() {
    //this.authService.login();
  }
  unreadCountersService = inject(UnreadCountersService);

  // Existing properties
  tariffTelApiUrl = environment.apiUrl;
  companyWebUrl = environment.companyWebUrl;
  companyName = environment.companyName;
  productName = environment.productName;
  // backgroundColor = environment.backgroundColor;
  // secondaryColor = environment.secondaryColor;
  // iconColor = environment.iconColor;
  // hoverColor = environment.hoverColor;

  profilePicture = '../assets/defaultprofile.jpg';

  // Add default logo from environment or static path
  // defaultLogo = environment.defaultLogo || '../assets/asda.png'; // Make sure this path exists
  @ViewChild('drawer') drawer: MatDrawer | undefined;
  currentYear: number = new Date().getFullYear();

  // controls notification dropdown visibility
  showNotifications = false;

  topSideMenuItems: WritableSignal<ISideBarMenuItem[]> = signal<ISideBarMenuItem[]>([
    { fieldName: 'sidebar.dashboard', icon: 'fa-solid fa-chart-line fa-lg', routeLink: '/', controlText: '', controlName: '' },
    { fieldName: 'sidebar.myorders', icon: 'fa-solid fa-truck-plane fa-lg', routeLink: '/orders', controlText: '', controlName: '' },
    { fieldName: 'sidebar.myreports', icon: 'fa-solid fa-file fa-lg', routeLink: '/reports', controlText: '', controlName: '' },
    { fieldName: 'sidebar.settings', icon: 'fa-solid fa-cog fa-lg', routeLink: '/settings', controlText: '', controlName: '' },
    { fieldName: 'sidebar.clientmanagement', icon: 'fa-solid fa-handshake fa-lg', routeLink: '/clients', controlText: '', controlName: '' },
    { fieldName: 'sidebar.itemtypemanagement', icon: 'fa-solid fa-list-check fa-lg', routeLink: '/item-type-management', controlText: '', controlName: '' },
  ]);

  constructor(private router: Router) {
    // Set default environment settings
    this.appSettings.setProfilePicture(this.profilePicture);
    this.appSettings.setCompanyWebUrl(this.companyWebUrl);
    this.appSettings.setCompanyName(this.companyName);
    this.appSettings.setProductName(this.productName);
    this.appSettings.setUserName(this.authService.getName());

    // Use effect() to monitor the side nav toggle signal. CHANGE EFFECT TO USE OUTPUT PROPERTY !!
    effect(() => {
      this.translateService.use(this.appSettings.currentLanguage());

      if (this.drawer) {
        if (this.appSettings.toggleSideNav() !== this.drawer.opened) {
          this.onSideNavToggled();  // Only call this if the signal doesn't match the current state
        }
      }
    });
   }

  onNotificationClick() {
    this.notificationComponent.notificationStore.loadNotifications();
  }

   onMessagesClick() {
     this.router.navigate(['/inbox']);
   }

  onLogoutClick() {
    this.authService.logout();
  }

   onSideNavToggled() {
     if (this.drawer) {
       // Toggle the drawer and update the signal based on its final state
       this.drawer.toggle().then(() => {
         // Set the signal to reflect the actual state of the drawer
         if (this.drawer) {
           this.appSettings.toggleSideNav.set(this.drawer.opened);
         }
       });
     }
   }

  onMenuItemSelected(menuItem: ISideBarMenuItem | null) {
    if (menuItem) {
      const routePath = menuItem.routeLink;

      // Check if we're in a child route of settings, and navigate to settings if so
      if (routePath === '/settings' && this.router.url.includes('/settings/')) {
        this.router.navigate(['/settings']);
      } else {
        this.router.navigate([routePath]);
      }
    }
  }
 }
