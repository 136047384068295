<div style="display: flex; justify-content: space-between; align-items: center;">
  <h6>Material Categories</h6>
  <button class="btn btn-primary" (click)="createMaterialCategory()">Create Category</button>
</div>

<div>
  <table class="table table-bordered table-striped mt-2" style="width: 100%">
    <thead>
      <tr>
        <th style="width:60%">Material (click to edit)</th>
      </tr>
    </thead>
    <tbody>
      <!-- Access the signal value by invoking it as a function -->
      <tr *ngFor="let category of store.filteredList()">
        <td class="d-flex justify-content-between align-items-center">
          <span class="clickable-description" (click)="editMaterialCategoryDescription(category)">
            {{ category.description.length > 100 ? category.description.slice(0, 100) + '...' : category.description }}
          </span>
        </td>
      </tr>
    </tbody>
  </table>
</div>


