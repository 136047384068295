import { Component, input } from '@angular/core';
import { IInboxMessage } from '../../../core/models/IInboxMessage';
import { DatePipe, formatDate } from '@angular/common';

@Component({
  selector: 'app-inbox-message-list',
  standalone: true,
  imports: [DatePipe],
  templateUrl: './inbox-message-list.component.html',
  styleUrl: './inbox-message-list.component.scss'
})
export class InboxMessageListComponent {
  messages = input.required<IInboxMessage[]>();

  alertClasses(msg: IInboxMessage) {
    return {
      'alert-primary': msg.fromUs,
      'alert-secondary': !msg.fromUs
    };
  }

  alertContainerClasses(message: IInboxMessage) {
    return {
      'ms-auto': message.fromUs,
      'ms-2': !message.fromUs,
      'me-auto': !message.fromUs,
      'me-2': message.fromUs,
    };
  }

  showMessageDate(index: number) {
    const msg = this.messages()[index];
    const olderMsg = this.messages()[index + 1];

    if (!olderMsg) {
      return true;
    }

    return formatDate(msg.sentAt, 'yyyy-MM-dd', 'en-US') !== formatDate(olderMsg.sentAt, 'yyyy-MM-dd', 'en-US');
  }
}
