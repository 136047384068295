import { Guid } from "guid-typescript";
import { VendorUser } from "./vendor-user.model";
import { IVendor } from "./IVendor";
import { IRegion } from "./IRegion";

export class Vendor implements IVendor
{


    constructor(
        public id: number,
        public createdAt: Date,
        public updatedAt: Date,
        public createdBy: Guid,
        public updatedBy: Guid,
        public isDeleted: boolean,
        public companyName: string,
        public email: string,
        public vendorUsers: VendorUser[],
        public status: boolean,
        public regionId: number,
        public vendorIdentifier: string,
        public administratorName?: string | undefined,
        public phoneNumber?: string | undefined,
        public streetAddress?: string | undefined,
        public city?: string | undefined,
        public state?: string | undefined,
        public postalCode?: string | undefined,
        public country?: string | undefined,
        public lastOrderDate?: string | undefined,
        public region?: IRegion| undefined,
        
    ) {

    }

}
