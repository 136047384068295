import { Component, EventEmitter, Inject, inject, Input, Output } from '@angular/core';
import { IItemTypeGroup } from '../../../../core/models/IItemTypeGroup';
import { FormArray, FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgFor, NgIf } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatButtonModule } from '@angular/material/button';
import { ToastrService } from 'ngx-toastr';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ItemTypeGroupStore } from '../store/itemtype-group.store';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTabsModule } from '@angular/material/tabs';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { group } from '@angular/animations';

@Component({
  selector: 'app-item-type-group-dialog',
  standalone: true,
  imports: [ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSlideToggleModule,
    MatButtonModule,
    MatDialogModule,
    MatCheckboxModule,
    MatIconModule,
    MatTooltipModule,
    MatTabsModule,
    TabsModule,
    NgIf],
  templateUrl: './item-type-group-dialog.component.html',
  styleUrl: './item-type-group-dialog.component.scss'
})
export class ItemTypeGroupDialogComponent {
  itemTypeGroupForm: FormGroup;
  editMode: boolean;
  isSaving = false;

  store = inject(ItemTypeGroupStore);
  toastrService = inject(ToastrService);

  constructor(
    public dialogRef: MatDialogRef<ItemTypeGroupDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { itemTypeGroup: IItemTypeGroup; editMode: boolean },
    private fb: FormBuilder
  ) {
    this.editMode = data.editMode;

    this.itemTypeGroupForm = this.fb.group({
      description: [data.itemTypeGroup.description, [Validators.required, Validators.maxLength(300)]],
      managedAtSize: [data.itemTypeGroup.managedAtSize || false],
      requiresConstructionMgmt: [data.itemTypeGroup.requiresConstructionMgmt || false],
      requiresMaterialMgmt: [data.itemTypeGroup.requiresMaterialMgmt || false],
      clarifyingInformation: [
        data.itemTypeGroup.clarifyingInformation || '',
        Validators.maxLength(1000)
      ],
      // groupcategories: this.fb.array(
      //   data.itemTypeGroup.groupCategories?.map(category =>
      //     this.fb.control(category.description, Validators.required)
      //   ) || []
      // ),
      newGroupCategory: ['']
    });
  }

  get groupCategories(): FormArray<FormControl> {
    return this.itemTypeGroupForm.get('groupCategories') as FormArray<FormControl>;
  }

  addGroupCategory(): void {
    const groupCategoryValue = this.itemTypeGroupForm.get('newCategory')?.value?.trim();
    if (groupCategoryValue) {
      this.groupCategories.push(this.fb.control(groupCategoryValue, Validators.required));
      this.itemTypeGroupForm.get('newCategory')?.reset();
    }
  }

  removeGroupCategory(index: number): void {
    this.groupCategories.removeAt(index);
  }

  onSave(): void {
    if (this.itemTypeGroupForm.valid && !this.isSaving) {
      this.isSaving = true;

      const updatedItemTypeGroup: IItemTypeGroup = {
        ...this.data.itemTypeGroup,
        ...this.itemTypeGroupForm.value
      };

      if (this.editMode) {
        // Update mode
        this.store.updateItemTypeGroup(updatedItemTypeGroup).subscribe({
          next: () => {
            this.toastrService.success('Item Type Group updated successfully', 'Success');
            this.dialogRef.close(updatedItemTypeGroup); // Close dialog and return updated item
            this.isSaving = false;
          },
          error: () => {
            this.toastrService.error('Failed to update Item Type Group', 'Error');
            this.isSaving = false;
          }
        });
      } else {
        // Create mode
        this.store.createItemTypeGroup(updatedItemTypeGroup).subscribe({
          next: (newItem) => {
            this.toastrService.success('Item Type Group created successfully', 'Success');
            this.dialogRef.close(newItem); // Close dialog and return new item
            this.isSaving = false;
          },
          error: () => {
            this.toastrService.error('Failed to create Item Type Group', 'Error');
            this.isSaving = false;
          }
        });
      }
    }
  }


  onCancel(): void {
    this.dialogRef.close();
  }
}

