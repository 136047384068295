<button mat-raised-button
[ariaLabel]="ariaLabel" 
color={{color}} 
disabled={{disabled}} 
[attr.routerLink]="routerLink !== '' ? routerLink : null"
>{{label}}
<mat-icon *ngIf="this.showSpinner">
    <mat-spinner color="{{spinnerColor}}" diameter="{{spinnerDiameter}}"></mat-spinner>
</mat-icon>
</button>
