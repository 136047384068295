import { CommonModule } from '@angular/common';
import { Component, input } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TabDetailListGroupComponent } from "./tab-detail-list-group/tab-detail-list-group.component";
import { IOrderTopPanelTabItems } from './IOrderTopPanelTabItems';
import { InboxDirectoryComponent } from "../../../inbox/inbox-directory/inbox-directory.component";
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatDivider } from '@angular/material/divider';

@Component({
  selector: 'app-top-panel',
  standalone: true,
  imports: [
    RouterModule,
    MatButtonModule,
    CommonModule,
    MatIcon,
    MatDivider,
    MatCardModule,
    MatProgressSpinnerModule,
    TabDetailListGroupComponent,
    InboxDirectoryComponent
],
  templateUrl: './top-panel.component.html',
  styleUrl: './top-panel.component.scss'
})
export class TopPanelComponent {
  objectToDisplay = input<any|undefined>(undefined)
  showLoadingSpinner = input<boolean>(false)
  selectedTabIndex = 0;
  title = input<string|undefined>(undefined)
  tabItems = input<IOrderTopPanelTabItems[]|undefined>(undefined)
  orderId = input<number>();
  orderLineId = input<number>();

  changeActiveTab(selectedTabPill: any){
    this.selectedTabIndex = selectedTabPill.currentTarget.attributes['data-index'].value;
  }
}
