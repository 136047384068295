<div class="dialog-wrapper">
  <h4 mat-dialog-title class="dialog-title">{{ data.headerTitle || 'TariffTel Product Classification' }}</h4>
  <hr />
  <mat-dialog-content class="dialog-content">
    <h6>{{ steps[selectedIndex]?.title }}</h6>
    <hr />

    <form [formGroup]="classificationForm" (ngSubmit)="onSubmit()">
      <div class="tab-content-container">

        <!-- Stage 1: Import Details -->
        <div *ngIf="selectedIndex === 0">
          <div *ngFor="let field of steps[selectedIndex]?.fields">
            <ng-container [ngSwitch]="field.type">
              <!-- Dropdown -->
              <mat-form-field *ngSwitchCase="'dropdown'" appearance="fill" class="full-width">
                <mat-label>{{ field.label }}</mat-label>
                <mat-select [formControlName]="field.key">
                  <mat-option *ngFor="let option of getFieldOptions(field.key)" [value]="option">
                    {{ option }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </ng-container>
          </div>
        </div>

        <!-- Stage 2: Define Sets -->
        <div *ngIf="selectedIndex === 1">
          <h6>Define Product Type</h6>
          <!-- Radio Buttons -->
          <mat-radio-group formControlName="productType" class="horizontal-radio-group">
            <mat-radio-button
              *ngFor="let option of steps[selectedIndex]?.fields[0]?.options"
              [value]="option.value"
            >
              {{ option.label }}
            </mat-radio-button>
          </mat-radio-group>

          <!-- Set/Pack Items Dropdown -->
          <div class="form-group mt-2" *ngIf="classificationForm.get('productType')?.value === 'setOrPack'">
            <mat-form-field appearance="fill" class="full-width">
              <mat-label>Set/Pack Items</mat-label>
              <mat-select formControlName="packItems">
                <mat-option *ngFor="let packItem of getFieldOptions('packItems')" [value]="packItem">
                  {{ packItem }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <!-- Identical Pack Items Dropdown -->
          <div class="form-group mt-2" *ngIf="classificationForm.get('productType')?.value === 'identicalPack'">
            <mat-form-field appearance="fill" class="full-width">
              <mat-label>Number of Identical Items</mat-label>
              <mat-select formControlName="identicalPackItems">
                <mat-option *ngFor="let identicalPackItem of getFieldOptions('identicalPackItems')" [value]="identicalPackItem">
                  {{ identicalPackItem }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <!-- Stage 3: Property Selection -->
        <div *ngIf="selectedIndex === 2" class="three-column-layout">
          <!-- Left Column -->
          <div class="column left-column">
            <h6>Select a Property Type</h6>
            <ul class="list-group">
              <li
                *ngFor="let item of leftList"
                (click)="selectLeftItem(item.key)"
                [class.active]="selectedLeftItem === item.key"
                class="list-group-item"
              >
                {{ item.label }}
              </li>
            </ul>
          </div>

          <!-- Middle Column -->
          <div class="column middle-column">
            <h6>Select {{ selectedLeftLabel }}</h6>
            <mat-radio-group formControlName="selectedRightItem">
              <label *ngFor="let option of rightListOptions" class="list-group-item">
                <mat-radio-button [value]="option.value" (change)="selectRightItem(option.value)">
                  {{ option.value }}
                </mat-radio-button>
              </label>
            </mat-radio-group>
          </div>

          <!-- Right Column -->
          <div class="column right-column">
            <h6>Details</h6>
            <!-- Display Additional Information -->
            <p *ngIf="selectedLeftItem">
              {{ additionalInfo[selectedLeftItem] }}
            </p>
            <p *ngIf="!selectedLeftItem" class="alert-message">
              Select a property from the left.
            </p>

            <!-- Display Image -->
            <div *ngIf="currentImageUrl; else noImage" style="text-align: center;">
              <img [src]="currentImageUrl" alt="Selected Item Image" class="product-image" />
            </div>
            <ng-template #noImage>
              <p class="no-image-message">No image available for the selected option.</p>
            </ng-template>
          </div>

        </div>

        <!-- Stage 4: Classification -->
        <div *ngIf="selectedIndex === 3">
          <div *ngIf="isClassifying" class="loading-container">
            <img src="assets/loading.gif" alt="Loading" class="loading-gif" />
            <p class="loading-text">Classifying...Please Wait</p>
          </div>

          <div *ngIf="!isClassifying">
            <h6>Classification Details</h6>
            <table *ngIf="classificationResult" class="table table-striped table-bordered">
              <thead class="table-dark">
                <tr>
                  <th *ngFor="let header of steps[selectedIndex]?.tableHeaders">
                    {{ header }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{ classificationResult.tariffCode }}</td>
                  <td>{{ classificationResult.cbam }}</td>
                  <td>{{ classificationResult.addCv }}</td>
                  <td>{{ classificationResult.preference }}</td>
                  <td>{{ classificationResult.thirdCountryDutyRate }}</td>
                  <td>{{ classificationResult.preferenceDutyRate }}</td>
                </tr>
              </tbody>
            </table>
            <p *ngIf="!classificationResult" class="alert-message">
              No matching classification found. Please check your inputs.
            </p>
          </div>
        </div>
      </div>
    </form>
  </mat-dialog-content>
  <hr />
  <mat-dialog-actions align="end" class="w-100">
    <button mat-raised-button role="link" (click)="navigateToAskQuestion()" class="me-auto">
        <mat-icon>
          question_answer
        </mat-icon>
        Ask Question about this
    </button>
    <button mat-raised-button (click)="previousStep()" [disabled]="selectedIndex === 0">Previous</button>
    <button
      mat-raised-button
      (click)="nextStep()"
      [disabled]="selectedIndex === steps.length - 1 && !classificationForm.valid"
    >
      Next
    </button>
    <button mat-raised-button (click)="close()">Cancel</button>
    <button mat-raised-button *ngIf="selectedIndex === steps.length - 1 && classificationResult" type="submit" (click)="finish()">Finish</button>
  </mat-dialog-actions>
</div>
