import { NgIf } from '@angular/common';
import { Component, Inject, inject } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatOptionModule } from '@angular/material/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { AttributeListingStore } from '../store/attributelistingstore';
import { ToastrService } from 'ngx-toastr';
import { IItemAttribute } from '../../../../core/models/IItemAttribute';

@Component({
  selector: 'app-attribute-add-shared-attribute-dialog',
  standalone: true,
  imports: [ReactiveFormsModule,
      MatFormFieldModule,
      MatOptionModule,
      MatInputModule,
      MatSelectModule,
      MatCheckboxModule,
      MatButtonModule,
      MatDialogModule],
  templateUrl: './attribute-add-shared-attribute-dialog.component.html',
  styleUrl: './attribute-add-shared-attribute-dialog.component.scss'
})
export class AttributeAddSharedAttributeDialogComponent {
  attributeForm: FormGroup;
  categories: { id: number; description: string }[] = [];
  isSaving = false;

  store = inject(AttributeListingStore);
  toastrService = inject(ToastrService);

  constructor(
    public dialogRef: MatDialogRef<AttributeAddSharedAttributeDialogComponent>,
    private fb: FormBuilder
  ) {
    this.attributeForm = this.fb.group({
      category: [null, Validators.required],
    });

    //this.categories = this.store.getCategories(
  }

  onSave(): void {
    if (this.attributeForm.valid && !this.isSaving) {
      this.isSaving = true;
      const selectedCategoryId = this.attributeForm.value.category;

      // Close the dialog with the selected category id
      this.dialogRef.close(selectedCategoryId);
    }
  }

  onCancel(): void {
    this.dialogRef.close();
  }
}
