import { NgFor } from '@angular/common';
import { Component, inject } from '@angular/core';
import { UiSwitchModule } from 'ngx-ui-switch';
import { SectionChapterHeadingStore } from '../store/sectionchapterstore';
import { ToastrService } from 'ngx-toastr';
import { IHeading } from '../../../../core/models/IHeading';

@Component({
  selector: 'app-heading-list',
  standalone: true,
  imports: [NgFor, UiSwitchModule],
  templateUrl: './heading-list.component.html',
  styleUrl: './heading-list.component.scss'
})
export class HeadingListComponent {
  store = inject(SectionChapterHeadingStore);
  toastrService = inject(ToastrService);

  // Access filtered list directly from the store
  filteredHeadings = this.store.filteredList;

  ngOnInit() {
  }

  onToggleActive(heading: IHeading) {
    // Optimistically toggle the heading's active status in the store
    const newStatus = !heading.active; // Explicitly toggle active status
    this.store.toggleActiveHeading(heading.id);

    // Call the service with the new status
    this.store.updateHeadingStatus({ ...heading, active: newStatus }).subscribe({
      next: () => {
        this.toastrService.success('Heading updated successfully', 'Success');
      },
      error: () => {
        this.toastrService.error('Failed to update Heading', 'Error');
        // Revert the change in the store
        this.store.toggleActiveHeading(heading.id);
      }
    });
  }
}
