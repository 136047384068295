import { Guid } from "guid-typescript";
import { Vendor } from "./vendor.model";
import { IVendorUser } from "./IVendorUser";

export class VendorUser implements IVendorUser
{

    constructor(
    public id: number,
    public createdAt: Date,
    public updatedAt: Date,
    public createdBy: Guid,
    public updatedBy: Guid,
    public isDeleted: boolean,
    public firstName: string,
    public lastName: string,
    public email: string,
    public active: boolean,
    public readOnlyPermission: boolean,
    public vendorId: number,
    public entraId: Guid,
    public displayName?: string|undefined,
    public vendor?: Vendor|undefined,
    ) {

    }

}
