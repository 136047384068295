<!-- Search and Filters Section -->
<div class="d-flex justify-content-between align-items-center mb-3 mt-2">
  <!-- Search Field -->
  <div class="col-lg-6">
    <input
      type="text"
      class="form-control"
      placeholder="Search by Client Description"
      [(ngModel)]="searchTerm"
      (ngModelChange)="onSearchTermChange($event)"
    />
  </div>

  <!-- Filters and Buttons -->
  <div class="col-lg-6 text-end">
    <div class="btn-group me-2" role="group" aria-label="Filter Buttons">
      <button
        [ngClass]="store.filter() === 'all' ? 'btn btn-primary' : 'btn btn-outline-primary'"
        (click)="store.changeFilter('all')"
        aria-label="Filter to show all clients"
      >
        All
      </button>
      <button
        [ngClass]="store.filter() === 'active' ? 'btn btn-primary' : 'btn btn-outline-primary'"
        (click)="store.changeFilter('active')"
        aria-label="Filter to show active clients"
      >
        Active
      </button>
      <button
        [ngClass]="store.filter() === 'inactive' ? 'btn btn-primary' : 'btn btn-outline-primary'"
        (click)="store.changeFilter('inactive')"
        aria-label="Filter to show inactive clients"
      >
        Not Active
      </button>
    </div>

    <!-- Reset Button -->
    <button class="btn btn-primary me-2" (click)="createClient()" aria-label="Create Client">
      Create Client
    </button>

    <!-- Reset Button -->
    <button class="btn btn-warning me-2" (click)="resetFilters()" aria-label="Reset filters and search">
      Reset
    </button>
    <!-- Refresh Button -->
    <button class="btn btn-secondary" (click)="forceRefresh()" aria-label="Refresh data">
      Refresh Data
    </button>
  </div>

</div>
<hr />
<div class="container">
  <div class="item-type-tab-set">
    <div>
      <table class="table table-bordered" style="width: 100%">
        <thead>
          <tr>
            <th scope="col" style="width: 95%">Description (Click to Edit)</th>
            <th scope="col" style="width: 5%" class="text-center">Active</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let client of store.filteredClients()">
            <td>
              <span class="clickable-description" (click)="editClient(client)">
                {{ client.clientName }}
              </span>
            </td>
            <td style="text-align: center;">
              <ui-switch
                [checked]="client.active"
                (change)="onToggleActive(client)"
              >
              </ui-switch>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

