import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IClient } from '../models/IClient';
import { IClientAccessDto } from '../models/IClientAccessDto';

@Injectable({
  providedIn: 'root'
})
export class ClientService {

  private clientUrl = `${environment.apiUrl}/api/client`

  constructor(private http: HttpClient) { }

  getClients(): Observable<IClient[]> {
    return this.http.get<IClient[]>(`${this.clientUrl}`);
  }

  // Fetch client access settings
  getClientAccessSettings(clientId: number): Observable<IClientAccessDto> {
    return this.http.get<IClientAccessDto>(`${this.clientUrl}/${clientId}/access-settings`);
  }

  // Update client access settings
  updateClientAccessSettings(clientId: number, accessSettings: IClientAccessDto): Observable<any> {
    return this.http.post(`${this.clientUrl}/${clientId}/update-access-settings`, accessSettings);
  }

  // Toggle client status
  toggleClientStatus(clientId: number, isActive: boolean): Observable<any> {
    return this.http.put(`${this.clientUrl}/${clientId}/active`, isActive, {
      headers: { 'Content-Type': 'application/json' }
    });
  }


  // Update an existing client
  updateClient(client: IClient): Observable<IClient> {
    return this.http.put<IClient>(`${this.clientUrl}/${client.id}`, client);
  }

  // Create a new client
  createClient(newClient: IClient): Observable<IClient> {
    return this.http.post<IClient>(this.clientUrl, newClient);
  }



}

