import { getState, patchState, signalStore, withComputed, withHooks, withMethods, withState } from "@ngrx/signals";
import { IRegion } from "../../../../core/models/IRegion";
import { computed, inject } from "@angular/core";
import { LocalStorageService } from "../../../../core/services/local-storage.service";
import { forkJoin, Observable } from 'rxjs';
import { IConstruction } from "../../../../core/models/IConstruction";
import { IConstructionCategory } from "../../../../core/models/IConstructionCategory";
import { RegionService } from "../../../../core/services/region.service";
import { ConstructionService } from "../../../../core/services/construction.service";
import { ItemtypeGroupService } from "../../../../core/services/itemtype-group.service";
import { IItemTypeGroup } from "../../../../core/models/IItemTypeGroup";

const constructioncategoryStoreKey = 'tarifftel_construction_category_list_data';
const constructioncategoryStoreTimestampKey = 'tarifftel_construction_category_list_data_timestamp';
const CACHE_DURATION = 60 * 60 * 1000; // 1 hour

type Filter = 'all' | 'active' | 'inactive';

type TariffTelConstructionListState = {
  constructions: IConstruction[];
  constructioncategories: IConstructionCategory[];
  regions: IRegion[];
  itemTypeGroups: IItemTypeGroup[];
  filter: Filter;
  searchTerm: string;
  selectedList: 'constructions' | 'constructioncategories';
  selectedItemTypeGroupId: number | null;
  selectedRegionId: number | null;
  selectedConstructionCategoryId: number | null;
  loading: boolean;
};

const initialState: TariffTelConstructionListState = {
  constructions: [],
  constructioncategories: [],
  regions: [],
  itemTypeGroups: [],
  filter: 'all',
  searchTerm: '',
  selectedList: 'constructions',
  selectedItemTypeGroupId: null,
  selectedRegionId: null,
  selectedConstructionCategoryId: null,
  loading: true,
};

export const ConstructionCategoryStore = signalStore(
  { providedIn: 'root' },
  withState(initialState),
  withComputed(({ constructions, constructioncategories, selectedList, selectedRegionId, selectedItemTypeGroupId, regions, itemTypeGroups }) => ({
    filteredList: computed(() => {
      let filtered: any[] = [];

      if (selectedList() === 'constructions') {
        filtered = constructions();

        // Apply filters for constructions only
        if (selectedRegionId()) {
          filtered = filtered.filter(item => item.regionId === selectedRegionId());
        }

        if (selectedItemTypeGroupId()) {
          filtered = filtered.filter(item => item.itemTypeGroupId === selectedItemTypeGroupId());
        }
      } else {
        // For construction categories, no need to apply these filters
        filtered = constructioncategories();
      }

      return filtered;
    }),

    selectedRegion: computed(() => {
      return regions().find(region => region.id === selectedRegionId());
    }),

    selectedItemTypeGroup: computed(() => {
      return itemTypeGroups().find(group => group.id === selectedItemTypeGroupId());
    })
  })),
  withMethods((store) => {
    const regionService = inject(RegionService);
    const constructionService = inject(ConstructionService); // Inject ConstructionService here
    const itemTypeGroupService = inject(ItemtypeGroupService);
    const localStorageService = inject(LocalStorageService);

    return {
      loadData() {
        const dataFromStorage = localStorageService.getItem(constructioncategoryStoreKey) || {};
        const timestamp = parseInt(localStorageService.getItem(constructioncategoryStoreTimestampKey) || '0', 10);
        const now = Date.now();

        patchState(store, { loading: true });

        if (Object.keys(dataFromStorage).length > 0 && (now - timestamp) < CACHE_DURATION) {
          patchState(store, dataFromStorage);
          patchState(store, { loading: false });

          if (getState(store).selectedItemTypeGroupId === null) {
            this.selectFirstItemTypeGroup();
          }

          if (getState(store).selectedRegionId === null && getState(store).regions.length > 0) {
            this.setSelectedRegionId(getState(store).regions[0].id);
          }
        } else {
          forkJoin({
            constructions: constructionService.getConstructions(),
            constructioncategories: constructionService.getConstructionCategories(),
            regions: regionService.getActiveRegions(),
            itemTypeGroups: itemTypeGroupService.getActiveConstructionItemTypeGroups(),
          }).subscribe({
            next: ({ constructions, constructioncategories, regions, itemTypeGroups }) => {
              patchState(store, { constructions, constructioncategories, regions, itemTypeGroups });
              localStorageService.setItem(constructioncategoryStoreKey, JSON.stringify({ constructions, constructioncategories, regions, itemTypeGroups }));
              localStorageService.setItem(constructioncategoryStoreTimestampKey, now.toString());
              patchState(store, { loading: false });

              if (getState(store).selectedItemTypeGroupId === null) {
                this.selectFirstItemTypeGroup();
              }

              if (getState(store).selectedRegionId === null && regions.length > 0) {
                this.setSelectedRegionId(regions[0].id);
              }
            },
            error: (err) => {
              console.error('Failed to load constructions, categories, regions, and Item Type Groups', err);
              patchState(store, { loading: false });
            }
          });
        }
      },

      getConstructionCategories(): Observable<IConstructionCategory[]> {
        return new Observable((observer) => {
          constructionService.getConstructionCategories().subscribe({
            next: (categories) => {
              patchState(store, { constructioncategories: categories });
              observer.next(categories);
              observer.complete();
            },
            error: (error) => {
              console.error('Failed to load construction categories', error);
              observer.error(error);
            }
          });
        });
      },

      setSelectedItemTypeGroupId(id: number | null) {
        patchState(store, { selectedItemTypeGroupId: id });
      },

      setSelectedRegionId(id: number | null) {
        patchState(store, { selectedRegionId: id });
      },

      setSelectedConstructionCategoryId(id: number | null) {
        patchState(store, { selectedConstructionCategoryId: id });
      },

      showTable(selectedList: 'constructions' | 'constructioncategories') {
        patchState(store, { selectedList });

        if (selectedList === 'constructions' && getState(store).selectedItemTypeGroupId === null) {
          this.selectFirstItemTypeGroup();
        }
      },

      setSearchTerm(term: string) {
        patchState(store, { searchTerm: term });
      },

      isDuplicateDescription(newConstruction: IConstruction): boolean {
        const materials = getState(store).constructions;
        return materials.some(construction => 
          construction.description.toLowerCase() === newConstruction.description.toLowerCase()
          && construction.itemTypeGroupId === newConstruction.itemTypeGroupId
          && construction.regionId === newConstruction.regionId
          && construction.id !== newConstruction.id
        );
      },

      createConstruction(construction: IConstruction): Observable<IConstruction> {
        console.log("Create Construction called !")
        return constructionService.createConstruction(construction);
      },

      updateConstruction(construction: IConstruction): Observable<IConstruction> {
        return constructionService.updateConstruction(construction);
      },

      deleteConstruction(constructionId: number): Observable<void> {
        return constructionService.deleteConstruction(constructionId);
      },

      addConstruction(newItem: IConstruction) {
        // Get the description of the selected category to display immediately
        const selectedCategory = getState(store).constructioncategories.find(
          category => category.id === newItem.constructionCategoryId
        );

        if (!selectedCategory) {
          throw new Error('Selected category is undefined');
        }

        // Set the constructionCategoryName for immediate display
        const constructionWithCategoryName = {
          ...newItem,
          constructionCategoryName: selectedCategory.description
        };

        patchState(store, {
          constructions: [...store.constructions(), constructionWithCategoryName].sort((a, b) => a.description.localeCompare(b.description))
        });
      },


      patchConstruction(updatedItem: IConstruction) {
        patchState(store, {
          constructions: store.constructions().map(item =>
            item.id === updatedItem.id ? updatedItem : item
          )
        });
      },

      removeConstructionFromStore(constructionId: number) {
        patchState(store, {
          constructions: getState(store).constructions.filter(construction => construction.id !== constructionId),
        });
      },

      isDuplicateCategory(description: string, categoryid?: number): boolean {
        const categories = getState(store).constructioncategories;
        return categories.some(category => category.description.toLowerCase() === description.toLowerCase() && category.id !== categoryid);
      },

      createCategory(category: IConstructionCategory): Observable<IConstructionCategory> {
        return constructionService.createCategory(category);
      },

      updateCategory(category: IConstructionCategory): Observable<IConstructionCategory> {
        return constructionService.updateCategory(category);
      },

      deleteCategory(categoryId: number): Observable<void> {
        return constructionService.deleteCategory(categoryId);
      },

      addCategory(newCategory: IConstructionCategory) {
        patchState(store, {
          constructioncategories: [...store.constructioncategories(), newCategory].sort((a, b) => a.description.localeCompare(b.description))
        });
      },

      patchCategory(updatedCategory: IConstructionCategory) {
        patchState(store, {
          constructioncategories: store.constructioncategories().map(cat => cat.id === updatedCategory.id ? updatedCategory : cat)
        });
      },

      removeCategoryFromStore(categoryId: number) {
        patchState(store, {
          constructioncategories: getState(store).constructioncategories.filter(cat => cat.id !== categoryId),
          constructions: getState(store).constructions.filter(construction => construction.constructionCategoryId !== categoryId),
        });
      },

      clearCache() {
        localStorageService.removeItem(constructioncategoryStoreKey);
        localStorageService.removeItem(constructioncategoryStoreTimestampKey);
        patchState(store, initialState);
      },

      selectFirstItemTypeGroup() {
        const { itemTypeGroups } = getState(store);
        if (itemTypeGroups.length > 0) {
          this.setSelectedItemTypeGroupId(itemTypeGroups[0].id);
        }
      },

      handleGroupClick(group: IItemTypeGroup) {
        this.setSelectedItemTypeGroupId(group.id);
      },

      regionsWithConstructionsForGroup(groupId: number) {
        const { constructions, regions } = getState(store);
        const regionsWithConstructions = new Set<number>();

        constructions.forEach(construction => {
          if (construction.itemTypeGroupId === groupId) {
            regionsWithConstructions.add(construction.regionId);
          }
        });

        return regions.filter(region => regionsWithConstructions.has(region.id));
      },
    };
  }),
  withHooks({
    onInit(store) {
      store.loadData();
    },
  })
);

