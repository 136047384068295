import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
  selector: 'app-button-with-mat-spinner',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatIconModule
  ],
  templateUrl: './button-with-mat-spinner.component.html',
  styleUrl: './button-with-mat-spinner.component.scss'
})
export class ButtonWithMatSpinnerComponent {
  @Input() label: string = ''
  @Input() color: string = 'primary'
  @Input() icon: string = ''
  @Input() disabled: boolean = false
  @Input() ariaLabel: string = ''
  @Input() routerLink: string = ''
  @Input() showSpinner: boolean = false
  @Input() spinnerColor: string = 'accent'
  @Input() spinnerDiameter: number = 15
}
