<mat-menu #notificationMenu="matMenu">
  <div mat-menu-item class="notification-header" (click)="$event.stopPropagation()">
    <h4>Notifications</h4>
    <mat-slide-toggle
      [checked]="filterUnread"
      (change)="toggleFilterUnread()"
      labelPosition="before"
    >Show unread</mat-slide-toggle>
  </div>
  @for (notification of getNotifications(); track notification.id) {
  <button mat-menu-item (click)="$event.stopPropagation()">
    <mat-icon>{{
      notification.isRead ? "notifications_none" : "notifications"
    }}</mat-icon>
    <div (click)="markAsRead(notification.id)" class="notification-item">
      <strong>{{ notification.title }}</strong>
      <p>{{ notification.content }}</p>
      <span class="timestamp">{{
        notification.createdAt | date : "short"
      }}</span>
    </div>
  </button>
  } @empty {
  <button mat-menu-item class="notification-empty" (click)="$event.stopPropagation()">
    <div class="notification-item">
      <span>No notifications</span>
    </div>
  </button>
  }
</mat-menu>
