import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { debounceTime, Subject } from 'rxjs';
import { ItemTypeGroupStore } from '../item-type-groups/store/itemtype-group.store';
import { AttributeListComponent } from "./attribute-list/attribute-list.component";
import { AttributeValueListComponent } from "./attribute-value-list/attribute-value-list.component";
import { SharedAttributeListComponent } from "./shared-attribute-list/shared-attribute-list.component";
import { SharedAttributeValueListComponent } from "./shared-attribute-value-list/shared-attribute-value-list.component";
import { AttributeListingStore } from './store/attributelistingstore';
import { ItemTypeListComponent } from './item-type-list/item-type-list.component';

@Component({
  selector: 'app-attribute-values-mgmt',
  standalone: true,
  imports: [CommonModule, FormsModule, AttributeListComponent, AttributeValueListComponent, ItemTypeListComponent],
  templateUrl: './attribute-values-mgmt.component.html',
  styleUrl: './attribute-values-mgmt.component.scss'
})
export class AttributeValuesMgmtComponent {
  readonly TYPESATTRIBUTESVALUES: 'typesattributevalues' = 'typesattributevalues';
  readonly SHAREDATTRIBUTES: 'sharedattributes' = 'sharedattributes';
  readonly ITEMTYPES: 'itemtypes' = 'itemtypes';

  selectedItem: 'typesattributevalues' | 'sharedattributes' | 'itemtypes' = this.TYPESATTRIBUTESVALUES;

  cdr = inject(ChangeDetectorRef);
  store = inject(AttributeListingStore);
  itemtypeGroupStore = inject(ItemTypeGroupStore);
  toastrService = inject(ToastrService);

  // Subject for search input with debounce
  private searchSubject = new Subject<string>();
  searchTerm: string = '';

  ngOnInit() {
    this.store.clearCache();
    this.itemtypeGroupStore.loadItemTypeGroups();
    this.store.loadData();

    if (this.store.selectedList() === this.TYPESATTRIBUTESVALUES && this.store.selectedItemTypeGroupId() === null) {
      this.store.selectFirstItemTypeGroup(); // This also loads attributes and values for the first group
    }

    this.searchSubject.pipe(
      debounceTime(300) // Adjust the debounce time as needed
    ).subscribe((term) => {
      this.store.setSearchTerm(term);
    });

    this.cdr.detectChanges();
  }

  onSearchTermChange(term: string) {
    this.searchSubject.next(term);
  }

  // New method to reset filters and search
  resetFilters() {
    this.searchTerm = ''; // Clear the search input
    this.store.setSearchTerm(''); // Explicitly update the store's searchTerm
    this.toastrService.info('Filters have been reset', 'Reset'); // Optional: Notify the user
  }

  forceRefresh() {
    this.store.clearCache();
    this.itemtypeGroupStore.clearCache();

    this.itemtypeGroupStore.loadItemTypeGroups();
    this.store.loadData();

    this.toastrService.info('Data has been refreshed', 'Refreshed');
  }

  showTable(item: 'typesattributevalues' | 'sharedattributes' | 'itemtypes') {
    this.selectedItem = item; // Update selectedItem
    this.store.showTable(item); // Call store method to update selectedList

    // Only select the first Item Type Group when switching to 'typesattributevalues'
    if (item === this.TYPESATTRIBUTESVALUES && this.store.selectedItemTypeGroupId() === null) {
      this.store.selectFirstItemTypeGroup();
    }
  }

}

