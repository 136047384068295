<lib-secondary-navbar
  [title]="'TariffTel Clients'"
></lib-secondary-navbar>

<div class="client-container">
  <mat-card
    class="tenant-card"
    appearance="outlined"
    *ngFor="let tenant of tenants"
    (mouseenter)="onMouseOver(tenant)"
    (mouseleave)="onMouseOut()">
    <div class="logo-container">
      <img class="company-logo img-responsive"  mat-card-image [src]="tenant.logo" />
    </div>
  </mat-card>
</div>

