import { Component, OnInit } from '@angular/core';
import { ExcelFileAttachBulkEntityLoadComponent } from '../../excel-file-attach-bulk-entity-load/excel-file-attach-bulk-entity-load.component';
import { ActivatedRoute} from '@angular/router';
import { BreadcrumbComponent } from "../../../../../core/components/breadcrumb/breadcrumb.component";

@Component({
  selector: 'app-bulk-add-vendor-users-attach-file',
  standalone: true,
  imports: [
    ExcelFileAttachBulkEntityLoadComponent,
    BreadcrumbComponent
],
  templateUrl: './bulk-add-vendor-users-attach-file.component.html',
  styleUrl: './bulk-add-vendor-users-attach-file.component.scss'
})
export class BulkAddVendorUsersAttachFileComponent implements OnInit {


  readonly VALIDFILEEXTENSIONS:string[] = [".xlsx"]; 
  readonly ACCEPTEDFILETYPES:string[] = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
  vendorUserEndpointSuffix:string = 'vendorUser/CreateVendorUsersFromExcelFile'
  readonly SAMPLEFILEPATH:string = "../../../assets/BulkLoadVendorUsersSampleFile.xlsx";
  readonly SAMPLEFILELINKNAME:string = "Vendor User Sample File";
  viewAllRecordsLinkPath:string = '/settings/vendor-management/vendor-detail/edit';
  readonly SAMPLEFILEMANDATORYFIELDS:string[] = ["Email","First Name","Last Name","Active (true or false value)","Read Only Permission (true or false value)"] 

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    const vendorUserIdStr:string | null = this.route.snapshot.paramMap.get('id');

    if(vendorUserIdStr) {
      this.vendorUserEndpointSuffix = this.vendorUserEndpointSuffix + "/" + Number(vendorUserIdStr)
      this.viewAllRecordsLinkPath = this.viewAllRecordsLinkPath + "/" + Number(vendorUserIdStr)
    }
    
  }
}
