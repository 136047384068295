import { computed, inject } from "@angular/core";
import { patchState, signalStore, withComputed, withHooks, withMethods, withState } from "@ngrx/signals";
import { NotificationService } from "../../../core/services/notification.service";
import { LocalStorageService } from "../../../core/services/local-storage.service";
import { INotification } from "../../../core/models/INotification";
import { AppSettingsService } from "@tarifftel/components-library";

const notificationStoreKey = 'tarifftel_notification_data';
const notificationStoreTimestampKey = notificationStoreKey + '_timestamp';
const CACHE_DURATION = 1000 * 1 * 5; // 5 seconds

type NotificationState = {
    notifications: INotification[]
}

const initialState: NotificationState = {
    notifications: []
};

export const NotificationStore = signalStore(
    { providedIn: 'root' },
    withState(initialState),
    withComputed(({ notifications }) => ({
        unreadNotifications: computed(() => {
            return notifications().filter(notification => !notification.isRead);
        })
    })),
    withMethods((
        store,
        notificationService = inject(NotificationService),
        localStorageService = inject(LocalStorageService),
        appSettingsService = inject(AppSettingsService)
    ) => ({
            loadNotifications() {
                const cachedState = localStorageService.getItem(notificationStoreKey) || {};
                const timestamp = parseInt(localStorageService.getItem(notificationStoreTimestampKey) || '0', 10);
                const now = Date.now();

                if (Object.keys(cachedState).length > 0 && (now - timestamp) < CACHE_DURATION) {
                    appSettingsService.setNotificationsCount(store.unreadNotifications().length);
                } else {
                    notificationService.getNotifications().subscribe({
                        next: (notifications) => {
                            this.patchNotificationStore(notifications);
                            appSettingsService.setNotificationsCount(store.unreadNotifications().length);
                        },
                    });
                }
            },

            patchNotificationStore(notifications: INotification[]) {
                patchState(store, { notifications });
                localStorageService.setItem(notificationStoreKey, JSON.stringify({ notifications }));
                localStorageService.setItem(notificationStoreTimestampKey, Date.now().toString());
            },

            markAsRead(notificationId: number) {
                if (store.notifications().find(notification => notification.id === notificationId)?.isRead) {
                    return;
                }
                notificationService.markAsRead(notificationId).subscribe({
                    next: () => {
                        const updatedNotifications = store.notifications().map(notification =>
                            notification.id === notificationId ? { ...notification, isRead: true } : notification
                        );
                        this.patchNotificationStore(updatedNotifications);
                        appSettingsService.setNotificationsCount(store.unreadNotifications().length);
                    }
                })
            },

            clearCache() {
                localStorageService.removeItem(notificationStoreKey);
                localStorageService.removeItem(notificationStoreTimestampKey);
                patchState(store, initialState);
            }
        }
    ))
);