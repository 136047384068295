import { Component } from '@angular/core';

@Component({
  selector: 'app-logged-out',
  standalone: true,
  imports: [],
  templateUrl: './logged-out.component.html',
  styleUrl: './logged-out.component.scss'
})
export class LoggedOutComponent {

}
