import { Component, effect, inject, input, Input, signal } from '@angular/core';
import { AgGridModule } from 'ag-grid-angular';
import { ColDef, Column, GetContextMenuItemsParams, GridApi, GridReadyEvent, MenuItemDef, SideBarDef, ToolPanelDef } from 'ag-grid-enterprise';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmModalComponent } from '../../../../../core/components/confirm-modal/confirm-modal.component';
import { AggridTopBtnToolbarComponent } from "../../aggrid-top-btn-toolbar/aggrid-top-btn-toolbar.component";
import { AggridTopToolbarBtn, AggridTopToolbarBtnsEnum } from '../../aggrid-top-btn-toolbar/AggridTopToolbarBtn';
import { IAggridTopToolbarBtn } from '../../aggrid-top-btn-toolbar/IAggridTopToolbarBtn';
import { VendorUserFormDialogComponent } from '../vendor-user-form-dialog/vendor-user-form-dialog.component';
import { Router, RouterModule } from '@angular/router';
import { AggridFurtherOptionsToolPanelComponent } from '../../aggrid-further-options-tool-panel/aggrid-further-options-tool-panel.component';
import { Subject } from 'rxjs';
import { UserFriendlyHttpErrorMessageDialogComponent } from '../../user-friendly-http-error-message-dialog/user-friendly-http-error-message-dialog.component';
import { Vendor } from '../../../../../core/models/vendor.model';
import { VendorUser } from '../../../../../core/models/vendor-user.model';
import { VendorManagementService } from '../../../../../core/services/vendor-management.service';
import { AggridFurtherOptions } from '../../aggrid-further-options-tool-panel/AggridFurtherOptions';
import 'ag-grid-enterprise';
import { VendorStore } from '../../store/vendors.store';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-aggrid-manage-vendor-users',
  standalone: true,
  imports: [
    AgGridModule,
    AggridTopBtnToolbarComponent,
    RouterModule
],
  templateUrl: './aggrid-manage-vendor-users.component.html',
  styleUrl: './aggrid-manage-vendor-users.component.scss'
})
export class AggridManageVendorUsersComponent {
  readonly EXCELEXPORTFILENAME = "Tarifftel Vendor Users"
  readonly EXCELEXPORTWORKSHEETNAME = "TarifftelVendorUsersWorksheet"
  vendorStore = inject(VendorStore)
  toastrService = inject(ToastrService)
  vendor = input<Vendor|undefined>(undefined);
  vendorDetailLoading = input<boolean>(false);

  constructor(private router: Router,public dialog: MatDialog,private vendorManagementService:VendorManagementService) {
    effect(() => {
      if(this.vendorStore.vendors() && this.vendor()) {
        const vendorUsers = this.vendorStore.getVendorById(this.vendor()!.id)?.vendorUsers;
        if(vendorUsers) {
          this.gridApi?.setGridOption('rowData', vendorUsers);
          this.gridApi?.setGridOption("loading", false);
        }
      }
      if(this.vendorDetailLoading()) {
        this.gridApi?.setGridOption("loading", this.vendorDetailLoading());
      }

    })

  }


  gridApi: GridApi|undefined;
  defaultColDef: ColDef = {
    flex:1,
    // allow every column to be aggregated
    enableValue: false,
    // allow every column to be grouped
    enableRowGroup: false,
    // allow every column to be pivoted
    enablePivot: false,
    filter: true,
    sortable:true,
    suppressMovable:true,
    editable: false,
    suppressHeaderMenuButton: true
  };
  sideBarDef = input<SideBarDef>({
        toolPanels: [
            {
              id: 'columns',
              labelDefault: 'Columns',
              labelKey: 'columns',
              iconKey: 'columns',
              toolPanel: 'agColumnsToolPanel',
              toolPanelParams: {
                suppressRowGroups: true,
                suppressValues: true,
                suppressPivots: true,
                suppressPivotMode: true,
                suppressColumnFilter: true,
                suppressColumnSelectAll: true,
                suppressColumnExpandAll: true,
              }
          },
          {
              id: 'filters',
              labelDefault: 'Filters',
              labelKey: 'filters',
              iconKey: 'filter',
              toolPanel: 'agFiltersToolPanel',
          },
          {
            id: 'furtherOptions',
            labelDefault: 'Further Options',
            labelKey: 'furtherOptions',
            iconKey: 'columns',
            toolPanel: AggridFurtherOptionsToolPanelComponent,
            toolPanelParams: {
              furtherOptionsToDisplay:[
                AggridFurtherOptions.ACTIVATEVENDORUSERSCONTEXTMENUITEMNAME,
                AggridFurtherOptions.DEACTIVATEVENDORUSERSCONTEXTMENUITEMNAME,
                AggridFurtherOptions.COPYMENUITEMNAME,
                AggridFurtherOptions.COPYWITHHEADERSMENUITEMNAME,
                AggridFurtherOptions.EXCELEXPORTSELECTEDROWSMENUITEMNAME,
                AggridFurtherOptions.EXCELEXPORTMENUITEMNAME,
              ],
              excelExportParams:{
                columnKeys:[],
                fileName: this.EXCELEXPORTFILENAME,
                sheetName:this.EXCELEXPORTWORKSHEETNAME,
              }
            }
          },
      ],
      position: 'right',
      defaultToolPanel: ''
  });
  rowData: VendorUser[]|null = [];
  themeClass: string = "ag-theme-quartz";
  columnDefs: ColDef[] = [
    {
      headerName: '',
      maxWidth:60,
      resizable: false,
      sortable:false,
      suppressFiltersToolPanel: true,
      suppressFloatingFilterButton:true,
      suppressColumnsToolPanel:true,
      checkboxSelection:true,
      headerCheckboxSelection:true,
      cellRenderer: "agGroupCellRenderer"
    },
    {
      field: 'firstName',
      hide:true
    },
    {
      field: 'lastName',
      hide:true
    },
    { field: 'displayName' },
    { field: 'email' },
    { field: 'active' },
    { field: 'readOnlyPermission' },
    {
      headerName: '',
      cellRenderer:() => {
        return `<a href="javascript:void(0)" style="text-decoration:underline" >Select</a>`;
      },
      onCellClicked:(params) => {

        this.router.navigateByUrl(`settings/vendor-management/vendor-detail/edit/${this.vendor()?.id}/vendor-user-detail/${params.data.id}`);
      },
      maxWidth:80,
      minWidth:80,
      resizable: false,
      sortable:false,
      suppressFiltersToolPanel: true,
      suppressFloatingFilterButton:true,
      suppressColumnsToolPanel:true,
      filter: false
    }
  ];
  deleteVendorUsersBtnDisabled:boolean = true;
  vendorUsersSubscription: any;
  aggridTopToolbarBtns:IAggridTopToolbarBtn[] = [
    new AggridTopToolbarBtn(AggridTopToolbarBtnsEnum.AddUser,"btn btn-secondary","Add Vendor User",false,""),
    new AggridTopToolbarBtn(AggridTopToolbarBtnsEnum.DeleteUser,"btn btn-danger","Delete Vendor User",this.deleteVendorUsersBtnDisabled,""),
  ]
  aggridContextMenuItems: (string | MenuItemDef)[] = [
    {
      // custom item
      name: AggridFurtherOptions.ACTIVATEVENDORUSERSCONTEXTMENUITEMNAME,
      action: () => {
        this.bulkChgActiveOfVendorUser(true);
      },
      cssClasses: ['bold'],
    },
    {
      // custom item
      name: AggridFurtherOptions.DEACTIVATEVENDORUSERSCONTEXTMENUITEMNAME,
      action: () => {
        this.bulkChgActiveOfVendorUser(false);
      },
      cssClasses: ['bold'],
    },
    'copy',
    'copyWithHeaders',
    {
      // custom item
      name: AggridFurtherOptions.EXCELEXPORTSELECTEDROWSMENUITEMNAME,
      action: () => {

        let exportExcelParams = {
          columnKeys: this.gridApi?.getColumns()?.slice(1).map((col: Column) => col.getColId()), // exclude the first column which is a checkbox column
          onlySelected:true,
          fileName: this.EXCELEXPORTFILENAME,
          sheetName:this.EXCELEXPORTWORKSHEETNAME,
        }

        if(this.chkRowsAreSelectedForBulks("",`Please select atleast one row to export selected rows to an excel file.`)) {
          this.gridApi?.exportDataAsExcel(exportExcelParams);
        }
      },
      cssClasses: ['bold'],
    },
    {
      // custom item
      name: AggridFurtherOptions.EXCELEXPORTMENUITEMNAME,
      action: () => {

        let exportExcelParams = {
          columnKeys: this.gridApi?.getColumns()?.slice(1).map((col: Column) => col.getColId()), // exclude the first column which is a checkbox column
          onlySelected:false,
          fileName: this.EXCELEXPORTFILENAME,
          sheetName:this.EXCELEXPORTWORKSHEETNAME,
        }
        this.gridApi?.exportDataAsExcel(exportExcelParams);
      },
      cssClasses: ['bold'],
    }
  ];





  ngOnChanges() {
    this.initVendorUsers();
  }

  deleteVendorUsersBtnEvent() {
    if(this.deleteVendorUsersBtnDisabled) {
      return;
    }

    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      data: {
        title:"",
        message:"Are you sure you would like to delete the selected users for this vendor?"
      }
    });


    dialogRef.afterClosed().subscribe(dialogResult => {
      if(dialogResult && this.gridApi && this.vendor) {
        var selectedData = this.gridApi.getSelectedRows();
        this.gridApi.setGridOption("loading", true);
        if(this.vendor()) {
          this.vendorStore.deleteVendorUsers(this.vendor()!,selectedData).subscribe({
            next:() => {
              this.toastrService.success("Selected Vendor User(s) were deleted successfully!");
              this.gridApi?.setGridOption("loading", false);
            },
            error:() => {
              this.dialog.open(UserFriendlyHttpErrorMessageDialogComponent);
            }
          });
        }

      }
    });

  }

  openVendorUserDetailsDialog(editMode:boolean,vendor?:Vendor,vendorUserRow?:VendorUser) {

    this.dialog.open(VendorUserFormDialogComponent, {
     height: '500px',
      width: '600px',
      data: {
        editMode: editMode,
        vendorRow:vendor,
        vendorUserRow:vendorUserRow,
        gridApi:this.gridApi
      },
    });
  }

  onGridReady(params: GridReadyEvent<Vendor>) {
    this.gridApi = params.api;

    this.initVendorUsers();

    this.sideBarDef().toolPanels?.forEach((toolPanel:ToolPanelDef|string) => {
      if((toolPanel as ToolPanelDef).id == "furtherOptions") {
        // need to exclude the first column which is the checkbox from the columns to export to excel
        (toolPanel as ToolPanelDef).toolPanelParams.excelExportParams.columnKeys = this.gridApi?.getColumns()?.slice(1).map((col: Column) => col.getColId());
      }
    })
    this.gridApi.refreshToolPanel();

    (this.gridApi as any).furtherOptionsToolPanelBulkUpdateVendorUsersStatus = new Subject<boolean>();
    (this.gridApi as any).furtherOptionsToolPanelBulkUpdateVendorUsersStatus.subscribe((activateVendorUserStatus:boolean) => {
      if (activateVendorUserStatus != undefined && this.gridApi && this.vendor()) {
        this.vendorStore.bulkUpdateVendorUsersActive(this.vendor()!,this.gridApi.getSelectedRows(),activateVendorUserStatus).subscribe({
          next:() => {
            this.toastrService.success("Vendor User(s) active field successfully updated!");
            this.gridApi?.setGridOption("loading", false);
          },
          error:(any: any) => {
            this.dialog.open(UserFriendlyHttpErrorMessageDialogComponent);
          }
        });
      }
    });
  }

  getContextMenuItems= (params:GetContextMenuItemsParams) => {
    return this.aggridContextMenuItems;
  }

  initVendorUsers() {
    this.rowData = [];
    if(this.vendor()?.id) {
      this.chgStatusOfAddVendorUserBtn(true);
      this.gridApi?.setGridOption("loading", true);
      if(this.vendor()) {
        this.rowData = this.vendor()!.vendorUsers;
        this.gridApi?.setGridOption("loading", false);
      }
    }
    else {
      this.chgStatusOfAddVendorUserBtn(false);
    }
  }

  chgStatusOfAddVendorUserBtn(enabled:boolean) {
    this.aggridTopToolbarBtns.forEach(aggridTopToolbarBtn => {
      if (aggridTopToolbarBtn.btnEnumVal == AggridTopToolbarBtnsEnum.AddUser) {
        aggridTopToolbarBtn.disabled = !enabled;
      }
    });
  }

  onSelectionChanged(event:any) {
    if(this.gridApi) {

      let selectedRowsLength = this.gridApi.getSelectedRows().length;
      this.deleteVendorUsersBtnDisabled = (selectedRowsLength > 0) ? false:true;

      this.aggridTopToolbarBtns.forEach(aggridTopToolbarBtn => {
        if (aggridTopToolbarBtn.btnEnumVal == AggridTopToolbarBtnsEnum.DeleteUser) {
          aggridTopToolbarBtn.disabled = this.deleteVendorUsersBtnDisabled;
        }
      });

    }

  }

  aggridTopToolbarBtnClicked(aggridTopToolbarBtn:IAggridTopToolbarBtn) {
    if(aggridTopToolbarBtn.btnEnumVal == AggridTopToolbarBtnsEnum.AddUser) {
      this.openVendorUserDetailsDialog(false,this.vendor());
    }
    else if(aggridTopToolbarBtn.btnEnumVal == AggridTopToolbarBtnsEnum.DeleteUser) {
      this.deleteVendorUsersBtnEvent()
    }
  }

  bulkChgActiveOfVendorUser(activateVendor:boolean) {
    if(this.chkRowsAreSelectedForBulks("",`Please select atleast one vendor to bulk ${activateVendor ? 'activate':'deactivate'} selected vendor users.`)) {
      const dialogRef = this.dialog.open(ConfirmModalComponent, {
        data: {
          title:"",
          message:`Are you sure you would like proceed to ${activateVendor ? 'activate':'deactivate'} selected vendor users?`
        }
      });

      dialogRef.afterClosed().subscribe(dialogResult => {
        if(dialogResult && this.gridApi && this.vendor()) {
          this.gridApi?.setGridOption("loading",true);
          this.vendorStore.bulkUpdateVendorUsersActive(this.vendor()!,this.gridApi.getSelectedRows(),activateVendor).subscribe({
            next:() => {
              this.toastrService.success("Vendor User(s) active field successfully updated!");
              this.gridApi?.setGridOption("loading", false);
            },
            error:(any: any) => {
              this.dialog.open(UserFriendlyHttpErrorMessageDialogComponent);
            }
          });
        }
      });
    }
  }

  chkRowsAreSelectedForBulks(confirmModalTitle:string,confirmModalMsg:string) {
    if(!this.gridApi) {
      return
    }

    let selectedVendorRows:VendorUser[] = this.gridApi.getSelectedRows();
    if(selectedVendorRows.length < 1) {
        this.dialog.open(ConfirmModalComponent, {
          data: {
            title:confirmModalTitle,
            message:confirmModalMsg
          }
        });
        return false;
    }
    else  {
      return true;
    }
  }

  getRouterLinkForVendorUserBulk() {
    let vendorUserRouterLinkUrl = this.router.url + "/bulk-add-vendor-users-attach-file"
    return [vendorUserRouterLinkUrl];
  }
}
