import { CommonModule, NgForOf, NgIf } from '@angular/common';
import { Component, inject, Inject } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatRadioModule } from '@angular/material/radio';
import { MatTabsModule } from '@angular/material/tabs';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatOptionModule } from '@angular/material/core';
import { classificationStore } from './store/classifierstore';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { IItemTypeGroup } from '../../../../core/models/IItemTypeGroup';
import { combineLatest } from 'rxjs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TariffData } from '../../../../core/services/tariffbook.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { IRegion } from '../../../../core/models/IRegion';
import { TabsModule } from 'ngx-bootstrap/tabs';

@Component({
  selector: 'app-classification-dialog',
  standalone: true,
  imports: [
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatDialogModule,
    MatTabsModule,
    MatRadioModule,
    MatButtonModule,
    MatTooltipModule,
    MatOptionModule,
    MatInputModule,
    MatCheckboxModule,
    TabsModule,
    NgForOf,
    NgIf
],
  templateUrl: './classification-dialog.component.html',
  styleUrl: './classification-dialog.component.scss'
})
export class ClassificationDialogComponent {
  store = inject(classificationStore);
  sanitizer = inject(DomSanitizer);

  classificationForm: FormGroup;
  selectedIndex = 0;
  uploadedImageName: string | null = null;
  uploadedImagePreview: string | null = null;
  selectedRegion: IRegion | null = null;
  selectedCountry: IRegion | null = null;


 // Dynamic steps array based on image upload
  get steps() {
    const baseSteps = [
      { label: 'Step 1: Geographic Details and Product Type' },
      { label: 'Step 2: Describe your product' },
      { label: 'Step 3: Product Image Upload' },
      { label: 'Step 4: Product Properties' },
      // { label: 'Step 5: Material(s)' },
      { label: 'Step 5: Codes Available (Please confirm by selecting a row below)' },
      { label: 'Step 6: Tariff and Classification Details' },
      { label: 'Step 7: Confirm Classification' }
    ];

    return baseSteps;
  }

  packItems = ['N/A', '2', '3', '4', '5', '6', '7', '8', '9', '10'];
  identicalPackItems = ['N/A', '2', '3', '4', '5', '6', '7', '8', '9', '10'];
  genders = ['Male', 'Female', 'Babies'];
  constructions = ['Hand-Made', 'Not Hand-Made'];
  selectedMaterials: any[] = [];

  constructor(
    private fb: FormBuilder,
    private toastrService: ToastrService,
    public dialogRef: MatDialogRef<ClassificationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      editMode?: boolean;
      headerTitle?: string;
      descriptionOfGoods?: string;
      importRegionCode?: string;
      countryOfOriginCode?: string;
      preselectedItemTypeGroupId?: number;
      productCode: string;
      orderLineId: number;
      itemTypeGroupName: string;
    }
  ) {
    // 1. Build the form
    this.classificationForm = this.fb.group({
      description: [data.descriptionOfGoods || '', Validators.required],
      itemTypeGroup: [this.getItemTypeGroupFromName(data.itemTypeGroupName) ?? null, Validators.required],
      region: [this.getRegionFromCode(data.importRegionCode) || null, Validators.required],
      countryOfOrigin: [this.getRegionFromCode(data.countryOfOriginCode) || null, Validators.required],
      construction: [null],
      productType: ['singleProduct'],
      packItems: [null],
      identicalPackItems: [null],
      image: [null],
      confirmClassification: [false, Validators.requiredTrue],
      meursingCode: ['']
    });

    setTimeout(() => {
      this.classificationForm.get('region')?.updateValueAndValidity();
      this.classificationForm.get('countryOfOrigin')?.updateValueAndValidity();
      this.classificationForm.get('itemTypeGroup')?.updateValueAndValidity();
    }, 0);

    this.classificationForm.get('region')?.valueChanges.subscribe((regionId) => {
      this.selectedRegion = regionId ? this.regions().find(r => r.id === regionId) || null : null;
    });

    // Subscribe to changes in the 'countryOfOrigin' form control
    this.classificationForm.get('countryOfOrigin')?.valueChanges.subscribe((countryId) => {
      this.selectedCountry = countryId ? this.countryOfOrigins().find(c => c.id === countryId) || null : null;
     });

    this.applyConstructionValidator();

    // 2. Combine latest changes from itemTypeGroup & region
    const groupCtrl = this.classificationForm.get('itemTypeGroup');
    const regionCtrl = this.classificationForm.get('region');

    if (groupCtrl && regionCtrl) {
      combineLatest([groupCtrl.valueChanges, regionCtrl.valueChanges]).subscribe(
        ([groupId, regionId]) => {
          // Only call the store if both are set (not null/undefined)
          if (groupId && regionId) {
            this.store.handleGroupAndRegionSelect(groupId, regionId);
          }
          this.applyConstructionValidator();
        }
      );
    }
  }

  // Get the dynamic index of a step based on its label
  getStepIndex(stepLabel: string): number {
    return this.steps.findIndex(step => step.label.includes(stepLabel));
  }

  handleFileInput(event: Event) {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files[0]) {
      const file = input.files[0];
      this.classificationForm.patchValue({ image: file });

      // Store the file name
      this.uploadedImageName = file.name;

      // Generate a preview URL
      const reader = new FileReader();
      reader.onload = () => {
        this.uploadedImagePreview = reader.result as string;
      };
      reader.readAsDataURL(file);
    }
  }

  clearUploadedImage() {
    this.uploadedImageName = null;
    this.uploadedImagePreview = null;
    this.classificationForm.patchValue({ image: null });
  }


  private applyConstructionValidator(): void {
    const constructionCtrl = this.classificationForm.get('construction');
    const selectedGroupId = this.classificationForm.get('itemTypeGroup')?.value;
    const selectedGroup = this.itemTypeGroups().find(g => g.id === selectedGroupId);

    if (selectedGroup?.requiresConstructionMgmt) {
      constructionCtrl?.setValidators([Validators.required]);
    } else {
      constructionCtrl?.clearValidators();
    }
    constructionCtrl?.updateValueAndValidity();
  }

  get selectedItemTypeTariffCode(): string {
    const selectedId = this.store.selectedItemTypeId();
    return this.store.filteredItemTypes().find(t => t.id === selectedId)?.tariffCode || 'No Item Type Selected';
  }

  get tariffData(): TariffData | null {
    const regionId = this.classificationForm.get('region')?.value;
    if (regionId === 159) return this.store.ukTariffData();
    if (regionId === 160) return this.store.usTariffData();
    if (regionId === 45) return this.store.euTariffData();
    return null;
  }

  get showMeursingCodeField(): boolean {
    return this.tariffData?.requiresMeursingCode ?? false;
  }

  openMeursingCodeLookup() {
    const url = 'https://www.gov.uk/additional-commodity-code/y';
    window.open(url, '_blank');
  }

  sanitizeHtml(content: string | null): SafeHtml {
    return content ? this.sanitizer.bypassSecurityTrustHtml(content) : '';
  }

  itemTypeGroups(): IItemTypeGroup[] {
    return this.store.itemTypeGroups();
  }

  regions() {
    return this.store.regions();
  }

  countryOfOrigins() {
    return this.store.countryOfOrigins();
  }

  onItemTypeGroupChange(itemTypeGroupId: number) {
    this.store.handleGroupAndRegionSelect(itemTypeGroupId, this.classificationForm.get('region')?.value || null);
  }

  // For Step 1 validation
  isStep2Valid(): boolean {
    return (
      !!this.classificationForm.get('description')?.valid &&
      !!this.classificationForm.get('itemTypeGroup')?.valid &&
      !!this.classificationForm.get('region')?.valid
    );
  }

  get requiresConstructionMgmt(): boolean {
    const selectedGroupId = this.classificationForm.get('itemTypeGroup')?.value;
    const group = this.itemTypeGroups().find(g => g.id === selectedGroupId);
    return !!group?.requiresConstructionMgmt;
  }

  get requiresMaterialMgmt(): boolean {
    const selectedGroupId = this.classificationForm.get('itemTypeGroup')?.value;
    const group = this.itemTypeGroups().find(g => g.id === selectedGroupId);
    return !!group?.requiresMaterialMgmt;
  }

  get isImageUploaded(): boolean {
    return this.classificationForm.get('image')?.value !== null;
  }

  onTariffCodeSelected(tariffCode: string, itemTypeId: number): void {
    const regionId = this.classificationForm.get('region')?.value;

    if (!regionId) {
      alert('Please select a region before proceeding.');
      return;
    }

    // 1. Set the selected item type in the store
    this.store.setSelectedItemType(itemTypeId);
    this.store.setSelectedTariffCode(tariffCode);

    // 2. Fetch the tariff data based on the selected region
    this.store.fetchTariffData(tariffCode, regionId);

    // 3. Move to Step 5
    this.nextStep();
  }

  nextStep(): void {
    // if (this.selectedIndex === 2 && !this.isImageUploaded) {
    //   alert('Please upload an image to proceed.');
    //   return;
    // }
    // if (this.selectedIndex === 3 && !this.store.selectedItemTypeId()) {
    //   alert('Please select an Item Type before proceeding.');
    //   return;
    // }
    if (this.selectedIndex < this.steps.length - 1) {
      this.selectedIndex++;
    }
  }

  previousStep(): void {
    if (this.selectedIndex > 0) {
      this.selectedIndex--;
    }
  }

  close(): void {
    this.dialogRef.close();
  }

  // onSubmit(): void {
  //   if (this.classificationForm.valid) {
  //     const selectedMaterials = this.selectedMaterials.map((material) => ({
  //       id: material.id,
  //       name: material.name,
  //       percentage: this.classificationForm.get(['percentages', material.id.toString()])?.value,
  //     }));

  //     this.dialogRef.close({
  //       ...this.classificationForm.value,
  //       selectedMaterials,
  //     });
  //   } else {
  //     this.classificationForm.markAllAsTouched();
  //   }
  // }

  submit(): void {
    console.log("submit");
    const tariffCode = this.store.selectedTariffCode();
    const itemTypeId = this.store.selectedItemTypeId();
    const productCode = this.data.productCode;
    const orderLineId = this.data.orderLineId;

    const selectedTariffData = this.store.ukTariffData();
    const tariffDataSubset = {
      description: selectedTariffData?.description || "",
      basic_duty_rate: selectedTariffData?.basic_duty_rate || null,
      validity_start_date: selectedTariffData?.validity_start_date || "",
      vatRate: selectedTariffData?.vatRate || ""
  };

  this.store.submitClassification({
      tariffCode,
      productCode,
      itemTypeId,
      orderLineId,
      ...tariffDataSubset // Spread tariffDataSubset fields
   }).subscribe((success) => {
     if (success) {
          this.toastrService.success("Successfully classified!");
          this.dialogRef.close({ tariffCode });
      } else {
         this.toastrService.error("Failed to classify. Please try again.");
      }
    });
  }

  getRegionClass(regionId: number | null): string {
    if (!regionId) return '';
    const region = this.regions().find(r => r.id === regionId);
    return region ? 'fi ' + region.class : '';
  }

  getRegionDescription(regionId: number | null): string {
    if (!regionId) return '';
    const region = this.regions().find(r => r.id === regionId);
    return region ? region.description : '';
  }

  getCountryClass(countryId: number | null): string {
    if (!countryId) return '';
    const country = this.countryOfOrigins().find(c => c.id === countryId);
    return country ? 'fi ' + country.class : '';
  }

  getCountryDescription(countryId: number | null): string {
    if (!countryId) return '';
    const country = this.countryOfOrigins().find(c => c.id === countryId);
    return country ? country.description : '';
  }

  getRegionFromCode(code: string | undefined): number {
    if (!code) return 0;
    const region = this.countryOfOrigins().find(r => r.countryCode === code);
    return region ? region.id : 0;
  }

  getItemTypeGroupFromName(name: string | undefined): number {
    if (!name) return 0;
    const itemTypeGroup = this.itemTypeGroups().find(r => r.description === name);
    return itemTypeGroup ? itemTypeGroup.id : 0;
  }
}
