import { NgFor } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { MaterialCategoryStore } from '../store/materialstore';
import { MatDialog } from '@angular/material/dialog';
import { IMaterialCategory } from '../../../../core/models/IMaterialCategory';
import { MaterialCategoryDialogComponent } from '../material-category-dialog/material-category-dialog.component';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-material-category-list',
  standalone: true,
  imports: [NgFor],
  templateUrl: './material-category-list.component.html',
  styleUrl: './material-category-list.component.scss'
})
export class MaterialCategoryListComponent implements OnInit {

  store = inject(MaterialCategoryStore);
  toastrService = inject(ToastrService);
  dialog = inject(MatDialog);


  ngOnInit() {
  }

  // Open the dialog to create or edit material category
  openMaterialCategoryDialog(editMode: boolean, materialCategory?: IMaterialCategory) {
    const dialogRef = this.dialog.open(MaterialCategoryDialogComponent, {
      width: '600px',
      data: {
        materialCategory: materialCategory ?? { id: 0, description: '' },
        editMode
      }
    });

    dialogRef.afterClosed().subscribe((result: IMaterialCategory) => {
      if (result) {
        if (editMode) {
          this.store.patchCategory(result);
        } else {
          this.store.addCategory(result);
        }
      }
    });
  }

  createMaterialCategory() {
    this.openMaterialCategoryDialog(false);
  }

  editMaterialCategoryDescription(materialCategory: IMaterialCategory) {
    this.openMaterialCategoryDialog(true, materialCategory);
  }

}
