import { getState, patchState, signalStore, withComputed, withHooks, withMethods, withState } from "@ngrx/signals";
import { ISizeCategory } from "../../../../core/models/ISizeCategory";
import { computed, inject } from "@angular/core";
import { RegionService } from "../../../../core/services/region.service";
import { SizeService } from "../../../../core/services/size.service";
import { ItemtypeGroupService } from "../../../../core/services/itemtype-group.service";
import { LocalStorageService } from "../../../../core/services/local-storage.service";
import { forkJoin, Observable } from "rxjs";
import { IDefaultSize } from "../../../../core/models/IDefaultSize";
import { IRegion } from "../../../../core/models/IRegion";
import { IItemTypeGroup } from "../../../../core/models/IItemTypeGroup";

const defaultSizesStoreKey = 'tarifftel_defaultsize_category_list_data';
const defaultSizesStoreTimestampKey = 'tarifftel_defaultsize_category_list_data_timestamp';
const CACHE_DURATION = 60 * 60 * 1000; // 1 hour

type Filter = 'all' | 'active' | 'inactive';

type TariffTelDefaultSizeListState = {
  defaultsizes: IDefaultSize[];
  sizecategories: ISizeCategory[];
  regions: IRegion[];
  itemTypeGroups: IItemTypeGroup[];
  filter: Filter;
  searchTerm: string;
  selectedList: 'sizes' | 'sizecategories';
  selectedItemTypeGroupId: number | null;
  selectedRegionId: number | null;
  selectedSizeCategoryId: number | null;
  selectedGender: string | null;
  loading: boolean;
};

const initialState: TariffTelDefaultSizeListState = {
  defaultsizes: [],
  sizecategories: [],
  regions: [],
  itemTypeGroups: [],
  filter: 'all',
  searchTerm: '',
  selectedList: 'sizes',
  selectedItemTypeGroupId: null,
  selectedRegionId: null,
  selectedSizeCategoryId: null,
  selectedGender: null,
  loading: true,
};

export const DefaultSizeStore = signalStore(
  { providedIn: 'root' },
  withState(initialState),
  withComputed(({ defaultsizes, sizecategories, selectedList, selectedRegionId, selectedItemTypeGroupId, regions, itemTypeGroups }) => ({
    // Filtered list based on selected section
    filteredList: computed(() => {
      const selectedGroupId = selectedItemTypeGroupId();
      if (selectedList() === 'sizes') {
        return defaultsizes().filter(item =>
          (!selectedRegionId() || item.regionId === selectedRegionId()) &&
          (!selectedItemTypeGroupId() || item.itemTypeGroupId === selectedItemTypeGroupId())
        );
      } else if (selectedList() === 'sizecategories') {
        return sizecategories();
      }
      return [];
    }),

    filteredSizes: computed(() => {
      if (selectedList() === 'sizes') {
        return defaultsizes().map(item => ({
          ...item,
          sizeCategoryName: item.sizeCategoryName
        })).filter(item =>
          (!selectedRegionId() || item.regionId === selectedRegionId()) &&
          (!selectedItemTypeGroupId() || item.itemTypeGroupId === selectedItemTypeGroupId())
        );
      }
      return [];
    }),

    filteredCategories: computed(() => {
      const itemTypeGroupId = selectedItemTypeGroupId();
      if (selectedList() === 'sizecategories') {
        return sizecategories().filter(category =>
          itemTypeGroupId ? category.itemTypeGroupId === itemTypeGroupId : true
        );
      }
      return [];
    }),

    filteredCategoriesForDialog: computed(() => {
      const itemTypeGroupId = selectedItemTypeGroupId();
      return sizecategories().filter(category =>
        itemTypeGroupId ? category.itemTypeGroupId === itemTypeGroupId : true
      );
    }),

    selectedRegion: computed(() => {
      return regions().find(region => region.id === selectedRegionId());
    }),

    selectedItemTypeGroup: computed(() => {
      return itemTypeGroups().find(group => group.id === selectedItemTypeGroupId());
    }),
  })),

  withMethods((store) => {
    const regionService = inject(RegionService);
    const sizeService = inject(SizeService);
    const itemTypeGroupService = inject(ItemtypeGroupService);
    const localStorageService = inject(LocalStorageService);

    return {
      loadData() {
        const dataFromStorage = localStorageService.getItem(defaultSizesStoreKey) || {};
        const timestamp = parseInt(localStorageService.getItem(defaultSizesStoreTimestampKey) || '0', 10);
        const now = Date.now();

        patchState(store, { loading: true });

        if (Object.keys(dataFromStorage).length > 0 && (now - timestamp) < CACHE_DURATION) {
          patchState(store, dataFromStorage);
          patchState(store, { loading: false });

          if (getState(store).selectedItemTypeGroupId === null) {
            this.selectFirstItemTypeGroup();
          }

          if (getState(store).selectedRegionId === null && getState(store).regions.length > 0) {
            this.setSelectedRegionId(getState(store).regions[0].id);
          }
        } else {
          forkJoin({
            defaultsizes: sizeService.getSizes(),
            sizecategories: sizeService.getSizeCategories(),
            regions: regionService.getActiveRegions(),
            itemTypeGroups: itemTypeGroupService.getManagedAtSizeItemTypeGroups()
          }).subscribe({
            next: ({ defaultsizes, sizecategories, regions, itemTypeGroups }) => {
              patchState(store, { defaultsizes, sizecategories, regions, itemTypeGroups });
              localStorageService.setItem(defaultSizesStoreKey, JSON.stringify({ defaultsizes, sizecategories, regions, itemTypeGroups }));
              localStorageService.setItem(defaultSizesStoreTimestampKey, now.toString());
              patchState(store, { loading: false });

              // Select the first item type group if no group is selected
              if (getState(store).selectedItemTypeGroupId === null) {
                this.selectFirstItemTypeGroup();
              }

              if (getState(store).selectedRegionId === null && regions.length > 0) {
                this.setSelectedRegionId(regions[0].id);
              }
            },
            error: (err) => {
              console.error('Failed to load default sizes, categories, regions, and Item Type Groups', err);
              patchState(store, { loading: false });
            }
          });
        }
      },

      getSizeCategories(): Observable<ISizeCategory[]> {
        return new Observable((observer) => {
          sizeService.getSizeCategories().subscribe({
            next: (categories) => {
              patchState(store, { sizecategories: categories });
              observer.next(categories);
              observer.complete();
            },
            error: (error) => {
              console.error('Failed to load size categories', error);
              observer.error(error);
            }
          });
        });
      },

      loadSizeCategoriesByGroup(itemTypeGroupId: number) {
        sizeService.getSizeCategoriesByGroup(itemTypeGroupId).subscribe({
          next: (sizecategories) => {
            patchState(store, { sizecategories });
          },
          error: (err) => {
            console.error('Failed to load size categories for the selected group', err);
          }
        });
      },

      setSelectedItemTypeGroupId(id: number | null) {
        patchState(store, { selectedItemTypeGroupId: id });
      },

      setSelectedRegionId(id: number | null) {
        patchState(store, { selectedRegionId: id });
      },

      setSelectedSizeCategoryId(id: number | null) {
        patchState(store, { selectedSizeCategoryId: id });
      },

      setSelectedGender(gender: string | null) {
        patchState(store, { selectedGender: gender });
      },

      showTable(selectedList: 'sizes' | 'sizecategories') {
        patchState(store, { selectedList });
      },

      setSearchTerm(term: string) {
        patchState(store, { searchTerm: term });
      },

      isDuplicateSizeCode(newSize: IDefaultSize): boolean {
        const size = getState(store).defaultsizes;
        return size.some(size => size.sizeCode.toLowerCase() === newSize.sizeCode.toLowerCase()
          && size.itemTypeGroupId === newSize.itemTypeGroupId
          && size.regionId === newSize.regionId
          && size.id !== newSize.id);
      },

      createSize(defaultSize: IDefaultSize): Observable<IDefaultSize> {
        return sizeService.createSize(defaultSize);
      },

      updateSize(defaultSize: IDefaultSize): Observable<IDefaultSize> {
        return sizeService.updateSize(defaultSize);
      },

      deleteSize(sizeId: number): Observable<void> {
        return sizeService.deleteSize(sizeId);
      },

      // Add new item directly to the state
      addSize(newItem: IDefaultSize) {
        const currentState = getState(store);

        // Find the related size category by ID
        const relatedCategory = currentState.sizecategories.find(cat => cat.id === newItem.sizeCategoryId);

        if (!relatedCategory) {
          throw new Error('Size category not found for the new item');
        }

        // Create a new item including the resolved category name
        const sizeWithCategory: IDefaultSize = {
          ...newItem,
          sizeCategoryName: relatedCategory.categoryName
        };

        // Add the new item with the category directly to the state
        patchState(store, {
          defaultsizes: [...currentState.defaultsizes, sizeWithCategory],
        });
      },

      // Update the item in the state
      patchSize(updatedItem: IDefaultSize) {
        // Access the current state to get the size categories
        const currentState = getState(store);
        const updatedCategory = currentState.sizecategories.find(cat => cat.id === updatedItem.sizeCategoryId);

        if (!updatedCategory) {
          throw new Error('Size category not found for the updated item');
        }

        const sizeWithCategory = {
          ...updatedItem,
          sizeCategoryName: updatedCategory.categoryName
        };

        patchState(store, {
          defaultsizes: currentState.defaultsizes.map(item =>
            item.id === updatedItem.id ? sizeWithCategory : item
          ),
        });
      },

      removeSizeFromStore(sizeId: number) {
        patchState(store, {
          defaultsizes: getState(store).defaultsizes.filter(size => size.id !== sizeId),
        });
      },

      isDuplicateCategory(newCategory: ISizeCategory): boolean {
        const categories = getState(store).sizecategories;
        return categories.some(category => 
          category.categoryName.toLowerCase() === newCategory.categoryName.toLowerCase() && 
          category.itemTypeGroupId === newCategory.itemTypeGroupId && 
          category.id !== newCategory.id
        );
      },

      createCategory(category: ISizeCategory): Observable<ISizeCategory> {
        return sizeService.createCategory(category);
      },

      updateCategory(category: ISizeCategory): Observable<ISizeCategory> {
        return sizeService.updateCategory(category);
      },

      deleteCategory(categoryId: number): Observable<void> {
        return sizeService.deleteCategory(categoryId);
      },

      patchCategory(updatedCategory: ISizeCategory) {
        patchState(store, {
          sizecategories: getState(store).sizecategories.map(cat =>
            cat.id === updatedCategory.id ? updatedCategory : cat
          ),
        });
      },

      addCategory(newCategory: ISizeCategory) {
        patchState(store, {
          sizecategories: [...getState(store).sizecategories, newCategory],
        });
      },

      removeCategoryFromStore(categoryId: number) {
        patchState(store, {
          sizecategories: getState(store).sizecategories.filter(cat => cat.id !== categoryId),
          defaultsizes: getState(store).defaultsizes.filter(size => size.sizeCategoryId !== categoryId),
        });
      },

      clearCache() {
        localStorageService.removeItem(defaultSizesStoreKey);
        localStorageService.removeItem(defaultSizesStoreTimestampKey);
        patchState(store, initialState);
      },


      selectFirstItemTypeGroup() {
        const { itemTypeGroups } = getState(store);
        if (itemTypeGroups.length > 0) {
          this.setSelectedItemTypeGroupId(itemTypeGroups[0].id);
        }
      },

      handleGroupClick(group: IItemTypeGroup) {
        this.setSelectedItemTypeGroupId(group.id);
      },

      handleRegionClick(region: IRegion) {
        this.setSelectedRegionId(region.id);
      },
    };
  }),

  withHooks({
    onInit(store) {
      store.loadData();
    },
  })
);
