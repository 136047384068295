import { CommonModule } from '@angular/common';
import { Component, computed, effect, Inject, inject, signal } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { DateAdapter, MAT_DATE_LOCALE, MatNativeDateModule, provideNativeDateAdapter } from '@angular/material/core';
import { MatDatepickerIntl, MatDatepickerModule } from '@angular/material/datepicker';
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ToastrService } from 'ngx-toastr';
import { ReportService } from '../../../core/services/report.service';
import { UserFriendlyHttpErrorMessageDialogComponent } from '../../settings/vendor-management/user-friendly-http-error-message-dialog/user-friendly-http-error-message-dialog.component';

@Component({
  selector: 'app-date-range-dialog',
  standalone: true,
  imports: [ 
    MatDialogModule, 
    MatFormFieldModule, 
    MatInputModule, 
    MatDatepickerModule,
    FormsModule,
    MatButtonModule,
    CommonModule, 
    MatNativeDateModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    MatDividerModule
  ],
  providers: [
    {provide: MAT_DATE_LOCALE, useValue: 'en-GB'},
    provideNativeDateAdapter()
  ],
  templateUrl: './date-range-dialog.component.html',
  styleUrls: ['./date-range-dialog.component.css']
})

export class DateRangeDialogComponent {
  private readonly _adapter = inject<DateAdapter<unknown, unknown>>(DateAdapter);
  private readonly LOCALE = 'en-GB'
  readonly DATEFORMATSTRING = 'DD/MM/YYYY'
  showMatSpinner = false;
  reportOptionTitle:string | undefined
  reportOptionImagePath:string | undefined
  reportOptionEndpointPath:string | undefined
  readonly reportForm = new FormGroup({
    fromDate: new FormControl(undefined,[Validators.required]),
    toDate: new FormControl(undefined,[Validators.required]),
  });
  downloadSuccess = false; // Flag to track download success
  downloadedReport:Blob | undefined

  constructor(private dialog:MatDialog,private reportService:ReportService,private toastrService:ToastrService,private dialogRef: MatDialogRef<DateRangeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: {reportOptionTitle: string,reportOptionImagePath:string,reportOptionEndpointPath: string,},
  ) {
    this.reportOptionTitle = data.reportOptionTitle;
    this.reportOptionImagePath = data.reportOptionImagePath;
    this.reportOptionEndpointPath = data.reportOptionEndpointPath;
    this._adapter.setLocale(this.LOCALE);
  }

  // Method to close the dialog without returning data
  onCancel(): void {
    this.dialogRef.close();
  }

  // Method to close the dialog and return the selected date range
  onDownload(): void {

    if(!this.reportOptionEndpointPath|| this.reportOptionEndpointPath?.length == 0) {
      console.log("Cannot download report as endpoint path for report is not set");
      this.dialogRef.close();
      this.dialog.open(UserFriendlyHttpErrorMessageDialogComponent);
      return;
    }

    if(this.reportForm.valid && this.reportForm.dirty && this.reportOptionEndpointPath && this.reportForm.controls.fromDate.value && this.reportForm.controls.toDate.value) {
      this.showMatSpinner = true;
      this.reportService.downloadReport(this.reportOptionEndpointPath,this.reportForm.controls.fromDate.value,this.reportForm.controls.toDate.value).subscribe({
        next:(reportResponseBlob:any) => {
          this.downloadedReport =reportResponseBlob;
          this.downloadFileInBrowser();
          this.downloadSuccess = true;
          this.showMatSpinner = false;
          this.toastrService.success(this.reportOptionTitle + " report downloaded successfully!");
          this.dialogRef.close();
        },
        error:() => {
          this.showMatSpinner = false;
          this.dialogRef.close();
          this.dialog.open(UserFriendlyHttpErrorMessageDialogComponent);
        }
      });
    }
  
  }

  downloadFileInBrowser() {
    if(this.downloadedReport) {
      const link = document.createElement('a');
      const url = window.URL.createObjectURL(this.downloadedReport);
      link.href = url;
      link.download = (this.reportOptionTitle) ? this.reportOptionTitle.replace(/\s/g, "") + '_Report.xlsx':'Report.xlsx'; //remove spaces from name of report
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
         
    }
    
  }

}
