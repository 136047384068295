
<h2 mat-dialog-title>{{reportOptionTitle + ' Report'}}</h2>
<mat-dialog-content>
  <!-- Show success message if download is successful -->
  <div *ngIf="downloadSuccess" class="success-message">
    Download successful!
  </div>
  <div *ngIf="showMatSpinner"class="mat-spinner-container">
      <div class="mat-spinner-inner-container">
        <mat-spinner [diameter]="40"></mat-spinner>
      </div>
      <h5 class="mt-4">Downloading...</h5> 
  </div>
  <div *ngIf="!downloadSuccess && !showMatSpinner" class="logo-container">
    <img class="company-logo img-responsive" [src]="reportOptionImagePath" />
  </div>
  <div *ngIf="!downloadSuccess && !showMatSpinner" class="my-2">
    <div class="mb-3">
      <h6>Select Date Range:</h6>
    </div>
    <mat-form-field appearance="outline" class="full-width">
      <mat-label>From - To Date</mat-label>
      <mat-date-range-input 
        [formGroup]="reportForm"
        [rangePicker]="reportsDateRangePicker">
        <input readonly matStartDate placeholder="From" formControlName="fromDate">
        <input readonly matEndDate placeholder="To Date" formControlName="toDate">
      </mat-date-range-input>
      <mat-hint>{{this.DATEFORMATSTRING + '–' + this.DATEFORMATSTRING}}</mat-hint>
      <mat-datepicker-toggle matIconSuffix [for]="reportsDateRangePicker"></mat-datepicker-toggle>
      <mat-date-range-picker #reportsDateRangePicker></mat-date-range-picker>
      <mat-error>
        <ng-container *ngIf="reportForm.controls.fromDate.errors?.['required']||reportForm.controls.toDate.errors?.['required']">
          Please select a date range.
        </ng-container>
      </mat-error>
    </mat-form-field>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <ng-container *ngIf="!downloadSuccess && !showMatSpinner">
    <div class="px-3">
      <a href="javascript:void(0)" (click)="onCancel()">Cancel</a>
    </div>
    <button mat-raised-button 
    color="primary" 
    [disabled]="!this.reportForm.dirty || !this.reportForm.valid"
    (click)="onDownload()" >Download</button>
  </ng-container>

</mat-dialog-actions>
