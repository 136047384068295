<div class="container">
  <div class="d-flex justify-content-between align-items-center">
    <h6>Item Type(s) for {{ store.selectedItemTypeGroupName() }}</h6>
    <button
      type="button"
      class="btn btn-primary btn-sm"
      (click)="createItemType()">
      Create a New Item Type
    </button>
  </div>
  <div class="alert alert-warning mt-2" role="alert" *ngIf="store.itemTypes().length === 0">
    No Item Type(s) exist for {{ store.selectedItemTypeGroupName() }}.
  </div>

  <table class="table table-striped mt-3" *ngIf="store.itemTypes().length > 0">
    <thead>
      <tr>
        <th scope="col" style="width:70%">Item Type</th>
        <th scope="col" style="width:15%">HS Code 1</th>
        <th scope="col" style="width:15%">HS Code 2</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of store.itemTypes()">
        <td>{{ item.description }}</td>
        <td>{{ item.tariffCode }}</td>
        <td>{{ item.tariffCode2 }}</td>
      </tr>
    </tbody>
  </table>
</div>

