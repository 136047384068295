import { CommonModule, DatePipe, TitleCasePipe } from '@angular/common';
import { Component, effect, inject, input } from '@angular/core';
import { AgGridModule } from 'ag-grid-angular';
import { ColDef, Column, GetContextMenuItemsParams, GridApi, GridReadyEvent, MenuItemDef, SideBarDef, ToolPanelDef } from 'ag-grid-enterprise';
import { AggridFurtherOptionsToolPanelComponent } from '../../settings/vendor-management/aggrid-further-options-tool-panel/aggrid-further-options-tool-panel.component';
import { AggridFurtherOptions } from '../../settings/vendor-management/aggrid-further-options-tool-panel/AggridFurtherOptions';
import { Router } from '@angular/router';
import { ProductClassificationDialogComponent } from '../order-details/product-classification-dialog/product-classification-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmModalComponent } from '../../../core/components/confirm-modal/confirm-modal.component';
import { DemoClassifierComponent } from '../../item-type-management/item-type-mgmt/demo-classifier/demo-classifier.component';
import { OrderService } from '../../../core/services/order.service';
import { ClassificationDialogComponent } from '../../item-type-management/item-type-mgmt/classification-dialog/classification-dialog.component';
import { classificationStore } from '../../item-type-management/item-type-mgmt/classification-dialog/store/classifierstore';
import { TariffData } from '../../../core/services/tariffbook.service';
import { TariffDetailsDialogComponent } from '../../item-type-management/item-type-mgmt/tariff-details-dialog/tariff-details-dialog.component';

@Component({
  selector: 'app-aggrid-orders',
  standalone: true,
  imports: [
    CommonModule,
    AgGridModule
  ],
  templateUrl: './aggrid-orders.component.html',
  styleUrl: './aggrid-orders.component.scss',
  providers:[TitleCasePipe]
})
export class AggridOrdersComponent {
  classificationStore = inject(classificationStore);
  readonly EXCELEXPORTFILENAME = input<string>("Tarifftel");
  readonly EXCELEXPORTWORKSHEETNAME = input<string>("Tarifftel Worksheet");
  readonly DATETIMEFORMAT = "dd MMM yyyy HH:mm";
  gridApi: GridApi|undefined;
  rowsObjects = input<any[] | undefined>(undefined);
  fieldsToInitiallyShowDetailRow = input<string[]|undefined>(undefined);
  fieldsToExcludeFromGridDetailRow = input<string[]>([
    "isDeleted",
    "createdAt",
    "updatedAt",
    "createdBy",
    "updatedBy",
  ]);
  excludeIdFieldsFromGridDetailRow = input<boolean>(false);
  includeSelectLink = input<boolean>(false);
  includeClassifyButton = input<boolean>(false);
  routingSelectLinkPath = input<string|undefined>(undefined);
  gridHeight= input<number>(485);
  showGridLoading= input<boolean>(true);

  defaultColDef: ColDef = {
    flex:1,
    // allow every column to be aggregated
    enableValue: true,
    // allow every column to be grouped
    enableRowGroup: true,
    // allow every column to be pivoted
    enablePivot: true,
    filter: true,
    sortable:true,
    suppressMovable:true,
    editable: false,
    suppressHeaderMenuButton: true
  };
  sideBarDef = input<SideBarDef>({
        toolPanels: [
            {
              id: 'columns',
              labelDefault: 'Columns',
              labelKey: 'columns',
              iconKey: 'columns',
              toolPanel: 'agColumnsToolPanel',
              toolPanelParams: {
                suppressRowGroups: true,
                suppressValues: true,
                suppressPivots: true,
                suppressPivotMode: true,
                suppressColumnFilter: false,
                suppressColumnSelectAll: false,
                suppressColumnExpandAll: false,
              }
          },
          {
              id: 'filters',
              labelDefault: 'Filters',
              labelKey: 'filters',
              iconKey: 'filter',
              toolPanel: 'agFiltersToolPanel'
          },
          {
            id: 'furtherOptions',
            labelDefault: 'Further Options',
            labelKey: 'furtherOptions',
            iconKey: 'columns',
            toolPanel: AggridFurtherOptionsToolPanelComponent,
            toolPanelParams: {
              furtherOptionsToDisplay:[
                AggridFurtherOptions.COPYMENUITEMNAME,
                AggridFurtherOptions.COPYWITHHEADERSMENUITEMNAME,
                AggridFurtherOptions.EXCELEXPORTSELECTEDROWSMENUITEMNAME,
                AggridFurtherOptions.EXCELEXPORTMENUITEMNAME
              ],
              excelExportParams:{
                columnKeys:[],
                fileName: this.EXCELEXPORTFILENAME(),
                sheetName:this.EXCELEXPORTWORKSHEETNAME(),
              }
            }
          },
      ],
      position: 'right',
      defaultToolPanel: ''
  })
  rowData!: any[]|undefined;
  themeClass: string = "ag-theme-quartz";
  columnDefs: ColDef[] = [
  ];
  aggridContextMenuItems: (string | MenuItemDef)[] = [
        'copy',
        'copyWithHeaders',
        {
          // custom item
          name: AggridFurtherOptions.EXCELEXPORTSELECTEDROWSMENUITEMNAME,
          action: () => {

            let exportExcelParams = {
              columnKeys: this.gridApi?.getColumns()?.slice(1).map((col: Column) => col.getColId()), // exclude the first column which is a checkbox column
              onlySelected:true,
              fileName: this.EXCELEXPORTFILENAME(),
              sheetName:this.EXCELEXPORTWORKSHEETNAME(),
            }

            if(this.chkRowsAreSelectedForBulks("",`Please select atleast one row to export selected rows to an excel file.`)) {
              this.gridApi?.exportDataAsExcel(exportExcelParams);
            }
          },
          cssClasses: ['bold'],
        },
        {
          // custom item
          name: AggridFurtherOptions.EXCELEXPORTMENUITEMNAME,
          action: () => {

            let exportExcelParams = {
              columnKeys: this.gridApi?.getColumns()?.slice(1).map((col: Column) => col.getColId()), // exclude the first column which is a checkbox column
              onlySelected:false,
              fileName: this.EXCELEXPORTFILENAME(),
              sheetName:this.EXCELEXPORTWORKSHEETNAME(),
            }
            this.gridApi?.exportDataAsExcel(exportExcelParams);
          },
          cssClasses: ['bold'],
        }
      ];

  constructor(private dialog:MatDialog,private router:Router,private titlecasePipe:TitleCasePipe, private orderService: OrderService ) {
    effect(() => {
      if(this.rowsObjects()) {
        this.initGridWithOrderLines();
      }

      this.gridApi?.setGridOption("loading", this.showGridLoading());
    });

  }

  initGridWithOrderLines() {
    this.gridApi?.setGridOption("loading", true);

    this.rowData = this.rowsObjects();

    if(this.rowData && this.rowData.length > 0) {
      this.setAggridColDefs(Object.keys(this.rowData[0]));
    }

    this.gridApi?.setGridOption("loading", this.showGridLoading());
  }


  onGridReady(params: GridReadyEvent<any>) {
    this.gridApi = params.api;

    this.initGridWithOrderLines();
  }

  setAggridColDefs(fields: string[]) {
    let filteredFieldsForAggrid = this.filterFieldsForAggrid(fields);
    this.setInitialFieldColumnDefForAggrid(filteredFieldsForAggrid)

    if(this.includeClassifyButton()) {
      this.addClassifyButtonToAggridColumnDefs();
    }

    if(this.includeSelectLink()) {
      this.addSelectLinkToAggridColumnDefs();
    }


      setTimeout(() => {
        this.sideBarDef().toolPanels?.forEach((toolPanel:ToolPanelDef|string) => {
          if((toolPanel as ToolPanelDef).id == "furtherOptions") {
            // need to exclude the first column which is the checkbox from the columns to export to excel
            (toolPanel as ToolPanelDef).toolPanelParams.excelExportParams.columnKeys = this.gridApi?.getColumns()?.slice(1).map((col: Column) => col.getColId());
          }
        })
        this.gridApi?.refreshToolPanel();
      });

  }

  filterFieldsForAggrid(fields: string[]) {
    if(this.excludeIdFieldsFromGridDetailRow()) {
      return fields.filter((field) => !field.toUpperCase().includes("ID") && !this.fieldsToExcludeFromGridDetailRow().includes(field));
    }
    else {
      return fields.filter((field) => !this.fieldsToExcludeFromGridDetailRow().includes(field));
    }
  }

  setInitialFieldColumnDefForAggrid(fields: string[]) {

    this.columnDefs = [
      {
        headerName: '',
        maxWidth:60,
        resizable: false,
        sortable:false,
        suppressFiltersToolPanel: true,
        suppressFloatingFilterButton:true,
        suppressColumnsToolPanel:true,
        checkboxSelection:true,
        headerCheckboxSelection:true,
        cellRenderer: "agGroupCellRenderer",
        suppressHeaderContextMenu:true,
        suppressHeaderMenuButton:true,
        filter: false
      }
    ];

    this.columnDefs.push(...fields.map((field:any) => {
      let mappedColDef:ColDef = {
        headerName: this.titlecasePipe.transform(this.convertPropNameToTitleCaseWithSpaces(field)),
        field: field,
        hide:(this.fieldsToInitiallyShowDetailRow()) ? !this.fieldsToInitiallyShowDetailRow()?.includes(field):false,
        filter:true,
        suppressColumnsToolPanel:false,
        suppressFloatingFilterButton:true,
        suppressFiltersToolPanel: false,
        suppressHeaderContextMenu:true,
        suppressHeaderMenuButton:true,
        suppressHeaderFilterButton:true
      }

      if(field.toUpperCase().includes("DATE")) {
        mappedColDef.cellRenderer = (data: any) => {
          return new DatePipe('en-US').transform(data.value,this.DATETIMEFORMAT)
        }
      }

      if (field.toUpperCase().includes("TARIFFCODE")) {
        mappedColDef.cellRenderer = (params: any) => {
          if (params.value) {
            return `<a href="javascript:void(0)" style="text-decoration:underline; color:blue;">${params.value}</a>`;
          }
          return "";
        };
        mappedColDef.onCellClicked = (params: any) => {
          if (params.value) {
            this.openTariffCodeDialog(params.data);
          }
        };
      }

      return mappedColDef;
    }))
  }

  addClassifyButtonToAggridColumnDefs() {
    this.columnDefs.push({
      headerName: '',
      field: '',
      cellRenderer: (params: any) => {
        const isDisabled = params.data.productStatusDescription === 'Approved & Classified';
        const buttonClass = isDisabled ? 'btn-disabled' : 'btn-primary';
        return `
          <div>
            <button class="btn ${buttonClass} btn-sm mb-2" ${isDisabled ? 'disabled' : ''}>
              Classify
            </button>
          </div>
        `;
      },
      onCellClicked:(params) => {
        if (params.data.productStatusDescription !== 'Approved & Classified') {
          this.classify(params.data);
        }
      },
      maxWidth:100,
      resizable: false,
      sortable:false,
      suppressFiltersToolPanel: true,
      suppressFloatingFilterButton:true,
      suppressColumnsToolPanel:true,
      filter: false,
      suppressHeaderContextMenu:true,
      suppressHeaderMenuButton:true
    })
  }

    addSelectLinkToAggridColumnDefs() {
      this.columnDefs.push(
        {
          headerName: '',
          cellRenderer:() => {
            return `<a href="javascript:void(0)" style="text-decoration:underline" >Select</a>`;
          },
          onCellClicked:(params) => {
            this.routeToOrderDetail(params.data.id);
          },
          maxWidth:80,
          resizable: false,
          sortable:false,
          suppressFiltersToolPanel: true,
          suppressFloatingFilterButton:true,
          suppressColumnsToolPanel:true,
          filter: false
        }
      );
    }

    convertPropNameToTitleCaseWithSpaces(propName:string):string {
      return this.titlecasePipe.transform(propName.replace(/([a-z])([A-Z])/g, '$1 $2'));
    }

    routeToOrderDetail(orderId:number) {
      this.router.navigate([this.routingSelectLinkPath(),orderId])
    }

    classify(rowData: any): void {
      // Suppose you have an `order` object with relevant fields
      console.log(rowData);
      const order = {
        descriptionOfGoods: rowData.productDescription,
        uniqueproductCode: rowData.uniqueProductCode,
        orderLineId: rowData.id,
        importRegionCode: rowData.importRegionCountryCode,
        countryOfOriginCode: rowData.countryOfOriginCode,
        itemTypeGroupName: rowData.itemTypeGroupName
      };

      this.openDialog(false, order);
    }

      openDialog(editMode: boolean, order?: any) {
        const dialogRef = this.dialog.open(ClassificationDialogComponent, {
          width: '1200px',
          disableClose: true,
          data: {
            editMode,
            headerTitle: `Classifying ${order.uniqueproductCode} - ${order.descriptionOfGoods} `,
            descriptionOfGoods: order?.descriptionOfGoods ?? '',
            productCode: order?.uniqueproductCode ?? '',
            orderLineId: order?.orderLineId ?? null,
            importRegionCode: order?.importRegionCode ?? null,
            countryOfOriginCode: order?.countryOfOriginCode ?? null,
            itemTypeGroupName: order?.itemTypeGroupName ?? null
          }
        });

        dialogRef.afterClosed().subscribe(result => {
          if (result && result.tariffCode) {
            this.refreshOrderDetails();
          }
        });
      }

      refreshOrderDetails(): void {
        const event = new CustomEvent("refreshOrderDetails");
        window.dispatchEvent(event);
      }

    classifyButtonEvent(rowData: any): void {
      if (rowData.productStatusDescription === 'Approved & Classified') {
        return; // Prevent action if the button is disabled
      }

      const dialogRef = this.dialog.open(DemoClassifierComponent, {
        data: {
          orderLine: rowData,
          headerTitle: `Classifying ${rowData.productCode} - ${rowData.productDescription} `,
        },
        width: '1000px', // Set the fixed width
        height: 'auto', // Set the height or leave it flexible
      });

      dialogRef.afterClosed().subscribe((result: any) => {
        if (result) {
          const newStatus = 'Under Customs Review';
          this.orderService.updateOrderStatus(rowData.id, newStatus).subscribe(() => {
            rowData.productStatusDescription = newStatus; // Update grid data
            this.gridApi?.applyTransaction({ update: [rowData] }); // Refresh grid
          });
        }
      });
    }

    chkRowsAreSelectedForBulks(confirmModalTitle:string,confirmModalMsg:string) {
      if(!this.gridApi) {
        return
      }

      let selectedVendorRows = this.gridApi.getSelectedRows();
      if(selectedVendorRows.length < 1) {
          this.dialog.open(ConfirmModalComponent, {
            data: {
              title:confirmModalTitle,
              message:confirmModalMsg
            }
          });
          return false;
      }
      else  {
        return true;
      }
    }

    getContextMenuItems= (params:GetContextMenuItemsParams) => {
      return this.aggridContextMenuItems;
    }


    openTariffCodeDialog(rowData: any): void {
      console.log(rowData);
      const tariffCode = rowData.tariffCode;
      const regionId = this.getRegionFromCode(rowData.importRegionCountryCode);
      const countryOfOrigin = this.getRegionFromCode(rowData.countryOfOriginCode);
      const itemTypeDescription = rowData.itemTypeDescription;

      // Fetch tariff data
      this.classificationStore.fetchTariffData(tariffCode, regionId);

      // Wait until data is updated in the store
      const checkData = setInterval(() => {
        let tariffData: TariffData | null = null;

        if (regionId === 159) tariffData = this.classificationStore.ukTariffData();
        if (regionId === 160) tariffData = this.classificationStore.usTariffData();
        if (regionId === 45) tariffData = this.classificationStore.euTariffData();

        if (tariffData) {
          clearInterval(checkData); // Stop checking once data is available

          this.dialog.open(TariffDetailsDialogComponent, {
            width: '1000px',
            height: '600px',
            data: {
              tariffCode,
              regionId,
              countryOfOrigin,
              itemTypeDescription,
              tariffData,
              regionDescription: this.getRegionDescription(regionId),
              countryOfOriginDescription: this.getCountryDescription(countryOfOrigin),
            }
          });
        }
      }, 200); // Check every 200ms until data is available
    }

    getRegionFromCode(code: string | undefined): number {
      if (!code) return 0;
      const region = this.countryOfOrigins().find(r => r.countryCode === code);
      return region ? region.id : 0;
    }

    countryOfOrigins() {
      return this.classificationStore.countryOfOrigins();
    }

    regions() {
      return this.classificationStore.regions();
    }

    getRegionClass(regionId: number | null): string {
      if (!regionId) return '';
      const region = this.regions().find(r => r.id === regionId);
      return region ? 'fi ' + region.class : '';
    }

    getRegionDescription(regionId: number | null): string {
      if (!regionId) return '';
      const region = this.regions().find(r => r.id === regionId);
      return region ? region.description : '';
    }

    getCountryClass(countryId: number | null): string {
      if (!countryId) return '';
      const country = this.countryOfOrigins().find(c => c.id === countryId);
      return country ? 'fi ' + country.class : '';
    }

    getCountryDescription(countryId: number | null): string {
      if (!countryId) return '';
      const country = this.countryOfOrigins().find(c => c.id === countryId);
      return country ? country.description : '';
    }
}
