<h2 mat-dialog-title>{{ editMode ? 'Edit Attribute' : 'Create Attribute' }}</h2>
<hr />
<form [formGroup]="attributeForm" (ngSubmit)="onSave()">
  <mat-dialog-content>
    <div class="form-group">

      <!-- Attribute Name Field -->
      <mat-form-field appearance="fill" class="full-width">
        <mat-label>Attribute Name</mat-label>
        <input matInput formControlName="attributeName" placeholder="Enter attribute name" maxlength="500"/>

        <mat-hint align="start">
          Defines a category or characteristic for an Item Type (e.g., 'Brass-wind').
        </mat-hint>

        <mat-hint align="end">{{ attributeForm.get('attributeName')?.value?.length || 0 }}/500</mat-hint>

        <mat-error *ngIf="attributeForm.get('attributeName')?.hasError('required')">
          Attribute Name is required
        </mat-error>

        <mat-error *ngIf="attributeForm.get('attributeName')?.hasError('maxlength')">
          Attribute Name cannot exceed 500 characters
        </mat-error>
      </mat-form-field>

      <!-- Description Field -->
      <mat-form-field appearance="fill" class="full-width">
        <mat-label>Description</mat-label>
        <textarea
          matInput
          formControlName="description"
          placeholder="Enter attribute description"
          maxlength="1500"
          rows="5"
          style="resize: none;"
        ></textarea>

        <mat-hint align="start">
          Additional context or details to assist vendors or classification (e.g., 'Wind instruments made of brass').
        </mat-hint>

        <mat-hint align="end">{{ attributeForm.get('description')?.value?.length || 0 }}/1500</mat-hint>

        <mat-error *ngIf="attributeForm.get('description')?.hasError('maxlength')">
          Description cannot exceed 1500 characters
        </mat-error>
      </mat-form-field>

    </div>
  </mat-dialog-content>
  <hr />
  <mat-dialog-actions align="end">
    <button mat-button type="button" (click)="onCancel()">Cancel</button>
    <button mat-flat-button color="primary" type="submit" [disabled]="attributeForm.invalid">
      {{ editMode ? 'Update' : 'Create' }}
    </button>
  </mat-dialog-actions>
</form>


