import { Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { RoleGuard } from '../../core/guards/role.guard';
import { SettingsComponent } from './settings.component';
import { VendorManagementComponent } from './vendor-management/vendor-management.component';
import { BulkAddVendorsAttachFileComponent } from './vendor-management/bulk-add-vendors-attach-file/bulk-add-vendors-attach-file.component';
import { VendorDetailComponent } from './vendor-management/vendor-detail/vendor-detail.component';
import { BulkAddVendorUsersAttachFileComponent } from './vendor-management/vendor-detail/bulk-add-vendor-users-attach-file/bulk-add-vendor-users-attach-file.component';
import { VendorUserDetailComponent } from './vendor-management/vendor-detail/vendor-user-detail/vendor-user-detail.component';
import { SizeManagementComponent } from './size-management/size-management.component';
import { canDeactivateConfirmDiscardUnsavedChangesGuard } from '../../core/guards/can-deactivate-confirm-discard-unsaved-changes.guard';
import { DepartmentMgmtComponent } from './department-mgmt/department-mgmt.component';

export const settingsRoutes: Routes = [
  {
    path: 'settings',
    canActivate: [MsalGuard],
    data: { breadcrumb: 'Settings' },
    // canActivate: [MsalGuard,RoleGuard],
    // data: { requiredRoles: [UserRole.SuperAdmin, UserRole.CustomsSuperUser, UserRole.ClientAdmin] },
    children: [
      {
        path:'',
        canActivate: [MsalGuard],
        component: SettingsComponent,
    
      },
      {
        path:'vendor-management',
        canActivate: [MsalGuard],
        data: { breadcrumb: 'Vendor Management' },
        children: [
          {
            path:'',
            canActivate: [MsalGuard],
            component: VendorManagementComponent,
           
          },
          {
            path:'bulk-add-vendors-attach-file',
            canActivate: [MsalGuard],
            component: BulkAddVendorsAttachFileComponent,
            data: { breadcrumb: 'Add Vendors Via Excel' },
          },
          {
            path:'vendor-detail/:createOrEditVendorMode/:id',
            canActivate: [MsalGuard],
            data: { breadcrumb: 'Vendor Detail' },
            children: [
              {
                path:'',
                canActivate: [MsalGuard],
                component: VendorDetailComponent,
                canDeactivate: [canDeactivateConfirmDiscardUnsavedChangesGuard],
                
              },
              {
                path:'bulk-add-vendor-users-attach-file',
                canActivate: [MsalGuard],
                component: BulkAddVendorUsersAttachFileComponent,
                data: { breadcrumb: 'Add Vendor Users Via Excel' },
              },
              {
                path:'vendor-user-detail/:id',
                canActivate: [MsalGuard],
                component: VendorUserDetailComponent,
                canDeactivate: [canDeactivateConfirmDiscardUnsavedChangesGuard],
                data: { breadcrumb: 'Vendor User Detail' },
              }
            ]
          },
        ]
      },
      {
        path:'size-management',
        canActivate: [MsalGuard],
        component: SizeManagementComponent
      },
      {
        path:'department-management',
        canActivate: [MsalGuard],
        component: DepartmentMgmtComponent
      }
    ]
  },
];
