import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbService {

  breadcrumbs: Array<{ label: string, url: string }> = [];

  constructor(private router: Router, private activatedRoute: ActivatedRoute) {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      this.breadcrumbs = this.createBreadcrumbs(this.activatedRoute.root);
    });
  }

  private createBreadcrumbs(route: ActivatedRoute, url: string = '', breadcrumbs: Array<{ label: string, url: string }> = []): Array<{ label: string, url: string }> {
    const children: ActivatedRoute[] = route.children;

    if (children.length === 0) {
      return breadcrumbs;
    }

    for (const child of children) {
      
        const routeURL: string = child.snapshot.url.map((segment: { path: any; }) => segment.path).join('/');
        if (routeURL !== '') {
          url += `/${routeURL}`;
        }

        if(!breadcrumbs.find(b => child.snapshot.data['breadcrumb'] && b.label == child.snapshot.data['breadcrumb'])) { // only add breadcrumb for route if doesn't already exist
          breadcrumbs.push({ label: child.snapshot.data['breadcrumb'], url: url });
        }
        return this.createBreadcrumbs(child, url, breadcrumbs);
    }

    return breadcrumbs;
  }
}
