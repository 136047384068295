import { CommonModule, NgIf } from '@angular/common';
import { ChangeDetectorRef, Component, inject } from '@angular/core';
import { SizeListComponent } from './size-list/size-list.component';
import { SizeCategoryListComponent } from './size-category-list/size-category-list.component';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { FormsModule } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { DefaultSizeStore } from './store/sizecategorystore';
import { IDefaultSize } from '../../../core/models/IDefaultSize';
import { DefaultSizeDialogComponent } from './default-size-dialog/default-size-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-size-mgmt',
  standalone: true,
  imports: [CommonModule, NgIf, SizeListComponent, SizeCategoryListComponent, TabsModule, FormsModule],
  templateUrl: './size-mgmt.component.html',
  styleUrl: './size-mgmt.component.scss'
})
export class SizeMgmtComponent {
  readonly SIZES = 'sizes';
  readonly SIZECATEGORIES = 'sizecategories';
  // readonly GENDERMAPPING = 'gendermapping';

  selectedItem: 'sizes' | 'sizecategories' = this.SIZES;
  searchTerm: string = '';

  cdr = inject(ChangeDetectorRef);
  store = inject(DefaultSizeStore);
  toastrService = inject(ToastrService);
  dialog = inject(MatDialog);

  private searchSubject = new Subject<string>();

  ngOnInit() {
    this.store.clearCache();
    this.store.loadData();
    this.store.setSearchTerm(this.searchTerm);
    this.cdr.detectChanges();
  }

  openAddEditDefaultSizeDialog(sizeData?: IDefaultSize) {
    const dialogRef = this.dialog.open(DefaultSizeDialogComponent, {
      width: '600px',
      data: {
        sizeCategories: this.store.filteredCategories()
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (sizeData) {
          // Update logic
          this.store.updateSize({ ...sizeData, ...result });
        } else {
          // Create logic
          this.store.createSize({
            itemTypeGroupId: this.store.selectedItemTypeGroupId(),
            regionId: this.store.selectedRegionId(),
            ...result
          });
        }
      }
    });
  }

  onSearchTermChange(term: string) {
    this.searchTerm = term;
    this.searchSubject.next(term);
  }

  resetFilters() {
    this.searchTerm = '';
    this.store.setSearchTerm('');
    this.toastrService.info('Filters have been reset', 'Reset');
  }

  forceRefresh() {
    this.store.clearCache();
    this.store.loadData();
    this.toastrService.info('Data has been refreshed', 'Refreshed');
  }

  showTable(item: 'sizes' | 'sizecategories') {
    this.store.showTable(item); // Pass the selected view to the store
    this.selectedItem = item;
  }
}
