import { Component } from '@angular/core';
import { IOrder } from '../../../core/models/IOrder';
import { Subscription } from 'rxjs';
import { UserFriendlyHttpErrorMessageDialogComponent } from '../../settings/vendor-management/user-friendly-http-error-message-dialog/user-friendly-http-error-message-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { OrderService } from '../../../core/services/order.service';
import { MatCardModule, MatCardTitle } from '@angular/material/card';
import { TopPanelComponent } from "./top-panel/top-panel.component";
import { IOrderTopPanelTabItems } from './top-panel/IOrderTopPanelTabItems';
import { CommonModule } from '@angular/common';
import { AggridOrdersComponent } from "../aggrid-orders/aggrid-orders.component";
import { BreadcrumbComponent } from "../../../core/components/breadcrumb/breadcrumb.component";
import { IOrderV2 } from '../../../core/models/IOrderV2';
import { IVendor } from '../../../core/models/IVendor';

@Component({
  selector: 'app-order-details',
  standalone: true,
  imports: [
    CommonModule,
    MatCardModule,
    MatCardTitle,
    TopPanelComponent,
    AggridOrdersComponent,
    BreadcrumbComponent
],
  templateUrl: './order-details.component.html',
  styleUrl: './order-details.component.scss'
})
export class OrderDetailsComponent {
  readonly ORDERDETAILROUTEPREFIX = "orders/order-details";
  readonly ORDERLINEDETAILROUTE = "order-line-details";
  order:IOrderV2 | undefined;
  orderId:number|undefined;
  orderSubscription:Subscription|undefined;
  showOrderDetailLoadingSpinner:boolean = true;
  topPanelTitle:string|undefined;
  tabItems: IOrderTopPanelTabItems[] = [
    {
      tabLabel: "Order Details",
      objectPropertyIndexStart: 0,
      objectPropertyIndexEnd: 7, // Adjusted to include order fields only
      hideIds: true
    },
    {
      tabLabel: "Vendor Information",
      objectPropertyIndexStart: 7,
      objectPropertyIndexEnd: 18, // Now using flattened vendor fields
      hideIds: true
    }
];


  orderLineDetailGridSelectLinkPath = "";
  orderLineGridFieldsToInitiallyShowDetailRow = [
    "orderQuantity",
    "shipDate",
    "uniqueProductCode",
    "uniqueProductCodeDescription",
    "productStatusDescription",
    "tariffCode",
  ];

  orderLineGridFieldsToExcludeFromGridDetailRow = [
    "isDeleted",
    "createdAt",
    "updatedAt",
    "createdBy",
    "updatedBy",
    "productAudits",
    "productItemSizes",
    "productItemFabrics"
  ]


  constructor(private dialog:MatDialog,private route: ActivatedRoute,private orderService:OrderService) {
    window.addEventListener("refreshOrderDetails", () => {
      if (this.orderId) {
        this.parseIdQueryStringParam();
      }
    });
  }

  ngOnInit() {
    this.showOrderDetailLoadingSpinner = true;
    this.parseIdQueryStringParam();

  }

  parseIdQueryStringParam() {
    const orderIdStrQueryParam:string | null = this.route.snapshot.paramMap.get('id');

    if(orderIdStrQueryParam) {
      this.orderId = +orderIdStrQueryParam
      this.orderSubscription = this.orderService.getOrderById(this.orderId).subscribe({
        next: (orderResponse: any) => {
          this.order = orderResponse;
          this.topPanelTitle = "Order " + this.order?.orderNumber;
          this.orderLineDetailGridSelectLinkPath = this.ORDERDETAILROUTEPREFIX + "/" + this.order?.id + "/" + this.ORDERLINEDETAILROUTE
        },
        error: (error: Error) => {
          this.dialog.open(UserFriendlyHttpErrorMessageDialogComponent);
          this.showOrderDetailLoadingSpinner = false;
        },
        complete: () => {
          this.showOrderDetailLoadingSpinner = false;
        }
      });
    } else {
      this.showOrderDetailLoadingSpinner = false;
    }
  }

}
