<h2 mat-dialog-title>{{ editMode ? 'Edit Client' : 'Create Client' }}</h2>
<hr />
<form [formGroup]="clientForm" (ngSubmit)="onSave()">
  <mat-dialog-content>
    <mat-tab-group>
       <!-- Tab 1: Client Info -->
       <mat-tab label="Client Info">
          <div class="client-info-tab">
            <div class="form-group">
              <mat-form-field appearance="fill" class="full-width">
                <mat-label>Client Name</mat-label>
                <input matInput formControlName="clientName" placeholder="Enter Client Name" maxlength="1000"/>

                <mat-hint align="end">{{ clientForm.get('clientName')?.value?.length || 0 }}/1000</mat-hint>

                <mat-error *ngIf="clientForm.get('clientName')?.hasError('required')">
                Client name is required
                </mat-error>
              </mat-form-field>

              <div class="color-grid">
                <div class="color-item">
                  <mat-form-field appearance="fill" class="small-width">
                    <mat-label>Primary Background Colour</mat-label>
                    <input matInput type="color" formControlName="primaryBackgroundColor" />
                  </mat-form-field>
                  <mat-form-field appearance="fill" class="small-width">
                    <mat-label>HEX</mat-label>
                    <input matInput formControlName="primaryBackgroundHex" placeholder="#FFFFFF" />
                  </mat-form-field>
                </div>

                <div class="color-item">
                  <mat-form-field appearance="fill" class="small-width">
                    <mat-label>Secondary Background Colour</mat-label>
                    <input matInput type="color" formControlName="secondaryBackgroundColor" />
                  </mat-form-field>
                  <mat-form-field appearance="fill" class="small-width">
                    <mat-label>HEX</mat-label>
                    <input matInput formControlName="secondaryBackgroundHex" placeholder="#000000" />
                  </mat-form-field>
                </div>

                <div class="color-item">
                  <mat-form-field appearance="fill" class="small-width">
                    <mat-label>Primary Background Text</mat-label>
                    <input matInput type="color" formControlName="primaryBackgroundTextColor" />
                  </mat-form-field>
                  <mat-form-field appearance="fill" class="small-width">
                    <mat-label>HEX</mat-label>
                    <input matInput formControlName="primaryBackgroundTextHex" placeholder="#FFFFFF" />
                  </mat-form-field>
                </div>

                <div class="color-item">
                  <mat-form-field appearance="fill" class="small-width">
                    <mat-label>Secondary Background Text</mat-label>
                    <input matInput type="color" formControlName="secondaryBackgroundTextColor" />
                  </mat-form-field>
                  <mat-form-field appearance="fill" class="small-width">
                    <mat-label>HEX</mat-label>
                    <input matInput formControlName="secondaryBackgroundTextHex" placeholder="#000000" />
                  </mat-form-field>
                </div>

                <div class="color-item">
                  <mat-form-field appearance="fill" class="small-width">
                    <mat-label>Hover Colour</mat-label>
                    <input matInput type="color" formControlName="hoverColor" />
                  </mat-form-field>
                  <mat-form-field appearance="fill" class="small-width">
                    <mat-label>HEX</mat-label>
                    <input matInput formControlName="hoverHex" placeholder="#c5c9ce" />
                  </mat-form-field>
                </div>

                <div class="color-item">
                  <mat-form-field appearance="fill" class="small-width">
                    <mat-label>Icon Colour</mat-label>
                    <input matInput type="color" formControlName="iconColor" />
                  </mat-form-field>
                  <mat-form-field appearance="fill" class="small-width">
                    <mat-label>HEX</mat-label>
                    <input matInput formControlName="iconHex" placeholder="#FFFFFF" />
                  </mat-form-field>
                </div>
              </div>

              <div class="file-upload">
                <label for="logoUpload">Company Logo</label><br />
                <input type="file" id="logoUpload" (change)="onLogoChange($event)" accept=".png, .jpg, .jpeg"/>
              </div>

            </div>
          </div>
       </mat-tab>

       <!-- Tab 2: Item Type Groups -->
       <mat-tab label="Item Type Groups" [disabled]="data.client.id === 0">
        <div class="itemtype-groups-tab">
          <div class="table-responsive">
            <table class="table table-striped table-hover table-bordered">
              <thead class="thead-light">
                <tr>
                  <th>Description</th>
                  <th style="text-align: center;">Active</th>
                </tr>
              </thead>
              <tbody formArrayName="itemTypeGroups">
                <tr *ngFor="let group of itemTypeGroups; let i = index">
                  <td>{{ group.description }}</td>
                  <td style="text-align: center;">
                    <ui-switch
                      [formControlName]="i"
                      [checked]="itemTypeGroupsArray.at(i).value"
                    >
                    </ui-switch>
                    <small *ngIf="!group.isGloballyActive">(Globally Inactive)</small>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </mat-tab>

      <!-- Tab 3: Active Regions -->
      <mat-tab label="Regions" [disabled]="data.client.id === 0">
        <div class="regions-tab">
          <div class="table-responsive">
            <table class="table table-striped table-hover table-bordered">
              <thead class="thead-light">
                <tr>
                  <th>Description</th>
                  <th style="text-align: center;">Active</th>
                </tr>
              </thead>
              <tbody formArrayName="regions">
                <tr *ngFor="let region of regions; let i = index">
                  <td>{{ region.description }}</td>
                  <td style="text-align: center;">
                    <div class="d-flex align-items-center justify-content-center">
                      <ui-switch
                        [formControlName]="i"
                        [checked]="regionsArray.at(i).value"
                      >
                      </ui-switch>
                      <small *ngIf="!region.isGloballyActive" class="ml-2">
                        (Globally Inactive)
                      </small>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </mat-tab>

    </mat-tab-group>
  </mat-dialog-content>

  <hr />
  <mat-dialog-actions align="end">
    <button mat-button type="button" (click)="onCancel()">Cancel</button>
    <button mat-flat-button color="primary" type="submit" [disabled]="clientForm.invalid">
      {{ editMode ? 'Update' : 'Create' }}
    </button>
  </mat-dialog-actions>
</form>


