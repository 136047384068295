import { ApplicationConfig, importProvidersFrom, provideZoneChangeDetection, APP_INITIALIZER } from '@angular/core';
import { PreloadAllModules, provideRouter, withPreloading } from '@angular/router';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import {
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalBroadcastService,
  MsalGuard,
  MsalGuardConfiguration,
  MsalInterceptor,
  MsalInterceptorConfiguration,
  MsalService,
  ProtectedResourceScopes,
} from '@azure/msal-angular';
import { IPublicClientApplication, InteractionType, PublicClientApplication } from '@azure/msal-browser';

import { routes } from './app.routes';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { loginRequest, msalConfig, protectedResources } from './auth.config';
import { provideToastr } from 'ngx-toastr';
import { setupAgGrid } from './common/ag-grid/ag-grid-setup';

const httpLoaderFactory: (http: HttpClient) => TranslateHttpLoader = (http: HttpClient) =>
  new TranslateHttpLoader(http, './assets/i18n/', '.json');

// MSAL instance factory
export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication(msalConfig);
}

// Guard configuration
export function MsalGuardConfigurationFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect, // Or InteractionType.Popup
    authRequest: loginRequest,
    loginFailedRoute: '/login-failed',
  };
}

// MSAL interceptor configuration factory
export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string | ProtectedResourceScopes> | null>();

  protectedResourceMap.set(protectedResources.tarifftel.endpoint, [
    {
      httpMethod: 'GET',
      scopes: [...protectedResources.tarifftel.scopes.read],
    },
    {
      httpMethod: 'POST',
      scopes: [...protectedResources.tarifftel.scopes.write],
    },
    {
      httpMethod: 'PUT',
      scopes: [...protectedResources.tarifftel.scopes.write],
    },
    {
      httpMethod: 'DELETE',
      scopes: [...protectedResources.tarifftel.scopes.write],
    },
  ]);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}

// App initializer to ensure MSAL is initialized
export function initializeMsal(msalService: MsalService) {
  return () => msalService.instance.initialize();
}

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(
      routes,
      withPreloading(PreloadAllModules),  // comment this line to enable lazy-loading
    ),
    provideAnimationsAsync(),
    provideToastr({
      positionClass: 'toast-bottom-right',  // Set global toastr position here
      timeOut: 3000,                        // Optional: set default timeout for toast
      closeButton: true,                    // Optional: show close button
      progressBar: true                     // Optional: show progress bar
    }),
    // provideHttpClient(),// don't need to provide this twice?
    importProvidersFrom([TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpLoaderFactory,
        deps: [HttpClient],
      },
    })]),
    MsalGuard,
    MsalBroadcastService,
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MsalGuardConfigurationFactory,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    MsalService,
    provideHttpClient(withInterceptorsFromDi()),
    // Add APP_INITIALIZER to ensure MSAL is initialized before the app starts
    {
      provide: APP_INITIALIZER,
      useFactory: initializeMsal,
      deps: [MsalService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: setupAgGrid,
      multi: true,
    },
  ],
};
