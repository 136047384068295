<app-breadcrumb></app-breadcrumb>
<div class="row mx-1 my-2">
    <app-top-panel
    [title]="topPanelTitle"
    [tabItems]="tabItems"
    [objectToDisplay]="this.orderLine"
    [showLoadingSpinner]="showOrderLineDetailLoadingSpinner"
    [orderId]="orderId"
    [orderLineId]="orderLine?.id"
    ></app-top-panel>
</div>
<!-- <ng-container *ngIf="this.orderLine">
    <div *ngFor="let cardItem of orderLineCardItems" class="row mx-1 my-2">
        <div class="col-12">
            <mat-card>
                <mat-card-header>
                    <mat-card-title class="py-2">
                        <h5>{{cardItem.cardTitle}}</h5>
                    </mat-card-title>
                </mat-card-header>
                <mat-card-content >
                    <app-aggrid-orders
                    [EXCELEXPORTFILENAME]="'Tarifftel Product Audit'"
                    [EXCELEXPORTWORKSHEETNAME]="'Tarifftel Product Audit Worksheet'"
                    [rowsObjects]="cardItem.aggridObjectListToShow"
                    [includeSelectLink]="false"
                    [includeClassifyButton]="false"
                    [excludeIdFieldsFromGridDetailRow]="true"
                    [showGridLoading]="this.showOrderLineDetailLoadingSpinner"
                    >
                    </app-aggrid-orders>
                </mat-card-content>
            </mat-card>
        </div>

    </div>
</ng-container> -->
