import { Component, inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-error',
  standalone: true,
  imports: [],
  templateUrl: './error.component.html',
  styleUrl: './error.component.scss'
})
export class ErrorComponent implements OnInit {
  errorMessage = 'An error occurred';

  route = inject(ActivatedRoute);

  ngOnInit(): void {
    const msg = this.route.snapshot.queryParams['msg'];
    if (msg) {
      this.errorMessage = msg;
    }
  }
}
