<lib-secondary-navbar [title]="'Tenant Onboarding'">]    
</lib-secondary-navbar>

<div class="containerdiv">
    <div class="bg-body border p-3 rounded">
        <h2 class="mb-3">Tenant Onboarding</h2>

        <h4>Instructions</h4>

        <p>
            This page provides a link to start the onboarding process.
            Once the tenant administrator signs-in and consents to the app's requested permissions,
            this application's service principle is provisioned into this new client's tenant.
        </p>
        <p>
            Once the tenant administrator has consented,
            we save the tenant Id in the database and the application is ready to accept users from this tenant.
        </p>
        <p>
            Click on the button below to initiate the admin consent process register your tenant.
            You must use an admin account on this step.
        </p>
        <h4>
            Pre-requisites
        </h4>
        <ul>
            <li>An Microsoft Entra ID tenant (the tenant you want to on-board)</li>
            <li>
                A user account in your Microsoft Entra ID tenant.
                <ul>
                    <li>
                        Does <strong>not</strong> work with personal accounts.
                    </li>
                    <li>
                        Instead, create a user account for the tenant you want to on-board,
                        assign the Directory Writers role to it (or Application Administrator?),
                        then sign in with that during the onboarding process.
                    </li>
                </ul>
            </li>
        </ul>

        <form (ngSubmit)="onSubmit()" role="form">
            <button mat-raised-button color="primary" type="submit">On-board my tenant</button>
        </form>
    </div>
</div>