import { CommonModule } from '@angular/common';
import { Component, inject, signal } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { HarmonizedCodesByHeadingComponent } from './harmonized-codes-by-heading/harmonized-codes-by-heading.component';
import { ItemTypesTableComponent } from './item-types-table/item-types-table.component';
import { TarifftelCodesByHeadingComponent } from './tarifftel-codes-by-heading/tarifftel-codes-by-heading.component';
import { TariffBookStore } from './store/tariffbookstore';
import { ISection } from '../../../core/models/ISection';
import { IChapter } from '../../../core/models/IChapter';
import { IHeading } from '../../../core/models/IHeading';
import { ClassificationDialogComponent } from './classification-dialog/classification-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-item-type-mgmt',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    HarmonizedCodesByHeadingComponent,
    AccordionModule,
    ItemTypesTableComponent,
    TarifftelCodesByHeadingComponent
  ],
  templateUrl: './item-type-mgmt.component.html',
  styleUrls: ['./item-type-mgmt.component.scss']
})
export class ItemTypeMgmtComponent {

  // === Store ===
  store = inject(TariffBookStore);
  dialog = inject(MatDialog);

  // === Signals from store ===
  sections = this.store.sections;           // signal
  chapters = this.store.chapters;           // signal
  headings = this.store.headings;           // signal

  selectedSectionId = this.store.selectedSectionId;
  selectedChapterId = this.store.selectedChapterId;
  selectedHeadingId = this.store.selectedHeadingId;

  selectedList = this.store.selectedList;

  // If you need them in HTML, you can keep these signals locally:
  selectedCode = signal(''); // or rely on store’s selectedCode
  codeType = signal<'6-digit' | '8-digit' | '10-digit' | 'unknown'>('unknown');

  // === UI booleans ===
  sectionDropdownOpen = false;
  chapterDropdownOpen = false;
  headingDropdownOpen = false;

  constructor() {
    // All data loading is done in store’s onInit() hook or loadData() method
  }

  // For switching store list (worldwide / tarifftel)
  selectList(list: 'worldwide' | 'tarifftel') {
    this.store.selectList(
      list === 'worldwide' ? 'worldwidebook' : 'tarifftelbook'
    );
  }

  // If child component emits the selected code, push it to the store:
  onCodeSelected(code: string) {
    console.log('onCodeSelected code:', code);
    this.store.setSelectedCode(code);
    // If you prefer local signals, you can mirror them:
    this.selectedCode.set(code);
    // (But if you do so, you'll also need to detect code type.)
    // Or rely on the store’s selectedCodeType if you move detection there.
    const detectedType = this.detectCodeType(code);
    this.codeType.set(detectedType);
  }

  private detectCodeType(code: string) {
    if (code.length === 6) return '6-digit';
    if (code.length === 8) return '8-digit';
    if (code.length === 10) return '10-digit';
    return 'unknown';
  }

  // Toggle dropdowns
  toggleDropdown(type: 'section' | 'chapter' | 'heading') {
    if (type === 'section') {
      this.sectionDropdownOpen = !this.sectionDropdownOpen;
    } else if (type === 'chapter') {
      this.chapterDropdownOpen = !this.chapterDropdownOpen;
    } else {
      this.headingDropdownOpen = !this.headingDropdownOpen;
    }
  }

  // Delegates to the store
  selectSection(section: ISection) {
    if (section) {
      this.store.selectSection(section.id);
      this.sectionDropdownOpen = false;
    }
  }
  selectChapter(chapter: IChapter) {
    if (chapter) {
      this.store.selectChapter(chapter.id);
      this.chapterDropdownOpen = false;
    }
  }
  selectHeading(heading: IHeading) {
    if (heading) {
      this.store.selectHeading(heading.id);
      this.headingDropdownOpen = false;
    }
  }

  // === For showing currently selected in the dropdown buttons ===
  get selectedSectionTitle(): string {
    const sId = this.selectedSectionId();
    const sec = this.sections().find(s => s.id === sId);
    return sec ? sec.numeral : 'Select Section';
  }

  get selectedChapterDescription(): string {
    const cId = this.selectedChapterId();
    const ch = this.chapters().find(c => c.id === cId);
    return ch ? `Chapter ${ch.chapterNumber}` : 'Select Chapter';
  }

  get selectedHeadingDescription(): string {
    const hId = this.selectedHeadingId();
    const hd = this.headings().find(h => h.id === hId);
    return hd ? hd.headingCode : 'Select Heading';
  }

  openDialog(editMode: boolean, order?: any) {
    const dialogRef = this.dialog.open(ClassificationDialogComponent, {
      width: '1200px',
      disableClose: true,
      data: {
        editMode,
        descriptionOfGoods: order?.descriptionOfGoods ?? '',
        region: order?.regionId ?? null,
        countryOfOrigin: order?.countryOfOriginId ?? null
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // handle the classification result
      }
    });
  }

  classify(): void {
    // Suppose you have an `order` object with relevant fields
    const order = {
      descriptionOfGoods: 'A load of Yoghurt',
      // regionId: 2,
      // countryOfOriginId: 5
    };

    this.openDialog(false, order);
  }
}
