import { Injectable, signal } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ReportService {
  baseUrl = environment.apiUrl;
  constructor(private httpClient:HttpClient) { }

  downloadReport(reportEndpointPath:string,fromDate: Date, toDate: Date) {
    const formattedFromDate = fromDate.toISOString().split('T')[0];
    const formattedToDate = toDate.toISOString().split('T')[0];

    return this.httpClient.get(this.baseUrl + reportEndpointPath,{ 
      params:{
        fromDate:formattedFromDate,
        toDate:formattedToDate,
      },
      responseType: 'blob' 
    });
  }
}
