import { Injectable } from '@angular/core';
import { Observable, Subject, tap } from 'rxjs';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { IRegion } from '../models/IRegion';

@Injectable({
  providedIn: 'root'
})
export class RegionService {

  clientUrl = environment.apiUrl;

  constructor(private http: HttpClient) { }

  getRegions(): Observable<IRegion[]> {
    return this.http.get<IRegion[]>(this.clientUrl + "/api/region");
  }

  getActiveRegions(): Observable<IRegion[]> {
    return this.http.get<IRegion[]>(`${this.clientUrl}/api/region/active`);
  }

  toggleRegionStatus(id: number, isActive: boolean): Observable<any> {
    return this.http.put(`${this.clientUrl}/api/region/${id}/active`, isActive, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }
}
