<mat-card>
    <mat-card-header>
        <mat-card-title>
            <h4 *ngIf="!this.showLoadingSpinner() && title()">{{title()}}</h4>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <div *ngIf="this.showLoadingSpinner()" class="mat-spinner-container">
            <mat-spinner></mat-spinner>
        </div>
        <div *ngIf="!this.showLoadingSpinner()" class="ms-1 mt-2">
            <ul class="nav nav-pills" id="pills-tab" role="tablist">
                <li *ngFor="let tabItem of tabItems();index as i" class="nav-item" role="presentation">
                    <button class="nav-link active" [ngClass]="{ 'active':selectedTabIndex==i}" [id]="'pills-' + i +'-order-tab'" data-bs-toggle="pill" [attr.data-bs-target]="'#pills-' + i + '-order-tab-content'" type="button" role="tab" [attr.data-index]="i" (click)="changeActiveTab($event)">
                        {{ tabItem.tabLabel }}
                    </button>
                </li>
                <li class="nav-item" role="presentation">
                    <button class="nav-link active" [class.active]="selectedTabIndex==tabItems()?.length" [id]="'pills-' + tabItems()?.length +'-order-tab'" data-bs-toggle="pill" [attr.data-bs-target]="'#pills-' + tabItems()?.length + '-order-tab-content'" type="button" role="tab" [attr.data-index]="tabItems()?.length" (click)="changeActiveTab($event)">
                        Inbox
                    </button>
                </li>
            </ul>
            <div class="tab-content my-2" id="pills-tabContent">
                <div *ngFor="let tabItem of tabItems();index as i" class="tab-pane fade"  [ngClass]="{ 'show active':selectedTabIndex==i}" [id]="'pills-' + i +'-order-tab-content'" role="tabpanel" [attr.aria-labelledby]="'pills-' + i +'-order-tab'">
                    <app-tab-detail-list-group
                    [objectToDisplay]="tabItem.isNested && tabItem.nestedObjectKey ? objectToDisplay()?.[tabItem.nestedObjectKey] : objectToDisplay()"
                    [objectPropertyIndexStart]="tabItem.objectPropertyIndexStart"
                    [objectPropertyIndexEnd]="tabItem.objectPropertyIndexEnd"
                    [hideIds]="tabItem.hideIds"
                    >
                    </app-tab-detail-list-group>
                </div>
                <div class="tab-pane fade border p-1 rounded" [ngClass]="{ 'show active':selectedTabIndex==tabItems()?.length}" [id]="'pills-' + tabItems()?.length +'-order-tab-content'" role="tabpanel" [attr.aria-labelledby]="'pills-' + tabItems()?.length +'-order-tab'">
                    <button mat-raised-button role="link" [routerLink]="['ask']" [queryParams]="{scope: 'customer'}" class="m-3">
                        <mat-icon>
                            factory
                        </mat-icon>
                        Ask Supplier about {{ title() }}
                    </button>
                    <button mat-raised-button role="link" [routerLink]="['ask']" [queryParams]="{scope: 'master'}" class="m-3">
                        <img width="26" height="26" src="/assets/tarifftel-small.png">
                        Ask Customs Team about {{ title() }}
                    </button>

                    <mat-divider></mat-divider>
                    
                    <div class="bg-body-tertiary">
                        <app-inbox-directory
                            [orderIdFilter]="orderId()"
                            [orderLineIdFilter]="orderLineId()">                        
                        </app-inbox-directory>
                    </div>
                </div>
            </div>
        </div>
    </mat-card-content>
</mat-card>
