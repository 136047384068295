import { Component, EventEmitter, inject, Output } from '@angular/core';
import { AppSettingsService, SecondaryNavbarComponent } from '@tarifftel/components-library';
import { IReportsCard } from './IReportsCard';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MatCardModule } from '@angular/material/card';
import { DateRangeDialogComponent } from './date-range-dialog/date-range-dialog.component';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-reports',
  standalone: true,
  imports: [
    CommonModule,
    SecondaryNavbarComponent,
    SecondaryNavbarComponent,
    TranslateModule,
    MatCardModule],
  templateUrl: './reports.component.html',
  styleUrl: './reports.component.scss'
})
export class ReportsComponent {


  constructor(private matDialog:MatDialog,
  ) {
  }

  reportOptions:{
    title:string,
    imagePath:string,
    endpointPath:string
  }[] = [
    {
      title:"Food Branded Classification",
      imagePath:"assets/mands.png",
      endpointPath:"/api/Report/exportFBCReport"
    },
    {
      title:"US Tariff Code Changes",
      imagePath:"assets/primark.png",
      endpointPath:""
    }
  ];

  onReportCardClickEvent(selectedReportCardTitle:string,selectedReportCardImagePath:string,selectedReportCardEndpointPath:string) {
    const dialogRef = this.matDialog.open(DateRangeDialogComponent,{
      data: {
        reportOptionTitle:selectedReportCardTitle,
        reportOptionImagePath:selectedReportCardImagePath,
        reportOptionEndpointPath:selectedReportCardEndpointPath
      }
    });

    dialogRef.afterClosed().subscribe((result) => {
    });
  }
}
