import { Component } from '@angular/core';

@Component({
  selector: 'app-order-submission',
  standalone: true,
  imports: [],
  templateUrl: './order-submission.component.html',
  styleUrl: './order-submission.component.scss'
})
export class OrderSubmissionComponent {

}
