import { Component, inject } from '@angular/core';
import { BreadcrumbComponent } from '../../../core/components/breadcrumb/breadcrumb.component';
import { ActivatedRoute } from '@angular/router';
import { NgIf } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatError, MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatIcon } from '@angular/material/icon';
import { UserFriendlyHttpErrorMessageDialogComponent } from '../../settings/vendor-management/user-friendly-http-error-message-dialog/user-friendly-http-error-message-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TextFieldModule } from '@angular/cdk/text-field';
import { MatOption, MatSelect } from '@angular/material/select';
import { QuestionService } from '../../../core/services/question.service';
import { ReactiveFormsModule, Validators, FormBuilder, FormControl } from '@angular/forms';
import { IKeyValuePair } from '../../../core/models/IKeyValuePair';
import { Observable } from 'rxjs';
import { IQuestionForm } from '../../../core/models/IQuestionForm';

@Component({
  selector: 'app-ask-question',
  standalone: true,
  imports: [
    BreadcrumbComponent,
    ReactiveFormsModule,
    NgIf,
    MatButtonModule,
    MatButtonToggleModule,
    MatError,
    MatSelect,
    MatOption,
    MatFormField,
    MatInput,
    MatIcon,
    MatLabel,
    MatProgressSpinnerModule,
    TextFieldModule,
  ],
  templateUrl: './ask-question.component.html',
  styleUrl: './ask-question.component.scss'
})
export class AskQuestionComponent {
  route = inject(ActivatedRoute);
  dialog = inject(MatDialog);
  questionService = inject(QuestionService);
  formBuilder = inject(FormBuilder);

  orderId: number | undefined;
  orderLineId: number | null = null;
  orderNumber = '';
  orderLineCode: string | null = null;
  categories: IKeyValuePair[] = [];
  loading = true;
  questionForm = this.formBuilder.nonNullable.group({
    scope: [ '', Validators.required ],
    subject: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(50)]],
    category: [null, Validators.required],
    body: new FormControl('', Validators.maxLength(1500))
  });

  ngOnInit() {
    const orderIdFragment = this.route.snapshot.paramMap.get('id');
    const orderLineIdFragment = this.route.snapshot.paramMap.get('lineId');
    const scope = this.route.snapshot.queryParams['scope'];

    if (scope) {
      this.questionForm.patchValue({ scope });
    }

    if (!orderIdFragment) {
      this.dialog.open(UserFriendlyHttpErrorMessageDialogComponent);
      return;
    }

    this.orderId = parseInt(orderIdFragment);

    var $getForm : Observable<IQuestionForm>;

    if (orderLineIdFragment) {
      this.orderLineId = parseInt(orderLineIdFragment);
      $getForm = this.questionService.getLineQuestionForm(this.orderId, this.orderLineId);
    }
    else {
      $getForm = this.questionService.getHeaderQuestionForm(this.orderId);
    }
    
    $getForm.subscribe({
      next: iForm => {
        this.orderNumber = iForm.orderNumber;
        this.orderLineCode = iForm.orderLineCode;
        this.categories = iForm.categories;
      },
      error: () => this.dialog.open(UserFriendlyHttpErrorMessageDialogComponent),
      complete: () => this.loading = false
    });
  }

  onSubmit() {
    if (!this.orderId) return;

    const questionDto = {
      orderId: this.orderId,
      orderLineId: this.orderLineId,
      ...this.questionForm.value,
    };

    // this.questionService.ask(questionDto);
  }
}
