  <h4 mat-dialog-title class="dialog-title">
    <i class="fa-solid fa-info-circle"></i> Tariff Details
  </h4>
  <hr />
<mat-dialog-content>

  <mat-tab-group>
    <!-- General Info Tab -->
    <mat-tab label="General Info">
      <table class="table table-bordered mt-3">
        <tbody>
          <tr>
            <th style="width:30%">Tariff Code</th>
            <td>{{ data.tariffCode || 'N/A' }}</td>
          </tr>
          <tr>
            <th>Item Type</th>
            <td>{{ data.itemTypeDescription || 'N/A' }}</td>
          </tr>
          <tr>
            <th>Region</th>
            <td>{{ data.regionDescription || 'N/A' }}</td>
          </tr>
          <tr>
            <th>Country of Origin</th>
            <td>{{ data.countryOfOriginDescription || 'N/A' }}</td>
          </tr>
          <tr>
            <th>Description</th>
            <td>{{ data.tariffData?.description || 'N/A' }}</td>
          </tr>
          <tr>
            <th>Section</th>
            <td>{{ data.tariffData?.section || 'N/A' }}</td>
          </tr>
          <tr>
            <th>Chapter</th>
            <td>{{ data.tariffData?.chapter || 'N/A' }}</td>
          </tr>
        </tbody>
      </table>
    </mat-tab>

    <!-- Duty Rates Tab -->
    <mat-tab label="Duty Rates">
      <table class="table table-bordered mt-3">
        <tbody>
          <tr>
            <th style="width:30%">Basic Duty Rate</th>
            <td [innerHTML]="sanitizeHtml(data.tariffData?.basic_duty_rate || 'N/A')"></td>
          </tr>
          <tr>
            <th>Preferential Duty Rate</th>
            <td>{{ data.tariffData?.preferentialDutyRate || 'N/A' }}</td>
          </tr>
          <tr>
            <th>Additional Duty Rate</th>
            <td>{{ data.tariffData?.additionalDutyRate || 'N/A' }}</td>
          </tr>
          <tr>
            <th>VAT Rate</th>
            <td>{{ data.tariffData?.vatRate || 'N/A' }}</td>
          </tr>
          <tr>
            <th>Excise Duty</th>
            <td>{{ data.tariffData?.exciseDuty || 'N/A' }}</td>
          </tr>
          <tr>
            <th>Preferential Duty Rates by Country</th>
            <td>
              <ul *ngIf="(data.tariffData?.preferentialDutyRatesByCountry ?? []).length > 0; else noRates">
                <li *ngFor="let rate of data.tariffData?.preferentialDutyRatesByCountry ?? []">
                  {{ rate.country }}: {{ rate.rate }}
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <th>Specific Duty Rates by Country</th>
            <td>
              <ul *ngIf="(data.tariffData?.specificDutyRatesByCountry ?? []).length > 0; else noRates">
                <li *ngFor="let rate of data.tariffData?.specificDutyRatesByCountry ?? []">
                  {{ rate.country }}: {{ rate.rate }}
                </li>
              </ul>
            </td>
          </tr>
        </tbody>
      </table>
    </mat-tab>

    <!-- Conditions & Quotas Tab -->
    <mat-tab label="Conditions & Quotas">
      <table class="table table-bordered mt-3">
        <tbody>
          <tr>
            <th style="width:30%">Meursing Code Required</th>
            <td>
              <span *ngIf="data.tariffData?.requiresMeursingCode; else noMeursing">
                Yes
              </span>
              <ng-template #noMeursing>No</ng-template>
            </td>
          </tr>
          <tr>
            <th>Quotas</th>
            <td>{{ (data.tariffData?.quotaInfo ?? []).join(', ') || 'N/A' }}</td>
          </tr>
          <tr>
            <th>Anti-Dumping Duty</th>
            <td>{{ data.tariffData?.antiDumpingDuty || 'N/A' }}</td>
          </tr>
          <tr>
            <th>Countervailing Duty</th>
            <td>{{ data.tariffData?.countervailingDuty || 'N/A' }}</td>
          </tr>
          <tr>
            <th>Rules of Origin</th>
            <td>{{ (data.tariffData?.rulesOfOrigin ?? []).join(', ') || 'N/A' }}</td>
          </tr>
          <tr>
            <th>Applicable Trade Agreements</th>
            <td>
              <ul *ngIf="(data.tariffData?.applicableTradeAgreements ?? []).length > 0; else noAgreements">
                <li *ngFor="let agreement of data.tariffData?.applicableTradeAgreements ?? []">{{ agreement }}</li>
              </ul>
            </td>
          </tr>
        </tbody>
      </table>
    </mat-tab>

    <!-- Footnotes Tab -->
    <mat-tab label="Footnotes">
      <table class="table table-bordered mt-3">
        <tbody>
          <tr>
            <th style="width:30%">Footnotes</th>
            <td>
              <ul *ngIf="(data.tariffData?.footnotes ?? []).length > 0; else noFootnotes">
                <li *ngFor="let footnote of data.tariffData?.footnotes ?? []" [innerHTML]="sanitizeHtml(footnote)"></li>
              </ul>
            </td>
          </tr>
        </tbody>
      </table>
    </mat-tab>

  </mat-tab-group>

  <!-- Reusable Templates for N/A -->
  <ng-template #noRates>N/A</ng-template>
  <ng-template #noFootnotes>N/A</ng-template>
  <ng-template #noAgreements>N/A</ng-template>

</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button (click)="close()">Close</button>
</mat-dialog-actions>
