import { Component, effect, OnInit } from '@angular/core';
import { SecondaryNavbarComponent } from '@tarifftel/components-library';
import { AggridExportoptionsComponent } from '../../../core/components/aggrid-exportoptions/aggrid-exportoptions.component';
import { MatCardModule } from '@angular/material/card';
import { AggridOrdersComponent } from "../aggrid-orders/aggrid-orders.component";
import { Subscription } from 'rxjs';
import { OrderService } from '../../../core/services/order.service';
import { MatDialog } from '@angular/material/dialog';
import { UserFriendlyHttpErrorMessageDialogComponent } from '../../settings/vendor-management/user-friendly-http-error-message-dialog/user-friendly-http-error-message-dialog.component';
import { IOrder } from '../../../core/models/IOrder';
import { TranslateModule } from '@ngx-translate/core';
import { AggridOrdersServerSideRowModelComponent } from "../aggrid-orders-server-side-row-model/aggrid-orders-server-side-row-model.component";
@Component({
  selector: 'app-order-list',
  standalone: true,
  imports: [SecondaryNavbarComponent, MatCardModule, AggridOrdersServerSideRowModelComponent, TranslateModule],
  templateUrl: './order-list.component.html',
  styleUrl: './order-list.component.scss'
})
export class OrderListComponent {
  readonly ORDERDETAILROUTEPREFIX = "orders/order-details";
  fieldsToInitiallyShowInGridRow = [
    "orderNumber",
    "orderStatusDescription",
    "deliveryDate",
    "vendorCompanyName",
   ];

  fieldsToExcludeFromInGridRow = [
    "isDeleted",
    "createdAt",
    "updatedAt",
    "createdBy",
    "updatedBy",
    "orderLines",
    "orderAudits"
  ];
  showGridLoadingIndicator = true;

}
