<a (click)="select.emit()" [class.active]="item().selected" class="list-group-item list-group-item-action" aria-current="true" [class]="itemClasses">
    <div class="d-flex w-100 justify-content-between">
        <h6 class="mb-1 text-truncate">
            {{ item().subject }}
        </h6>
        <span *ngIf="item().unresolved" class="badge text-bg-danger">Unresolved</span>
    </div>
    <p class="mb-1 text-truncate">{{ item().preview }}</p>
    <div class="row">
        <div class="col-auto me-auto">
            <small>{{ item().sender }}</small>
        </div>
        <div class="col-auto">
            <small>{{ item().sentAt | dateAgo }}</small>
        </div>
    </div>
</a>