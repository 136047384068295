<div class="containingdiv">
  <lib-primary-navbar
    (sideNavToggled)="onSideNavToggled()"
    (notificationClick)="onNotificationClick()"
    (messagesClick)="onMessagesClick()"
    (logoutClick)="onLogoutClick()"
    [notificationMenu]="notification.menu"
  >
  </lib-primary-navbar>
  <app-notification #notification="menuInOtherComponent"></app-notification>
  <mat-drawer-container class="drawer-container" [hasBackdrop]="false">
    <mat-drawer #drawer mode="side" [opened]="appSettings.toggleSideNav()" style="width: 250px">
      <lib-side-bar-component
        [mainMenuLinks]="topSideMenuItems"
        (menuItemSelected)="onMenuItemSelected($event)"
        [routerLinkActive]="['active']"
        [routerLinkActiveOptions]="{ exact: false }"
      ></lib-side-bar-component>
    </mat-drawer>
    <mat-drawer-content class="drawer-content">
      <router-outlet></router-outlet>
    </mat-drawer-content>
  </mat-drawer-container>
  <lib-footer></lib-footer>
</div>
