<app-vendor-add-user-form
    [updateVendorUserMode]="false"
    [showMainFormCentralLoadingSpinner]="false"
    [editMode]="false"
    [vendorToEdit]="vendorToEdit"
    [vendorUserToEdit]="vendorUserToEdit"
    [showCancelButton]="true"
    [gridApi]="gridApi"
    (cancelButtonSelected)="closeModal()">
</app-vendor-add-user-form>
