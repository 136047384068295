import { getState, patchState, signalStore, withComputed, withHooks, withMethods, withState } from '@ngrx/signals';
import { computed, effect, inject, WritableSignal } from '@angular/core';
import { LocalStorageService } from '../../../../core/services/local-storage.service';
import { MatDialog } from '@angular/material/dialog';
import { UserFriendlyHttpErrorMessageDialogComponent } from '../../../settings/vendor-management/user-friendly-http-error-message-dialog/user-friendly-http-error-message-dialog.component';
import { IVendor } from '../../../../core/models/IVendor';
import { VendorManagementService } from '../../../../core/services/vendor-management.service';
import { Vendor } from '../../../../core/models/vendor.model';
import { Observable, Subscription, tap } from 'rxjs';
import { IVendorUser } from '../../../../core/models/IVendorUser';
import { VendorUser } from '../../../../core/models/vendor-user.model';

const vendorStoreKey = 'tarifftel_vendors';

const vendorStoreTimestampKey = 'tarifftel_vendors_timestamp';
const CACHE_DURATION = 60 * 60 * 1000; // 1 hour in milliseconds

type VendorState = {
  vendors: IVendor[];
}

const initialState: VendorState = {
  vendors: []
}

export const VendorStore = signalStore(
  { providedIn: 'root'},
  withState(initialState),
  withMethods((store) => {
    const vendorService = inject(VendorManagementService);
    const localStorageService = inject(LocalStorageService);
    const matDialogService = inject(MatDialog)

    return {
      loadVendors(loadFromRequestNotStateOverride:boolean) {
        const vendorsFromStorage = localStorageService.getItem(vendorStoreKey) || [];
        const timestamp = parseInt(localStorageService.getItem(vendorStoreTimestampKey) || '0', 10);
        const now = Date.now();
        if (!loadFromRequestNotStateOverride && vendorsFromStorage.length > 0 && (now - timestamp) < CACHE_DURATION) {
          return new Observable(observer => {
            patchState(store, { vendors: vendorsFromStorage });
            observer.next();
            observer.complete();
          });

        } else {
          // Fetch from backend
          return vendorService.getVendors().pipe(
            tap({
              next:(vendorsResponse:any) => {
                patchState(store, { vendors:vendorsResponse });
                localStorageService.setItem(vendorStoreTimestampKey, now.toString());
              }
            })
          );

        }
      },
      getVendorById(id:number) {
        const vendor = store.vendors().find(v => v.id === id);
        if (vendor) {
          return vendor;
        } else {
          console.error('Failed to find vendor');
          matDialogService.open(UserFriendlyHttpErrorMessageDialogComponent);
          return undefined;
        }

      },
      getVendorUserById(id:number) {
        const vendorUser = store.vendors()
          .flatMap((vendor: IVendor) => vendor.vendorUsers)
          .find((vendorUser) => vendorUser.id === id);

        if (vendorUser) {
          return vendorUser;
        } else {
          console.error('Failed to find vendor user');
          matDialogService.open(UserFriendlyHttpErrorMessageDialogComponent);
          return undefined;
        }

      },
      updateVendor(id:number,newVendorToApply:IVendor) {
        return vendorService.updateVendor(id,newVendorToApply).pipe(
          tap({
            next:(vendor:any) => {
              var vendorsFromStore:IVendor[] = store.vendors();
              vendorsFromStore = vendorsFromStore.map(v => v.id == vendor.id ?
                vendor:v
              );
              patchState(store, { vendors: vendorsFromStore });
            }
          })
        )
      },
      addVendor(newVendorToAdd: IVendor) {
        return vendorService.addVendor(newVendorToAdd).pipe(
          tap({
            next:(vendor:any) => {
              patchState(store, { vendors: [...store.vendors(),vendor] });
            }
          })
        )
      },
      addVendorUser(vendor: IVendor,vendorUserToAdd:IVendorUser) {
        return vendorService.addVendorUser(vendor,vendorUserToAdd).pipe(
          tap({
            next:(vendorUserResponse:any) => {
              var vendorsFromStore: IVendor[] = store.vendors();
              vendorsFromStore = vendorsFromStore.map(v => {
                if(v.id == vendor.id) {
                  v.vendorUsers.push(vendorUserResponse);
                }
                return v;
              }
              );
              patchState(store, { vendors: vendorsFromStore });
            }
          })
        )
      },
      updateVendorUser(vendor: IVendor,vendorUserIdToUpdate: number,vendorUserToUpdate:IVendorUser) {

        return vendorService.updateVendorUser(vendorUserIdToUpdate,vendorUserToUpdate).pipe(
          tap({
            next:(vendorUserResponse:any) => {
              var vendorsFromStore: IVendor[] = store.vendors();
              vendorsFromStore = vendorsFromStore.map(vendorFromStore => {
                if(vendorFromStore.id == vendor.id) {
                  vendorFromStore.vendorUsers = vendorFromStore.vendorUsers.map(vendorUserFromStore => vendorUserFromStore.id == vendorUserResponse.id ?
                    vendorUserResponse:vendorUserFromStore
                  );
                }
                return vendorFromStore;
              }
              );
              patchState(store, { vendors: vendorsFromStore });
            }
          })
        )
      },
      updateVendorStatus(vendorsToChangeStatus: Vendor[], activateVendor: boolean) {
        return vendorService.bulkUpdateVendorsStatus(vendorsToChangeStatus,activateVendor).pipe(
          tap({
            next:(vendorsUpdatedResponse:any) => {
              var vendorsFromStore:IVendor[] = store.vendors();

              vendorsFromStore = vendorsFromStore.map(v => {
                const vendorToChange = vendorsUpdatedResponse.find((vendorUpdatedResponse: IVendor) => vendorUpdatedResponse.id === v.id);
                if (vendorToChange) {
                  return vendorToChange;
                }
                return v;
              });
              patchState(store, { vendors: vendorsFromStore });
            }
          })
        );
      },
      bulkUpdateVendorUsersActive(vendor:IVendor,vendorUsersToChgStatus:VendorUser[],activateVendorUser:boolean) {
        return vendorService.bulkUpdateVendorUsersActive(vendorUsersToChgStatus,activateVendorUser).pipe(
          tap({
            next:(vendorUsersUpdatedResponse:any) => {
              var vendorsFromStore: IVendor[] = store.vendors();
              vendorsFromStore = vendorsFromStore.map(vendorFromStore => {
                if (vendorFromStore.id === vendor.id) {
                  vendorFromStore.vendorUsers = vendorFromStore.vendorUsers.map(vendorUserFromStore => {
                    const updatedUser = vendorUsersUpdatedResponse.find((vendorUserUpdatedResponse: IVendorUser) => vendorUserUpdatedResponse.id === vendorUserFromStore.id);
                    return updatedUser ? updatedUser : vendorUserFromStore;
                  });
                }
                return vendorFromStore;
              });
              patchState(store, { vendors: vendorsFromStore });
            }
          })
        );
      },
      deleteVendors(vendorsToDelete:Vendor[]) {
        return vendorService.deleteVendors(vendorsToDelete).pipe(
          tap({
            next:(deleteVendorResponse:any) => {
              var vendorsFromStore:IVendor[] = store.vendors();
              vendorsFromStore = vendorsFromStore.filter(v => !vendorsToDelete.includes(v))
              patchState(store, { vendors: vendorsFromStore });
            }
          })
        );
      },
      deleteVendorUsers(vendor:IVendor,vendorUsersToDelete:IVendorUser[]) {
        return vendorService.deleteVendorUsers(vendor,vendorUsersToDelete).pipe(
          tap({
            next:(deleteVendorResponse:any) => {
              var vendorsFromStore: IVendor[] = store.vendors();
              vendorsFromStore = vendorsFromStore.map(v => {
                if (v.id === vendor.id) {
                  v.vendorUsers = v.vendorUsers.filter(v => !vendorUsersToDelete.includes(v))
                }
                return v;
              }
              );
              patchState(store, { vendors: vendorsFromStore });
            }
          })
        );
      },
      clearCache() {
        localStorageService.removeItem(vendorStoreKey);
        localStorageService.removeItem(vendorStoreTimestampKey);
        patchState(store, { vendors: [] });
      }
    };
  }),
  withHooks({
    onInit(store) {
      const localStorageService = inject(LocalStorageService);
      effect(() => {
        const state = getState(store);
        const now = Date.now();
        localStorageService.setItem(
          vendorStoreKey,
          state.vendors
        );
        localStorageService.setItem(
          vendorStoreTimestampKey,
          now.toString()
        );
      });
    },
  })
);
