import {
  patchState,
  signalStore,
  withComputed,
  withHooks,
  withMethods,
  withState
} from '@ngrx/signals';
import { IChapter } from '../../../../core/models/IChapter';
import { IHeading } from '../../../../core/models/IHeading';
import { ISection } from '../../../../core/models/ISection';
import { computed, inject } from '@angular/core';
import { ItemTypeService } from '../../../../core/services/itemtype.service';
import { LocalStorageService } from '../../../../core/services/local-storage.service';
import { ITariffItem } from '../../../../core/models/ITariffItem';
import { TariffBookService } from '../../../../core/services/tariffbook.service';

const tariffBookStoreKey = 'tarifftel_book_store_data';
const tariffBookStoreTimeStampKey = 'tarifftel_book_store_timestamp';
const CACHE_DURATION = 60 * 60 * 1000; // 1 hour

type TariffBookState = {
  sections: ISection[];
  chapters: IChapter[];
  headings: IHeading[];

  selectedHeadingCode: string | null;
  subHeadings: ITariffItem[];
  eightDigitCommodities: ITariffItem[];
  tenDigitCommodities: ITariffItem[];

  selectedCode: string | null;
  selectedCodeType: '6-digit' | '8-digit' | '10-digit' | 'unknown';

  selectedList: 'worldwidebook' | 'tarifftelbook';
  loading: boolean;
  loadingRightPanel: boolean;

  selectedSectionId: number | null;
  selectedChapterId: number | null;
  selectedHeadingId: number | null;
};

const initialState: TariffBookState = {
  sections: [],
  chapters: [],
  headings: [],

  selectedHeadingCode: null,
  subHeadings: [],
  eightDigitCommodities: [],
  tenDigitCommodities: [],
  selectedCode: null,
  selectedCodeType: 'unknown',

  selectedList: 'worldwidebook',
  loading: true,
  loadingRightPanel: false,

  selectedSectionId: null,
  selectedChapterId: null,
  selectedHeadingId: null
};

export const TariffBookStore = signalStore(
  { providedIn: 'root' },
  withState(initialState),
  withComputed(({ headings, selectedList, loading }) => ({
    // example computed
    filteredList: computed(() => {
      if (selectedList() === 'worldwidebook') {
        return headings();
      }
      return [];
    })
  })),
  withMethods((store) => {
    const itemTypeService = inject(ItemTypeService);
    const localStorageService = inject(LocalStorageService);
    const tariffBookService = inject(TariffBookService);

    return {
      loadData() {
        const dataFromStorage = localStorageService.getItem(tariffBookStoreKey);
        const timestamp = parseInt(
          localStorageService.getItem(tariffBookStoreTimeStampKey) || '0',
          10
        );
        const now = Date.now();

        patchState(store, { loading: true });

        if (dataFromStorage && now - timestamp < CACHE_DURATION) {
          const parsed = JSON.parse(dataFromStorage);
          patchState(store, {
            sections: parsed.sections || [],
            loading: false
          });
        } else {
          itemTypeService.getActiveSections().subscribe({
            next: (sections) => {
              patchState(store, { sections, loading: false });
              localStorageService.setItem(
                tariffBookStoreKey,
                JSON.stringify({ sections })
              );
              localStorageService.setItem(
                tariffBookStoreTimeStampKey,
                now.toString()
              );
            },
            error: (err) => {
              console.error('Failed to load Sections', err);
              patchState(store, { loading: false });
            }
          });
        }
      },

      selectList(list: 'worldwidebook' | 'tarifftelbook') {
        patchState(store, { selectedList: list });
      },

      setSelectedCode(code: string) {
        const determined = this.determineCodeTypeByLength(code);
        patchState(store, {
          selectedCode: code,
          selectedCodeType: determined
        });
      },

      // If you still want these:
      select6DigitCode(code: string) {
        patchState(store, {
          selectedCode: code,
          selectedCodeType: '6-digit'
        });
      },
      select8DigitCode(code: string) {
        patchState(store, {
          selectedCode: code,
          selectedCodeType: '8-digit'
        });
      },
      select10DigitCode(code: string) {
        patchState(store, {
          selectedCode: code,
          selectedCodeType: '10-digit'
        });
      },

      determineCodeTypeByLength(code: string) {
        // Adjust if your real logic is about trailing zeros.
        if (code.length === 6) return '6-digit';
        if (code.length === 8) return '8-digit';
        if (code.length === 10) return '10-digit';
        return 'unknown';
      },

      selectSection(sectionId: number) {
        patchState(store, {
          selectedSectionId: sectionId,
          selectedChapterId: null,
          selectedHeadingId: null,
          chapters: [],
          headings: [],
          subHeadings: [],
          eightDigitCommodities: [],
          tenDigitCommodities: [],
          loading: true
        });

        itemTypeService.getChaptersBySectionId(sectionId).subscribe({
          next: (chapters) => {
            patchState(store, { chapters, loading: false });
          },
          error: (err) => {
            console.error('Failed to load chapters', err);
            patchState(store, { loading: false });
          }
        });
      },

      selectChapter(chapterId: number) {
        patchState(store, {
          selectedChapterId: chapterId,
          selectedHeadingId: null,
          headings: [],
          subHeadings: [],
          eightDigitCommodities: [],
          tenDigitCommodities: [],
          loading: true
        });

        itemTypeService.getHeadingsByChapterId(chapterId).subscribe({
          next: (headings) => {
            patchState(store, { headings, loading: false });
          },
          error: (err) => {
            console.error('Failed to load headings', err);
            patchState(store, { loading: false });
          }
        });
      },

      selectHeading(headingId: number) {
        patchState(store, {
          selectedHeadingId: headingId,
          selectedCode: null,
          subHeadings: [],
          eightDigitCommodities: [],
          tenDigitCommodities: [],
          loadingRightPanel: true
        });

        const foundHeading = store.headings().find(h => h.id === headingId);
        if (!foundHeading) {
          console.warn('No heading found in store for ID:', headingId);
          patchState(store, { loadingRightPanel: false });
          return;
        }

        const headingCode = foundHeading.headingCode;

        tariffBookService.getMergedTariffData(headingCode).subscribe({
          next: (items) => {
            const subHeadings: ITariffItem[] = [];
            const eightDigitCommodities: ITariffItem[] = [];
            const tenDigitCommodities: ITariffItem[] = [];

            console.log("ITEMS RETURNED", items);

            items.forEach(item => {
              // THE PROBLEM IS HERE
              const codeType = this.determineCodeTypeByLength(item.code);
              if (codeType === '6-digit') {
                subHeadings.push(item);
              } else if (codeType === '8-digit') {
                eightDigitCommodities.push(item);
              } else if (codeType === '10-digit') {
                tenDigitCommodities.push(item);
              }
            });

            console.log("SUBHEADINGS", subHeadings);
            console.log("EIGHT DIGIT", eightDigitCommodities);
            console.log("TEN DIGIT", tenDigitCommodities);

            patchState(store, {
              subHeadings,
              eightDigitCommodities,
              tenDigitCommodities,
              loadingRightPanel: false
            });
          },
          error: (err) => {
            console.error('Failed to load subHeadings', err);
            patchState(store, { loadingRightPanel: false });
          }
        });
      },

      clearCache() {
        localStorageService.removeItem(tariffBookStoreKey);
        localStorageService.removeItem(tariffBookStoreTimeStampKey);
        patchState(store, initialState);
      }
    };
  }),
  withHooks({
    onInit(store) {
      store.loadData();
    }
  })
);
