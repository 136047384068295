import { getState, patchState, signalStore, withComputed, withHooks, withMethods, withState } from "@ngrx/signals";
import { computed, inject } from "@angular/core";
import { LocalStorageService } from "../../../../core/services/local-storage.service";
import { forkJoin } from "rxjs";
import { ItemTypeAttributeService } from "../../../../core/services/item-type-attribute.service";
import { IItemAttribute } from "../../../../core/models/IItemAttribute";
import { IAttributeValue } from "../../../../core/models/IAttributeValue";
import { IRegion } from "../../../../core/models/IRegion";
import { RegionService } from "../../../../core/services/region.service";
import { IItemTypeGroup } from "../../../../core/models/IItemTypeGroup";

const itemTypeDialogStoreKey = 'itemtype_dialog_data';
const itemTypeDialogStoreTimestampKey = 'tarifftel_itemtype_dialog_data_timestamp';
const CACHE_DURATION = 60 * 60 * 1000; // 1 hour

type ItemTypeState = {
  itemTypeGroup: IItemTypeGroup | null;
  regions: IRegion[];
  selectedRegionId: number | null;
  attributes: IItemAttribute[];
  attributeValues: IAttributeValue[];
  selectedAttributeId: number | null;

  // Track the user-built string from double clicked values
  selectedValuesString: string;
  generatedItemTypes: string[];
  loading: boolean;
};

const initialState: ItemTypeState = {
  itemTypeGroup: null,
  regions: [],
  selectedRegionId: null,
  attributes: [],
  attributeValues: [],
  selectedAttributeId: null,
  selectedValuesString: '',
  generatedItemTypes: [],
  loading: false
};

export const ItemTypeDialogStore = signalStore(
  { providedIn: 'root' },
  withState(initialState),
  withComputed(({ itemTypeGroup, regions, attributes, attributeValues, selectedRegionId, selectedAttributeId, selectedValuesString }) => ({

    selectedRegion: computed(() => {
      return regions().find(r => r.id === selectedRegionId()) || null;
    }),

    selectedRegionDescription: computed(() => {
      const region = regions().find(r => r.id === selectedRegionId());
      return region ? region.description : 'No Region Selected';
    }),

    selectedAttribute: computed(() => {
      return attributes().find(a => a.id === selectedAttributeId()) || null;
    }),

    selectedAttributeName: computed(() => {
      const attr = attributes().find(a => a.id === selectedAttributeId());
      return attr ? attr.attributeName : 'No Attribute Selected';
    }),


  })),
  withMethods((store) => {
    const regionService = inject(RegionService);
    const itemTypeAttributeService = inject(ItemTypeAttributeService);
    const localStorageService = inject(LocalStorageService);

    return {
      setItemTypeGroup(itemTypeGroup: IItemTypeGroup) {
        patchState(store, { itemTypeGroup });
      },
      loadData() {
        const storedString = localStorageService.getItem(itemTypeDialogStoreKey);
        const timestampString = localStorageService.getItem(itemTypeDialogStoreTimestampKey);
        const now = Date.now();

        // Parse the JSON only if we have something
        let dataFromStorage: Partial<ItemTypeState> | null = null;
        if (storedString) {
          try {
            dataFromStorage = JSON.parse(storedString);
          } catch (err) {
            console.warn('Failed to parse JSON from localStorage', err);
          }
        }

        const timestamp = parseInt(timestampString ?? '0', 10);

        if (dataFromStorage && (now - timestamp) < CACHE_DURATION) {
          // We have valid cached data; patch state
          patchState(store, dataFromStorage);
        } else {
          // Otherwise, fetch from server
          patchState(store, { loading: true });

          forkJoin({
            regions: regionService.getActiveRegions(),
          }).subscribe({
            next: ({ regions }) => {
              patchState(store, {
                regions,
                selectedRegionId: regions.length > 0 ? regions[0].id : null,
                loading: false
              });
              localStorageService.setItem(itemTypeDialogStoreKey, JSON.stringify({ regions }));
              localStorageService.setItem(itemTypeDialogStoreTimestampKey, now.toString());
            },
            error: (err) => {
              console.error('Failed to load Data', err);
              patchState(store, { loading: false });
            }
          });
        }
      },

      // setSelectedRegion(regionId: number) {
      //   patchState(store, { selectedRegionId: regionId });
      // },

      loadAttributes() {
        const { itemTypeGroup } = getState(store);
        if (!itemTypeGroup) return;

        patchState(store, { loading: true });

        itemTypeAttributeService.getAttributesByItemTypeGroupId(itemTypeGroup.id).subscribe({
          next: (attributes) => {
            // Sort attributes alphabetically by attributeName
            attributes.sort((a, b) => a.attributeName.localeCompare(b.attributeName));
            patchState(store, { attributes, loading: false });

            if (attributes.length > 0) {
              patchState(store, { selectedAttributeId: attributes[0].id });
              this.loadAttributeValues(attributes[0].id);
            } else {
              patchState(store, { selectedAttributeId: null, attributeValues: [] });
            }
          },
          error: (error) => {
            console.error("Failed to load attributes", error);
            patchState(store, { loading: false });
          }
        });
      },


      loadAttributeValues(attributeId: number) {
        patchState(store, { selectedAttributeId: attributeId, loading: true });
        itemTypeAttributeService.getItemTypeAttributeValuesByItemTypeAttributeId(attributeId).subscribe({
          next: (attributeValues) => {
            patchState(store, { attributeValues, loading: false });
          },
          error: (error) => {
            console.error("Failed to load attribute values", error);
            patchState(store, { loading: false });
          }
        });
      },

      selectRegion(regionId: number) {
        patchState(store, { selectedRegionId: regionId });
      },

      addValueToSelection(valueName: string) {
        const { selectedValuesString } = getState(store);
        let updatedString = '';
        if (selectedValuesString) {
          updatedString = `${selectedValuesString} - ${valueName}`;
        } else {
          updatedString = valueName;
        }
        patchState(store, { selectedValuesString: updatedString });
      },

      addItemTypeToGeneratedList() {
        const { selectedValuesString, generatedItemTypes } = getState(store);
        if (selectedValuesString) {
          patchState(store, {
            generatedItemTypes: [...generatedItemTypes, selectedValuesString],
            selectedValuesString: ''
          });
        }
      },

      clearSelectedValues() {
        patchState(store, { selectedValuesString: '' });
      },

      clearCache() {
        localStorageService.removeItem(itemTypeDialogStoreKey);
        localStorageService.removeItem(itemTypeDialogStoreTimestampKey);
        patchState(store, initialState);
      },

    };
  }),
  withHooks({
    onInit(store) {
      store.loadData();
    },
  })
);
