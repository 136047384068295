import { Component, inject } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { TenantSettingsService } from '../../core/services/tenant-settings.service';
import { ITenantSettings } from '../../common/interfaces/ITenantSettings';
import { NgFor } from '@angular/common';
import { AppSettingsService, SecondaryNavbarComponent } from '@tarifftel/components-library';

@Component({
  selector: 'app-tenant-selection',
  standalone: true,
  imports: [SecondaryNavbarComponent, MatCardModule, NgFor],
  templateUrl: './tenant-selection.component.html',
  styleUrl: './tenant-selection.component.scss'
})
export class TenantSelectionComponent {

  tenantPath = 'assets/tenants.json'

  tenantService = inject(TenantSettingsService);
  appSettings = inject(AppSettingsService);

  tenants: ITenantSettings[] = [];

  ngOnInit(): void {
    this.loadTenants(this.tenantPath);
  }

  loadTenants(tenantPath: string) {
    this.tenantService.getTenantsWithTariffTelFirst().subscribe(tenants => {
      this.tenants = tenants;
    });
  }

  onMouseOver(tenant: ITenantSettings): void {
    this.appSettings.logo.set(tenant.logo);
    this.appSettings.primaryBackgroundColor.set(tenant.backgroundColor);
    this.appSettings.secondaryBackgroundColor.set(tenant.secondaryColor);
    this.appSettings.hoverColor.set(tenant.hoverColor);
    this.appSettings.iconColor.set(tenant.iconColor);
  }

  onMouseOut(): void {
    this.appSettings.resetTemporarySettings();
  }

}
