import { Component, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { ISection } from '../../../../core/models/ISection';
import { SectionChapterHeadingStore } from '../store/sectionchapterstore';
import { ToastrService } from 'ngx-toastr';
import { CommonModule, NgIf } from '@angular/common';
import { UiSwitchModule } from 'ngx-ui-switch';
import { AppSettingsService } from '@tarifftel/components-library';

@Component({
  selector: 'app-section-list',
  standalone: true,
  imports: [CommonModule, UiSwitchModule],
  templateUrl: './section-list.component.html',
  styleUrl: './section-list.component.scss',
  providers: [
    {
      provide: UiSwitchModule,
      useFactory: () => UiSwitchModule.forRoot({
        size: 'medium',
        color: 'rgb(0, 189, 99)',
        switchColor: '#3f4d97',
        defaultBgColor: '#cfe2ff',
        defaultBoColor: '#476EFF',
        checkedLabel: 'on',
        uncheckedLabel: 'off'
      })
    }
  ]
})
export class SectionListComponent {
  appSettings = inject(AppSettingsService);
  store = inject(SectionChapterHeadingStore);
  toastrService = inject(ToastrService);

  // Access filtered list directly from the store
  filteredSections = this.store.filteredList;

  ngOnInit() {
  }

  onToggleActive(section: ISection) {
    // Optimistically toggle the heading's active status in the store
    const newStatus = !section.active; // Explicitly toggle active status
    this.store.toggleActiveSection(section.id);

    // Call the service with the new status
    this.store.updateSectionStatus({ ...section, active: newStatus }).subscribe({
      next: () => {
        this.toastrService.success('Section updated successfully', 'Success');
      },
      error: () => {
        this.toastrService.error('Failed to update Section', 'Error');
        // Revert the change in the store
        this.store.toggleActiveSection(section.id);
      }
    });
  }
}
