import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable, of } from 'rxjs';
import { ITenantSettings } from '../../common/interfaces/ITenantSettings';
import { IClientAccessDto } from '../models/IClientAccessDto';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TenantSettingsService {

  clientUrl = environment.apiUrl;
  private tenantUrl = 'assets/tenants.json';
  private tenants: ITenantSettings[] = [];

  constructor(private http: HttpClient) {
     // Load tenants from the JSON file initially
     this.loadTenantsFromFile();
  }

  private loadTenantsFromFile(): void {
    this.http.get<ITenantSettings[]>(this.tenantUrl).subscribe(data => {
      this.tenants = data;
    });
  }

  getTenantsWithTariffTelFirst(): Observable<ITenantSettings[]> {
   return this.http.get<ITenantSettings[]>(this.tenantUrl).pipe(
      map(tenants => tenants
        .map(tenant => ({
          ...tenant,
          logo: '../' + tenant.logo
        }))
        .sort((a, b) => {
          if (a.clientName === 'TariffTel') return -1; // "TariffTel" comes first
          if (b.clientName === 'TariffTel') return 1;
          return a.clientName.localeCompare(b.clientName); // Sort the rest alphabetically
        })
      )
    );
  }

  getTenantsWithoutTariffTel(): Observable<ITenantSettings[]> {
    return this.http.get<ITenantSettings[]>(this.tenantUrl).pipe(
      map(tenants => tenants
        .filter(tenant => tenant.clientName !== 'TariffTel') // Filter out "TariffTel"
        .map(tenant => ({
          ...tenant,
          logo: '../' + tenant.logo
        }))
        .sort((a, b) => a.clientName.localeCompare(b.clientName)) // Sort the rest alphabetically
      )
    );
  }

  toggleTenantStatus(id: number, isActive: boolean): Observable<any> {
    // Mock behavior for updating tenant's active status
    const tenantIndex = this.tenants.findIndex(tenant => tenant.id === id);
    if (tenantIndex !== -1) {
      this.tenants[tenantIndex].active = isActive;
    }
    return of(this.tenants[tenantIndex]);
  }

  // Create tenant - Temporarily update the local JSON-like array
  createTenant(clientName: string, newTenantData: ITenantSettings): Observable<ITenantSettings> {
    const newTenant: ITenantSettings = {
      ...newTenantData, // Spread the properties first
      id: this.tenants.length + 1, // Then explicitly assign the id
      clientName: clientName, // Keep or overwrite the clientName
      active: true // Ensure active is set to true
    };
    this.tenants.push(newTenant);
    return of(newTenant);
  }


  updateTenant(updatedTenant: ITenantSettings): Observable<ITenantSettings> {
    const tenantIndex = this.tenants.findIndex(t => t.id === updatedTenant.id);
    if (tenantIndex !== -1) {
      this.tenants[tenantIndex] = updatedTenant;
    }
    return of(this.tenants[tenantIndex]);
  }

  getClientAccessSettings(clientId: number): Observable<IClientAccessDto> {
    return this.http.get<IClientAccessDto>(`${this.clientUrl}/api/client/${clientId}/access-settings`);
  }

  // Update client access settings for Item Type Groups and Regions
  updateClientAccessSettings(clientId: number, accessSettings: IClientAccessDto): Observable<any> {
    return this.http.post(`${this.clientUrl}/api/client/${clientId}/update-access-settings`, accessSettings);
  }
}
