import { CommonModule, NgFor } from '@angular/common';
import { Component, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ItemTypeGroupStore } from './store/itemtype-group.store';
import { debounceTime, Subject, take } from 'rxjs';
import { UiSwitchModule } from 'ngx-ui-switch';
import { IItemTypeGroup } from '../../../core/models/IItemTypeGroup';
import { MatDialog } from '@angular/material/dialog';
import { ItemTypeGroupDialogComponent } from './item-type-group-dialog/item-type-group-dialog.component';
import { MaterialCategoryStore } from '../material-mgmt/store/materialstore';
import { ConstructionCategoryStore } from '../construction-mgmt/store/constructionstore';
import { AttributeListingStore } from '../attribute-values-mgmt/store/attributelistingstore';
import { DefaultSizeStore } from '../size-mgmt/store/sizecategorystore';
import { classificationStore } from '../item-type-mgmt/classification-dialog/store/classifierstore';
import { ItemTypeGroupListComponent } from "./item-type-group-list/item-type-group-list.component";
import { MaterialConditionsListComponent } from "./material-conditions-list/material-conditions-list.component";
import { GroupCategoriesListComponent } from "./group-categories-list/group-categories-list.component";
import { ConstructionListComponent } from "../construction-mgmt/construction-list/construction-list.component";
import { GroupConstructionsListComponent } from "./group-constructions-list/group-constructions-list.component";

@Component({
  selector: 'app-item-type-groups',
  standalone: true,
  imports: [CommonModule, FormsModule, UiSwitchModule, ItemTypeGroupListComponent, MaterialConditionsListComponent, GroupCategoriesListComponent, GroupConstructionsListComponent],
  templateUrl: './item-type-groups.component.html',
  styleUrls: ['./item-type-groups.component.scss'],
  providers: [
    {
      provide: UiSwitchModule,
      useFactory: () => UiSwitchModule.forRoot({
        size: 'medium',
        color: 'rgb(0, 189, 99)',
        switchColor: '#3f4d97',
        defaultBgColor: '#cfe2ff',
        defaultBoColor: '#476EFF',
        checkedLabel: 'on',
        uncheckedLabel: 'off'
      })
    }
  ]
})
export class ItemTypeGroupsComponent {

  store = inject(ItemTypeGroupStore);
  classifierStore = inject(classificationStore);
  materialCategoryStore = inject(MaterialCategoryStore);
  attributelistingStore = inject(AttributeListingStore);
  constructionStore = inject(ConstructionCategoryStore);
  defaultSizeStore = inject(DefaultSizeStore);
  toastrService = inject(ToastrService);
  dialog = inject(MatDialog);

  readonly ITEMTYPEGROUPS: 'itemtypegroups' = 'itemtypegroups';
  readonly GROUPCATEGORIES: 'groupcategories' = 'groupcategories';
  readonly MATERIALS: 'materials' = 'materials';
  readonly CONSTRUCTIONS: 'constructions' = 'constructions';

  selectedItem: 'itemtypegroups' | 'groupcategories' | 'materials' | 'constructions' = this.ITEMTYPEGROUPS;


  // Subject for search input with debounce
  // private searchSubject = new Subject<string>();
  // searchTerm: string = '';

  // ngOnInit() {
  //   this.store.loadItemTypeGroups();
  //   this.searchSubject.pipe(
  //     debounceTime(300) // Adjust the debounce time as needed
  //   ).subscribe((term) => {
  //     this.store.setSearchTerm(term);
  //   });
  // }

  // onToggleActive(itemTypeGroup: IItemTypeGroup) {
  //   // Optimistically toggle the Item Type Groups active status in the store
  //   const newStatus = !itemTypeGroup.active; // Explicitly toggle active status
  //   this.store.toggleActiveItemTypeGroup(itemTypeGroup.id);

  //   // Call the service with the new status
  //   this.store.updateItemTypeGroup({ ...itemTypeGroup, active: newStatus }).subscribe({
  //     next: () => {
  //       this.clearSystemStoreCaches();
  //       this.updateSystemStores();
  //       this.toastrService.success('Item Type Group updated successfully', 'Success');
  //     },
  //     error: () => {
  //       this.toastrService.error('Failed to update Item Type Group', 'Error');
  //       // Revert the change in the store
  //       this.store.toggleActiveItemTypeGroup(itemTypeGroup.id);
  //     }
  //   });
  // }

  // clearSystemStoreCaches() {
  //   this.materialCategoryStore.clearCache();
  //   this.constructionStore.clearCache();
  //   this.defaultSizeStore.clearCache();
  //   this.attributelistingStore.clearCache();
  //   this.classifierStore.clearCache();
  // }

  // updateSystemStores() {
  //   this.materialCategoryStore.loadData();
  //   this.constructionStore.loadData();
  //   this.defaultSizeStore.loadData();
  //   this.attributelistingStore.loadData();
  //   this.classifierStore.loadData();
  // }

  // forceRefresh() {
  //   this.store.clearCache();
  //   this.store.loadItemTypeGroups();
  //   this.toastrService.info('Data has been refreshed', 'Refreshed');
  // }

  // onSearchTermChange(term: string) {
  //   this.searchSubject.next(term);
  // }

  // // New method to reset filters and search
  // resetFilters() {
  //   this.searchTerm = ''; // Clear the search input
  //   this.store.setSearchTerm(''); // Explicitly update the store's searchTerm
  //   this.toastrService.info('Filters have been reset', 'Reset'); // Optional: Notify the user
  // }

  showTable(item: 'itemtypegroups' | 'groupcategories' | 'materials' | 'constructions') {
    this.selectedItem = item; // Update selectedItem
    this.store.showTable(item); // Call store method to update selectedList
  }

  // openDialog(editMode: boolean, itemTypeGroup?: IItemTypeGroup) {
  //   const dialogRef = this.dialog.open(ItemTypeGroupDialogComponent, {
  //     width: '600px',
  //     data: {
  //       itemTypeGroup: itemTypeGroup ?? { id: 0, description: '', clarifyingInformation: '', active: true },
  //       editMode
  //     }
  //   });

  //   dialogRef.afterClosed().subscribe((result: IItemTypeGroup) => {
  //     if (result) {
  //       if (editMode) {
  //         this.store.patchItemTypeGroup(result); // Update the item in the store
  //       } else {
  //         this.store.addItemTypeGroup(result); // Add the new item to the store
  //       }

  //       // this.clearSystemStoreCaches();
  //       // this.updateSystemStores();
  //     }
  //   });
  // }

  // createItemTypeGroup() {
  //   this.openDialog(false);
  // }

  // editItemTypeGroup(itemTypeGroup: IItemTypeGroup) {
  //   this.openDialog(true, itemTypeGroup);
  // }
}
