import { Component, effect, inject, OnInit, signal } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { VendorUserFormComponent } from "../vendor-user-form/vendor-user-form.component";
import { UserFriendlyHttpErrorMessageDialogComponent } from '../../user-friendly-http-error-message-dialog/user-friendly-http-error-message-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { IVendorUser } from '../../../../../core/models/IVendorUser';
import { VendorManagementService } from '../../../../../core/services/vendor-management.service';
import { FormGroup } from '@angular/forms';
import { ConfirmModalComponent } from '../../../../../core/components/confirm-modal/confirm-modal.component';
import { CanComponentWithUnSavedFormChangesDeactivate } from '../../../../../core/guards/can-deactivate-confirm-discard-unsaved-changes.guard';
import { BreadcrumbComponent } from "../../../../../core/components/breadcrumb/breadcrumb.component";
import { VendorStore } from '../../store/vendors.store';
import { IVendor } from '../../../../../core/models/IVendor';

@Component({
  selector: 'app-vendor-user-detail',
  standalone: true,
  imports: [VendorUserFormComponent, BreadcrumbComponent],
  templateUrl: './vendor-user-detail.component.html',
  styleUrl: './vendor-user-detail.component.scss'
})
export class VendorUserDetailComponent implements OnInit,CanComponentWithUnSavedFormChangesDeactivate {
  vendorUser = signal<IVendorUser | undefined>(undefined);
  vendor = signal<IVendor | undefined>(undefined);
  vendorUserId:number|undefined;
  showVendorUserLoadingSpinner:boolean = true;
  vendorUserForm:FormGroup | undefined;
  vendorStore = inject(VendorStore)

  constructor(private dialog:MatDialog,private route: ActivatedRoute,private vendorManagementService:VendorManagementService) {
  }

  ngOnInit() {
    const vendorUserIdStr:string | null = this.route.snapshot.paramMap.get('id');

    this.showVendorUserLoadingSpinner = true;

    if(vendorUserIdStr) {
      this.vendorUserId = +vendorUserIdStr
      this.loadVendors(false);
    }   
  }

  onVendorUserDetailFormChange(vendorUserForm:any) {
    this.vendorUserForm = vendorUserForm;
  }

  canDeactivate() {
    if(this.vendorUserForm && this.vendorUserForm?.dirty) {
      const dialogRef = this.dialog.open(ConfirmModalComponent, {
        data: {
          title:"",
          message:"Changes to the vendor user form have not been saved. Do you want to continue and discard the current changes?"
        }
      });
  
      return dialogRef.afterClosed();
    }
    else {
      return true;
    }
  
  }

  refreshVendorStore() {
    this.showVendorUserLoadingSpinner = true;
    this.loadVendors(true);
  }

  loadVendors(loadFromRequestNotStateOverride:boolean) {
    this.vendorStore.loadVendors(loadFromRequestNotStateOverride).subscribe(
      {
        next:() => {
          if(this.vendorUserId) {
            this.vendorUser.set(this.vendorStore.getVendorUserById(this.vendorUserId));
            if(this.vendorUser() && this.vendorUser()?.vendorId) {
              this.vendor.set(this.vendorStore.getVendorById(this.vendorUser()?.vendorId!));
            }
            this.showVendorUserLoadingSpinner = false;
          }
        },
        error: (error) => {
          console.error('Failed to load vendors for vendor user detail', error);
          this.dialog.open(UserFriendlyHttpErrorMessageDialogComponent);
        }
      }
    );
  }
}
