<div class="container-fluid">
  <div class="row mb-3">
    <div class="col-12 col-md">
      <!-- Dynamic heading based on the selected type -->
      <h6 class="mb-3 mb-md-0">
        {{
          descriptionType() === '6-digit' ? '6-digit Headings/Sub-headings/Commodities' :
          descriptionType() === '8-digit' ? '8-digit Commodity Codes' :
          '10-digit Commodity Codes for ' + (selectedSubHeading?.code || '')
        }}
      </h6>
    </div>
    <div class="col-12 col-md-auto text-md-end">
      <!-- Container for buttons -->
      <!-- NOTE: Using .btn-toolbar instead of .d-flex to keep only this section no-wrap. -->
      <div class="btn-toolbar">
        <button class="btn btn-warning me-2 mb-2" aria-label="Create Item Type">
          Create Item Type
        </button>
        <!-- 6-digit (HS) Codes -->
        <button class="btn btn-primary me-2 mb-2"
                (click)="showSixDigitView()"
                [disabled]="descriptionType() === '6-digit'"
                aria-label="6 digit (HS) codes">
          6-digit Codes
          <span *ngIf="count6Digit > 0" class="badge bg-light text-dark ms-1">
            {{ count6Digit }}
          </span>
        </button>
        <!-- 8-digit Codes -->
        <button class="btn btn-info me-2 mb-2"
                (click)="showEightDigitView()"
                [disabled]="descriptionType() === '8-digit'"
                aria-label="8-digit Codes">
          8-digit Codes
          <span *ngIf="count8Digit > 0" class="badge bg-light text-dark ms-1">
            {{ count8Digit }}
          </span>
        </button>
        <!-- 10-digit Codes -->
        <button class="btn btn-secondary mb-2"
                (click)="showTenDigitView()"
                [disabled]="descriptionType() === '10-digit'"
                aria-label="10-digit Codes">
          10-digit Codes
          <span *ngIf="count10Digit > 0" class="badge bg-light text-dark ms-1">
            {{ count10Digit }}
          </span>
        </button>
      </div>
    </div>
  </div>

  <hr />

  <!-- Loading Indicator -->
  <div *ngIf="isLoading" class="text-center my-4">
    <div class="spinner-border text-primary" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>

  <!-- 6-digit Codes List -->
  <div *ngIf="descriptionType() === '6-digit' && !isLoading">
    <!-- Search Input for 6-digit Codes -->
    <div class="mb-3">
      <input
        type="text"
        class="form-control"
        placeholder="Search 6-digit Codes"
        [(ngModel)]="searchTerm6Digit"
        (input)="applyFilter()"
        aria-label="Search 6-digit Codes"
      >
    </div>

    <ul class="list-group">
      <li
        *ngFor="let subHeading of filteredSubHeadings"
        class="list-group-item d-flex justify-content-between align-items-start"
        [class.active]="selectedSubHeading?.code === subHeading.code"
        (click)="selectSubHeading(subHeading)"
        style="cursor: pointer;"
        tabindex="0"
        (keyup.enter)="selectSubHeading(subHeading)"
        attr.aria-label="Select 6-digit Code {{ subHeading.code | slice:0:6 }}"
      >
        <div>
          <strong>{{ subHeading.code | slice:0:6 }}:</strong>
          {{ subHeading.description }}
        </div>
      </li>
    </ul>
  </div>

  <!-- 8-digit Codes List -->
  <div *ngIf="descriptionType() === '8-digit' && !isLoading">
    <!-- Search Input for 8-digit Codes -->
    <div class="mb-3">
      <input
        type="text"
        class="form-control"
        placeholder="Search 8-digit Codes"
        [(ngModel)]="searchTerm8Digit"
        (input)="applyFilter()"
        aria-label="Search 8-digit Codes"
      >
    </div>

    <ul class="list-group">
      <li
        *ngFor="let commodity of filteredEightDigitCommodities"
        class="list-group-item d-flex justify-content-between align-items-start"
        [class.active]="selectedEightDigitCommodity?.code === commodity.code"
        (click)="selectEightDigitCommodity(commodity)"
        style="cursor: pointer;"
        tabindex="0"
        (keyup.enter)="selectEightDigitCommodity(commodity)"
        attr.aria-label="Select 8-digit Code {{ commodity.code | slice:0:8 }}"
      >
        <div>
          <strong>{{ commodity.code | slice:0:8 }}:</strong>
          {{ commodity.description }}
        </div>
      </li>
    </ul>
  </div>

  <!-- 10-digit Codes List -->
  <div *ngIf="descriptionType() === '10-digit' && !isLoading" class="mb-3">
    <!-- Search Input for 10-digit Codes -->
    <div class="mb-3">
      <input
        type="text"
        class="form-control"
        placeholder="Search 10-digit Codes"
        [(ngModel)]="searchTerm10Digit"
        (input)="applyFilter()"
        aria-label="Search 10-digit Codes"
      >
    </div>

    <ul *ngIf="filteredTenDigitCommodities.length > 0" class="list-group">
      <li
        *ngFor="let commodity of filteredTenDigitCommodities"
        class="list-group-item d-flex justify-content-between align-items-start"
        [class.active]="selectedTenDigitCommodity?.code === commodity.code"
        (click)="selectTenDigitCommodity(commodity)"
        style="cursor: pointer;"
        tabindex="0"
        (keyup.enter)="selectTenDigitCommodity(commodity)"
        attr.aria-label="Select 10-digit Code {{ commodity.code }}"
      >
        <!-- Display filtered 10-digit commodity codes -->
        <div>
          <strong>{{ commodity.code }}:</strong>
          {{ commodity.description }}
        </div>
        <!-- Display flag based on origin of the data -->
        <span class="fi"
              [ngClass]="commodity.origin === 'US' ? 'fi-us' : 'fi-gb'"
              style="font-size: 24px">
        </span>
      </li>
    </ul>
  </div>

  <!-- Handle No Codes Found -->
  <div *ngIf="!isLoading && (count6Digit === 0 && count8Digit === 0 && count10Digit === 0)">
    <div class="alert alert-warning text-center" role="alert">
      No codes available for the selected heading.
    </div>
  </div>

  <hr />
</div>
