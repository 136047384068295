import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, forkJoin } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ITariffItem } from '../models/ITariffItem';
import { IData } from '../models/UK Tariff Book/IData';
import { ITariffTelCode } from '../models/ITariffTelCode';
import { environment } from '../../../environments/environment';

export interface TariffData {
  goods_nomenclature_item_id: string;
  description: string;
  basic_duty_rate: string | null;
  preferentialDutyRate: string | null;
  additionalDutyRate: string | null;
  exciseDuty: string | null;
  validity_start_date: string;
  vatRate: string;
  section: string;
  chapter: string;
  importMeasures: string[];
  footnotes: string[];
  requiresMeursingCode: boolean;
  quotaInfo?: string[];
  antiDumpingDuty?: string | null;
  countervailingDuty?: string | null;
  rulesOfOrigin?: string[];
  tradeAgreements?: string[];
  preferentialDutyRatesByCountry?: { country: string; rate: string }[];
  applicableTradeAgreements?: string[];
  rulesOfOriginByCountry?: string[];
  quotaAvailabilityByCountry?: string[];
  antiDumpingDutiesByCountry?: { country: string; duty: string }[];
  specificDutyRatesByCountry?: { country: string; rate: string }[];
}


@Injectable({
  providedIn: 'root'
})
export class TariffBookService {

  private apiUKCommodityUrl = 'https://www.trade-tariff.service.gov.uk/api/v2/commodities/';
  private apiEUCommodityUrl = 'https://www.trade-tariff.service.gov.uk/xi/api/v2/commodities/';

  clientUrl = environment.apiUrl;

  constructor(private http: HttpClient) {}

  private parseCodeLevels(code: string) {
    // code might be short (e.g., '9702') or up to 10 digits
    const result = {
      heading4: '',
      subheading6: '',
      subheading8: '',
      subheading10: ''
    };

    if (code.length >= 4) {
      result.heading4 = code.substring(0, 4);
    }
    if (code.length >= 6) {
      result.subheading6 = code.substring(0, 6);
    }
    if (code.length >= 8) {
      result.subheading8 = code.substring(0, 8);
    }
    if (code.length === 10) {
      result.subheading10 = code;
    }
    return result;
  }

  getUKTariffCodeData(tariffCode: string): Observable<TariffData> {
    console.log("Get UK Tariff Code Data for: ", tariffCode);
    return this.http.get<any>(`${this.apiUKCommodityUrl}${tariffCode}`).pipe(
      map(response => {
        const attributes = response.data.attributes;
        const included = response.included || [];

        // Dynamically find the preferential duty rate
        const preferentialDuty = included.find((i: any) =>
          i.type === 'duty_expression' && i.attributes.base?.includes('GBP')
        )?.attributes.verbose_duty || 'N/A';

        // Extract specific duty rates by country
        const specificDutyRatesByCountry = included
          .filter((i: any) => i.type === 'duty_expression')
          .map((d: any) => ({
            rate: d.attributes.verbose_duty,
          }));

        return {
          goods_nomenclature_item_id: attributes.goods_nomenclature_item_id,
          description: attributes.description,
          basic_duty_rate: response.included.find((i: any) => i.type === 'import_trade_summary')?.attributes.basic_third_country_duty || 'N/A',
          validity_start_date: attributes.validity_start_date.split('T')[0],
          preferentialDutyRate: preferentialDuty,
          additionalDutyRate: response.included.find((i: any) => i.type === 'import_trade_summary')?.attributes.additional_duty || 'N/A',
          vatRate: attributes.applicable_vat_options?.VATZ || 'N/A',
          section: included.find((i: any) => i.type === 'section')?.attributes.title || 'N/A',
          chapter: included.find((i: any) => i.type === 'chapter')?.attributes.description || 'N/A',
          importMeasures: response.data.relationships.import_measures.data.map((m: any) => m.id),
          footnotes: included.filter((i: any) => i.type === 'footnote').map((fn: any) => fn.attributes.description),
          quotaInfo: included.filter((i: any) => i.type === 'import_measures' && i.attributes.measure_type?.includes('Quota')).map((q: any) => q.attributes.measure_conditions || 'N/A'),
          requiresMeursingCode: attributes.requires_meursing_code ?? included.find((i: any) => i.type === 'meursing_code')?.attributes.required ?? false,
          antiDumpingDuty: included.find((i: any) => i.type === 'import_measures' && i.attributes.measure_type?.includes('Anti-dumping'))?.attributes.duty_amount || 'N/A',
          countervailingDuty: included.find((i: any) => i.type === 'import_measures' && i.attributes.measure_type?.includes('Countervailing'))?.attributes.duty_amount || 'N/A',
          rulesOfOrigin: included.filter((i: any) => i.type === 'import_measures' && i.attributes.measure_type?.includes('Rules of Origin')).map((r: any) => r.attributes.measure_conditions || 'N/A'),
          exciseDuty: response.included.find((i: any) => i.type === 'import_trade_summary')?.attributes.excise_duty || 'N/A',
          tradeAgreements: included.filter((i: any) => i.type === 'trade_agreement').map((a: any) => a.attributes.agreement_title || 'N/A'),
          specificDutyRatesByCountry: specificDutyRatesByCountry,
        };
      })
    );
}


  getEUTariffCodeData(tariffCode: string): Observable<TariffData> {
    console.log("Get EU Tariff Code Data for: ", tariffCode);
    return this.http.get<any>(`${this.apiEUCommodityUrl}${tariffCode}`).pipe(
      map(response => {
        const attributes = response.data.attributes;
        const included = response.included || [];

        return {
          goods_nomenclature_item_id: attributes.goods_nomenclature_item_id,
          description: attributes.description,
          basic_duty_rate: response.included.find((i: any) => i.type === 'import_trade_summary')?.attributes.basic_third_country_duty || 'N/A',
          validity_start_date: attributes.validity_start_date.split('T')[0],
          preferentialDutyRate: response.included.find((i: any) => i.type === 'duty_expression')?.attributes.formatted_base || 'N/A',
          additionalDutyRate: response.included.find((i: any) => i.type === 'import_trade_summary')?.attributes.additional_duty || 'N/A',
          vatRate: attributes.applicable_vat_options?.VATZ || 'N/A',
          section: included.find((i: any) => i.type === 'section')?.attributes.title || 'N/A',
          chapter: included.find((i: any) => i.type === 'chapter')?.attributes.description || 'N/A',
          importMeasures: response.data.relationships.import_measures.data.map((m: any) => m.id),
          footnotes: included.filter((i: any) => i.type === 'footnote').map((fn: any) => fn.attributes.description),
          quotaInfo: included.filter((i: any) => i.type === 'import_measures' && i.attributes.measure_type?.includes('Quota')).map((q: any) => q.attributes.measure_conditions || 'N/A'),
          requiresMeursingCode: attributes.meursing_code ?? false,
          antiDumpingDuty: included.find((i: any) => i.type === 'import_measures' && i.attributes.measure_type?.includes('Anti-dumping'))?.attributes.duty_amount || 'N/A',
          countervailingDuty: included.find((i: any) => i.type === 'import_measures' && i.attributes.measure_type?.includes('Countervailing'))?.attributes.duty_amount || 'N/A',
          rulesOfOrigin: included.filter((i: any) => i.type === 'import_measures' && i.attributes.measure_type?.includes('Rules of Origin')).map((r: any) => r.attributes.measure_conditions || 'N/A'),
          exciseDuty: response.included.find((i: any) => i.type === 'import_trade_summary')?.attributes.excise_duty || 'N/A',
          tradeAgreements: included.filter((i: any) => i.type === 'trade_agreement').map((a: any) => a.attributes.agreement_title || 'N/A'),
        };
      })
    );
  }

  // Fetch and merge data from both UK and US APIs based on the heading code
  getMergedTariffData(headingCode: string): Observable<ITariffItem[]> {
    const ukApiUrl = `https://www.trade-tariff.service.gov.uk/api/v2/headings/${headingCode}`;
    const usApiUrl = `/us-tariff-api?from=${headingCode}&to=${(parseInt(headingCode) + 1).toString()}&format=JSON&styles=true`;

    const ukObservable = this.http.get<IData>(ukApiUrl).pipe(
      tap((response: IData) => {
        console.log('Raw UK response:', response);
      }),
      map((response: IData) => {
        // Filter for 'commodity' items
        return response.included.filter(item => item.type === 'commodity');
      }),
      tap((filteredItems) => {
        console.log('Filtered UK items:', filteredItems);
      }),
      // map each commodity to an array of ITariffItem(s)
      map((filteredItems) =>
        filteredItems.flatMap(item => this.mapUkDataToStandardItem(item))
      )
    );

    const usObservable = this.http.get<any[]>(usApiUrl).pipe(
      tap((usData) => {
        console.log('Raw US response:', usData);
      }),
      map((usData) => {
        if (typeof usData === 'string') {
          try {
            usData = JSON.parse(usData);
          } catch (e) {
            console.error('Failed to parse US API response:', e);
            return [];
          }
        }
        return usData;
      }),
      tap((parsedUsData) => {
        console.log('Parsed US data:', parsedUsData);
      }),
      // map each US raw item to an array of ITariffItem(s)
      map((usData) =>
        usData.flatMap(item => this.mapUsDataToStandardItem(item))
      )
    );

    return forkJoin([ukObservable, usObservable]).pipe(
      tap(([ukItems, usItems]) => {
        console.log('UK items:', ukItems);
        console.log('US items:', usItems);
      }),
      map(([ukItems, usItems]) => {
        // Merge them all
        const mergedData = [...ukItems, ...usItems];

        // If you only want unique "6-digit" placeholders plus 10-digit items,
        // you can do something like:
        const sixDigitItems = this.getUniqueSixDigitItems(mergedData);
        // or use 8-digit if needed
        const eightDigitItems = this.getUniqueEightDigitItems(mergedData);
        const tenDigitItems = mergedData.filter(
          item => item.code.length === 10 &&
                  !item.code.endsWith('0000') // i.e. truly 10-digit commodity
        );

        // Combine them in the order you want
        const finalItems = [
          ...sixDigitItems,
          ...eightDigitItems,  // optional
          ...tenDigitItems
        ];

        // Sort them by code
        finalItems.sort((a, b) => a.code.localeCompare(b.code));
        return finalItems;
      }),
      tap((finalItems) => {
        console.log('Final merged data:', finalItems);
      })
    );
  }


  private getUniqueSixDigitItems(items: ITariffItem[]): ITariffItem[] {
    const seen = new Set<string>();
    const results: ITariffItem[] = [];

    // 6-digit placeholders will always look like "xxxxxx0000"
    items.forEach(item => {
      if (item.code.length === 10 && item.code.endsWith('0000')) {
        const sixDigitKey = item.code.substring(0, 6);
        if (!seen.has(sixDigitKey)) {
          seen.add(sixDigitKey);
          results.push(item);
        }
      }
    });

    return results;
  }

  private getUniqueEightDigitItems(items: ITariffItem[]): ITariffItem[] {
    const seen = new Set<string>();
    const results: ITariffItem[] = [];

    // 8-digit placeholders will always look like "xxxxxxxx00"
    items.forEach(item => {
      if (item.code.length === 10 && item.code.endsWith('00') && !item.code.endsWith('0000')) {
        const eightDigitKey = item.code.substring(0, 8);
        if (!seen.has(eightDigitKey)) {
          seen.add(eightDigitKey);
          results.push(item);
        }
      }
    });
    return results;
  }


  private mapUkDataToStandardItem(item: any): ITariffItem[] {
    const code10 = item.attributes.goods_nomenclature_item_id; // e.g. "9702900000"
    const description = item.attributes.description_plain || '';

    // Return a single item with a unique 'id'
    return [
      {
        code: code10,
        description,
        origin: 'UK',
        // Combine the code plus '_UK' to ensure uniqueness
        id: code10 + '_UK'
      }
    ];
  }
  private mapUsDataToStandardItem(item: any): ITariffItem[] {
    let normalized = item.htsno.replace(/\./g, '').trim();
    const levels = this.parseCodeLevels(normalized);
    const description = item.description || '';

    const results: ITariffItem[] = [];

    // 4-digit heading (if code length is exactly 4)
    if (normalized.length === 4) {
      results.push({
        code: levels.heading4,   // e.g. "9702"
        description,
        origin: 'US',
        // note the unique ID
        id: levels.heading4 + '_US_4'
      });
    }

    // 6-digit
    if (levels.subheading6) {
      const code10 = levels.subheading6.padEnd(10, '0');  // e.g. "9702100000"
      results.push({
        code: code10,
        description,
        origin: 'US',
        // ID indicates it's the 6-digit placeholder
        id: code10 + '_US_6'
      });
    }

    // 8-digit
    if (levels.subheading8) {
      const code10 = levels.subheading8.padEnd(10, '0');
      results.push({
        code: code10,
        description,
        origin: 'US',
        id: code10 + '_US_8'
      });
    }

    // 10-digit
    if (levels.subheading10) {
      results.push({
        code: levels.subheading10,
        description,
        origin: 'US',
        id: levels.subheading10 + '_US_10'
      });
    }

    return results;
  }


  getAllTariffTelCodes(): Observable<ITariffTelCode[]> {
    return this.http.get<ITariffTelCode[]>(`${this.clientUrl}/api/tarifftelcode`);
  }

  getAllActiveTariffTelCodes(): Observable<ITariffTelCode[]> {
    return this.http.get<ITariffTelCode[]>(`${this.clientUrl}/api/tarifftelcode/active`);
  }
}
