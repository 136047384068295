import { getState, patchState, signalStore, withComputed, withHooks, withMethods, withState } from "@ngrx/signals";
import { IChapter } from "../../../../core/models/IChapter";
import { IHeading } from "../../../../core/models/IHeading";
import { ISection } from "../../../../core/models/ISection";
import { computed, effect, inject, WritableSignal } from "@angular/core";
import { LocalStorageService } from "../../../../core/services/local-storage.service";
import { MatDialog } from "@angular/material/dialog";
import { ItemTypeService } from "../../../../core/services/itemtype.service";
import { forkJoin, Observable } from "rxjs";

const sectionchapterheadingStoreKey = 'tarifftel_hs_list_data';
const sectionchapterheadingStoreTimestampKey = 'tarifftel_hs_list_data_timestamp';
const CACHE_DURATION = 60 * 60 * 1000; // 1 hour

type Filter = 'all' | 'active' | 'inactive';

type TariffTelHSListState = {
  sections: ISection[];
  chapters: IChapter[];
  headings: IHeading[];
  filter: Filter;
  searchTerm: string;
  selectedList: 'sections' | 'chapters' | 'headings';
}

const initialState: TariffTelHSListState = {
  sections: [],
  chapters: [],
  headings: [],
  filter: 'all',
  searchTerm: '',
  selectedList: 'sections'
}

export const SectionChapterHeadingStore = signalStore(
  { providedIn: 'root' },
  withState(initialState),
  withComputed(({ sections, chapters, headings, filter, searchTerm, selectedList }) => ({
    activeSections: computed(() =>
      sections().filter((sectionItem) => sectionItem.active)
    ),
    activeChapters: computed(() =>
      chapters().filter((chapterItem) => chapterItem.active)
    ),
    activeHeadings: computed(() =>
      headings().filter((headingItem) => headingItem.active)
    ),
    filteredList: computed(() => {
      let filtered: any[] = [];

      // Select the list based on the selectedList state
      switch (selectedList()) {
        case 'sections':
          filtered = sections();
          break;
        case 'chapters':
          filtered = chapters();
          break;
        case 'headings':
          filtered = headings();
          break;
        default:
          break;
      }

      // Apply Filter (active/inactive)
      if (filter() !== 'all') {
        filtered = filtered.filter(item => filter() === 'active' ? item.active : !item.active);
      }

      // Apply Search Term
      const term = searchTerm().toLowerCase().trim();
      if (term) {
        filtered = filtered.filter(item => {
          // Use `title` for sections, `description` for others
          const fieldToSearch = selectedList() === 'sections' ? item.title : item.description;
          return fieldToSearch && fieldToSearch.toLowerCase().includes(term);
        });
      }

      return filtered;
    })
  })),
  withMethods((store) => {
    const itemTypeService = inject(ItemTypeService);
    const localStorageService = inject(LocalStorageService);
    const matDialogService = inject(MatDialog);

    return {
      loadData(loadingSignal?: WritableSignal<boolean>) {
        const dataFromStorage = localStorageService.getItem(sectionchapterheadingStoreKey) || {};
        const timestamp = parseInt(localStorageService.getItem(sectionchapterheadingStoreTimestampKey) || '0', 10);
        const now = Date.now();

        if (Object.keys(dataFromStorage).length > 0 && (now - timestamp) < CACHE_DURATION) {
          patchState(store, dataFromStorage);
          if (loadingSignal) {
            loadingSignal.set(false);
          }
        } else {
          // Fetch sections, chapters, and headings in parallel
          forkJoin({
            sections: itemTypeService.getAllSections(),
            chapters: itemTypeService.getAllChapters(),
            headings: itemTypeService.getAllHeadings()
          }).subscribe({
            next: ({ sections, chapters, headings }) => {
              patchState(store, { sections, chapters, headings });
              localStorageService.setItem(sectionchapterheadingStoreKey, JSON.stringify({ sections, chapters, headings }));
              localStorageService.setItem(sectionchapterheadingStoreTimestampKey, now.toString());
              if (loadingSignal) {
                loadingSignal.set(false);
              }
            },
            error: (err) => {
              console.error('Failed to load sections, chapters, and headings', err);
              if (loadingSignal) {
                loadingSignal.set(false);
                //matDialogService.open(UserFriendlyHttpErrorMessageDialogComponent);
              }
            }
          });
        }
      },

      changeFilter(filter: Filter) {
        patchState(store, { filter });
      },

      showTable(selectedList: 'sections' | 'chapters' | 'headings') {
        patchState(store, { selectedList });
      },

      // Toggle active state for a section
      toggleActiveSection(id: number) {
        patchState(store, {
          sections: store.sections().map(section => {
            if (section.id === id) {
              return { ...section, active: !section.active };
            }
            return section;
          })
        });
      },

      // Toggle active state for a chapter
      toggleActiveChapter(id: number) {
        patchState(store, {
          chapters: store.chapters().map(chapter => {
            if (chapter.id === id) {
              return { ...chapter, active: !chapter.active };
            }
            return chapter;
          })
        });
      },

      // Toggle active state for a heading
      toggleActiveHeading(id: number) {
        patchState(store, {
          headings: store.headings().map(heading => {
            if (heading.id === id) {
              return { ...heading, active: !heading.active };
            }
            return heading;
          })
        });
      },

      updateSectionStatus(section: ISection) {
        console.log("Section to update", section);
        return itemTypeService.toggleSectionStatus(section.id, section.active);
      },

      updateChapterStatus(chapter: IChapter) {
        return itemTypeService.toggleChapterStatus(chapter.id, chapter.active);
      },

      updateHeadingStatus(heading: IHeading) {
        return itemTypeService.toggleHeadingStatus(heading.id, heading.active);
      },

      setSearchTerm(term: string) {
        patchState(store, { searchTerm: term });
      },

      clearCache() {
        localStorageService.removeItem(sectionchapterheadingStoreKey);
        localStorageService.removeItem(sectionchapterheadingStoreTimestampKey);
        patchState(store, { sections: [], chapters: [], headings: [], searchTerm: '', filter: 'all' });
      }
    };
  }),
  withHooks({
    onInit(store) {
      const localStorageService = inject(LocalStorageService);
      store.loadData();

      effect(() => {
        const state = getState(store);
        const now = Date.now();
        localStorageService.setItem(
          sectionchapterheadingStoreKey,
          JSON.stringify({ sections: state.sections, chapters: state.chapters, headings: state.headings })
        );
        localStorageService.setItem(
          sectionchapterheadingStoreTimestampKey,
          now.toString()
        );
      });
    },
  })
);
