export enum UserRole {
  SuperAdmin = 'SuperAdmin',
  CustomsSuperUser = 'CustomsSuperUser',
  ClientAdmin = 'ClientAdmin',
  ClientVendor = 'ClientVendor',
  ClientUser = 'ClientUser'
}

export const RoleDisplayNames = new Map<UserRole, string>([
  [UserRole.SuperAdmin, 'Super Admin'],
  [UserRole.CustomsSuperUser, 'Customs Super User'],
  [UserRole.ClientAdmin, 'Client Admin'],
  [UserRole.ClientVendor, 'Client Vendor'],
  [UserRole.ClientUser, 'Client User'],
]);


