<nav *ngIf="breadcrumbs && breadcrumbs.length > 1" class="py-2 px-4">
    <ng-container *ngFor="let breadcrumb of breadcrumbs; let last = last">
      <ng-container *ngIf="!last">
        <a [routerLink]="breadcrumb.url">{{ breadcrumb.label }}</a> &gt;
      </ng-container>
      <ng-container *ngIf="last">
        {{ breadcrumb.label }}
      </ng-container>
    </ng-container>
</nav>
