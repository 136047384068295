import { inject, Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { IUnreadCounters } from '../models/IUnreadCounters';
import { LocalStorageService } from './local-storage.service';
import { NavigationEnd, Router } from '@angular/router';
import { AppSettingsService } from '@tarifftel/components-library';

@Injectable({
  providedIn: 'root',
})
  
export class UnreadCountersService {
  http = inject(HttpClient);
  localStorageService = inject(LocalStorageService);
  appSettings = inject(AppSettingsService);
  readonly clientUrl = environment.apiUrl;
  readonly storeTimestampKey = 'unread_counters_timestamp';
  
  refreshRequired() {
    const CACHE_DURATION = 1 * 30 * 1000; // 30 seconds    
    const timestamp = parseInt(this.localStorageService.getItem(this.storeTimestampKey) || '0', 10);
    const now = Date.now();
    return (now - timestamp) > CACHE_DURATION;
  }

  clearCache() {
    this.localStorageService.removeItem(this.storeTimestampKey);
  }
  
  // track navigation events, each time a navigation ends gives a chance to refresh the counters
  constructor(public router: Router) {
    // clear cache on app start
    // appSettings will be reset before the first navigation and always requires an update
    this.clearCache();

    router.events.subscribe(e => {
      if (e instanceof NavigationEnd) {
        if (this.refreshRequired()) {
          this.getCounters().subscribe(counters => {
            // set timestamp
            this.localStorageService.setItem(this.storeTimestampKey, Date.now());
            
            // update appSettings' signals
            this.appSettings.setMessagesCount(counters.inboxItems);
            this.appSettings.setNotificationsCount(counters.notifications);
          })
        }
      }
    });
  }

  getCounters() {
    return this.http.get<IUnreadCounters>(`${this.clientUrl}/api/unread-counters/`);
  }
}