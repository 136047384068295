<div class="row">
    <div class="btn-toolbar mb-2 ms-1" role="toolbar">
        <div class="btn-group mt-2 me-2" role="group" >
            <a href="javascript:void(0)" [routerLink]="getRouterLinkForVendorUserBulk()">Add Vendor Users Via Excel File</a>
        </div>
        <app-aggrid-top-btn-toolbar
        [aggridTopToolbarBtns]="this.aggridTopToolbarBtns"
        (aggridTopToolbarBtnClicked)="aggridTopToolbarBtnClicked($event)"
        ></app-aggrid-top-btn-toolbar>
    </div>
</div>

<ag-grid-angular
style="height: 650px ; width:  100%;"
[columnDefs]="columnDefs"
[defaultColDef]="defaultColDef"
[rowData]="rowData"
[sideBar]="sideBarDef()"
[class]="themeClass"
[rowSelection]="'multiple'"
[animateRows]="true"
[pagination]="true"
[paginationPageSize]="20"
[getContextMenuItems]="getContextMenuItems"
(selectionChanged)="onSelectionChanged($event)"
(gridReady)="onGridReady($event)"
/>

