import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { IInboxItem } from '../models/IInboxItem';

@Injectable({
  providedIn: 'root'
})
export class InboxItemsService {

  clientUrl = environment.apiUrl;

  constructor(private http: HttpClient) { }

  getAll(): Observable<IInboxItem[]> {
    return this.http.get<IInboxItem[]>(`${this.clientUrl}/api/inbox/items`);
  }
  
  getByQuery(query: string): Observable<IInboxItem[]> {
    if (!query) {
      return this.getAll();
    }

    let params = new HttpParams().set('query', query);
    return this.http.get<IInboxItem[]>(`${this.clientUrl}/api/inbox/items/query`, { params });
  }
}
