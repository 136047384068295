
<app-breadcrumb></app-breadcrumb>
<div class="row mx-1 my-2">
    <app-top-panel
    [title]="topPanelTitle"
    [tabItems]="tabItems"
    [objectToDisplay]="this.order"
    [showLoadingSpinner]="showOrderDetailLoadingSpinner"
    [orderId]="order?.id"
    ></app-top-panel>
</div>
<div *ngIf="this.order" class="row mx-1 my-2">
    <div class="col-12">
        <mat-card>
            <mat-card-header>
                <mat-card-title class="py-2">
                    <h5>Order Lines</h5>
                </mat-card-title>
            </mat-card-header>
            <mat-card-content >
                <app-aggrid-orders
                [EXCELEXPORTFILENAME]="'Tarifftel Order Lines'"
                [EXCELEXPORTWORKSHEETNAME]="'Tarifftel Order Lines Worksheet'"
                [rowsObjects]="this.order.orderLines"
                [includeSelectLink]="true"
                [includeClassifyButton]="true"
                [routingSelectLinkPath]="orderLineDetailGridSelectLinkPath"
                [fieldsToInitiallyShowDetailRow]="orderLineGridFieldsToInitiallyShowDetailRow"
                [fieldsToExcludeFromGridDetailRow]="orderLineGridFieldsToExcludeFromGridDetailRow"
                [excludeIdFieldsFromGridDetailRow]="true"
                [showGridLoading]="this.showOrderDetailLoadingSpinner"
                >
                </app-aggrid-orders>
            </mat-card-content>
        </mat-card>
    </div>

</div>
<!-- <div *ngIf="this.order" class="row mx-1 my-2">
  <div class="col-12">
      <mat-card>
          <mat-card-header>
              <mat-card-title>
                  <h5>Order Audit</h5>
              </mat-card-title>
          </mat-card-header>
          <mat-card-content>
              <app-aggrid-orders
              [EXCELEXPORTFILENAME]="'Tarifftel Order Audit'"
              [EXCELEXPORTWORKSHEETNAME]="'Tarifftel Order Audit Worksheet'"
              [rowsObjects]="this.order.orderAudits"
              [includeSelectLink]="false"
              [includeClassifyButton]="false"
              [routingSelectLinkPath]="orderLineDetailGridSelectLinkPath"
              [fieldsToInitiallyShowDetailRow]="auditGridFieldsToInitiallyShowDetailRow"
              [excludeIdFieldsFromGridDetailRow]="true"
              [showGridLoading]="this.showOrderDetailLoadingSpinner"
              >
              </app-aggrid-orders>
          </mat-card-content>
      </mat-card>


  </div>

</div> -->
