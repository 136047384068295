import { NgFor, NgIf } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { MaterialCategoryStore } from '../store/materialstore';
import { IMaterial } from '../../../../core/models/IMaterial';
import { MaterialDialogComponent } from '../material-dialog/material-dialog.component';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-material-list',
  standalone: true,
  imports: [TabsModule, NgIf, NgFor],
  templateUrl: './material-list.component.html',
  styleUrl: './material-list.component.scss'
})
export class MaterialListComponent implements OnInit {

  store = inject(MaterialCategoryStore);
  toastrService = inject(ToastrService);
  dialog = inject(MatDialog);

  ngOnInit() {
  }

  // Method to handle tab click and update selected RegionId
  handleTabClick(regionId: number, regionDescription: string, regionClass: string) {
    this.store.setSelectedRegionId(regionId);
    this.logCurrentState(); // Log the current state for debugging
  }

  // Handle Item Type Group click from the parent component
  handleGroupClick(itemTypeGroupId: number) {
    console.log(`Item Type Group clicked: ${itemTypeGroupId}`);
    this.store.setSelectedItemTypeGroupId(itemTypeGroupId);
    this.logCurrentState(); // Log the current state for debugging
  }

  // Log the current state for debugging
  logCurrentState() {
    console.log('Current State:', {
      selectedItemTypeGroupId: this.store.selectedItemTypeGroupId(),
      selectedRegionId: this.store.selectedRegionId(),
      filteredConstructions: this.store.filteredList(),
    });
  }

  // Open the dialog to create or edit material
  openMaterialDialog(editMode: boolean, material?: IMaterial) {
    const regionId = this.store.selectedRegionId();
    const itemTypeGroupId = this.store.selectedItemTypeGroupId();

    const dialogRef = this.dialog.open(MaterialDialogComponent, {
      width: '600px',
      data: {
        material: material ?? { id: 0, description: '', materialCategoryId: null, manMade: false, metallizedYarn: false, regionId, itemTypeGroupId },
        editMode
      }
    });

    dialogRef.afterClosed().subscribe((result: IMaterial) => {
      if (result) {
        if (editMode) {
          this.store.patchMaterial(result);
        } else {
          this.store.addMaterial(result);
        }
      }
    });
  }

  createMaterial() {
    this.openMaterialDialog(false);
  }

  editMaterialDescription(material: IMaterial) {
    this.openMaterialDialog(true, material);
  }

  getTruncatedDescription(material: IMaterial) {
    const charLimit = material.clarifyingInformation ? 25 : 35;
    return material.description.length > charLimit
      ? material.description.slice(0, charLimit) + '...'
      : material.description;
  }

}
