<h2 mat-dialog-title>{{ editMode ? 'Edit Department' : 'Create Department' }}</h2>
<hr />
<form [formGroup]="departmentForm" (ngSubmit)="onSave()">
  <mat-dialog-content>
    <div class="form-group">
      <!-- Description Field -->
      <mat-form-field appearance="fill" class="full-width">
        <mat-label>Department</mat-label>
        <input matInput formControlName="description" placeholder="Enter Department Name" />
        <mat-hint align="end">{{ departmentForm.get('description')?.value?.length || 0 }}/500</mat-hint>

         <!-- Required error message -->
        <mat-error *ngIf="departmentForm.get('description')?.hasError('required')">
          Department Name is required
        </mat-error>

        <mat-error *ngIf="departmentForm.get('description')?.hasError('maxlength')">
          Department Name cannot exceed 500 characters
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill" class="full-width">
        <mat-label>Department Code</mat-label>
        <input matInput formControlName="code" placeholder="Enter Code" />
        <mat-hint align="end">{{ departmentForm.get('code')?.value?.length || 0 }}/50</mat-hint>

        <mat-error *ngIf="departmentForm.get('code')?.hasError('maxlength')">
          Department Name cannot exceed 50 characters
        </mat-error>
      </mat-form-field>

    </div>
  </mat-dialog-content>

  <hr />
  <mat-dialog-actions align="end">
    <button mat-button type="button" (click)="onCancel()">Cancel</button>
    <button mat-flat-button color="primary" type="submit" [disabled]="departmentForm.invalid || isSaving">
      {{ editMode ? 'Update' : 'Create' }}
    </button>
  </mat-dialog-actions>
</form>
