import { NgFor, NgIf } from '@angular/common';
import { Component, inject, Inject } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatOptionModule } from '@angular/material/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { ToastrService } from 'ngx-toastr';
import { AttributeListingStore } from '../store/attributelistingstore';
import { IItemAttribute } from '../../../../core/models/IItemAttribute';

@Component({
  selector: 'app-attribute-dialog',
  standalone: true,
  imports: [ReactiveFormsModule,
    MatFormFieldModule,
    MatOptionModule,
    MatInputModule,
    MatSelectModule,
    MatCheckboxModule,
    MatButtonModule,
    MatDialogModule,
    NgIf],
  templateUrl: './attribute-dialog.component.html',
  styleUrl: './attribute-dialog.component.scss'
})
export class AttributeDialogComponent {
  attributeForm: FormGroup;
  editMode: boolean;
  isSaving = false;

  store = inject(AttributeListingStore);
  toastrService = inject(ToastrService);

  constructor(
    public dialogRef: MatDialogRef<AttributeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { attribute: IItemAttribute; editMode: boolean },
    private fb: FormBuilder
  ) {
    this.editMode = data.editMode;

    this.attributeForm = this.fb.group({
      attributeName: [data.attribute.attributeName, [Validators.required, Validators.maxLength(500)]],
      description: [data.attribute.description || '', Validators.maxLength(1500)],
    });
  }

  onSave(): void {
    if (this.attributeForm.valid && !this.isSaving) {
      this.isSaving = true; // Prevent double-clicks

      const updatedAttribute: IItemAttribute = {
        ...this.data.attribute,
        ...this.attributeForm.value,
        itemTypeGroupId: this.data.attribute.itemTypeGroupId // Ensure itemTypeGroupId is included
      };

      if (this.store.isDuplicateAttribute(updatedAttribute)) {
        this.toastrService.error('Attribute name already exists', 'Error');
        this.isSaving = false;
      } else if (this.editMode) {
        this.store.updateAttribute(updatedAttribute).subscribe({
          next: () => {
            this.toastrService.success('Attribute updated successfully', 'Success');
            this.dialogRef.close(updatedAttribute);
            this.isSaving = false; // Reset after success
          },
          error: (error) => {
            if (error.status === 500) {
              this.toastrService.error('Failed to update Attribute', 'Error');
            }
            else {
              this.toastrService.error(error.error);
            }
            this.isSaving = false;
          }
        });
      } else {
        // Create mode: call create method in the store
        this.store.createAttribute(updatedAttribute).subscribe({
          next: (newAttribute) => {
            this.toastrService.success('Attribute created successfully', 'Success');
            this.dialogRef.close(newAttribute);
            this.isSaving = false;
          },
          error: (error) => {
            if (error.status === 500) {
              this.toastrService.error('Failed to create Attribute', 'Error');
            }
            else {
              this.toastrService.error(error.error);
            }
              this.isSaving = false;
          }
        });
      }
    }
  }

  onCancel(): void {
    this.dialogRef.close();
  }
}
