import { NgFor, NgIf } from '@angular/common';
import { ChangeDetectorRef, Component, inject, OnInit } from '@angular/core';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ConstructionCategoryStore } from '../store/constructionstore';
import { IConstruction } from '../../../../core/models/IConstruction';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import { ConstructionDialogComponent } from '../construction-dialog/construction-dialog.component';

@Component({
  selector: 'app-construction-list',
  standalone: true,
  imports: [TabsModule, NgIf, NgFor],
  templateUrl: './construction-list.component.html',
  styleUrl: './construction-list.component.scss'
})
export class ConstructionListComponent implements OnInit {

  store = inject(ConstructionCategoryStore);
  toastrService = inject(ToastrService);
  dialog = inject(MatDialog);

  ngOnInit() {
  }

  // Method to handle tab click and update selected RegionId
  handleTabClick(regionId: number, regionDescription: string, regionClass: string) {
    this.store.setSelectedRegionId(regionId);
    this.logCurrentState(); // Log the current state for debugging
  }

  // Handle Item Type Group click from the parent component
  handleGroupClick(itemTypeGroupId: number) {
    this.store.setSelectedItemTypeGroupId(itemTypeGroupId);
    this.logCurrentState(); // Log the current state for debugging
  }

  // Log the current state for debugging
  logCurrentState() {
    console.log('Current State:', {
      selectedItemTypeGroupId: this.store.selectedItemTypeGroupId(),
      selectedRegionId: this.store.selectedRegionId(),
      filteredConstructions: this.store.filteredList(),
    });
  }

  // Open the dialog to create or edit construction
  openConstructionDialog(editMode: boolean, construction?: IConstruction) {
    const regionId = this.store.selectedRegionId();
    const itemTypeGroupId = this.store.selectedItemTypeGroupId();

    const dialogRef = this.dialog.open(ConstructionDialogComponent, {
      width: '600px',
      data: {
        construction: construction ?? { id: 0, description: '', constructionCategoryId: null, includeInCustomsDescription: false, regionId, itemTypeGroupId },
        editMode
      }
    });

    dialogRef.afterClosed().subscribe((result: IConstruction) => {
      if (result) {
        if (editMode) {
          this.store.patchConstruction(result);
        } else {
          this.store.addConstruction(result);
        }
      }
    });
  }

  createConstruction() {
    this.openConstructionDialog(false);
  }

  editConstructionDescription(construction: IConstruction) {
    this.openConstructionDialog(true, construction);
  }

  getTruncatedDescription(construction: IConstruction) {
    const charLimit = construction.clarifyingInformation ? 25 : 35;
    return construction.description.length > charLimit
      ? construction.description.slice(0, charLimit) + '...'
      : construction.description;
  }
}
