<lib-secondary-navbar
[title]="'sidebar.myorders' | translate"
></lib-secondary-navbar>
<mat-card>
  <mat-card-content>
      <app-aggrid-orders-server-side-row-model
      [includeSelectLink]="true"
      [routingSelectLinkPath]="ORDERDETAILROUTEPREFIX"
      [fieldsToInitiallyShowDetailRow]="fieldsToInitiallyShowInGridRow"
      [fieldsToExcludeFromGridDetailRow]="fieldsToExcludeFromInGridRow"
      [excludeIdFieldsFromGridDetailRow]="true"
      [gridHeight]="650"
      [showGridLoading]="showGridLoadingIndicator"
      >
      </app-aggrid-orders-server-side-row-model>
  </mat-card-content>
</mat-card>
