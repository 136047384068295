<div class="container-fluid">
  <div style="display: flex; justify-content: space-between; align-items: center;">
    <h6>Harmonized System (HS) Sections:</h6>
  </div>
  <table class="table table-striped table-hover">
    <thead>
      <tr>
        <th>Description</th>
        <th>Status</th>
      </tr>
    </thead>
    <tbody>
      <!-- Access signal values directly in the *ngFor loop -->
      <tr *ngFor="let section of filteredSections()">
        <td>{{ section.title }}</td>
        <td style="text-align: center">
          <ui-switch
            [checked]="section.active"
            (change)="onToggleActive(section)"
          >
          </ui-switch>
        </td>
      </tr>
    </tbody>
  </table>
</div>
