import { NgFor, NgIf } from '@angular/common';
import { ChangeDetectorRef, Component, inject, OnInit } from '@angular/core';
import { ConstructionCategoryStore } from '../store/constructionstore';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import { IConstructionCategory } from '../../../../core/models/IConstructionCategory';
import { ConstructionCategoryDialogComponent } from '../construction-category-dialog/construction-category-dialog.component';

@Component({
  selector: 'app-construction-category-list',
  standalone: true,
  imports: [NgFor],
  templateUrl: './construction-category-list.component.html',
  styleUrl: './construction-category-list.component.scss'
})
export class ConstructionCategoryListComponent implements OnInit {

  store = inject(ConstructionCategoryStore);
  toastrService = inject(ToastrService);
  dialog = inject(MatDialog);

  ngOnInit() {
  }

  openConstructionCategoryDialog(editMode: boolean, constructionCategory?: IConstructionCategory) {
    const dialogRef = this.dialog.open(ConstructionCategoryDialogComponent, {
      width: '600px',
      data: {
        constructionCategory: constructionCategory ?? { id: 0, description: '' },
        editMode
      }
    });

    dialogRef.afterClosed().subscribe((result: IConstructionCategory) => {
      if (result) {
        if (editMode) {
          this.store.patchCategory(result);
        } else {
          this.store.addCategory(result);
        }
      }
    });
  }


  createConstructionCategory() {
    this.openConstructionCategoryDialog(false);
  }

  editConstructionCategoryDescription(constructionCategory: IConstructionCategory) {
    this.openConstructionCategoryDialog(true, constructionCategory);
  }
}
