<form [formGroup]="vendorDetailForm">
    <mat-card>
        <mat-card-header>
           <div class="card-header-container">
                <div>
                    <mat-card-title>{{title}}</mat-card-title>
                </div>
                <div class="mt-1">
                    <mat-slide-toggle #editModeSlideToggle *ngIf="updateVendorMode" [checked]="!updateVendorReadOnlyMode" (click)="editFormSlideToggleChange(editModeSlideToggle)"><b>Edit Form</b></mat-slide-toggle>
                </div>
            </div>
        </mat-card-header>
        <mat-card-content>

            <div *ngIf="showMainFormCentralLoadingSpinner || this.showSaveLoadingSpinner" class="mat-spinner-container">
                <mat-spinner></mat-spinner>
            </div>

            <div *ngIf="!showMainFormCentralLoadingSpinner && !this.showSaveLoadingSpinner" class="ms-1 mt-2">
                <ul class="nav nav-pills" id="pills-tab" role="tablist">
                    <li class="nav-item" role="presentation">
                        <button class="nav-link active" [ngClass]="{ 'active':selectedTabIndex==0}" id="pills-first-create-update-vendor-tab" data-bs-toggle="pill" data-bs-target="#pills-first-create-update-vendor-tab-content" type="button" role="tab" data-index=0 (click)="changeActiveTab($event)">
                            Company Name, Address
                        </button>
                    </li>
                    <li class="nav-item" role="presentation">
                        <button class="nav-link" [ngClass]="{ 'active':selectedTabIndex==1}" id="pills-second-create-update-vendor-tab" data-bs-toggle="pill" data-bs-target="#pills-second-create-update-vendor-tab-content" type="button" role="tab" data-index=1 (click)="changeActiveTab($event)">
                            Other vendor details
                        </button>
                    </li>
                </ul>
                <div *ngIf="showValidationRejectionAlertMessage && !showMainFormCentralLoadingSpinner && !this.showSaveLoadingSpinner" class="alert alert-danger mt-2" role="alert">
                   {{validationRejectionAlertMessage}}
                </div>
                <div class="tab-content" id="pills-tabContent">
                    <div class="tab-pane fade"  [ngClass]="{ 'show active':selectedTabIndex==0}" id="pills-first-create-update-vendor-tab-content" role="tabpanel" aria-labelledby="pills-first-create-update-vendor-tab">

                        <div class="row">
                            <div class="col pt-3">
                                <app-reactive-form-control-input
                            [inputElementReadOnly]="updateVendorReadOnlyMode"
                                [reactiveFormControlName]="'companyName'"
                                [inputElementID]="'companyNameInput'"
                                [inputElementLabel]="'Company Name'"
                                [inputElementType]="'text'"
                                [reactiveFormGroup]="this.vendorDetailForm"
                                (reactiveFormGroupChange)="setVendorDetailFormGroup($any($event))"
                                ></app-reactive-form-control-input>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <app-reactive-form-control-input
                                [inputElementReadOnly]="updateVendorReadOnlyMode"
                                [reactiveFormControlName]="'streetAddress'"
                                [inputElementID]="'streetAddressInput'"
                                [inputElementLabel]="'Street Address'"
                                [inputElementType]="'text'"
                                [reactiveFormGroup]="this.vendorDetailForm"
                                (reactiveFormGroupChange)="setVendorDetailFormGroup($any($event))"
                                ></app-reactive-form-control-input>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4 col-sm-12">
                                <app-reactive-form-control-input
                                [inputElementReadOnly]="updateVendorReadOnlyMode"
                                [reactiveFormControlName]="'city'"
                                [inputElementID]="'cityInput'"
                                [inputElementLabel]="'City'"
                                [inputElementType]="'text'"
                                [reactiveFormGroup]="this.vendorDetailForm"
                                (reactiveFormGroupChange)="setVendorDetailFormGroup($any($event))"
                                ></app-reactive-form-control-input>
                            </div>
                            <div class="col-md-4 col-sm-12">
                                <app-reactive-form-control-input
                                [inputElementReadOnly]="updateVendorReadOnlyMode"
                                [reactiveFormControlName]="'state'"
                                [inputElementID]="'stateInput'"
                                [inputElementLabel]="'State'"
                                [inputElementType]="'text'"
                                [reactiveFormGroup]="this.vendorDetailForm"
                                (reactiveFormGroupChange)="setVendorDetailFormGroup($any($event))"
                                ></app-reactive-form-control-input>
                            </div>
                            <div class="col-md-4 col-sm-12">
                                <app-reactive-form-control-input
                                [inputElementReadOnly]="updateVendorReadOnlyMode"
                                [reactiveFormControlName]="'postalCode'"
                                [inputElementID]="'postalCodeInput'"
                                [inputElementLabel]="'Postal Code'"
                                [inputElementType]="'text'"
                                [reactiveFormGroup]="this.vendorDetailForm"
                                (reactiveFormGroupChange)="setVendorDetailFormGroup($any($event))"
                                ></app-reactive-form-control-input>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <app-reactive-form-control-input
                                [inputElementReadOnly]="updateVendorReadOnlyMode"
                                [reactiveFormControlName]="'country'"
                                [inputElementID]="'countryInput'"
                                [inputElementLabel]="'Country'"
                                [inputElementType]="'text'"
                                [reactiveFormGroup]="this.vendorDetailForm"
                                (reactiveFormGroupChange)="setVendorDetailFormGroup($any($event))"
                                ></app-reactive-form-control-input>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade"  [ngClass]="{ 'show active':selectedTabIndex==1}" id="pills-second-create-update-vendor-tab-content" role="tabpanel" aria-labelledby="pills-second-create-update-vendor-tab">
                        <div class="row pt-3">
                            <div class="col-md-6 col-sm-12" >
                                <app-reactive-form-control-input
                                    [inputElementReadOnly]="updateVendorReadOnlyMode"
                                        [reactiveFormControlName]="'email'"
                                        [inputElementID]="'emailInput'"
                                        [inputElementLabel]="'Email'"
                                        [inputElementType]="'text'"
                                        [reactiveFormGroup]="this.vendorDetailForm"
                                        (reactiveFormGroupChange)="setVendorDetailFormGroup($any($event))"
                                    ></app-reactive-form-control-input>
                            </div>
                            <div class="col-md-6 col-sm-12">
                                <app-reactive-form-control-input
                                    [inputElementReadOnly]="updateVendorReadOnlyMode"
                                    [reactiveFormControlName]="'region'"
                                    [inputElementID]="'regionInput'"
                                    [inputElementLabel]="'Region'"
                                    [inputElementType]="'select'"
                                    [inputElementSelectItems]="$any(regionStore.regions())"
                                    [inputElementSelectItemLabelPropertyName]="'description'"
                                    [inputElementSelectCompareWithFunc]="regionComparisonFunction"
                                    [reactiveFormGroup]="this.vendorDetailForm"
                                    (reactiveFormGroupChange)="setVendorDetailFormGroup($any($event))"
                                ></app-reactive-form-control-input>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6 col-sm-12">
                                    <app-reactive-form-control-input
                                [inputElementReadOnly]="updateVendorReadOnlyMode"
                                    [reactiveFormControlName]="'administratorName'"
                                    [inputElementID]="'administratorNameInput'"
                                    [inputElementLabel]="'Administrator Name'"
                                    [inputElementType]="'text'"
                                    [reactiveFormGroup]="this.vendorDetailForm"
                                    (reactiveFormGroupChange)="setVendorDetailFormGroup($any($event))"
                                ></app-reactive-form-control-input>
                            </div>
                            <div class="col-md-6 col-sm-12">
                                <app-reactive-form-control-input
                            [inputElementReadOnly]="updateVendorReadOnlyMode"
                                            [reactiveFormControlName]="'phoneNumber'"
                                            [inputElementID]="'phoneNumberInput'"
                                            [inputElementLabel]="'Phone Number'"
                                            [inputElementType]="'text'"
                                            [reactiveFormGroup]="this.vendorDetailForm"
                                            (reactiveFormGroupChange)="setVendorDetailFormGroup($any($event))"
                                        ></app-reactive-form-control-input>
                            </div>
                        </div>
                        <div class="row">
                          <div class="col-md-6 col-sm-12">
                            <app-reactive-form-control-input
                        [inputElementReadOnly]="updateVendorReadOnlyMode"
                                        [reactiveFormControlName]="'vendorIdentifier'"
                                        [inputElementID]="'vendorIdentifierInput'"
                                        [inputElementLabel]="'Vendor Identifier'"
                                        [inputElementType]="'text'"
                                        [reactiveFormGroup]="this.vendorDetailForm"
                                        (reactiveFormGroupChange)="setVendorDetailFormGroup($any($event))"
                                    ></app-reactive-form-control-input>
                        </div>
                      </div>
                        <mat-divider></mat-divider>
                        <div class="row pt-4">
                            <div class="col pb-5">
                                <app-reactive-form-control-input
                            [inputElementReadOnly]="updateVendorReadOnlyMode"
                                [reactiveFormControlName]="'status'"
                                [inputElementID]="'statusSwitch'"
                                [inputElementLabel]="'Active'"
                                [inputElementType]="'SLIDETOGGLE'"
                                [reactiveFormGroup]="this.vendorDetailForm"
                                (reactiveFormGroupChange)="setVendorDetailFormGroup($any($event))"
                                ></app-reactive-form-control-input>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </mat-card-content>
        <mat-card-actions align="end">
            <div *ngIf="showCancelButton && !this.showSaveLoadingSpinner && !updateVendorReadOnlyMode" class="mx-3">
                <a href="javascript:void(0)" (click)="cancelBtnClickEvent()">Cancel</a>
            </div>
            <app-button-with-mat-spinner
            *ngIf="(selectedTabIndex > 0) && !this.showSaveLoadingSpinner"
            (click)="onTabBackButtonClicked()"
            [label]="'Back'"
            class="me-2"
            ></app-button-with-mat-spinner>
            <app-button-with-mat-spinner
            *ngIf="(selectedTabIndex < (NUMBEROFTABS-1)) && !this.showSaveLoadingSpinner"
            (click)="onTabNextButtonClicked()"
            [label]="'Next'"
            class="me-2"
            ></app-button-with-mat-spinner>
            <app-button-with-mat-spinner
            *ngIf="!updateVendorReadOnlyMode && (selectedTabIndex == (NUMBEROFTABS-1))"
            (click)="submitVendorDetailForm()"
            [disabled]="!this.vendorDetailForm.dirty || !this.vendorDetailForm.valid || this.showSaveLoadingSpinner"
            [label]="'Save'"
            [showSpinner]="this.showSaveLoadingSpinner"
            class="me-2"
            ></app-button-with-mat-spinner>
        </mat-card-actions>
</mat-card>


</form>
