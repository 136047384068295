import { CommonModule, NgFor } from '@angular/common';
import { Component, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { UiSwitchModule } from 'ngx-ui-switch';
import { ClientStore } from '../store/client.store';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import { debounceTime, Subject } from 'rxjs';
import { ClientDialogComponent } from '../client-dialog/client-dialog.component';
import { IClient } from '../../../core/models/IClient';

@Component({
  selector: 'app-client-list',
  standalone: true,
  imports: [CommonModule, NgFor, FormsModule, UiSwitchModule],
  templateUrl: './client-list.component.html',
  styleUrl: './client-list.component.scss',
  providers: [
    {
      provide: UiSwitchModule,
      useFactory: () => UiSwitchModule.forRoot({
        size: 'medium',
        color: 'rgb(0, 189, 99)',
        switchColor: '#3f4d97',
        defaultBgColor: '#cfe2ff',
        defaultBoColor: '#476EFF',
        checkedLabel: 'on',
        uncheckedLabel: 'off'
      })
    }
  ]
})
export class ClientListComponent {

  store = inject(ClientStore);
  toastrService = inject(ToastrService);
  dialog = inject(MatDialog);

  // Subject for search input with debounce
  private searchSubject = new Subject<string>();
  searchTerm: string = '';

  ngOnInit() {
    this.store.loadClients();

    // Subscribe to search input changes with debounce
    this.searchSubject.pipe(
      debounceTime(300) // Adjust the debounce time as needed
    ).subscribe((term) => {
      this.store.setSearchTerm(term);
    });
  }

  onToggleActive(client: IClient) {
    const newStatus = !client.active; // Toggle the active status
    // Optimistically update the UI to show the toggled state, but revert on error
    this.store.updateClient({ ...client, active: newStatus }).subscribe({
      next: () => {
        this.toastrService.success('Client updated successfully', 'Success');
        this.store.patchClient({ ...client, active: newStatus }); // Update state with new status
      },
      error: () => {
        this.toastrService.error('Failed to update client status', 'Error');
        // No need to toggle in store here, since we only update on success
      }
    });
  }


  forceRefresh() {
    this.store.clearCache();
    this.store.loadClients();
    this.toastrService.info('Data has been refreshed', 'Refreshed');
  }

  onSearchTermChange(term: string) {
    this.searchSubject.next(term);
  }

  // New method to reset filters and search
  resetFilters() {
    this.searchTerm = ''; // Clear the search input
    this.store.setSearchTerm(''); // Explicitly update the store's searchTerm
    this.toastrService.info('Filters have been reset', 'Reset'); // Optional: Notify the user
  }

  openDialog(editMode: boolean, client?: IClient) {
    const dialogRef = this.dialog.open(ClientDialogComponent, {
      width: '820px', // Make sure the width is appropriate
      data: { client: client ?? { id: 0, clientName: '', active: true }, editMode },
    });

    // dialogRef.afterClosed().subscribe((result: IClient) => {
    //   if (result) {
    //     if (editMode) {
    //       this.store.updateClient(result).subscribe(() => {
    //         this.store.patchClient(result);
    //       });
    //     } else {
    //       this.store.createClient(result).subscribe((newItem) => {
    //         this.store.addClient(newItem);
    //       });
    //     }
    //   }
    // });

    dialogRef.afterClosed().subscribe((result: IClient) => {
      if (result && editMode) {
        this.store.updateClient(result).subscribe(() => {
          this.store.patchClient(result);
        });
      }
    });
  }


  createClient() {
    this.openDialog(false);
  }

  editClient(client: IClient) {
    this.openDialog(true, client);
  }
}
