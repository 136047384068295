import { Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { ClientsComponent } from './clients.component';

export const clientRoutes: Routes = [
  {
    path: 'clients',
    component: ClientsComponent,
    canActivate: [MsalGuard],
  }
];
