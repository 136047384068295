<ul  *ngIf="this.objectToDisplay()" class="list-group">
    <ng-container *ngFor="let orderProp of sliceOrderObjProps(objectToDisplay()!,objectPropertyIndexStart()!,objectPropertyIndexEnd()!)|keyvalue:preserveOriginalOrder">
        <li *ngIf="!excludeProperty(orderProp.key)" class="list-group-item" >
            <div class="row">
                <div class="col-6">
                    <label>{{convertPropNameToTitleCaseWithSpaces(orderProp.key)|titlecase}}:</label>
                </div>
                <div class="col-6">
                    @if (isBoolean(orderProp.value)) {
                        <mat-slide-toggle [disabled]="true" [checked]="orderProp.value"></mat-slide-toggle>
                    }
                    @else if (orderProp.key.toUpperCase().includes('DATE')) {
                        <span>{{formatDateTime(orderProp.value)}}</span>
                    } @else {
                        <span>{{orderProp.value}}</span>
                    }
                </div>
            </div>
        </li>
    </ng-container>
    
</ul>
