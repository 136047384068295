import { Component, input, output } from '@angular/core';
import { IInboxItem } from '../../../core/models/IInboxItem';
import { DateAgoPipe } from '../../../core/pipes/date-ago.pipe';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-inbox-item',
  standalone: true,
  imports: [DateAgoPipe, NgIf],
  templateUrl: './inbox-item.component.html',
  styleUrl: './inbox-item.component.scss'
})
export class InboxItemComponent {
  item = input.required<IInboxItem>();
  select = output<void>();

  get itemClasses() {
    return {
      'text-primary-emphasis': this.item().unread && !this.item().selected,
      'fw-semibold': this.item().unread && !this.item().selected,
    }
  }
}
