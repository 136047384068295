import { Injectable, signal } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ExcelFileAttachBulkEntityLoadService {
  baseUrl = environment.apiUrl;

  guidelinesRegionLoadingSignal = signal<boolean>(false);

  constructor(private httpClient:HttpClient) { }

  checkFileExtensionFromInputTypeFile(file:File,validFileExtensions:string[]) {
    var fileExtension = file.name.substring(file.name.lastIndexOf('.'));
    if(fileExtension && validFileExtensions.includes(fileExtension)) {
      return true;
    }
    else {
      return false;
    }
  }

  uploadFileViaPost(file:File,endpointUrlSuffix:string) {
      let formToPost = new FormData();
      formToPost.append('file',file,file.name);
      return this.httpClient.post(this.baseUrl + "/api/" + endpointUrlSuffix, formToPost)
  }
}
