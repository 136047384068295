import { NgFor } from '@angular/common';
import { Component, inject } from '@angular/core';
import { UiSwitchComponent, UiSwitchModule } from 'ngx-ui-switch';
import { SectionChapterHeadingStore } from '../store/sectionchapterstore';
import { ToastrService } from 'ngx-toastr';
import { IChapter } from '../../../../core/models/IChapter';

@Component({
  selector: 'app-chapter-list',
  standalone: true,
  imports: [NgFor, UiSwitchModule],
  templateUrl: './chapter-list.component.html',
  styleUrl: './chapter-list.component.scss',
  providers: [
    {
      provide: UiSwitchModule,
      useFactory: () => UiSwitchModule.forRoot({
        size: 'medium',
        color: 'rgb(0, 189, 99)',
        switchColor: '#3f4d97',
        defaultBgColor: '#cfe2ff',
        defaultBoColor: '#476EFF',
        checkedLabel: 'on',
        uncheckedLabel: 'off'
      })
    }
  ]
})
export class ChapterListComponent {
  store = inject(SectionChapterHeadingStore);
  toastrService = inject(ToastrService);

  // Access filtered list directly from the store
  filteredChapters = this.store.filteredList;

  ngOnInit() {
  }

  onToggleActive(chapter: IChapter) {
    // Optimistically toggle the chapter's active status in the store
    const newStatus = !chapter.active; // Explicitly toggle active status
    this.store.toggleActiveChapter(chapter.id);

    // Call the service with the new status
    this.store.updateChapterStatus({ ...chapter, active: newStatus }).subscribe({
      next: () => {
        this.toastrService.success('Chapter updated successfully', 'Success');
      },
      error: () => {
        this.toastrService.error('Failed to update Chapter', 'Error');
        // Revert the change in the store
        this.store.toggleActiveChapter(chapter.id);
      }
    });
  }

}
