<form [formGroup]="userDetailForm">
    <mat-card>
        <mat-card-header>
            <div class="card-header-container">
                <div>
                    <mat-card-title>{{title}}</mat-card-title>
                </div>
                <div class="mt-1">
                    <mat-slide-toggle *ngIf="updateVendorUserMode"  #editModeSlideToggle [checked]="!updateVendorUserReadOnlyMode" (change)="editFormSlideToggleChange(editModeSlideToggle)"><b>Edit Form</b></mat-slide-toggle>
                </div>
            </div>
        </mat-card-header>
        <mat-card-content [ngClass]="{'mat-card-content-dialog-only': !updateVendorUserMode}">

            <div *ngIf="showMainFormCentralLoadingSpinner || this.showSaveLoadingSpinner" class="mat-spinner-container">
                <mat-spinner></mat-spinner>
            </div>
            <div *ngIf="showValidationRejectionAlertMessage && !showMainFormCentralLoadingSpinner && !this.showSaveLoadingSpinner" class="alert alert-danger mt-2" role="alert">
                {{validationRejectionAlertMessage}}
             </div>
            <ng-container *ngIf="!showMainFormCentralLoadingSpinner && !this.showSaveLoadingSpinner">
                <div class="row pt-4">
                    <div class="col-md-6 col-sm-12">
                        <app-reactive-form-control-input
                            [inputElementReadOnly]="updateVendorUserReadOnlyMode"
                            [reactiveFormControlName]="'firstName'"
                            [inputElementLabel]="'First Name'"
                            [inputElementType]="'text'"
                            [reactiveFormGroup]="this.userDetailForm"
                            (reactiveFormGroupChange)="setUserDetailFormGroup($any($event))"
                        ></app-reactive-form-control-input>
                    </div>
                    <div class="col-md-6 col-sm-12">
                        <app-reactive-form-control-input
                        [inputElementReadOnly]="updateVendorUserReadOnlyMode"
                        [reactiveFormControlName]="'lastName'"
                        [inputElementLabel]="'Last Name'"
                        [inputElementType]="'text'"
                        [reactiveFormGroup]="this.userDetailForm"
                        (reactiveFormGroupChange)="setUserDetailFormGroup($any($event))"
                        ></app-reactive-form-control-input>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <app-reactive-form-control-input
                        [inputElementReadOnly]="editMode"
                        [reactiveFormControlName]="'email'"
                        [inputElementLabel]="'Email'"
                        [inputElementType]="'text'"
                        [reactiveFormGroup]="this.userDetailForm"
                        (reactiveFormGroupChange)="setUserDetailFormGroup($any($event))"
                    ></app-reactive-form-control-input>
                    </div>
                </div>
                <mat-divider></mat-divider>
                <div class="pt-4 row">
                    <div class="pb-3">
                        <app-reactive-form-control-input
                        [inputElementReadOnly]="updateVendorUserReadOnlyMode"
                        [reactiveFormControlName]="'active'"
                        [inputElementID]="'activeSwitch'"
                        [inputElementLabel]="'Active'"
                        [inputElementType]="'SLIDETOGGLE'"
                        [reactiveFormGroup]="this.userDetailForm"
                        (reactiveFormGroupChange)="setUserDetailFormGroup($any($event))"
                        ></app-reactive-form-control-input>
                    </div>
                    <div class="py-3">
                        <app-reactive-form-control-input
                        [inputElementReadOnly]="updateVendorUserReadOnlyMode"
                        [reactiveFormControlName]="'readOnlyPermission'"
                        [inputElementID]="'readOnlyPermissionSwitch'"
                        [inputElementLabel]="'Read Only Permission'"
                        [inputElementType]="'SLIDETOGGLE'"
                        [reactiveFormGroup]="this.userDetailForm"
                        (reactiveFormGroupChange)="setUserDetailFormGroup($any($event))"
                        ></app-reactive-form-control-input>
                    </div>
                </div>
            </ng-container>
        </mat-card-content>
        <mat-card-actions align="end">
            <div *ngIf="showCancelButton && !this.showSaveLoadingSpinner && !updateVendorUserReadOnlyMode" class="mx-3">
                <a (click)="cancelBtnClickEvent()" href="javascript:void(0)" >Cancel</a>
            </div>
            <app-button-with-mat-spinner
            *ngIf="!updateVendorUserReadOnlyMode"
            (click)="submitUserDetailForm()"
            [disabled]="!this.userDetailForm.dirty || !this.userDetailForm.valid || this.showSaveLoadingSpinner"
            [label]="'Save'"
            [showSpinner]="this.showSaveLoadingSpinner"
            class="me-4"
            ></app-button-with-mat-spinner>
        </mat-card-actions>
    </mat-card>    
</form>


