import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { debounceTime, Subject } from 'rxjs';
import { ChapterListComponent } from "./chapter-list/chapter-list.component";
import { SectionListComponent } from "./section-list/section-list.component";
import { SectionChapterHeadingStore } from './store/sectionchapterstore';
import { HeadingListComponent } from './heading-list/heading-list.component';

@Component({
  selector: 'app-section-chapter-mgmt',
  standalone: true,
  imports: [FormsModule, CommonModule, ChapterListComponent, SectionListComponent, HeadingListComponent],
  templateUrl: './section-chapter-mgmt.component.html',
  styleUrl: './section-chapter-mgmt.component.scss'
})
export class SectionChapterMgmtComponent {
  readonly SECTIONS: 'sections' = 'sections';
  readonly CHAPTERS: 'chapters' = 'chapters';
  readonly HEADINGS: 'headings' = 'headings';

  selectedItem: 'sections' | 'chapters' | 'headings' = this.SECTIONS; // Default to 'sections'
  searchTerm: string = '';

  store = inject(SectionChapterHeadingStore);
  toastrService = inject(ToastrService);

  private searchSubject = new Subject<string>();

  ngOnInit() {
    this.store.clearCache();
    this.store.loadData();
    this.store.setSearchTerm(this.searchTerm);
  }

  showTable(item: 'sections' | 'chapters' | 'headings'): void {
    this.selectedItem = item;
    this.store.showTable(item);
  }

  onSearchTermChange(term: string) {
    this.searchTerm = term;
    this.store.setSearchTerm(term);  // Update the store's search term
  }

  resetFilters() {
    this.searchTerm = '';
    this.store.setSearchTerm('');
    this.store.changeFilter('all'); // Reset the filter to show all
    this.toastrService.info('Filters have been reset', 'Reset');
  }

  forceRefresh() {
    this.store.clearCache();
    this.store.loadData();
    this.toastrService.info('Data has been refreshed', 'Refreshed');
  }
}
