import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ISection } from '../models/ISection';
import { IChapter } from '../models/IChapter';
import { IHeading } from '../models/IHeading';
import { environment } from '../../../environments/environment';
import { IItemType } from '../models/IItemType';

@Injectable({
  providedIn: 'root'
})
export class ItemTypeService {

  clientUrl = environment.apiUrl;

  constructor(private http: HttpClient) { }

  getAllSections(): Observable<ISection[]> {
    return this.http.get<ISection[]>(`${this.clientUrl}/api/section`);
  }

  getActiveSections(): Observable<ISection[]> {
    return this.http.get<ISection[]>(`${this.clientUrl}/api/section/active`);
  }

  getChaptersBySectionId(sectionId: number): Observable<IChapter[]> {
    return this.http.get<IChapter[]>(`${this.clientUrl}/api/chapter/section/${sectionId}`);
  }

  getHeadingsByChapterId(chapterId: number): Observable<IHeading[]> {
    return this.http.get<IHeading[]>(`${this.clientUrl}/api/heading/chapter/${chapterId}`);
  }

  getAllChapters(): Observable<IChapter[]> {
    return this.http.get<IChapter[]>(`${this.clientUrl}/api/chapter`);
  }

  getActiveChapters(): Observable<IChapter[]> {
    return this.http.get<IChapter[]>(`${this.clientUrl}/api/chapter/active`);
  }

  getAllHeadings(): Observable<IHeading[]> {
    return this.http.get<IHeading[]>(`${this.clientUrl}/api/heading`);
  }

  getActiveHeadings(): Observable<IHeading[]> {
    return this.http.get<IHeading[]>(`${this.clientUrl}/api/heading/active`);
  }

  // Updated to accept codeType
  getItemTypesByTariffCode(code: string, codeType: '6-digit' | '8-digit' | '10-digit'): Observable<any[]> {
    // Adjust the API endpoint or parameters based on codeType if necessary
    // Example using query parameters
    // let params = new HttpParams().set('tariffCode', code).set('type', codeType);
    let params = new HttpParams().set('tariffCode', code);
    return this.http.get<any[]>(`${this.clientUrl}/api/itemtype/${codeType}`);
  }

  getItemTypesByItemTypeGroupId(itemTypeGroupId: number): Observable<IItemType[]> {
    return this.http.get<IItemType[]>(`${this.clientUrl}/api/ItemType/${itemTypeGroupId}/itemtypes`);
  }

  getItemTypesByItemTypeGroupIdAndRegionId(itemTypeGroupId: number, regionId: number): Observable<IItemType[]> {
    return this.http.get<IItemType[]>(`${this.clientUrl}/api/ItemType/getByGroupAndRegionId?itemTypeGroupId=${itemTypeGroupId}&regionId=${regionId}`);
  }

  deleteItemType(itemTypeId: number): Observable<void> {
    return this.http.delete<void>(`${this.clientUrl}/api/itemtype/${itemTypeId}`);
  }

  // Toggle the active status of a chapter by ID
  toggleChapterStatus(id: number, isActive: boolean): Observable<any> {
    return this.http.put(`${this.clientUrl}/api/chapter/${id}/active`, isActive, {
        headers: {
            'Content-Type': 'application/json'
        }
    });
  }

  // Toggle the active status of a heading by ID
  toggleHeadingStatus(id: number, isActive: boolean): Observable<any> {
    return this.http.put(`${this.clientUrl}/api/heading/${id}/active`, isActive, {
        headers: {
            'Content-Type': 'application/json'
        }
    });
  }

  // Toggle the active status of a section by ID
  toggleSectionStatus(id: number, isActive: boolean): Observable<any> {
    return this.http.put(`${this.clientUrl}/api/section/${id}/active`, isActive, {
        headers: {
            'Content-Type': 'application/json'
        }
    });
  }
}

