import { CommonModule, NgFor } from '@angular/common';
import { Component, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ItemTypeGroupStore } from '../store/itemtype-group.store';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import { debounceTime, Subject } from 'rxjs';
import { IItemTypeGroup } from '../../../../core/models/IItemTypeGroup';
import { ItemTypeGroupDialogComponent } from '../item-type-group-dialog/item-type-group-dialog.component';

@Component({
  selector: 'app-group-constructions-list',
  standalone: true,
  imports: [CommonModule,
    FormsModule],
  templateUrl: './group-constructions-list.component.html',
  styleUrl: './group-constructions-list.component.scss'
})
export class GroupConstructionsListComponent {
  store = inject(ItemTypeGroupStore);
  toastrService = inject(ToastrService);
  dialog = inject(MatDialog);

  private searchSubject = new Subject<string>();
  searchTerm: string = '';

  ngOnInit() {
    this.store.loadData();
    this.searchSubject.pipe(
      debounceTime(300) // Adjust the debounce time as needed
    ).subscribe((term) => {
      this.store.setSearchTerm(term);
    });
  }

   forceRefresh() {
      this.store.clearCache();
      this.store.loadData();
      this.toastrService.info('Data has been refreshed', 'Refreshed');
    }

    onSearchTermChange(term: string) {
      this.searchSubject.next(term);
    }

    // New method to reset filters and search
    resetFilters() {
      this.searchTerm = ''; // Clear the search input
      this.store.setSearchTerm(''); // Explicitly update the store's searchTerm
      this.toastrService.info('Filters have been reset', 'Reset'); // Optional: Notify the user
    }

    openDialog(editMode: boolean, itemTypeGroup?: IItemTypeGroup) {
      const dialogRef = this.dialog.open(ItemTypeGroupDialogComponent, {
        width: '600px',
        data: {
          itemTypeGroup: itemTypeGroup ?? { id: 0, description: '', clarifyingInformation: '', active: true },
          editMode
        }
      });

      dialogRef.afterClosed().subscribe((result: IItemTypeGroup) => {
        if (result) {
          if (editMode) {
            this.store.patchItemTypeGroup(result); // Update the item in the store
          } else {
            this.store.addItemTypeGroup(result); // Add the new item to the store
          }

          // this.clearSystemStoreCaches();
          // this.updateSystemStores();
        }
      });
    }


    createItemTypeGroup() {
      this.openDialog(false);
    }

    editItemTypeGroup(itemTypeGroup: IItemTypeGroup) {
      this.openDialog(true, itemTypeGroup);
    }
}

