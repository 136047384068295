import { CommonModule, NgFor } from '@angular/common';
import { Component, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { SecondaryNavbarComponent } from '@tarifftel/components-library';
import { UiSwitchModule } from 'ngx-ui-switch';
import { DepartmentStore } from './store/departmentstore';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import { debounceTime, Subject } from 'rxjs';
import { IDepartment } from '../../../core/models/IDepartment';
import { DepartmentDialogComponent } from './department-dialog/department-dialog.component';

@Component({
  selector: 'app-department-mgmt',
  standalone: true,
  imports: [SecondaryNavbarComponent, CommonModule, NgFor, FormsModule, UiSwitchModule],
  templateUrl: './department-mgmt.component.html',
  styleUrls: ['./department-mgmt.component.scss'],
  providers: [
    {
      provide: UiSwitchModule,
      useFactory: () => UiSwitchModule.forRoot({
        size: 'medium',
        color: 'rgb(0, 189, 99)',
        switchColor: '#3f4d97',
        defaultBgColor: '#cfe2ff',
        defaultBoColor: '#476EFF',
        checkedLabel: 'on',
        uncheckedLabel: 'off'
      })
    }
  ]
})
export class DepartmentMgmtComponent {

  store = inject(DepartmentStore);
  toastrService = inject(ToastrService);
  dialog = inject(MatDialog);

  // Subject for search input with debounce
  private searchSubject = new Subject<string>();
  searchTerm: string = '';

  ngOnInit() {
    this.store.loadDepartments();

    // Subscribe to search input changes with debounce
    this.searchSubject.pipe(
      debounceTime(300) // Adjust the debounce time as needed
    ).subscribe((term) => {
      this.store.setSearchTerm(term);
    });
  }

  onToggleActive(department: IDepartment) {
    // Optimistically toggle the departments active status in the store
    const newStatus = !department.active; // Explicitly toggle active status
    this.store.toggleActiveDepartment(department.id);

    // Call the service with the new status
    this.store.updateDepartment({ ...department, active: newStatus }).subscribe({
      next: () => {
        this.toastrService.success('Department status updated', 'Success');},
      error: () => {
        this.toastrService.error('Failed to update Department', 'Error');
        // Revert the change in the store
        this.store.toggleActiveDepartment(department.id);
      }
    });
  }

  forceRefresh() {
    this.store.clearCache();
    this.store.loadDepartments();
    this.toastrService.info('Data has been refreshed', 'Refreshed');
  }

  onSearchTermChange(term: string) {
    this.searchSubject.next(term);
  }

  // New method to reset filters and search
  resetFilters() {
    this.searchTerm = ''; // Clear the search input
    this.store.setSearchTerm(''); // Explicitly update the store's searchTerm
    this.toastrService.info('Filters have been reset', 'Reset'); // Optional: Notify the user
  }

  openDialog(editMode: boolean, department?: IDepartment) {
    const dialogRef = this.dialog.open(DepartmentDialogComponent, {
      width: '600px',
      data: {
        department: department ?? { id: 0, description: '', code: '', active: true },
        editMode
      }
    });

    dialogRef.afterClosed().subscribe((result: IDepartment) => {
      if (result) {
        if (editMode) {
          this.store.patchDepartment(result); // Update the item in the store
        } else {
          this.store.addDepartment(result); // Add the new item to the store
        }
      }
    });
  }

  createDepartment() {
    this.openDialog(false);
  }

  editDepartment(department: IDepartment) {
    this.openDialog(true, department);
  }
}
