import { Component, EventEmitter, inject, Input, Output, SimpleChanges } from '@angular/core';
import { ITariffTelCode } from '../../../../core/models/ITariffTelCode';
import { TariffBookService } from '../../../../core/services/tariffbook.service';
import { signalStore } from '@ngrx/signals';
import { itemTypeStore } from '../store/itemtypestore';

@Component({
  selector: 'app-tarifftel-codes-by-heading',
  standalone: true,
  imports: [],
  templateUrl: './tarifftel-codes-by-heading.component.html',
  styleUrl: './tarifftel-codes-by-heading.component.scss'
})
export class TarifftelCodesByHeadingComponent {
  // @Output() selectedCode = new EventEmitter<string>();  // Output for the selected code
  // isLoading = false;

  // // Inject the store instead of the service
  // store = inject(itemTypeStore);

  // // Use computed properties to get the selected section, chapter, and heading from the store
  // selectedSection = this.store.selectedSectionId();
  // selectedChapter = this.store.selectedChapterId();
  // selectedHeading = this.store.selectedHeadingId();

  // tarifftelCodes = this.store.filteredList();  // Get the filtered TariffTel codes from the store

  ngOnInit(): void {
  }

  // Select a specific code and emit it
  // selectTariffTelCode(code: string): void {
  //   this.selectedCode.emit(code);
  // }
}
