<h2 mat-dialog-title>{{ editMode ? 'Edit Size Category' : 'Create Size Category' }}</h2>
<hr />
<form [formGroup]="sizeCategoryForm" (ngSubmit)="onSave()">
  <mat-dialog-content>
    <div class="form-group">


      <!-- Description Field -->
      <mat-form-field appearance="fill" class="full-width">
        <mat-label>Category Name</mat-label>
        <input matInput formControlName="categoryName" placeholder="Enter category name" maxlength="200" />

        <!-- Hint for guidance -->
        <mat-hint align="start">
          Defines a category for grouping related sizes by Item Type Group.
        </mat-hint>

        <!-- Character count -->
        <mat-hint align="end">
          {{ sizeCategoryForm.get('categoryName')?.value?.length || 0 }}/200
        </mat-hint>

        <!-- Required error message -->
        <mat-error *ngIf="sizeCategoryForm.get('categoryName')?.hasError('required')">
          Category Name is required
        </mat-error>

        <!-- Max length error message -->
        <mat-error *ngIf="sizeCategoryForm.get('categoryName')?.hasError('maxlength')">
          Category Name cannot exceed 200 characters
        </mat-error>
      </mat-form-field>
    </div>
  </mat-dialog-content>
  <hr />
  <div class="action-buttons">
    <mat-dialog-actions>
      <button mat-flat-button color="warn" type="button" (click)="openConfirmDeleteModal()"
        *ngIf="editMode">
        Delete
      </button>
    </mat-dialog-actions>
    <mat-dialog-actions>
      <button mat-button type="button" (click)="onCancel()">Cancel</button>
      <button mat-flat-button color="primary" type="submit" [disabled]="sizeCategoryForm.invalid  || isSaving">
        {{ editMode ? 'Update' : 'Create' }}
      </button>
    </mat-dialog-actions>
  </div>
</form>
