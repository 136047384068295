import { CommonModule, NgFor, NgIf } from '@angular/common';
import { Component, inject, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { ITenantSettings } from '../../../common/interfaces/ITenantSettings';
import { MatOptionModule } from '@angular/material/core';
import { MatTabsModule } from '@angular/material/tabs';
import { IItemTypeGroupAccessDto } from '../../../core/models/IItemTypeGroupAccessDto';
import { IRegionAccessDto } from '../../../core/models/IRegionAccessDto';
import { ClientStore } from '../store/client.store';
import { ToastrService } from 'ngx-toastr';
import { IClientAccessDto } from '../../../core/models/IClientAccessDto';
import { UiSwitchModule } from 'ngx-ui-switch';
import { IClient } from '../../../core/models/IClient';
import { AppSettingsService } from '@tarifftel/components-library';

@Component({
  selector: 'app-client-dialog',
  standalone: true,
  imports: [FormsModule,
    ReactiveFormsModule,
    CommonModule,
    MatFormFieldModule,
    MatOptionModule,
    MatInputModule,
    MatSlideToggleModule,
    MatTabsModule,
    MatButtonModule,
    MatDialogModule,
    UiSwitchModule,
    NgFor,
    NgIf],
  templateUrl: './client-dialog.component.html',
  styleUrl: './client-dialog.component.scss',
  providers: [
    {
      provide: UiSwitchModule,
      useFactory: () => UiSwitchModule.forRoot({
        size: 'medium',
        color: 'rgb(0, 189, 99)',
        switchColor: '#3f4d97',
        defaultBgColor: '#cfe2ff',
        defaultBoColor: '#476EFF',
        checkedLabel: 'on',
        uncheckedLabel: 'off'
      })
    }
  ]
})
export class ClientDialogComponent implements OnInit {
  clientForm!: FormGroup;
  itemTypeGroups: IItemTypeGroupAccessDto[] = [];
  regions: IRegionAccessDto[] = [];

  store = inject(ClientStore);
  toastrService = inject(ToastrService);
  appSettings = inject(AppSettingsService);

  editMode: boolean;
  selectedLogo: File | null = null;

  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<ClientDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { client: IClient; editMode: boolean }
  ) {
    this.editMode = data.editMode;
  }

  ngOnInit(): void {
    this.clientForm = this.fb.group({
      clientName: [this.data.client.clientName, [Validators.required, Validators.maxLength(1000)]],
      itemTypeGroups: this.fb.array([]),
      active: false,
      regions: this.fb.array([]),
      primaryBackgroundColor: [this.data.client.primaryBackgroundColor || '#FFFFFF'],
      primaryBackgroundHex: [this.data.client.primaryBackgroundColor || '#FFFFFF'],
      primaryBackgroundTextColor: [this.data.client.primaryBackgroundTextColor || '#FFFFFF'],
      primaryBackgroundTextHex: [this.data.client.primaryBackgroundTextColor || '#FFFFFF'],
      secondaryBackgroundColor: [this.data.client.secondaryBackgroundColor || '#000000'],
      secondaryBackgroundHex: [this.data.client.secondaryBackgroundColor || '#000000'],
      secondaryBackgroundTextColor: [this.data.client.secondaryBackgroundTextColor || '#000000'],
      secondaryBackgroundTextHex: [this.data.client.secondaryBackgroundTextColor || '#000000'],
      hoverColor: [this.data.client.hoverColor || '#c5c9ce'],
      hoverHex: [this.data.client.hoverColor || '#c5c9ce'],
      iconColor: [this.data.client.iconColor || '#FFFFFF'],
      iconHex: [this.data.client.iconColor || '#FFFFFF'],
      logoUrl: [this.data.client.logoUrl || '']
    });

    // Load access settings and initialize the FormArrays
    if (this.data.client.id !== 0) {

      console.log("Load Client Access Settings");

      this.appSettings.logo.set(this.data.client.logoUrl);
      this.appSettings.primaryBackgroundColor.set(this.data.client.primaryBackgroundColor);
      this.appSettings.secondaryBackgroundColor.set(this.data.client.secondaryBackgroundColor);
      this.appSettings.hoverColor.set(this.data.client.hoverColor);
      this.appSettings.iconColor.set(this.data.client.iconColor);

      this.store.loadClientAccessSettings(this.data.client.id).subscribe({
          next: (accessSettings) => {
            this.itemTypeGroups = accessSettings.itemTypeGroups;
            this.regions = accessSettings.regions;

            // Populate FormArrays for toggles
            this.populateFormArray('itemTypeGroups', this.itemTypeGroups);
            this.populateFormArray('regions', this.regions);
          },
          error: () => this.toastrService.error('Failed to load access settings', 'Error')
        });
    }

    // Update AppSettings on color/logo changes
    this.clientForm.get('primaryBackgroundColor')?.valueChanges.subscribe(value => {
      this.appSettings.primaryBackgroundColor.set(value);
    });
    this.clientForm.get('secondaryBackgroundColor')?.valueChanges.subscribe(value => {
      this.appSettings.secondaryBackgroundColor.set(value);
    });
    this.clientForm.get('hoverColor')?.valueChanges.subscribe(value => {
      this.appSettings.hoverColor.set(value);
    });
    this.clientForm.get('iconColor')?.valueChanges.subscribe(value => {
      this.appSettings.iconColor.set(value);
    });

    // Synchronize background color with HEX
    this.clientForm.get('primaryBackgroundColor')?.valueChanges.subscribe(color => {
      this.clientForm.get('primaryBackgroundHex')?.setValue(color, { emitEvent: false });
    });
    this.clientForm.get('primaryBackgroundColor')?.valueChanges.subscribe(hex => {
      this.clientForm.get('primaryBackgroundColor')?.setValue(hex, { emitEvent: false });
    });

    // Repeat the same for secondaryColor, hoverColor, and iconColor
    this.syncColors('secondaryBackgroundColor', 'secondaryBackgroundHex');
    this.syncColors('hoverColor', 'hoverHex');
    this.syncColors('iconColor', 'iconHex');
  }

  syncColors(colorControl: string, hexControl: string): void {
    this.clientForm.get(colorControl)?.valueChanges.subscribe(color => {
      this.clientForm.get(hexControl)?.setValue(color, { emitEvent: false });
    });
    this.clientForm.get(hexControl)?.valueChanges.subscribe(hex => {
      this.clientForm.get(colorControl)?.setValue(hex, { emitEvent: false });
    });

    if (this.data.client.id !== 0) {
      this.store.loadClientAccessSettings(this.data.client.id).subscribe({
        next: (accessSettings) => {
          this.itemTypeGroups = accessSettings.itemTypeGroups;
          this.regions = accessSettings.regions;
        },
        error: () => this.toastrService.error('Failed to load access settings', 'Error')
      });
    }
  }

  populateFormArray(arrayName: string, items: any[]) {
    const formArray = this.clientForm.get(arrayName) as FormArray;
    formArray.clear(); // Clear previous items to avoid duplication issues
    items.forEach(item => formArray.push(new FormControl(item.isClientActive)));
  }

  get itemTypeGroupsArray() {
    return this.clientForm.get('itemTypeGroups') as FormArray;
  }

  get regionsArray() {
    return this.clientForm.get('regions') as FormArray;
  }

  //this method is replaced by the one below. Will be changed once clarified
  onSaveReplaced(): void {
    const updatedAccessSettings: IClientAccessDto = {
      itemTypeGroups: this.itemTypeGroups.map((group, i) => ({
        ...group,
        isClientActive: this.itemTypeGroupsArray.at(i).value,
      })),
      regions: this.regions.map((region, i) => ({
        ...region,
        isClientActive: this.regionsArray.at(i).value,
      })),
    };

    this.store.updateClientAccessSettings(this.data.client.id, updatedAccessSettings).subscribe({
      next: () => {
        this.toastrService.success('Client access settings updated successfully', 'Success');
        this.dialogRef.close();
      },
      error: () => this.toastrService.error('Failed to update access settings', 'Error')
    });
  }

  onSave(): void {
    const updatedClient: IClient = {
      ...this.clientForm.value,
      id: this.data.client.id,  // Preserve existing ID for editing
    };

    if (this.editMode) {
      // Edit existing client
      this.store.updateClient(updatedClient).subscribe({
        next: () => {
          this.store.patchClient(updatedClient); // Ensure UI updates immediately
          this.toastrService.success('Client updated successfully', 'Success');
          this.dialogRef.close(updatedClient);
        },
        error: () => this.toastrService.error('Failed to update client', 'Error')
      });
    } else {
      // Create new client
      this.store.createClient(updatedClient).subscribe({
        next: (createdClient) => {
          this.store.addClient(createdClient);
          this.toastrService.success('Client created successfully', 'Success');
          this.dialogRef.close(createdClient);
        },
        error: () => this.toastrService.error('Failed to create client', 'Error')
      });
    }
  }

  onCancel(): void {
    this.appSettings.resetDefaultSettings();
    this.dialogRef.close();
  }

  onLogoChange(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files?.length) {
      const file = input.files[0];

      // Check if the file is an image
      if (!file.type.startsWith('image/')) {
        this.toastrService.error('Invalid file type! Please upload an image.', 'Error');
        input.value = ''; // Clear the file input
        return;
      }

      const reader = new FileReader();
      reader.onload = () => {
        const logoUrl = reader.result as string;
        this.clientForm.get('logoUrl')?.setValue(logoUrl);
        this.appSettings.logo.set(logoUrl); // Update the logo in real time
      };
      reader.readAsDataURL(input.files[0]);
    }
  }
}
