import { Component, inject, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { TabsModule } from 'ngx-bootstrap/tabs';

@Component({
  selector: 'app-tariff-details-dialog',
  standalone: true,
  imports: [CommonModule, MatDialogModule, MatButtonModule, MatTableModule, MatTabsModule, TabsModule],
  templateUrl: './tariff-details-dialog.component.html',
  styleUrl: './tariff-details-dialog.component.scss',
})
export class TariffDetailsDialogComponent {
  sanitizer = inject(DomSanitizer);
  constructor(
    public dialogRef: MatDialogRef<TariffDetailsDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      tariffCode: string;
      regionId: number;
      countryOfOrigin: number;
      itemTypeDescription: string;
      tariffData: any;
      regionDescription: string;
      countryOfOriginDescription: string;
    }
  ) {}

  close(): void {
    this.dialogRef.close();
  }

    sanitizeHtml(content: string | null): SafeHtml {
      return content ? this.sanitizer.bypassSecurityTrustHtml(content) : '';
    }
}
