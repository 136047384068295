<h2 mat-dialog-title>{{ editMode ? 'Edit Construction Category' : 'Create Construction Category' }}</h2>
<hr />
<form [formGroup]="constructionCategoryForm" (ngSubmit)="onSave()">
  <mat-dialog-content>
    <div class="form-group">
      <!-- Description Field -->
      <mat-form-field appearance="fill" class="full-width">
        <mat-label>Description</mat-label>
        <input matInput formControlName="description" placeholder="Enter category description" maxlength="750" />

        <!-- Hint for guidance -->
        <mat-hint align="start">
          Defines a category for organizing related constructions.
        </mat-hint>

        <mat-hint align="end">{{ constructionCategoryForm.get('description')?.value?.length || 0 }}/750</mat-hint>

        <!-- Required error message -->
        <mat-error *ngIf="constructionCategoryForm.get('description')?.hasError('required')">
          Description is required
        </mat-error>

        <!-- Max length error message -->
        <mat-error *ngIf="constructionCategoryForm.get('description')?.hasError('maxlength')">
          Description cannot exceed 750 characters
        </mat-error>
      </mat-form-field>
    </div>
  </mat-dialog-content>
  <hr />
  <div class="action-buttons">
    <mat-dialog-actions>
      <button mat-flat-button color="warn" type="button" (click)="openConfirmDeleteModal()"
        *ngIf="editMode">
        Delete
      </button>
    </mat-dialog-actions>
    <mat-dialog-actions>
      <button mat-button type="button" (click)="onCancel()">Cancel</button>
      <button mat-flat-button color="primary" type="submit" [disabled]="constructionCategoryForm.invalid || isSaving">
        {{ editMode ? 'Update' : 'Create' }}
      </button>
    </mat-dialog-actions>
  </div>
</form>
