/**
 * This file contains authentication parameters. Contents of this file
 * is roughly the same across other MSAL.js libraries. These parameters
 * are used to initialize Angular and MSAL Angular configurations in
 * in app.module.ts file.
 */

import {
  LogLevel,
  Configuration,
  BrowserCacheLocation,
} from '@azure/msal-browser';
import { environment } from '../environments/environment';

/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */
export const msalConfig: Configuration = {
  auth: environment.msalConfig.auth,
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage, // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
  },
  system: {
    allowNativeBroker: false,
    loggerOptions: {
      loggerCallback(logLevel: LogLevel, message: string) {// for debugging, ought to remove this eventually
        console.log(message);
      },
      logLevel: LogLevel.Info,// or .Verbose
      piiLoggingEnabled: false,
    },
  },
};

export const protectedResources = {
  tarifftel: {
    endpoint: environment.apiUrl,
    scopes: {
      read: [environment.msalConfig.readScope],
      write: [environment.msalConfig.writeScope],
    },
  },
};

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const loginRequest = {
  scopes: [
    environment.msalConfig.readScope,// this cannot be correct, but short on time
    environment.msalConfig.writeScope,// this cannot be correct, but short on time
    'openid',
    'profile',
    'email'
  ],
};
