import { getState, patchState, signalStore, withComputed, withHooks, withMethods, withState } from "@ngrx/signals";
import { computed, effect, inject } from "@angular/core";
import { LocalStorageService } from "../../../../core/services/local-storage.service";
import { Observable } from 'rxjs';
import { IDepartment } from "../../../../core/models/IDepartment";
import { DepartmentService } from "../../../../core/services/department.service";

const departmentsStoreKey = 'tarifftel_department_mgmt';
const departmentsTimestampKey = 'tarifftel_department_mgmt_timestamp';
const CACHE_DURATION = 60 * 60 * 1000; // 1 hour in milliseconds

type DepartmentFilter = 'all' | 'active' | 'inactive';

type DepartmentState = {
  departments: IDepartment[];
  filter: DepartmentFilter;
  searchTerm: string;
  loading: boolean; // Add loading state
};

const initialState: DepartmentState = {
  departments: [],
  filter: 'all',
  searchTerm: '',
  loading: true
};

export const DepartmentStore = signalStore(
  { providedIn: 'root' },
  withState(initialState),
  withComputed(({ departments, filter, searchTerm }) => ({
    activeRegions: computed(() =>
      departments().filter((department) => department.active)
    ),
    filteredDepartments: computed(() => {
      let filtered = departments();

      // Apply Filter
      switch (filter()) {
        case 'active':
          filtered = filtered.filter(department => department.active);
          break;
        case 'inactive':
          filtered = filtered.filter(department => !department.active);
          break;
        default:
          // 'all' - no additional filtering
          break;
      }

      // Apply Search
      const term = searchTerm().toLowerCase().trim();
      if (term) {
        filtered = filtered.filter(department =>
          department.description.toLowerCase().includes(term)
        );
      }

      return filtered;
    })
  })),
  withMethods((store) => {
    const departmentsService = inject(DepartmentService);
    const localStorageService = inject(LocalStorageService);

    return {
      loadDepartments() {
        // Retrieve from local storage and parse the JSON string into an array
        patchState(store, { loading: true }); // Set loading to true initially
        const departmentsFromStorage = JSON.parse(localStorageService.getItem(departmentsStoreKey) || '[]');
        const timestamp = parseInt(localStorageService.getItem(departmentsTimestampKey) || '0', 10);
        const now = Date.now();

        if (departmentsFromStorage.length > 0 && (now - timestamp) < CACHE_DURATION) {
          patchState(store, { departments: departmentsFromStorage });
        } else {
          // Fetch from backend
          departmentsService.getAllDepartments().subscribe({
            next: (departments) => {
              patchState(store, { departments });
              localStorageService.setItem(departmentsStoreKey, JSON.stringify(departments));
              localStorageService.setItem(departmentsTimestampKey, now.toString());
            },
            error: (error) => {
              console.error('Failed to load departments', error);
            }
          });
        }
      },

      changeFilter(filter: DepartmentFilter) {
        patchState(store, { filter });
      },

      toggleActiveDepartment(id: number) {
        patchState(store, {
          departments: store.departments().map(department => {
            if (department.id === id) {
              return { ...department, active: !department.active };
            }
            return department;
          })
        });
      },

      updateDepartmentStatus(department: IDepartment): Observable<any> {
        return departmentsService.toggleDepartmentStatus(department.id, department.active);
      },

      createDepartment(department: IDepartment): Observable<IDepartment> {
        return departmentsService.createDepartment(department.description, department.code);
      },

      updateDepartment(department: IDepartment): Observable<IDepartment> {
        return departmentsService.updateDepartment(department);
      },

      setSearchTerm(term: string) {
        patchState(store, { searchTerm: term });
      },

       // Add new item directly to the state
       addDepartment(newItem: IDepartment) {
        patchState(store, {
          departments: [...store.departments(), newItem]
            .sort((a, b) => a.description.localeCompare(b.description))
        });
      },

      // Update the item in the state
      patchDepartment(updatedDepartment: IDepartment) {
        patchState(store, {
          departments: store.departments().map(item =>
            item.id === updatedDepartment.id ? updatedDepartment : item
          )
        });
      },

      clearCache() {
        localStorageService.removeItem(departmentsStoreKey);
        localStorageService.removeItem(departmentsTimestampKey);
        patchState(store, { departments: [] }); // Optionally, clear departments in the store
      }
    };
  }),
  withHooks({
    onInit(store) {
      const localStorageService = inject(LocalStorageService);
      store.loadDepartments();

      effect(() => {
        const state = getState(store);
        const now = Date.now();
        // Save the state as a string in local storage
        localStorageService.setItem(departmentsStoreKey, JSON.stringify(state.departments));
        localStorageService.setItem(departmentsTimestampKey, now.toString());
      });
    },
  })
);
