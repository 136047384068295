import { Component, computed, effect, inject, Input, OnInit } from '@angular/core';
import { CommonModule, NgFor, NgIf } from '@angular/common';
import { AttributeListingStore } from '../store/attributelistingstore';
import { MatDialog } from '@angular/material/dialog';
import { AttributeDialogComponent } from '../attribute-dialog/attribute-dialog.component';
import { IItemAttribute } from '../../../../core/models/IItemAttribute';
import { AttributeAddSharedAttributeDialogComponent } from '../attribute-add-shared-attribute-dialog/attribute-add-shared-attribute-dialog.component';
import { ConfirmModalComponent } from '../../../../core/components/confirm-modal/confirm-modal.component';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-attribute-list',
  standalone: true,
  imports: [CommonModule, NgFor,
    NgIf
  ],
  templateUrl: './attribute-list.component.html',
  styleUrl: './attribute-list.component.scss'
})
export class AttributeListComponent implements OnInit {

  store = inject(AttributeListingStore);
  dialog = inject(MatDialog);
  toastrService = inject(ToastrService);

 // Access the selected attribute name from the store's computed signal
 selectedAttributeName = this.store.selectedAttributeName;
 selectedItemTypeAttributeId = this.store.selectedItemTypeAttributeId;

  ngOnInit() {
  }

  selectAttribute(attributeId: number) {
    this.store.setSelectedAttributeId(attributeId);
    this.store.loadAttributeValues(attributeId);
  }


  createAttribute() {
    this.openDialog(false);
  }

  sharedAttribute() {
    this.openAddSharedAttributeDialog();
  }

  editAttribute() {
    const selectedAttribute = this.store.attributes().find(attr => attr.id === this.selectedItemTypeAttributeId());
    if (selectedAttribute) {
      this.openDialog(true, selectedAttribute);
    }
  }

  openAddSharedAttributeDialog() {
    const dialogRef = this.dialog.open(AttributeAddSharedAttributeDialogComponent, {
      width: '600px',
    });

    dialogRef.afterClosed().subscribe((selectedCategoryId) => {
      if (selectedCategoryId) {
        // Logic to handle the selected shared attribute
        //this.store.addSharedAttributeToGroup(selectedCategoryId);
      }
    });
  }

  openDialog(editMode: boolean, attribute?: IItemAttribute) {
    // Retrieve the selected itemTypeGroupId from the store
    const itemTypeGroupId = this.store.selectedItemTypeGroupId();

    const dialogRef = this.dialog.open(AttributeDialogComponent, {
      width: '600px',
      data: {
        attribute: attribute ?? { id: 0, attributeName: '', description: '', itemTypeGroupId: itemTypeGroupId || null },
        editMode
      }
    });

    dialogRef.afterClosed().subscribe((result: IItemAttribute) => {
      if (result) {
        if (editMode) {
          this.store.patchAttribute(result);
        } else {
          this.store.addAttribute(result);
        }
      }
    });
  }

  removeAttribute(attributeId: number): void {
    const hasValues = this.store.attributeValues().some(av => av.itemAttributeId === attributeId);

    if (hasValues) {
      // Show toaster message
      this.toastrService.error('Cannot delete attribute with values.');
      return; // Do not proceed to open the delete dialog
    }

    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      data: {
        title: 'Confirm Deletion',
        message: 'Are you sure you want to delete this attribute?'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.store.removeAttribute(attributeId);
      }
    });
  }

}
