<lib-secondary-navbar
[title]="'sidebar.myreports' | translate"
></lib-secondary-navbar>

<div class="client-container">
  <mat-card *ngFor="let reportOption of reportOptions" class="tenant-card" appearance="outlined" (click)="onReportCardClickEvent(reportOption.title,reportOption.imagePath,reportOption.endpointPath)">
    <div class="logo-container">
      <img class="company-logo img-responsive" mat-card-image [src]="reportOption.imagePath" />
    </div>
    <mat-card-content>
      <h3 class="report-name">{{reportOption.title}}</h3>
    </mat-card-content>
  </mat-card>
</div>

