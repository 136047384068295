<ag-grid-angular
[attr.style]="'height:' + gridHeight() + 'px;width:  100%;'"
[columnDefs]="columnDefs"
[defaultColDef]="defaultColDef"
[rowData]="rowData"
[sideBar]="sideBarDef()"
[class]="themeClass"
[rowSelection]="'multiple'"
[animateRows]="true"
[pagination]="true"
[paginationPageSize]="20"
[enableCharts]="true"
[enableRangeSelection]="true"
[getContextMenuItems]="getContextMenuItems"
(gridReady)="onGridReady($event)"
></ag-grid-angular>
