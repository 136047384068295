<div class="container">
  <h6>Attribute(s) for {{ store.selectedItemTypeGroupName() }}:</h6>
  <div class="d-flex">
    <button
      type="button"
      class="btn btn-primary btn-sm me-2"
      (click)="createAttribute()"
      [disabled]="!store.selectedItemTypeGroupId()"
      aria-disabled="true">
      Create Attribute
    </button>
    <!-- <button
      type="button"
      class="btn btn-secondary btn-sm me-2"
      (click)="sharedAttribute()"
      [disabled]="!store.selectedItemTypeGroupId()">
      Add Shared Attribute
    </button> -->
    <button
      *ngIf="store.selectedItemTypeAttributeId()"
      type="button"
      class="btn btn-warning btn-sm"
      (click)="editAttribute()">
      Edit Attribute
    </button>
  </div>
  <br />

  <!-- Alert when no attributes exist -->
  <div *ngIf="store.attributes().length === 0" class="alert alert-warning" role="alert">
    No Attributes exist.
  </div>

  <div *ngIf="store.attributes().length > 0" class="itemtypeattributes">
    <div>
      <ul class="list-group">
        <li
          *ngFor="let attribute of store.attributes()"
          class="list-group-item d-flex justify-content-between align-items-center"
          [ngClass]="{ 'active': attribute.id === store.selectedItemTypeAttributeId() }"
          (click)="selectAttribute(attribute.id)"
          >
          <span class="list-group-item-text">{{ attribute.attributeName }}</span>
          <span
                    class="text-danger"
                    (click)="removeAttribute(attribute.id)"
                  >
                    ×
                  </span>
          <!--<div class="d-flex">
             <span *ngIf="attribute.isShared" class="badge rounded-pill bg-warning text-dark me-2">Shared Attribute</span>
            <span class="badge bg-secondary">{{ attribute.valueCount || 0 }}</span>
          </div>-->
        </li>
      </ul>
    </div>
  </div>

</div>

