import { Component, input, output } from '@angular/core';
import { IInboxItem } from '../../../core/models/IInboxItem';
import { InboxItemComponent } from "../inbox-item/inbox-item.component";
import { NgFor } from '@angular/common';

@Component({
  selector: 'app-inbox-item-list',
  standalone: true,
  imports: [InboxItemComponent, NgFor],
  templateUrl: './inbox-item-list.component.html',
  styleUrl: './inbox-item-list.component.scss'
})
export class InboxItemListComponent {
  items = input.required<IInboxItem[]>();
  selectItem = output<IInboxItem>();
}
