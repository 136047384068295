<div class="form-floating my-3">
    <input id="items-filter-query"
        [formControl]="query" type="text" class="form-control" placeholder="Search" />
    <label for="items-filter-query">🔍 Search</label>
</div>

<div [formGroup]="onlyCheckboxes">
    <div class="form-check mb-2">
        <input class="form-check-input"
            formControlName="unreadOnly" type="checkbox" value="" id="unread-only-check">
        <label class="form-check-label" for="unread-only-check">
            Unread messages only
        </label>
    </div>
    <div class="form-check mb-3">
        <input class="form-check-input"
            formControlName="unresolvedOnly" type="checkbox" value=""
            id="unresolved-only-check">
        <label class="form-check-label" for="unresolved-only-check">
            Unresolved questions only
        </label>
    </div>
</div>