
<app-breadcrumb></app-breadcrumb>
<div  class="row mx-1 my-2">
    <div class="col-12" align="end">
        <button class="btn btn-secondary" 
        [disabled]="showVendorLoadingSpinner()"
        (click)="refreshRegionStore()" 
        aria-label="Refresh Region Data">Refresh Region Data</button>
        <button *ngIf="this.updateVendorMode" class="btn btn-secondary ms-2" 
        [disabled]="showVendorLoadingSpinner()"
        (click)="refreshVendorStore()" 
        aria-label="Refresh Vendor Data">Refresh Vendor Data</button>
    </div>
</div>
<div class="row mx-1 my-2">
    <div class="col-12">
        <app-vendor-form
        [showMainFormCentralLoadingSpinner]="showVendorLoadingSpinner()"
        [(vendorToEdit)]="vendor"
        [showCancelButton]="true"
        (vendorDetailFormChange)="onVendorFormChangeEvent($event)"
        ></app-vendor-form>
    </div>
</div>
<div class="row mx-1 my-2">
    <div class="col-12">
        <mat-card>
            <mat-card-header>
                <mat-card-title>Manage Users</mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <app-aggrid-manage-vendor-users
                [vendor]="vendor"
                [vendorDetailLoading]="showVendorLoadingSpinner()"
                ></app-aggrid-manage-vendor-users>
            </mat-card-content>
        </mat-card>
    </div>
 
</div>







