
<app-breadcrumb></app-breadcrumb>
<div class="row mx-1 my-2">
    <div class="col-12" align="end">
        <button class="btn btn-secondary" 
        [disabled]="showVendorUserLoadingSpinner"
        (click)="refreshVendorStore()" 
        aria-label="Refresh Vendor User Data">Refresh Vendor User Data</button>
    </div>
</div>
<div class="row mx-1 my-2">
    <app-vendor-add-user-form
    [updateVendorUserMode]="true"
    [showMainFormCentralLoadingSpinner]="showVendorUserLoadingSpinner"
    [editMode]="true"
    [vendorToEdit]="vendor()"
    [vendorUserToEdit]="vendorUser()"
    [showCancelButton]="true"
    (vendorUserDetailFormChange)="onVendorUserDetailFormChange($event)"
    ></app-vendor-add-user-form>
</div>

