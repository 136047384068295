import { Component, effect, input } from '@angular/core';
import { ProductClassificationDialogComponent } from '../order-details/product-classification-dialog/product-classification-dialog.component';
import { ColDef, Column, GetContextMenuItemsParams, GridApi, GridReadyEvent, IServerSideDatasource, MenuItemDef, SideBarDef, ToolPanelDef, ValueParserLiteParams } from 'ag-grid-enterprise';
import { AggridFurtherOptionsToolPanelComponent } from '../../settings/vendor-management/aggrid-further-options-tool-panel/aggrid-further-options-tool-panel.component';
import { AggridFurtherOptions } from '../../settings/vendor-management/aggrid-further-options-tool-panel/AggridFurtherOptions';
import { OrderService } from '../../../core/services/order.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { CommonModule, DatePipe, TitleCasePipe } from '@angular/common';
import { AgGridModule } from 'ag-grid-angular';
import { UserFriendlyHttpErrorMessageDialogComponent } from '../../settings/vendor-management/user-friendly-http-error-message-dialog/user-friendly-http-error-message-dialog.component';
import { ConfirmModalComponent } from '../../../core/components/confirm-modal/confirm-modal.component';
import { IOrderV2 } from '../../../core/models/IOrderV2';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-aggrid-orders-server-side-row-model',
  standalone: true,
  imports: [
    CommonModule,
    AgGridModule,
  ],
  templateUrl: './aggrid-orders-server-side-row-model.component.html',
  styleUrl: './aggrid-orders-server-side-row-model.component.scss',
  providers:[TitleCasePipe]
})
export class AggridOrdersServerSideRowModelComponent {
  readonly EXCELEXPORTFILENAME = "Tarifftel Orders"
  readonly EXCELEXPORTWORKSHEETNAME = "Tarifftel Orders Worksheet"
  readonly DATEFORMAT = "yyyy-MM-dd";
  gridApi: GridApi|undefined;
  showOrderGridLoading: boolean = false;
  fieldsToInitiallyShowDetailRow = input<string[]|undefined>(undefined);
  fieldsToExcludeFromGridDetailRow = input<string[]>([
    "isDeleted",
    "createdAt",
    "updatedAt",
    "createdBy",
    "updatedBy",
  ]);
  excludeIdFieldsFromGridDetailRow = input<boolean>(false);
  includeSelectLink = input<boolean>(false);
  includeClassifyButton = input<boolean>(false);
  routingSelectLinkPath = input<string|undefined>(undefined);
  gridHeight= input<number>(485);
  showGridLoading= input<boolean>(true);

  defaultColDef: ColDef = {
    flex:1,
    // allow every column to be aggregated
    enableValue: true,
    // allow every column to be grouped
    enableRowGroup: true,
    // allow every column to be pivoted
    enablePivot: true,
    filter: true,
    sortable:true,
    suppressMovable:true,
    editable: false,
    suppressHeaderMenuButton: true
  };
  sideBarDef = input<SideBarDef>({
        toolPanels: [
            {
              id: 'columns',
              labelDefault: 'Columns',
              labelKey: 'columns',
              iconKey: 'columns',
              toolPanel: 'agColumnsToolPanel',
              toolPanelParams: {
                suppressRowGroups: true,
                suppressValues: true,
                suppressPivots: true,
                suppressPivotMode: true,
                suppressColumnFilter: false,
                suppressColumnSelectAll: false,
                suppressColumnExpandAll: false,
              }
          },
          {
            id: 'furtherOptions',
            labelDefault: 'Further Options',
            labelKey: 'furtherOptions',
            iconKey: 'columns',
            toolPanel: AggridFurtherOptionsToolPanelComponent,
            toolPanelParams: {
              furtherOptionsToDisplay:[
                AggridFurtherOptions.COPYMENUITEMNAME,
                AggridFurtherOptions.COPYWITHHEADERSMENUITEMNAME,
                AggridFurtherOptions.EXCELEXPORTSELECTEDROWSMENUITEMNAME
              ],
              excelExportParams:{
                columnKeys:[],
                fileName: this.EXCELEXPORTFILENAME,
                sheetName:this.EXCELEXPORTWORKSHEETNAME,
              }
            }
          },
      ],
      position: 'right',
      defaultToolPanel: ''
  });
  rowData!: any[]|undefined;
  themeClass: string = "ag-theme-quartz";
  columnDefs: ColDef[] = [
  ];
  getRowId = (params: any) => params.data.id.toString();
  aggridContextMenuItems: (string | MenuItemDef)[] = [
      'copy',
      'copyWithHeaders',
      {
        // custom item
        name: AggridFurtherOptions.EXCELEXPORTSELECTEDROWSMENUITEMNAME,
        action: () => {

          let exportExcelParams = {
            columnKeys: this.gridApi?.getColumns()?.slice(1).map((col: Column) => col.getColId()), // exclude the first column which is a checkbox column
            onlySelected:true,
            fileName: this.EXCELEXPORTFILENAME,
            sheetName:this.EXCELEXPORTWORKSHEETNAME,
          }

          if(this.chkRowsAreSelectedForBulks("",`Please select atleast one row to export selected rows to an excel file.`)) {
            this.gridApi?.exportDataAsExcel(exportExcelParams);
          }
        },
        cssClasses: ['bold'],
      }
    ];

  constructor(private ordersService:OrderService,private dialog:MatDialog,private router:Router,private titlecasePipe:TitleCasePipe,private toastrService:ToastrService ) {
  }

  getServerSideDatasource(): IServerSideDatasource {
    return {
      getRows: (params) => {
        var response = this.ordersService.getOrdersAggridPagination(params.request).subscribe({
          next:(response:any)=> {
            if (response.success) {
              // call the success callback
              params.success({
                rowData: response.rows,
                rowCount: response.lastRow,
              });

              if(response.rows && response.rows.length > 0) {
                this.setAggridColDefs(Object.keys(response.rows[0]));
              }
            } else {
              // inform the grid request failed
              params.fail();
            }


          },
          error:()=> {
            this.dialog.open(UserFriendlyHttpErrorMessageDialogComponent);
          }
        }

        )

      }
    };
  }

  onGridReady(params: GridReadyEvent<any>) {
    this.gridApi = params.api;
    this.gridApi?.setGridOption("serverSideDatasource", this.getServerSideDatasource());
  }

  setAggridColDefs(fields: string[]) {
    let filteredFieldsForAggrid = this.filterFieldsForAggrid(fields);
    this.setInitialFieldColumnDefForAggrid(filteredFieldsForAggrid)

    if(this.includeClassifyButton()) {
      this.addClassifyButtonToAggridColumnDefs();
    }

    if(this.includeSelectLink()) {
      this.addSelectLinkToAggridColumnDefs();
    }

    setTimeout(() => {
      this.sideBarDef().toolPanels?.forEach((toolPanel:ToolPanelDef|string) => {
        if((toolPanel as ToolPanelDef).id == "furtherOptions") {
          // need to exclude the first column which is the checkbox from the columns to export to excel
          (toolPanel as ToolPanelDef).toolPanelParams.excelExportParams.columnKeys = this.gridApi?.getColumns()?.slice(1).map((col: Column) => col.getColId());
        }
      })
      this.gridApi?.refreshToolPanel();
    });

  }

  filterFieldsForAggrid(fields: string[]) {
    if(this.excludeIdFieldsFromGridDetailRow()) {
      return fields.filter((field) => !field.toUpperCase().includes("ID") && !this.fieldsToExcludeFromGridDetailRow().includes(field));
    }
    else {
      return fields.filter((field) => !this.fieldsToExcludeFromGridDetailRow().includes(field));
    }
  }

  setInitialFieldColumnDefForAggrid(fields: string[]) {

    this.columnDefs = [
      {
        headerName: '',
        maxWidth:60,
        resizable: false,
        sortable:false,
        suppressFiltersToolPanel: true,
        suppressFloatingFilterButton:true,
        suppressColumnsToolPanel:true,
        checkboxSelection:true,
        headerCheckboxSelection:false,
        cellRenderer: "agGroupCellRenderer",
        suppressHeaderContextMenu:true,
        suppressHeaderMenuButton:true,
        filter: false
      }
    ];

    this.columnDefs.push(...fields.map((field:any) => {
      let mappedColDef:ColDef = {
        headerName: this.titlecasePipe.transform(this.convertPropNameToTitleCaseWithSpaces(field)),
        field: field,
        hide:(this.fieldsToInitiallyShowDetailRow()) ? !this.fieldsToInitiallyShowDetailRow()?.includes(field):false,
        filter:true,
        suppressColumnsToolPanel:false,
        suppressFloatingFilterButton:true,
        suppressFiltersToolPanel: false,
        suppressHeaderContextMenu:true,
        suppressHeaderMenuButton:true,
        suppressHeaderFilterButton:true
      }

      if(field.toUpperCase().includes("DATE")) {
        mappedColDef.cellRenderer = (data: any) => {
          return new DatePipe('en-US').transform(data.value,this.DATEFORMAT)
        }
        mappedColDef.cellDataType = "dateString";
      }

      if(field.toUpperCase() == "VENDORSTATUS") {
        mappedColDef.cellDataType = "boolean";
      }
      return mappedColDef;
    }))
  }

  addClassifyButtonToAggridColumnDefs() {
    this.columnDefs.push({
      headerName: '',
      field: '',
      /*
      cellRenderer: AggridOrdersDetailClassifyButtonRendererComponent,
      cellRendererParams: {
        clicked: function(params:any) {
          console.log(params);
          this.classifyButtonEvent();
        }
      },
      */
      cellRenderer : function(params:any){
          return '<div><button class="btn btn-primary btn-sm mb-2">Classify</button></div>'
      },
      onCellClicked:(params) => {
        this.classifyButtonEvent(params.data);
      },
      maxWidth:100,
      resizable: false,
      sortable:false,
      suppressFiltersToolPanel: true,
      suppressFloatingFilterButton:true,
      suppressColumnsToolPanel:true,
      filter: false,
      suppressHeaderContextMenu:true,
      suppressHeaderMenuButton:true
    })
  }

    addSelectLinkToAggridColumnDefs() {
      this.columnDefs.push(
        {
          headerName: '',
          cellRenderer:() => {
            return `<a href="javascript:void(0)" style="text-decoration:underline" >Select</a>`;
          },
          onCellClicked:(params) => {
            this.routeToOrderDetail(params.data.id);
          },
          maxWidth:80,
          resizable: false,
          sortable:false,
          suppressFiltersToolPanel: true,
          suppressFloatingFilterButton:true,
          suppressColumnsToolPanel:true,
          filter: false
        }
      );
    }

    convertPropNameToTitleCaseWithSpaces(propName:string):string {
      return this.titlecasePipe.transform(propName.replace(/([a-z])([A-Z])/g, '$1 $2'));
    }

    routeToOrderDetail(orderId:number) {
      this.router.navigate([this.routingSelectLinkPath(),orderId])
    }

    classifyButtonEvent(rowData:any) {
      const dialogRef = this.dialog.open(ProductClassificationDialogComponent, {
          data: {
            orderLine:rowData
          }
      });

      dialogRef.afterClosed().subscribe(() => {
      });
    }
    clearFilters() {
      this.gridApi?.setAdvancedFilterModel(null);
    }

    chkRowsAreSelectedForBulks(confirmModalTitle:string,confirmModalMsg:string) {
        if(!this.gridApi) {
          return
        }

        let selectedVendorRows = this.gridApi.getSelectedRows();
        if(selectedVendorRows.length < 1) {
            this.dialog.open(ConfirmModalComponent, {
              data: {
                title:confirmModalTitle,
                message:confirmModalMsg
              }
            });
            return false;
        }
        else  {
          return true;
        }
      }

    getContextMenuItems= (params:GetContextMenuItemsParams) => {
      return this.aggridContextMenuItems;
    }


    onFileSelected(event: Event): void {
      const input = event.target as HTMLInputElement;
      const file = input.files?.[0];

      if (!file) {
        return;
      }

      this.showOrderGridLoading = true; // ✅ Show loading indicator

      this.ordersService.uploadOrdersViaExcel(file).subscribe({
        next: () => {
          this.toastrService.success("Orders added successfully!");
          if (this.gridApi) {
            this.gridApi.refreshServerSide(); // Refresh server-side data source
          }
        },
        error: (errorResponse) => {
          console.error("Error uploading orders:", errorResponse);

          if (errorResponse.error && errorResponse.error.errors) {
            const errors = errorResponse.error.errors;
            let formattedErrors = "";



            Object.keys(errors).forEach(orderNumber => {
              formattedErrors += `<strong>Order ${orderNumber}:</strong><ul>`;
              errors[orderNumber].forEach((error: string) => {
                formattedErrors += `<li>${error}</li>`;
              });
              formattedErrors += `</ul>`;
            });

            console.error("Error uploading orders:", formattedErrors);
            // Open dialog with formatted error list
            this.dialog.open(UserFriendlyHttpErrorMessageDialogComponent, {
              data: {
                title: "Order Upload Failed",
                message: formattedErrors,
                buttonText: "Okay",
                isValidationError: true
              }
            });
            this.showOrderGridLoading = false;
          } else {
            // Generic error handling
            this.dialog.open(UserFriendlyHttpErrorMessageDialogComponent, {
              data: {
                title: "Upload Failed",
                message: "An unexpected error occurred. Please try again.",
                buttonText: "Okay",
                isValidationError: true
              }
            });
            this.showOrderGridLoading = false;
          }
        },
        complete: () => {
          this.showOrderGridLoading = false; // ✅ Hide loading indicator
          input.value = ''; // Reset the file input so the same file can be uploaded again
        }
      });
    }
}
