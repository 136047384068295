import { CanDeactivateFn } from '@angular/router';
import { ConfirmModalComponent } from '../components/confirm-modal/confirm-modal.component';
import { inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';

export interface CanComponentWithUnSavedFormChangesDeactivate {
  canDeactivate:() => boolean | Observable<boolean>;
}

export const canDeactivateConfirmDiscardUnsavedChangesGuard: CanDeactivateFn<CanComponentWithUnSavedFormChangesDeactivate> = (component) => {
  
  return component.canDeactivate();
};
