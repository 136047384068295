<app-breadcrumb></app-breadcrumb>
<app-excel-file-attach-bulk-entity-load
    [validFileExtensions]="VALIDFILEEXTENSIONS"
    [acceptedFileTypes]="ACCEPTEDFILETYPES"
    [fileUploadendpointSuffix]="vendorUserEndpointSuffix"
    [sampleFilePath]="SAMPLEFILEPATH"
    [sampleFileLinkName]="SAMPLEFILELINKNAME"
    [viewAllRecordsLinkPath]="viewAllRecordsLinkPath"
    [sampleFileMandatoryFields]="SAMPLEFILEMANDATORYFIELDS"
    [vendorUserMode]="true"
></app-excel-file-attach-bulk-entity-load>
