<h2 mat-dialog-title>Select Shared Attribute</h2>
<hr />
<form [formGroup]="attributeForm" (ngSubmit)="onSave()">
  <mat-dialog-content>
    <div class="form-group">
      <mat-form-field appearance="fill" class="full-width">
        <mat-label>Select Shared Attribute Name</mat-label>
        <mat-select formControlName="category">
          <mat-option *ngFor="let category of categories" [value]="category.id">
            {{ category.description }}
          </mat-option>
        </mat-select>
        <mat-hint align="start">Select shared attribute to add to Item Type Group.</mat-hint>
      </mat-form-field>
    </div>
  </mat-dialog-content>
  <hr />
  <mat-dialog-actions align="end">
    <button mat-button type="button" (click)="onCancel()">Cancel</button>
    <button mat-flat-button color="primary" type="submit" [disabled]="attributeForm.invalid || isSaving">
      Select
    </button>
  </mat-dialog-actions>
</form>
