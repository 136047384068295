import { CommonModule, NgFor, NgIf } from '@angular/common';
import { Component, inject, Inject } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatOptionModule } from '@angular/material/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { IMaterialCategory } from '../../../../core/models/IMaterialCategory';
import { ToastrService } from 'ngx-toastr';
import { MaterialCategoryStore } from '../store/materialstore';
import { ConfirmModalComponent } from '../../../../core/components/confirm-modal/confirm-modal.component';

@Component({
  selector: 'app-material-category-dialog',
  standalone: true,
  imports: [ReactiveFormsModule,
    MatFormFieldModule,
    MatOptionModule,
    MatInputModule,
    MatSelectModule,
    MatCheckboxModule,
    MatButtonModule,
    MatDialogModule,
    CommonModule,
    NgIf],
  templateUrl: './material-category-dialog.component.html',
  styleUrl: './material-category-dialog.component.scss'
})
export class MaterialCategoryDialogComponent {

  materialCategoryForm: FormGroup;
  editMode: boolean;
  categories: IMaterialCategory[] = [];
  isSaving = false;

  store = inject(MaterialCategoryStore);
  toastrService = inject(ToastrService);
  dialog = inject(MatDialog);

  constructor(
    public dialogRef: MatDialogRef<MaterialCategoryDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { materialCategory: IMaterialCategory; editMode: boolean },
    private fb: FormBuilder
  ) {
    this.editMode = data.editMode;

    // Initialize form with material category data
    this.materialCategoryForm = this.fb.group({
      description: [data.materialCategory.description, [Validators.required, Validators.maxLength(750)]],
    });
  }

  onSave(): void {
    if (this.materialCategoryForm.valid) {
      const updatedMaterialCategory: IMaterialCategory = {
        ...this.data.materialCategory,
        ...this.materialCategoryForm.value,
      };

      if (this.store.isDuplicateCategory(updatedMaterialCategory.description, updatedMaterialCategory.id)) {
        this.toastrService.error('Material Category Description already exists.');
        this.isSaving = false;
      } else if (this.editMode  && !this.isSaving) {
        // Update material
        this.store.updateCategory(updatedMaterialCategory).subscribe({
          next: () => {
            this.toastrService.success('Material Category updated successfully', 'Success');
            this.dialogRef.close(updatedMaterialCategory);
            this.isSaving = false;
          },
          error: (error) => {
            if (error.status === 500) {
              this.toastrService.error('Failed to update material category', 'Error');
            } else {
              this.toastrService.error(error.error, 'Error');
            }
            this.isSaving = false;
          }
        });
      } else {
        // Create new material category
        this.store.createCategory(updatedMaterialCategory).subscribe({
          next: (newMaterialCategory) => {
            this.toastrService.success('Material Category created successfully', 'Success');
            this.dialogRef.close(newMaterialCategory);
            this.isSaving = false;
          },
          error: (error) => {
            if (error.status === 500) {
              this.toastrService.error('Failed to create material category', 'Error');
            } else {
              this.toastrService.error(error.error, 'Error');
            }
            this.isSaving = false;
          }
        });
      }
    }
  }

  onDelete(): void {
    const materialCategory = this.data.materialCategory;

    if (materialCategory.id) {
      this.store.deleteCategory(materialCategory.id).subscribe({
        next: () => {
          this.toastrService.success('Material category deleted successfully', 'Success');
          this.store.removeCategoryFromStore(materialCategory.id);
          this.dialogRef.close(materialCategory);
        },
        error: (error) => {
          if (error.status === 500) {
            this.toastrService.error('Failed to delete material category', 'Error');
          } else {
            this.toastrService.error(error.error, 'Error');
          }
        }
      });
    }
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  openConfirmDeleteModal() {
    const entityName = 'Material Category';
    const dialogRef = this.dialog.open(ConfirmModalComponent, {
          data: {
            title: `Are you sure you want to delete this ${ entityName }?`,
            message: `All information associated to this ${ entityName } will be permanently deleted.
              <span class="text-danger">This operation cannot be undone.</span>`
          },
          width: '400px',
        });
    
        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            this.onDelete();
          }
        });
  }
}
