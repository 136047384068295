<h2 mat-dialog-title>{{ editMode ? 'Edit Attribute Value' : 'Create Attribute Value' }}</h2>
<hr />
<form [formGroup]="attributeValueForm" (ngSubmit)="onSave()">
  <mat-dialog-content>
    <mat-tab-group>
      <!-- Tab 1: Attribute Info -->
      <mat-tab label="Attribute Info">
        <div class="tab-content form-group">
          <mat-form-field appearance="fill" class="full-width">
            <mat-label>Attribute Value Name</mat-label>
            <input matInput formControlName="valueName" placeholder="Enter value name" maxlength="500"/>
            <mat-hint align="start">
              A specific option or example under the Attribute (e.g., 'Trumpet', 'Tuba').
            </mat-hint>

            <mat-hint align="end">{{ attributeValueForm.get('valueName')?.value?.length || 0 }}/500</mat-hint>

            <mat-error *ngIf="attributeValueForm.get('valueName')?.hasError('required')">
              Attribute Value Name is required
            </mat-error>

            <mat-error *ngIf="attributeValueForm.get('valueName')?.hasError('maxlength')">
              Attribute Value Name cannot exceed 500 characters
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="fill" class="full-width">
            <mat-label>Description</mat-label>
            <textarea matInput
            formControlName="description"
            rows="5"
            maxlength="1500"
            placeholder="Enter description"
            style="resize: none;">
            </textarea>
            <mat-hint align="start">
              Detailed information about the attribute value (e.g., 'A brass instrument with three valves').
            </mat-hint>
            <mat-hint align="end">{{ attributeValueForm.get('description')?.value?.length || 0 }}/1500</mat-hint>

            <mat-error *ngIf="attributeValueForm.get('description')?.hasError('maxlength')">
              Description cannot exceed 1500 characters
            </mat-error>
          </mat-form-field>
        </div>
      </mat-tab>

      <!-- Tab 2: Sub Attributes -->
      <mat-tab label="Sub Attributes">
        <div class="tab-content">
          <hr />
          <div class="d-flex">
            <!-- Sub Attribute Input -->
            <div class="flex-fill me-3">
              <h6>Enter Sub Attribute Name</h6>
              <!-- THIS IS TEMPORARILY DISABLED AS I WANT TO PREVENT SUB ATTRIBUTES DURING CLIENT DEMO -->
              <div class="input-group mb-3">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Enter Sub Attribute Name"
                  formControlName="newSubAttribute"
                  (keydown.enter)="onEnterKey($event, 'subAttribute')"
                />
                <button
                  type="button"
                  class="btn btn-primary"
                  (click)="addSubAttribute()"
                  [disabled]="!attributeValueForm.get('newSubAttribute')?.value?.trim() || isDuplicateSubAttribute(
                    this.attributeValueForm.get('newSubAttribute')?.value?.trim(),
                    data.attributeValue.subAttributes ?? []
                  )"
                >
                  Add
                </button>
              </div>
              @if (this.attributeValueForm.get('newSubAttribute')?.value?.trim() &&
                isDuplicateSubAttribute(
                  this.attributeValueForm.get('newSubAttribute')?.value?.trim(),
                  data.attributeValue.subAttributes ?? []
                  )) {
                <div class="alert alert-danger">Sub Attribute Name already exists</div>
              }

              <!-- Alert if no Sub Attributes -->
              <div *ngIf="!data.attributeValue.subAttributes?.length" class="alert alert-warning">
                No Sub Attributes added for this Attribute
              </div>

              <!-- Sub Attribute List -->
              <ul class="list-group">
                <li
                  *ngFor="let subAttribute of data.attributeValue.subAttributes || []"
                  class="list-group-item d-flex justify-content-between align-items-center"
                  [class.active]="subAttribute.id === selectedSubAttribute?.id"
                  (click)="selectSubAttribute(subAttribute)"
                >
                  <span>{{ subAttribute.subAttributeName }}</span>
                  <span
                    class="text-danger"
                    *ngIf="!subAttribute.subAttributeValues?.length"
                    (click)="removeSubAttribute(subAttribute.id, $event)"
                  >
                    ×
                  </span>
                </li>
              </ul>


            </div>

            <!-- Sub Attribute Value Input -->
            <div class="flex-fill">
              <h6>Enter Sub Attribute Value</h6>
              <div class="input-group mb-3">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Enter Sub Attribute Value"
                  formControlName="newSubAttributeValue"
                  (keydown.enter)="onEnterKey($event, 'subAttributeValue')"
                />
                <button
                  type="button"
                  class="btn btn-primary"
                  (click)="addSubAttributeValue(selectedSubAttribute?.id || 0)"
                  [disabled]="!attributeValueForm.get('newSubAttributeValue')?.value?.trim()
                    || !selectedSubAttribute
                    || isDuplicateSubAttributeValue(
                    this.attributeValueForm.get('newSubAttributeValue')?.value?.trim(),
                    selectedSubAttribute.subAttributeValues ?? []
                  )"
                >
                  Add
                </button>
              </div>
              @if ( selectedSubAttribute
                && this.attributeValueForm.get('newSubAttributeValue')?.value?.trim()
                && isDuplicateSubAttributeValue(
                  this.attributeValueForm.get('newSubAttributeValue')?.value?.trim(),
                  selectedSubAttribute.subAttributeValues ?? []
                  )) {
                <div class="alert alert-danger">Sub Attribute Value already exists</div>
              }

              <div *ngIf="!selectedSubAttribute?.subAttributeValues?.length" class="alert alert-warning">
                No values added for this Sub Attribute.
              </div>

              <!-- Sub Attribute Value List -->
              <ul class="list-group">
                <li
                  *ngFor="let value of selectedSubAttribute?.subAttributeValues || []"
                  class="list-group-item d-flex justify-content-between align-items-center"
                >
                  <span>{{ value.valueName }}</span>
                  <span
                    class="text-danger"
                    (click)="removeSubAttributeValue(selectedSubAttribute?.id ?? 0, value.id)"
                  >
                    ×
                  </span>
                </li>
              </ul>



            </div>
          </div>
        </div>
      </mat-tab>

      <!-- Tab 3: Aliases -->
      <mat-tab label="Associated Terms">
        <div class="tab-content">
          <div class="input-group mb-3">
            <input
              type="text"
              class="form-control"
              placeholder="Enter associated term"
              formControlName="newAlias"
            />
            <button
              type="button"
              class="btn btn-primary"
              (click)="addAlias()"
              [disabled]="!attributeValueForm.get('newAlias')?.value?.trim()"
            >
              Add Assoc Term
            </button>
          </div>
          <div *ngIf="aliases.length > 0">
            <table class="table table-bordered table-striped">
              <thead>
                <tr>
                  <th>Associated Term</th>
                  <th style="text-align: center">Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let aliasControl of aliases.controls; let i = index">
                  <td><input matInput [formControl]="aliasControl" class="form-control" /></td>
                  <td style="text-align: center">
                    <button
                      type="button"
                      class="btn btn-danger btn-sm"
                      (click)="removeAlias(i)"
                    >
                      Remove
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div *ngIf="aliases.length === 0" class="alert alert-warning mt-2">No Associated Terms added.</div>
        </div>
      </mat-tab>

      <!-- Tab 4: Image Upload
      <mat-tab label="Image Upload">
        <div class="tab-content text-center">
          <label for="imageUpload">Upload Image</label>
          <input type="file" id="imageUpload" (change)="onImageSelected($event)" />
          <div *ngIf="imagePreview" class="d-flex justify-content-center mt-3">
            <img
              [src]="imagePreview"
              alt="Image Preview"
              class="img-fluid"
              style="max-width: 100%; max-height: 180px; border-radius: 8px;"
            />
          </div>
        </div>
      </mat-tab> -->
    </mat-tab-group>
  </mat-dialog-content>

  <hr />
  <mat-dialog-actions align="end">
    <button mat-button type="button" (click)="onCancel()">Cancel</button>
    <button mat-flat-button color="primary" type="submit" [disabled]="attributeValueForm.invalid || isSaving">
      {{ editMode ? 'Update' : 'Create' }}
    </button>
  </mat-dialog-actions>
</form>
