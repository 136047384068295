<div style="display: flex; justify-content: space-between; align-items: center;">
  <h6>Construction(s) for {{ store.selectedItemTypeGroup()?.description }} for {{ store.selectedRegion()?.description }}</h6>
  <button class="btn btn-primary"  (click)="createConstruction()" >Create Construction</button>
</div>
<div>
  <!-- Dynamic Tabset for Regions -->
  <tabset>
    <!-- Iterate through the regions from the store -->
    <tab *ngFor="let region of store.regions()" (selectTab)="handleTabClick(region.id, region.description, region.class)">
      <ng-template tabHeading>
        <!-- Use the `regionClass` to dynamically display flags -->
        <span [class]="region.class" style="font-size: 26px"></span>
      </ng-template>

      <div *ngIf="store.selectedRegionId() === region.id">
        <!-- Display alert if no constructions exist for the selected region and item type group -->
        <div *ngIf="store.filteredList().length === 0; else showTable" class="alert alert-warning mt-3" role="alert">
          No constructions found for '{{ store.selectedItemTypeGroup()?.description }}' for '{{ region.description }}'.
        </div>

        <ng-template #showTable>
          <!-- Table for Displaying Constructions for the Selected ItemTypeGroup and Region -->
          <table class="table table-bordered table-striped" style="width: 100%">
            <thead>
              <tr>
                <th style="width:50%">Description (click to edit)</th>
                <th style="text-align: center; width: 20%">Incl in Customs Desc</th>
                <th style="text-align: center; width: 20%">Category</th>
                <!-- <th style="text-align: center; width: 10%">Delete</th> -->
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let construction of store.filteredList()">
                <td class="d-flex justify-content-between align-items-center">
                  <span class="clickable-description" (click)="editConstructionDescription(construction)">
                    {{ getTruncatedDescription(construction) }}
                  </span>
                  <!-- <span>{{ construction.description }}</span> -->
                  <span *ngIf="construction.clarifyingInformation" class="badge rounded-pill bg-warning text-dark">
                    Clarifying Information
                  </span>
                </td>
                <td style="text-align: center">{{ construction.includeInCustomsDescription ? 'Yes' : 'No' }}</td>
                <td style="text-align: center">{{ construction.constructionCategoryName }}</td>
                <!-- <td style="text-align: center"></td> -->
              </tr>
            </tbody>
          </table>
        </ng-template>
      </div>
    </tab>
  </tabset>
</div>