<div class="dialog-wrapper">
  <h4 mat-dialog-title class="dialog-title"><i class="fa-solid fa-wand-magic-sparkles"></i>{{ data.headerTitle || ' TariffTel Product Classification' }}</h4>
  <hr />
  <mat-dialog-content class="dialog-content">
    <div style="display: flex; justify-content: space-between; align-items: center;">
      <h6>{{ steps[selectedIndex].label }}</h6>

      <!-- Only show the 'available codes' text on step 3 (index=2) -->
      <ng-container *ngIf="selectedIndex === 3">
        <h6>
          {{ store.filteredItemTypes().length }} codes available
          <button class="btn btn-primary btn-sm ml-2" (click)="store.resetAttributeSelections()" *ngIf="store.isFiltered()">
            Reset All Filters
          </button>

        </h6>
      </ng-container>
    </div>
    <!-- <hr /> -->

    <form [formGroup]="classificationForm" (ngSubmit)="submit()">
      <div class="tab-content-container">

        <!-- Step 1 - Add/Remove Set/Pack Items -->
        <div *ngIf="selectedIndex === getStepIndex('Step 1: Geographic Details and Product Type')" class="form-container">

          <mat-form-field appearance="fill" class="full-width">
            <mat-label>Import Region</mat-label>
            <mat-select formControlName="region">
              <!-- Custom Trigger to show flag after selection -->
              <mat-select-trigger>
                <span *ngIf="classificationForm.get('region')?.value as selectedRegionId">
                  <span class="fi" [ngClass]="getRegionClass(selectedRegionId)" style="margin-right: 8px;"></span>
                  {{ getRegionDescription(selectedRegionId) }}
                </span>
              </mat-select-trigger>

              <mat-option *ngFor="let r of regions()" [value]="r.id">
                <span class="fi" [ngClass]="'fi-' + r.class" style="margin-right: 8px;"></span>
                {{ r.description }}
              </mat-option>
            </mat-select>
            <mat-hint align="start">
              Select the Import region for these goods.
            </mat-hint>
            <mat-error *ngIf="classificationForm.get('region')?.hasError('required')">
              Import Region is required
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="fill" class="full-width">
            <mat-label>Country of Origin</mat-label>
            <mat-select formControlName="countryOfOrigin">
              <mat-select-trigger>
                <span *ngIf="classificationForm.get('countryOfOrigin')?.value as selectedCountryId">
                  <span class="fi" [ngClass]="getCountryClass(selectedCountryId)" style="margin-right: 8px;"></span>
                  {{ getCountryDescription(selectedCountryId) }}
                </span>
              </mat-select-trigger>

              <mat-option *ngFor="let c of countryOfOrigins()" [value]="c.id">
                <span class="fi" [ngClass]="'fi ' + c.class" style="margin-right: 8px;"></span>
                {{ c.description }}
              </mat-option>
            </mat-select>
            <mat-hint align="start">
              Select the Country of Origin for these goods.
            </mat-hint>
            <mat-error *ngIf="classificationForm.get('countryOfOrigin')?.hasError('required')">
              Country of Origin is required
            </mat-error>
          </mat-form-field>


          <hr />
          <h6 class="mt-2">Product Type:</h6>

          <mat-radio-group formControlName="productType" class="radio-group">
            <mat-radio-button value="singleProduct" class="radio-button">
              Single Product
            </mat-radio-button>
            <mat-radio-button value="setOrPack" class="radio-button">
              Product is a set or a pack where items have differences
            </mat-radio-button>
            <mat-radio-button value="identicalPack" class="radio-button">
              Product is sold as a pack of identical items
            </mat-radio-button>
          </mat-radio-group>

          <div class="form-group" *ngIf="classificationForm.get('productType')?.value === 'setOrPack'">
            <mat-form-field appearance="fill" class="full-width">
              <mat-label>Set/Pack Item(s) for this Product</mat-label>
              <mat-select formControlName="packItems">
                <mat-option *ngFor="let packItem of packItems" [value]="packItem">
                  {{ packItem }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="form-group" *ngIf="classificationForm.get('productType')?.value === 'identicalPack'">
            <mat-form-field appearance="fill" class="full-width">
              <mat-label>Number of Identical Items</mat-label>
              <mat-select formControlName="identicalPackItems">
                <mat-option
                  *ngFor="let identicalPackItem of identicalPackItems"
                  [value]="identicalPackItem"
                >
                  {{ identicalPackItem }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <!-- Step 2 - Describe your product -->
        <div *ngIf="selectedIndex === getStepIndex('Step 2: Describe your product')" class="form-container">
          <mat-form-field appearance="fill" class="full-width">
            <mat-label>Description of Goods</mat-label>
            <textarea
              matInput
              formControlName="description"
              rows="1"
              placeholder="Description of goods on order"
              class="no-resize"
            ></textarea>
            <mat-hint align="start">
              Please detail the goods from the order. e.g. If garment, please specify gender etc.
            </mat-hint>
            <mat-error *ngIf="classificationForm.get('description')?.hasError('required')">
              Description is required
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="fill" class="full-width">
            <mat-label>Item Type Group</mat-label>
            <mat-select formControlName="itemTypeGroup" (selectionChange)="onItemTypeGroupChange($event.value)">
              <mat-option *ngFor="let group of itemTypeGroups()" [value]="group.id">
                {{ group.description }}
              </mat-option>
            </mat-select>
            <mat-hint align="start">
              Select the Item Type Group relevant to these goods.
            </mat-hint>
            <mat-error *ngIf="classificationForm.get('itemTypeGroup')?.hasError('required')">
              Item Type Group is required
            </mat-error>
          </mat-form-field>

          <!-- Display clarifying information separately -->
          <div class="clarifying-information" *ngIf="store.selectedClarifyingInformation()">
            <p>{{ store.selectedClarifyingInformation() }}</p>
          </div>



          <!-- <mat-form-field appearance="fill" class="full-width">
            <mat-label>Gender</mat-label>
            <mat-select formControlName="gender">
              <mat-option *ngFor="let gender of genders" [value]="gender">
                {{ gender }}
              </mat-option>
            </mat-select>
            <mat-hint align="start">
              Select the Gender related to these goods.
            </mat-hint>
          </mat-form-field> -->

          <mat-form-field appearance="fill" class="full-width" *ngIf="requiresConstructionMgmt">
            <mat-label>Construction</mat-label>
            <mat-select formControlName="construction">
              <mat-option *ngFor="let construction of constructions" [value]="construction">
                {{ construction }}
              </mat-option>
            </mat-select>
            <mat-hint align="start">
              Select the Construction related to these goods.
            </mat-hint>
            <mat-error *ngIf="classificationForm.get('construction')?.hasError('required')">
              Construction is required
            </mat-error>
          </mat-form-field>

        </div>

        <!-- Step 3 - Image and Order Analysis -->
        <div *ngIf="selectedIndex === getStepIndex('Step 3: Product Image Upload')" class="form-container">
          <div class="custom-form-field full-width">
            <label class="custom-label">Upload Product Image (Upload a clear image of the product if available.)</label>
            <button mat-raised-button color="primary" (click)="fileInput.click(); $event.stopPropagation()" style="margin-right: 10px;" type="button">
              Choose Image
            </button>
            <input
              #fileInput
              type="file"
              accept="image/*"
              (change)="handleFileInput($event)"
              hidden
            />

            <!-- Display the uploaded image name and a clear button -->
            <div *ngIf="uploadedImageName" class="uploaded-image-info">
              <p><strong>Selected Image:</strong> {{ uploadedImageName }}
                <button mat-button color="warn" (click)="clearUploadedImage(); $event.stopPropagation()" style="margin-left: 10px;" type="button">
                  Clear
                </button>
              </p>
            </div>

            <!-- Display the preview of the uploaded image -->
            <div *ngIf="uploadedImagePreview">
              <h6>Uploaded Image Preview:</h6>
              <img
                [src]="uploadedImagePreview"
                alt="Uploaded Image"
                class="uploaded-image-preview"
                style="max-width: 200px; margin-top: 10px; border: 1px solid #ddd; border-radius: 5px;"
              />
            </div>
          </div>

        </div>

        <!-- Step 4 - Product Properties  -->
        <div *ngIf="selectedIndex === getStepIndex('Step 4: Product Properties')" class="two-column-layout">
          <div class="column left-column">
            <h6>Select from the Steps below: (If value not valid, move to next step)</h6>
            <ul class="list-group">
              <li
                *ngFor="let attribute of store.visibleAttributesWithStep(); let i = index"
                [class.active]="attribute.id === store.selectedItemTypeAttributeId()"
                (click)="store.selectAttributeAndLoadValues(attribute.id)"
                class="list-group-item"
              >
              {{ attribute.stepLabel }}
              <span *ngIf="store.completedSteps()[i]?.completed" class="prominent-tick">
                <i class="fa fa-check"></i>
              </span>
              </li>
            </ul>
          </div>

          <div class="column right-column">
            <h6>Available Values</h6>
            <mat-radio-group
                [value]="store.selectedAttributeValueId()"
                (change)="store.setSelectedAttributeValueId($event.value)"
                class="list-group"
              >
              <mat-radio-button [value]="null" class="list-group-item">
                Not Selected or displayed in this list
              </mat-radio-button>

              <mat-radio-button
                *ngFor="let val of store.visibleValuesForSelectedAttribute()"
                [value]="val.id"
                class="list-group-item radio-button-item"
              >
                <div class="radio-item-content">
                  <span class="value-text">{{ val.valueName }}</span>
                  <span
                    *ngIf="val.hasClarifyingInfo" class="badge clarifying-info-badge"
                    [matTooltip]="val.description"
                    matTooltipPosition="above"
                  >
                    Clarifying Info
                  </span>
                </div>

              </mat-radio-button>
            </mat-radio-group>

          </div>
        </div>

        <!-- Step 5 - Material(s) -->
        <!-- <div *ngIf="selectedIndex === getStepIndex('Step 5: Material(s)')" class="form-container">
        </div> -->

        <!-- Step 6 - Codes Available -->
        <div *ngIf="selectedIndex === getStepIndex('Step 5: Codes Available (Please confirm by selecting a row below)')" class="form-container">

          <table class="table table-bordered" style="width: 100%">
            <thead>
              <tr>
                <th scope="col" style="width: 90%">Description (Click row to Confirm)</th>
                <th scope="col" style="width: 10%" class="text-center">Tariff Code</th>
              </tr>
            </thead>
            <tbody>
              <tr
                *ngFor="let t of store.filteredItemTypes()"
                [class.selected]="t.id === store.selectedItemTypeId()"
                (click)="onTariffCodeSelected(t.tariffCode, t.id)"
                class="clickable-row"
              >
                <td>{{ t.description }}</td>
                <td class="text-center">{{ t.tariffCode }}</td>
              </tr>
            </tbody>
          </table>

        </div>

        <!-- Step 7 - Tariff and Classification Details -->
        <div *ngIf="selectedIndex === getStepIndex('Step 6: Tariff and Classification Details')" class="form-container">
          <h6>Classification and Tariff Details</h6>
          <div *ngIf="tariffData">

            <tabset type="pills">

              <!-- General Info Tab -->
              <tab heading="General Info">
                <table class="table table-bordered mt-3">
                  <tbody>
                    <tr>
                      <th style="width:25%">Region</th>
                      <td>{{ getRegionDescription(selectedRegion?.id ?? null) || 'N/A' }}</td>
                    </tr>
                    <tr>
                      <th>Country of Origin</th>
                      <td>{{ getCountryDescription(selectedCountry?.id ?? null) || 'N/A' }}</td>
                    </tr>
                    <tr>
                      <th>Tariff Code</th>
                      <td>{{ tariffData.goods_nomenclature_item_id || 'N/A' }}</td>
                    </tr>
                    <tr>
                      <th>Description</th>
                      <td>{{ tariffData.description || 'N/A' }}</td>
                    </tr>
                    <tr>
                      <th>Section</th>
                      <td>{{ tariffData.section || 'N/A' }}</td>
                    </tr>
                    <tr>
                      <th>Chapter</th>
                      <td>{{ tariffData.chapter || 'N/A' }}</td>
                    </tr>
                  </tbody>
                </table>
              </tab>

              <!-- Duty Rates Tab -->
              <tab heading="Duty Rates">
                <table class="table table-bordered mt-3">
                  <tbody>
                    <tr>
                      <th style="width:25%">Basic Duty Rate</th>
                      <td [innerHTML]="sanitizeHtml(tariffData.basic_duty_rate || 'N/A')"></td>
                    </tr>
                    <tr>
                      <th>Preferential Duty Rate</th>
                      <td [innerHTML]="sanitizeHtml(tariffData.preferentialDutyRate || 'N/A')"></td>
                    </tr>
                    <tr>
                      <th>Additional Duty Rate</th>
                      <td>{{ tariffData.additionalDutyRate || 'N/A' }}</td>
                    </tr>
                    <tr>
                      <th>VAT Rate</th>
                      <td>{{ tariffData.vatRate || 'N/A' }}</td>
                    </tr>
                    <tr>
                      <th>Excise Duty</th>
                      <td>{{ tariffData.exciseDuty || 'N/A' }}</td>
                    </tr>
                    <tr>
                      <th>Preferential Duty Rates by Country</th>
                      <td>
                        <ul *ngIf="(tariffData?.preferentialDutyRatesByCountry ?? []).length > 0; else noRates">
                          <li *ngFor="let rate of tariffData?.preferentialDutyRatesByCountry ?? []">
                            {{ rate.country }}: {{ rate.rate }}
                          </li>
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <th>Specific Duty Rates by Country</th>
                      <td>
                        <ul *ngIf="(tariffData?.specificDutyRatesByCountry ?? []).length > 0; else noRates">
                          <li *ngFor="let rate of tariffData?.specificDutyRatesByCountry ?? []">
                            {{ rate.country }}: {{ rate.rate }}
                          </li>
                        </ul>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </tab>

              <!-- Conditions & Quotas Tab -->
              <tab heading="Conditions & Quotas">
                <table class="table table-bordered mt-3">
                  <tbody>
                    <tr>
                      <th style="width:25%">Meursing Code Required</th>
                      <td>
                        <span *ngIf="tariffData?.requiresMeursingCode; else noMeursing">
                          Yes
                          <input
                            matInput
                            formControlName="meursingCode"
                            placeholder="Enter Meursing Code"
                            class="form-control d-inline w-50 ml-2"
                            style="max-width: 300px; margin-top: 5px;"
                          />
                          <a
                            href="javascript:void(0)"
                            (click)="openMeursingCodeLookup()"
                            class="ml-2 text-primary"
                            style="text-decoration: underline; cursor: pointer;">
                            Lookup Meursing Code
                          </a>
                        </span>
                        <ng-template #noMeursing>No</ng-template>
                      </td>
                    </tr>
                    <tr>
                      <th>Quotas</th>
                      <td>{{ (tariffData.quotaInfo ?? []).join(', ') || 'N/A' }}</td>
                    </tr>
                    <tr>
                      <th>Anti-Dumping Duty</th>
                      <td>{{ tariffData.antiDumpingDuty || 'N/A' }}</td>
                    </tr>
                    <tr>
                      <th>Countervailing Duty</th>
                      <td>{{ tariffData.countervailingDuty || 'N/A' }}</td>
                    </tr>
                    <tr>
                      <th>Rules of Origin</th>
                      <td>{{ (tariffData.rulesOfOrigin ?? []).join(', ') || 'N/A' }}</td>
                    </tr>
                    <tr>
                      <th>Applicable Trade Agreements</th>
                      <td>
                        <ul *ngIf="(tariffData?.applicableTradeAgreements ?? []).length > 0; else noAgreements">
                          <li *ngFor="let agreement of tariffData?.applicableTradeAgreements ?? []">{{ agreement }}</li>
                        </ul>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </tab>

              <!-- Footnotes Tab -->
              <tab heading="Footnotes">
                <table class="table table-bordered mt-3">
                  <tbody>
                    <tr>
                      <th style="width:25%">Footnotes</th>
                      <td>
                        <ul *ngIf="(tariffData?.footnotes ?? []).length > 0; else noFootnotes">
                          <li *ngFor="let footnote of tariffData?.footnotes ?? []" [innerHTML]="sanitizeHtml(footnote)"></li>
                        </ul>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </tab>
            </tabset>

            <!-- Reusable Templates for N/A -->
            <ng-template #noRates>N/A</ng-template>
            <ng-template #noFootnotes>N/A</ng-template>
            <ng-template #noAgreements>N/A</ng-template>

          </div>
        </div>


        <!-- Step 7 - Confirm Classification -->
        <div *ngIf="selectedIndex === getStepIndex('Step 7: Confirm Classification')" class="form-container">
          <h6>Confirm Classification</h6>

          <!-- Simplified Table -->
          <table class="table table-bordered">
            <tbody>

              <!-- Region Row -->
              <tr>
                <th style="width: 25%;">Region</th>
                <td>
                  <span *ngIf="selectedRegion">
                    <span class="fi" [ngClass]="getRegionClass(selectedRegion ? selectedRegion.id : null)" style="margin-right: 5px;"></span>
                    {{ getRegionDescription(selectedRegion ? selectedRegion.id : null) }}
                  </span>
                  <span *ngIf="!selectedRegion">N/A</span>
                </td>
              </tr>

              <!-- Country of Origin Row -->
              <tr>
                <th style="width: 25%;">Country of Origin</th>
                <td>
                  <span *ngIf="selectedCountry">
                    <span class="fi" [ngClass]="getCountryClass(selectedCountry ? selectedCountry.id : null)" style="margin-right: 5px;"></span>
                    {{ getCountryDescription(selectedCountry ? selectedCountry.id : null) }}
                  </span>
                  <span *ngIf="!selectedCountry">N/A</span>
                </td>
              </tr>

              <!-- Tariff Code Row -->
              <tr>
                <th style="width: 25%;">Tariff Code</th>
                <td>{{ tariffData?.goods_nomenclature_item_id || 'N/A' }}</td>
              </tr>

              <!-- Description Row -->
              <tr>
                <th style="width: 25%;">Description</th>
                <td>{{ tariffData?.description || 'N/A' }}</td>
              </tr>

            </tbody>
          </table>

          <!-- Confirmation Checkbox -->
          <div class="form-check mt-3">
            <input
              type="checkbox"
              id="confirmClassification"
              formControlName="confirmClassification"
              class="form-check-input"
            />
            <label for="confirmClassification" class="form-check-label">
              I confirm that the above information is correct.
            </label>
          </div>
        </div>


      </div>
    </form>
  </mat-dialog-content>
  <hr />
  <mat-dialog-actions align="end">
    <button mat-button (click)="previousStep()" [disabled]="selectedIndex === 0">Previous</button>

    <!-- Disable if: Step 1 invalid OR Step 4 percentages != 100 -->
    <!-- <button
      mat-button
      (click)="nextStep()"
      [disabled]="
        (selectedIndex === 1 && !isStep2Valid()) ||
        (selectedIndex === 3 && !store.selectedItemTypeId())"
    >
      Next
    </button> -->

    <button
      mat-button
      (click)="nextStep()"
    >
      Next
    </button>

    <button
      mat-button
      *ngIf="selectedIndex === steps.length - 1"
      type="submit"
      (click)="submit()"
      [disabled]="!classificationForm.get('confirmClassification')?.value">
      Finish
    </button>


    <button mat-button (click)="close()">Cancel</button>
  </mat-dialog-actions>
</div>
