
<div class="container-fluid">
  <div style="display: flex; justify-content: space-between; align-items: center;">
    <h6>Harmonized System (HS) Headings:</h6>
  </div>
  <table class="table table-striped table-hover">
    <thead>
      <tr>
        <th>Description</th>
        <th>Status</th>
      </tr>
    </thead>
    <tbody>
      <!-- Access signal values directly in the *ngFor loop -->
      <tr *ngFor="let heading of filteredHeadings()">
        <td>{{ heading.description }}</td>
        <td style="text-align: center">
          <ui-switch
            [checked]="heading.active"
            (change)="onToggleActive(heading)"
          >
          </ui-switch>
        </td>
      </tr>
    </tbody>
  </table>
</div>
