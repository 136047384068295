import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { provideNativeDateAdapter } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSelectModule } from '@angular/material/select';

@Component({
  selector: 'app-reactive-form-control-input',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatInputModule,
    MatIconModule,
    MatFormFieldModule,
    MatCheckboxModule,
    MatSlideToggleModule,
    MatSelectModule
  ],
  providers: [provideNativeDateAdapter()],
  templateUrl: './reactive-form-control-input.component.html',
  styleUrl: './reactive-form-control-input.component.scss'
})
export class ReactiveFormControlInputComponent implements OnInit {
 
  readonly DATETIMEFORMAT = "dd MMM yyyy HH:mm";

  @Input() inputElementReadOnly: boolean = false;
  @Input() inputElementID: string | undefined;
  @Input() inputElementType: string | undefined;
  @Input() reactiveFormControlName: string | undefined;
  @Input() inputElementLabel: string | undefined;
  @Input() reactiveFormGroup: FormGroup<any>|any;
  @Output() reactiveFormGroupChange = new EventEmitter<FormGroup>();
  @Output() inputTextOnChangeEvent = new EventEmitter();
  @ViewChild("slideToggleElement") slideToggleElementRef: ElementRef | undefined;
  @Input() inputElementSelectItems: any[]|undefined // optional (mandatory for select element only) 
  @Input() inputElementSelectItemLabelPropertyName: string|undefined // optional (mandatory for select element only)  
  @Input() inputElementSelectCompareWithFunc:((o1: any, o2: any) => boolean // optional (mandatory for select element only) 
  ) | undefined // optional (mandatory for select element only) 

  ngOnInit(): void {

      
    
  }

  onInputTextChangeEvent() {
    this.inputTextOnChangeEvent.emit();
  }

  changeSlideToggleControlValue(reactiveFormControlName:string): void {
    const slideToggleFormControl = this.reactiveFormGroup.controls[reactiveFormControlName];
    this.reactiveFormGroup.controls[reactiveFormControlName].setValue(!slideToggleFormControl.value);
    this.reactiveFormGroup.controls[reactiveFormControlName].markAsDirty();
  }
}
