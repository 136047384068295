import { inject, Inject, Injectable, signal, WritableSignal } from '@angular/core';
import { Router } from '@angular/router';
import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import { AuthenticationResult, EventMessage, EventType, InteractionStatus, InteractionType, PopupRequest, RedirectRequest } from '@azure/msal-browser';
import { UserRole } from '../models/role.model';
import { AppSettingsService } from '@tarifftel/components-library';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  appSettings = inject(AppSettingsService);
  isAuthenticated = false;

  constructor(
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private router: Router,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
  ) {
    this.msalBroadcastService.inProgress$.subscribe((status: InteractionStatus) => {
      if (status === InteractionStatus.None && !this.isAuthenticated) {
        const account = this.authService.instance.getAllAccounts();
        if (account.length === 0) {
          this.login();
        }
      }
    });

    this.msalBroadcastService.msalSubject$.subscribe((result: EventMessage) => {
      if (result.eventType === EventType.LOGIN_SUCCESS || result.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) {
        const payload = result.payload as AuthenticationResult;
        if (payload.account) {
          this.authService.instance.setActiveAccount(payload.account);
          this.isAuthenticated = true;
          this.updateUserName();
          this.getUserProfile();
        }
      }
    });
  }

  login() {
    if (this.isAuthenticated) {
      return;  // Avoid login if already authenticated
    }

    const loginRequest = {
      scopes: [
        'openid',
        'profile',
        'email',
        environment.msalConfig.readScope,
        environment.msalConfig.writeScope
      ],
    };

    if (this.msalGuardConfig.interactionType === InteractionType.Redirect) {
      this.authService.loginRedirect(loginRequest as RedirectRequest);
    } else {
      this.authService.loginPopup(loginRequest as PopupRequest)
        .subscribe((response: AuthenticationResult) => {
          this.authService.instance.setActiveAccount(response.account);
          this.isAuthenticated = true;
        });
    }
  }

  logout() {
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      this.authService.logoutPopup({
        account: this.authService.instance.getActiveAccount(),
      });
    } else {
      this.authService.logoutRedirect({
        account: this.authService.instance.getActiveAccount(),
      });
    }
  }

  getRole(): string {
    const activeAccount = this.authService.instance.getActiveAccount();
    if (activeAccount && activeAccount.idTokenClaims && activeAccount.idTokenClaims.roles) {
      return activeAccount.idTokenClaims.roles[0];
    }
    return '';
  }

  getRolesFromClaims(): UserRole[] {
    const account = this.authService.instance.getActiveAccount();
    if (account && account.idTokenClaims) {
      return (account.idTokenClaims['roles'] || []).map((role: string) => UserRole[role as keyof typeof UserRole]);
    }
    return [];
  }

  getName(): string {
    return this.authService.instance.getActiveAccount()?.name || '';
  }

  private updateUserName(): void {
    const activeAccount = this.authService.instance.getActiveAccount();
    const name = activeAccount?.name || '';
    this.appSettings.setUserName(name); // Update AppSettingsService directly
  }

  getUserProfile() {
    // Implement user profile fetch logic here
  }
}
