import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IMaterial } from '../models/IMaterial';
import { IMaterialCategory } from '../models/IMaterialCategory';

@Injectable({
  providedIn: 'root'
})
export class MaterialService {

  clientUrl = environment.apiUrl;

  constructor(private http: HttpClient) { }

  getMaterials(): Observable<IMaterial[]> {
    return this.http.get<IMaterial[]>(this.clientUrl + "/api/material");
  }

  getMaterialCategories(): Observable<IMaterialCategory[]> {
    return this.http.get<IMaterialCategory[]>(this.clientUrl + "/api/materialcategory");
  }

  // Create a new material
  createMaterial(material: IMaterial): Observable<IMaterial> {
    return this.http.post<IMaterial>(`${this.clientUrl}/api/material`, material, {
      headers: { 'Content-Type': 'application/json' }
    });
  }

  // Update an existing material
  updateMaterial(material: IMaterial): Observable<IMaterial> {
    return this.http.put<IMaterial>(`${this.clientUrl}/api/material/${material.id}`, material, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }

  deleteMaterial(materialId: number): Observable<void> {
    return this.http.delete<void>(`${this.clientUrl}/api/material/${materialId}`);
  }

  // New method for creating a material category
  createCategory(category: IMaterialCategory): Observable<IMaterialCategory> {
    return this.http.post<IMaterialCategory>(`${this.clientUrl}/api/materialcategory`, category, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }

  updateCategory(category: IMaterialCategory): Observable<IMaterialCategory> {
    return this.http.put<IMaterialCategory>(`${this.clientUrl}/api/materialcategory/${category.id}`, category, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }

  deleteCategory(categoryId: number): Observable<void> {
    return this.http.delete<void>(`${this.clientUrl}/api/materialcategory/${categoryId}`);
  }
}
