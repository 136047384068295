import { Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { ReportsComponent } from './reports.component';

export const reportRoutes: Routes = [
  {
    path: 'reports',
    component: ReportsComponent,
    canActivate: [MsalGuard],
  },
];
