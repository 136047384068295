import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { TopPanelComponent } from "../top-panel/top-panel.component";
import { IOrderTopPanelTabItems } from '../top-panel/IOrderTopPanelTabItems';
import { Subscription } from 'rxjs';
import { UserFriendlyHttpErrorMessageDialogComponent } from '../../../settings/vendor-management/user-friendly-http-error-message-dialog/user-friendly-http-error-message-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { OrderService } from '../../../../core/services/order.service';
import { MatCardModule } from '@angular/material/card';
import { AggridOrdersComponent } from "../../aggrid-orders/aggrid-orders.component";
import { IOrderLineCardItem } from './IOrderLineCardItem';
import { BreadcrumbComponent } from "../../../../core/components/breadcrumb/breadcrumb.component";
import { IOrderLineV2 } from '../../../../core/models/IOrderLineV2';

@Component({
  selector: 'app-order-line-details',
  standalone: true,
  imports: [
    CommonModule,
    TopPanelComponent,
    MatCardModule,
    AggridOrdersComponent,
    BreadcrumbComponent
],
  templateUrl: './order-line-details.component.html',
  styleUrl: './order-line-details.component.scss'
})
export class OrderLineDetailsComponent {
  orderId: number | undefined;
  orderLine:IOrderLineV2 | undefined;
  orderLineId:number|undefined;
  orderLineSubscription:Subscription|undefined;
  showOrderLineDetailLoadingSpinner:boolean = true;
  topPanelTitle:string|undefined = "Order Line";
  tabItems:IOrderTopPanelTabItems[] = [
    {
      tabLabel:"Order Line Details",
      objectPropertyIndexStart:0,
      objectPropertyIndexEnd:10,
      hideIds:true
    },
    {
      tabLabel:"Product Information",
      objectPropertyIndexStart:10,
      objectPropertyIndexEnd:21,
      hideIds:true
    }
  ]
  orderLineCardItems:IOrderLineCardItem[] = []

  constructor(private dialog:MatDialog,private route: ActivatedRoute,private orderService:OrderService) {
  }


  ngOnInit() {
    this.showOrderLineDetailLoadingSpinner = true;
    this.parseIdQueryStringParam();

  }

  parseIdQueryStringParam() {
    const orderIdStr = this.route.snapshot.paramMap.get('id');
    this.orderId = orderIdStr ? parseInt(orderIdStr) : undefined;

    const orderLineIdStrQueryParam:string | null = this.route.snapshot.paramMap.get('lineId');

    if(orderLineIdStrQueryParam) {
      this.orderLineId = +orderLineIdStrQueryParam
      this.orderLineSubscription = this.orderService.getOrderLineById(this.orderLineId).subscribe({
        next:(orderLineResponse:any)=> {
          this.orderLine = orderLineResponse;
          console.log(this.orderLine);
          //this.topPanelTitle = "Order Line"
          // this.orderLineCardItems = [
          //   {
          //     cardTitle:"Product Audit",
          //     aggridObjectListToShow:this.orderLine?.productAudits
          //   }

          // ]
        },
        error:(error:Error) => {
          this.dialog.open(UserFriendlyHttpErrorMessageDialogComponent);
          this.showOrderLineDetailLoadingSpinner = false;
        },
        complete:() => {
          this.showOrderLineDetailLoadingSpinner = false;
        }
      });
    }
    else {
      this.showOrderLineDetailLoadingSpinner = false;
    }
  }
}
