<mat-card class="guidelines-card mx-2 my-2">
    <mat-card-header>
        <mat-card-title><h4>Excel File Content Guidelines For Bulk Data Loading:</h4></mat-card-title>
    </mat-card-header>
    <mat-divider></mat-divider>
    <mat-card-content class="guidelines-mat-card-content">
        <ng-container *ngIf="showGuidelinesLoadingSpinner">
            <div class="mat-spinner-container">
                <mat-spinner class="ms-1"></mat-spinner>
            </div>  
        </ng-container>
        <ng-container *ngIf="!showGuidelinesLoadingSpinner"> 
            <ul class="list-group mt-3">
                <li class="list-group-item">
                    <div class="ps-2 pt-2">
                        <h5>Please ensure that the structure of the excel file you intend to attach matches the sample provided below:</h5>
                    </div>
                    <ul >
                        <li><a href="{{sampleFilePath}}" download>{{sampleFileLinkName}}</a></li>
                        <li><b>The excel file must be version 2007 or later (file extension .xlsx)</b></li>
                        <li><b>The excel file must contain the header row as shown in the sample file</b></li>
                    </ul>
                </li>
                <li class="list-group-item">
                        <h5 class="ps-2 pt-2">Required/Mandatory fields:</h5>
                        <ul >
                            <li *ngFor="let mandatoryField of sampleFileMandatoryFields">{{mandatoryField}}</li>
                        </ul>
                </li>
                <li *ngIf="vendorMode" class="list-group-item">
                    <h5 class="ps-2 pt-2">The Region field must contain one of the following values:</h5>
                    <ul class="regions-list">
                        <li *ngFor="let region of regionStore.regions()">{{region.description}}</li>
                    </ul>
                </li>
                <li *ngIf="sampleFileNonMandatoryFieldsGuidelines && sampleFileNonMandatoryFieldsGuidelines!.length > 0" class="list-group-item">
                    <h5 class="ps-2 pt-2">Optional fields:</h5>
                    <ul >
                        <li *ngFor="let optionalField of sampleFileNonMandatoryFieldsGuidelines">{{optionalField}}</li>
                    </ul>
                </li>
            </ul>
        </ng-container> 
    </mat-card-content>
</mat-card>
<mat-card *ngIf="!showGuidelinesLoadingSpinner" class="mx-2 my-2 upload-file-card">
    <mat-card-content>
      
        <div *ngIf="!showUploadLoadingSpinner" class="row pt-2">
            <div>
                <button type="button" mat-raised-button (click)="fileInput.click()" [color]="'primary'">{{getAttachFileToBeginButtonLabel()}}</button>
                <input #fileInput hidden (change)="onFileSelected($event)"  type="file" [attr.accept]="acceptedFileTypes" [(ngModel)]="fileInputSelectValue">
            </div>
        </div>
        <ng-container *ngIf="showUploadLoadingSpinner">
            <div class="mat-spinner-load-data-container">
                <div class="mat-spinner-load-data">
                    <mat-spinner></mat-spinner>
                </div>
                <h5 class="mt-4">Validating and loading file data into system...</h5>
            </div>  
        </ng-container>
        <div *ngIf="uploadCompleteValidationNoRejection" class="row py-3 upload-complete-no-rejection">
            <div class="py-3">
                <h5>Bulk {{getModeEntityString()}} data load complete!</h5>
            </div>
            <div *ngIf="viewAllRecordsLinkPath">
                <div class="py-2">
                    <a [routerLink]="viewAllRecordsLinkPath">View {{getModeEntityString()}} grid</a>
                </div>
            </div>
        </div>
        <div *ngIf="uploadCompleteValidationRejection" class="row py-3">
            <div class="row">
                <h5>Bulk data load was unsuccessful due to the following file content validation rejection items:</h5>
            </div>
            <div *ngIf="rejectionTextAreaTextContent.length > 0" class="row mx-2 mt-2">
                <div>
                    <ul >
                        <li><a [download]='"tarifftelbulk" + getModeEntityString() + "uploadrejectionlog.txt"' [href]="validationRejectionFileLocalDownloadHref">Download Rejection Log</a></li>
                    </ul>
                </div>
            </div>
            <div class="row">
                <div class="py-1">
                    <mat-form-field appearance="outline" class="validation-error-textarea-form-field">
                        <textarea matInput 
                        [(ngModel)]="rejectionTextAreaTextContent" 
                        [cdkTextareaAutosize]="true" 
                        cdkAutosizeMinRows="13"
                        cdkAutosizeMaxRows="13"
                        readonly></textarea>
                    </mat-form-field>
                </div>
            </div>
          
        </div>
    </mat-card-content>
</mat-card>



