import { CommonModule, NgIf } from '@angular/common';
import { Component, inject, OnInit, ChangeDetectorRef  } from '@angular/core';
import { ConstructionListComponent } from "./construction-list/construction-list.component";
import { ConstructionCategoryListComponent } from "./construction-category-list/construction-category-list.component";
import { TabsModule } from 'ngx-bootstrap/tabs';
import { FormsModule } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ConstructionCategoryStore } from './store/constructionstore';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-construction-mgmt',
  standalone: true,
  imports: [CommonModule, NgIf, ConstructionListComponent, ConstructionCategoryListComponent, TabsModule, FormsModule],
  templateUrl: './construction-mgmt.component.html',
  styleUrl: './construction-mgmt.component.scss'
})
export class ConstructionMgmtComponent implements OnInit {
  readonly CONSTRUCTIONS: 'constructions' = 'constructions';
  readonly CONSTRUCTIONCATEGORIES: 'constructioncategories' = 'constructioncategories';

  selectedItem: 'constructions' | 'constructioncategories' = this.CONSTRUCTIONS; // Default to 'constructions'
  searchTerm: string = '';

  cdr = inject(ChangeDetectorRef);
  store = inject(ConstructionCategoryStore);
  toastrService = inject(ToastrService);

  private searchSubject = new Subject<string>();

  ngOnInit() {
    this.store.clearCache();
    this.store.loadData();

    // Only set initial state for `selectedItemTypeGroupId` if the list is 'constructions'
    if (this.store.selectedList() === this.CONSTRUCTIONS && this.store.selectedItemTypeGroupId() === null) {
      this.store.selectFirstItemTypeGroup();
    }

    this.store.setSearchTerm(this.searchTerm);

    this.cdr.detectChanges();
  }

  onSearchTermChange(term: string) {
    this.searchTerm = term;
    this.searchSubject.next(term);
  }

  resetFilters() {
    this.searchTerm = '';
    this.store.setSearchTerm(''); // Update the store's search term
    this.toastrService.info('Filters have been reset', 'Reset');
  }

  forceRefresh() {
    this.store.clearCache();
    this.store.loadData();
    this.toastrService.info('Data has been refreshed', 'Refreshed');
  }

  // Updated method to switch between constructions and construction categories
  showTable(item: 'constructions' | 'constructioncategories') {
    this.store.showTable(item);

    // Only select the first Item Type Group when switching to 'constructions'
    if (item === this.CONSTRUCTIONS && this.store.selectedItemTypeGroupId() === null) {
      this.store.selectFirstItemTypeGroup();
    }
  }
}
