<hr />
<div class="d-flex justify-content-between align-items-center mb-3 mt-2">
  <!-- Search Field -->
  <div class="col-lg-6">
    <input
      type="text"
      class="form-control"
      placeholder="Search by Attribute, Value and Shared Attributes"
      [(ngModel)]="searchTerm"
      (ngModelChange)="onSearchTermChange($event)"
    />
  </div>

  <!-- Filters and Buttons -->
  <div class="col-lg-6 text-end">
    <!-- Reset Button -->
    <button class="btn btn-warning me-2" (click)="resetFilters()" aria-label="Reset filters and search">
      Reset
    </button>
    <!-- Refresh Button -->
    <button class="btn btn-secondary" (click)="forceRefresh()" aria-label="Refresh data">
      Refresh Data
    </button>
  </div>

</div>
<hr />
<div class="grid-container">
  <div class="left-column">
    <div class="container-fluid">
      <div class="itemtypeattributes">
        <div>
          <h6>Select an Option Below:</h6>
          <ul class="list-group">
            <li
              class="list-group-item d-flex justify-content-between align-items-center"
              [ngClass]="{ 'active': store.selectedList() === 'typesattributevalues' }"
              (click)="showTable('typesattributevalues')"
            >
              Types, Attributes & Values
            </li>
            <!-- <li
              class="list-group-item d-flex justify-content-between align-items-center"
              [ngClass]="{ 'active': store.selectedList() === 'sharedattributes' }"
              (click)="showTable('sharedattributes')"
            >
              Shared Attribute Management
            </li> -->
            <li
              class="list-group-item d-flex justify-content-between align-items-center"
              [ngClass]="{ 'active': store.selectedList() === 'itemtypes' }"
              (click)="showTable('itemtypes')"
            >
              Item Types
            </li>
          </ul>
        </div>
      </div>

      <div class="itemtypeattributes" *ngIf="store.selectedList() === 'typesattributevalues' || store.selectedList() === 'itemtypes'">
        <hr />
        <div>
          <h6>Select an Item Type Group:</h6>
          <div class="scrollable-item-group-list">
            <ul class="list-group">
              <li
                class="list-group-item d-flex justify-content-between align-items-center"
                *ngFor="let group of store.itemTypeGroups()"
                [ngClass]="{ 'active': group.id === store.selectedItemTypeGroupId() }"
                (click)="store.handleGroupClick(group)"
              >
                {{ group.description }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="right-column">
    <div class="container-fluid" *ngIf="store.selectedList() === 'typesattributevalues'">
      <div class="row">
        <div class="col-md-6">
          <app-attribute-list></app-attribute-list>
        </div>
        <div class="col-md-6">
          <app-attribute-value-list></app-attribute-value-list>
        </div>
      </div>
    </div>

    <!-- <div class="container-fluid" *ngIf="store.selectedList() === 'sharedattributes'">
      <div class="row">
        <div class="col-md-6">
          <app-shared-attribute-list></app-shared-attribute-list>
        </div>
        <div class="col-md-6">
          <app-shared-attribute-value-list></app-shared-attribute-value-list>
        </div>
      </div>
    </div> -->

    <div class="container-fluid" *ngIf="store.selectedList() === 'itemtypes'">
      <div class="row">
        <div class="col-md-12">
          <app-item-type-list></app-item-type-list>
        </div>
      </div>
  </div>
</div>

