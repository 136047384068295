<hr />
<div class="container-fluid">
  <div class="search-container">
    <div class="d-flex justify-content-end align-items-center mb-3 mt-2">
      <button class="btn btn-warning me-2" (click)="resetFilters()" aria-label="Reset filters and search">Reset</button>
      <button class="btn btn-secondary" (click)="forceRefresh()" aria-label="Refresh data">Refresh Data</button>
    </div>
  </div>

  <hr />
  <div class="grid-container">
    <div class="left-column">
      <h6>Select an Option Below:</h6>
      <ul class="list-group">
        <li
          class="list-group-item d-flex justify-content-between align-items-center"
          [ngClass]="{ 'active': store.selectedList() === 'materials' }"
          (click)="showTable('materials')"
        >
          Materials
        </li>
        <li
          class="list-group-item d-flex justify-content-between align-items-center"
          [ngClass]="{ 'active': store.selectedList() === 'materialcategories' }"
          (click)="showTable('materialcategories')"
        >
          Material Categories
        </li>
      </ul>

      <hr />

      <div *ngIf="store.selectedList() === 'materials'">
        <h6>Select an Item Type Group:</h6>
        <div class="scrollable-item-group-list">
          <ul class="list-group">
            <li
              class="list-group-item d-flex justify-content-between align-items-center"
              *ngFor="let group of store.itemTypeGroups()"
              [ngClass]="{ 'active': group.id === store.selectedItemTypeGroupId() }"
              (click)="store.handleGroupClick(group)"
            >
              {{ group.description }}

              <span class="region-flags">
                <span
                  *ngFor="let region of store.regionsWithMaterialsForGroup(group.id)"
                  [class]="region.class"
                  title="{{ region.description }}"
                  style="font-size: 16px; margin-left: 5px;"
                ></span>
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="right-column">
      <div *ngIf="store.selectedList() === 'materials'">
        <app-material-list></app-material-list>
      </div>
      <div *ngIf="store.selectedList() === 'materialcategories'">
        <app-material-category-list></app-material-category-list>
      </div>

    </div>
  </div>
</div>

