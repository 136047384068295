import { Component, inject, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatOptionModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { AttributeListingStore } from '../store/attributelistingstore';
import { ToastrService } from 'ngx-toastr';
import { CommonModule, NgFor, NgIf } from '@angular/common';
import { IAttributeValue } from '../../../../core/models/IAttributeValue';
import { MatTabsModule } from '@angular/material/tabs';
import { MatIconModule } from '@angular/material/icon';
import { ISubAttributeValue } from '../../../../core/models/ISubAttributeValue';
import { ISubAttribute } from '../../../../core/models/ISubAttribute';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  selector: 'app-attribute-value-dialog',
  standalone: true,
  imports: [ReactiveFormsModule,
    CommonModule,
    MatFormFieldModule,
    MatOptionModule,
    MatInputModule,
    MatSelectModule,
    MatCheckboxModule,
    MatIconModule,
    MatButtonModule,
    MatDialogModule,
    MatTooltipModule,
    MatTabsModule,
    TabsModule,
    FormsModule,
    NgFor,
    NgIf],
  templateUrl: './attribute-value-dialog.component.html',
  styleUrl: './attribute-value-dialog.component.scss'
})
export class AttributeValueDialogComponent implements OnInit {
  attributeValueForm: FormGroup;
  selectedImageFile: File | null = null; // Store the selected image
  imagePreview: string | null = null; // Store the preview URL
  selectedSubAttribute: ISubAttribute | null = null;
  editMode: boolean;
  imageFile: File | null = null;
  isSaving = false;

  store = inject(AttributeListingStore);
  toastrService = inject(ToastrService);

  constructor(
    public dialogRef: MatDialogRef<AttributeValueDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { attributeValue: IAttributeValue; editMode: boolean },
    private fb: FormBuilder
  ) {
    this.editMode = data.editMode;

    this.attributeValueForm = this.fb.group({
      valueName: [data.attributeValue.valueName, [Validators.required, Validators.maxLength(500)], // Mandatory field
      ],
      description: [data.attributeValue.description || '', Validators.maxLength(1500)], // Optional
      aliases: this.fb.array(
        data.attributeValue.attributeValueAliases?.map(alias =>
          this.fb.control(alias.alias, Validators.required) // Optional aliases
        ) || []
      ),
      newAlias: [''], // Optional
      newSubAttribute: [''], // Optional
      newSubAttributeValue: [''], // Optional
      images: this.fb.array(
        data.attributeValue.attributeValueImages?.map(image => this.fb.control(image.imageUrl)) || []
      ),
    });


  }

  ngOnInit(): void {
    if (this.editMode && this.data.attributeValue.id) {
      this.store.loadSubAttributes(this.data.attributeValue.id); // Load all SubAttributes for this AttributeValue
    }

    this.data.attributeValue.subAttributes = this.data.attributeValue.subAttributes || [];
  }



  get aliases(): FormArray<FormControl> {
    return this.attributeValueForm.get('aliases') as FormArray<FormControl>;
  }

  addAlias(): void {
    const aliasValue = this.attributeValueForm.get('newAlias')?.value?.trim();
    if (aliasValue) {
      this.aliases.push(this.fb.control(aliasValue, Validators.required));
      this.attributeValueForm.get('newAlias')?.reset();
    }
  }

  isDuplicateSubAttribute(subAttributeName: string, subAttributes: ISubAttribute[]): boolean {
    return subAttributes.some(
      (subAttr) =>
        subAttr.subAttributeName.toLowerCase() === subAttributeName.toLowerCase()
    );
  }

  addSubAttribute(): void {
    const subAttributeName = this.attributeValueForm.get('newSubAttribute')?.value?.trim();
    if (subAttributeName) {
      const newSubAttribute: ISubAttribute = {
        id: Math.floor(Math.random() * -1000), // Temporary negative ID for frontend-only sub-attributes
        subAttributeName,
        attributeValueId: this.data.attributeValue.id,
        subAttributeValues: [],
      };

      this.data.attributeValue.subAttributes = this.data.attributeValue.subAttributes || [];
      this.data.attributeValue.subAttributes.push(newSubAttribute);

      // Automatically select the new sub-attribute
      this.selectSubAttribute(newSubAttribute);

      this.attributeValueForm.get('newSubAttribute')?.reset();
    }
  }

  isDuplicateSubAttributeValue(subAttributeValueName: string, subAttributeValues: ISubAttributeValue[]): boolean {
    return subAttributeValues.some(
      (subAttrVal) =>
        subAttrVal.valueName.toLowerCase() === subAttributeValueName.toLowerCase()
    );
  }

  addSubAttributeValue(subAttributeId?: number): void {
    if (!subAttributeId) {
      console.error('Invalid Sub Attribute ID');
      return;
    }

    const subAttributeValueName = this.attributeValueForm.get('newSubAttributeValue')?.value?.trim();
    if (subAttributeValueName) {
      const newSubAttributeValue: ISubAttributeValue = {
        id: Math.floor(Math.random() * -1000), // Temporary unique negative ID
        valueName: subAttributeValueName,
        subAttributeId,
      };

      const subAttribute = this.data.attributeValue.subAttributes?.find(sa => sa.id === subAttributeId);
      if (subAttribute) {
        subAttribute.subAttributeValues = subAttribute.subAttributeValues || [];
        subAttribute.subAttributeValues.push(newSubAttributeValue);
        console.log('Sub Attribute Value Added:', newSubAttributeValue);
        this.attributeValueForm.get('newSubAttributeValue')?.reset();
      } else {
        console.error('Sub Attribute not found for ID:', subAttributeId);
      }
    }
  }


  removeSubAttribute(subAttributeId: number, event: MouseEvent): void {
    event.stopPropagation(); // Prevent selecting the sub-attribute when clicking the remove cross
    const subAttribute = this.data.attributeValue.subAttributes?.find(sa => sa.id === subAttributeId);

    if (subAttribute && !subAttribute.subAttributeValues?.length) {
      this.data.attributeValue.subAttributes = this.data.attributeValue.subAttributes?.filter(
        sa => sa.id !== subAttributeId
      );
      console.log('Removed Sub Attribute:', subAttributeId);
    } else {
      console.error('Cannot remove Sub Attribute with attached values.');
    }
  }


  removeSubAttributeValue(subAttributeId: number, subAttributeValueId: number): void {
    console.log('Removing Sub Attribute Value:', subAttributeValueId, 'from Sub Attribute:', subAttributeId);

    const subAttribute = this.data.attributeValue.subAttributes?.find(sa => sa.id === subAttributeId);

    if (subAttribute) {
      subAttribute.subAttributeValues = subAttribute.subAttributeValues?.filter(
        value => value.id !== subAttributeValueId
      ) || [];
      console.log('Updated Sub Attribute Values for ID:', subAttributeId, subAttribute.subAttributeValues);
    } else {
      console.error('Sub Attribute not found for ID:', subAttributeId);
    }
  }


  selectSubAttribute(subAttribute: ISubAttribute | null): void {
    this.data.attributeValue.subAttributes?.forEach(attr => {
      attr.isActive = attr === subAttribute; // Mark only the selected sub-attribute as active
    });

    this.selectedSubAttribute = subAttribute;

    if (subAttribute) {
      this.attributeValueForm.get('newSubAttributeValue')?.enable(); // Enable input for sub-attribute values
    } else {
      this.attributeValueForm.get('newSubAttributeValue')?.disable(); // Disable input when none selected
    }
  }

  onEnterKey(event: Event, action: 'subAttribute' | 'subAttributeValue'): void {
    const keyboardEvent = event as KeyboardEvent; // Explicitly cast to KeyboardEvent
    keyboardEvent.preventDefault(); // Prevent form submission
    if (action === 'subAttribute') {
      this.addSubAttribute();
    } else if (action === 'subAttributeValue' && this.selectedSubAttribute) {
      this.addSubAttributeValue(this.selectedSubAttribute.id);
    }
  }

  removeAlias(index: number): void {
    this.aliases.removeAt(index);
  }


  onImageSelected(event: Event): void {
    const fileInput = event.target as HTMLInputElement;
    if (fileInput.files && fileInput.files.length > 0) {
      this.selectedImageFile = fileInput.files[0];

      // Generate preview for the selected image
      const reader = new FileReader();
      reader.onload = () => {
        this.imagePreview = reader.result as string;
      };
      reader.readAsDataURL(this.selectedImageFile);
    }
  }

  onSave(): void {
    if (this.attributeValueForm.valid) {
      this.isSaving = true;

      const attributeValueData: IAttributeValue = {
        ...this.data.attributeValue,
        ...this.attributeValueForm.value,
        attributeValueAliases: this.aliases.value.map((alias: string) => ({
          id: 0, // Default ID for new aliases
          alias,
          attributeValueId: this.data.attributeValue.id,
        })),
        subAttributes: (this.data.attributeValue.subAttributes ?? []).map(subAttribute => ({
          ...subAttribute,
          subAttributeValues: subAttribute.subAttributeValues ?? [], // Provide default empty array
        })),
      };

      if (this.store.isDuplicateAttributeValue(attributeValueData)) {
        this.toastrService.error('Attribute value already exists.');
        this.isSaving = false;
        return;
      }

      const saveOperation = this.editMode
        ? this.store.updateAttributeValue(attributeValueData, this.selectedImageFile || undefined) // Handle null case
        : this.store.createAttributeValue(attributeValueData, this.selectedImageFile || undefined);

      saveOperation.subscribe({
        next: (savedAttributeValue) => {
          this.dialogRef.close(savedAttributeValue);
          this.isSaving = false;
          this.toastrService.success('Attribute value saved successfully!');
        },
        error: (error) => {
          if (error.status === 500) {
            this.toastrService.error('Failed to save attribute value.');
          }
          else {
            this.toastrService.error(error.error);
          }
          this.isSaving = false;
        },
      });
    }
  }



  onCancel(): void {
    this.selectedSubAttribute = null;
    this.dialogRef.close();
  }
}
