import { Component, computed, EventEmitter, inject, Input, Output, signal } from '@angular/core';
import { CommonModule, NgFor, NgIf } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { MatTabsModule } from '@angular/material/tabs';
import { TariffBookStore } from '../store/tariffbookstore';
import { ITariffItem } from '../../../../core/models/ITariffItem';

@Component({
  selector: 'app-harmonized-codes-by-heading',
  standalone: true,
  imports: [
    NgFor,
    NgIf,
    FormsModule,
    TabsModule,
    MatTabsModule,
    CommonModule
  ],
  templateUrl: './harmonized-codes-by-heading.component.html',
  styleUrls: ['./harmonized-codes-by-heading.component.scss']
})
export class HarmonizedCodesByHeadingComponent {
  @Input() headingCode: string = '';
  @Output() selectedCode = new EventEmitter<string>();

  store = inject(TariffBookStore);

  subHeadings = this.store.subHeadings;
  eightDigitCommodities = this.store.eightDigitCommodities;
  tenDigitCommodities = this.store.tenDigitCommodities;

  // If you want to highlight the currently selected items, you can read them from the store as well,
  // or keep local references. Choose one approach consistently:
  selectedSubHeading: ITariffItem | null = null;
  selectedEightDigitCommodity: ITariffItem | null = null;
  selectedTenDigitCommodity: ITariffItem | null = null;

  isLoading = computed(() => this.store.loading());
  selectedCodeType = computed(() => this.store.selectedCodeType());

  searchTerm6Digit = '';
  searchTerm8Digit = '';
  searchTerm10Digit = '';

  // Filtered signals
  get filteredSubHeadings(): ITariffItem[] {
    const all = this.subHeadings();
    if (!this.searchTerm6Digit.trim()) return all;
    const term = this.searchTerm6Digit.toLowerCase();
    return all.filter(item =>
      item.code.toLowerCase().includes(term) ||
      item.description.toLowerCase().includes(term)
    );
  }

  get filteredEightDigitCommodities(): ITariffItem[] {
    const all = this.eightDigitCommodities();
    if (!this.searchTerm8Digit.trim()) return all;
    const term = this.searchTerm8Digit.toLowerCase();
    return all.filter(item =>
      item.code.toLowerCase().includes(term) ||
      item.description.toLowerCase().includes(term)
    );
  }

  get filteredTenDigitCommodities(): ITariffItem[] {
    const all = this.tenDigitCommodities();
    if (!this.searchTerm10Digit.trim()) return all;
    const term = this.searchTerm10Digit.toLowerCase();
    return all.filter(item =>
      item.code.toLowerCase().includes(term) ||
      item.description.toLowerCase().includes(term)
    );
  }

  get count6Digit(): number {
    return this.filteredSubHeadings.length;
  }
  get count8Digit(): number {
    return this.filteredEightDigitCommodities.length;
  }
  get count10Digit(): number {
    return this.filteredTenDigitCommodities.length;
  }

  // The local 'view toggle':
  descriptionType = signal<'6-digit' | '8-digit' | '10-digit'>('6-digit');

  showSixDigitView() {
    this.descriptionType.set('6-digit');
  }
  showEightDigitView() {
    this.descriptionType.set('8-digit');
  }
  showTenDigitView() {
    this.descriptionType.set('10-digit');
  }

  selectSubHeading(item: ITariffItem) {
    this.selectedSubHeading = item;    // local highlight
    // Let the store know the code:
    this.store.select6DigitCode(item.code);
    this.selectedCode.emit(item.code);
  }
  selectEightDigitCommodity(item: ITariffItem) {
    this.selectedEightDigitCommodity = item;
    this.store.select8DigitCode(item.code);
  }
  selectTenDigitCommodity(item: ITariffItem) {
    this.selectedTenDigitCommodity = item;
    this.store.select10DigitCode(item.code);
  }

  applyFilter() {
    // triggers your getters to refilter
  }
}
