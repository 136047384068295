import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ItemTypeService } from '../../../../core/services/itemtype.service';
import { NgFor, NgIf } from '@angular/common';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-item-types-table',
  standalone: true,
  imports: [NgIf, NgFor],
  templateUrl: './item-types-table.component.html',
  styleUrl: './item-types-table.component.scss'
})
export class ItemTypesTableComponent implements OnChanges {
  @Input() code!: string; // The selected code (6-digit, 8-digit, or 10-digit)
  @Input() codeType: '6-digit' | '8-digit' | '10-digit' | 'unknown' = 'unknown'; // Code type to distinguish between code types
  itemTypes: any[] = []; // Store the fetched item types

  isLoading: boolean = false; // Loading indicator

  constructor(private itemTypeService: ItemTypeService, private toastrService: ToastrService) {}

  ngOnChanges(changes: SimpleChanges): void {
    // if (changes['code'] && this.code && this.codeType !== 'unknown') {
    //   this.loadItemTypes(this.code, this.codeType);
    // } else {
    //   this.itemTypes = [];
    // }
  }

  loadItemTypes(code: string, type: '6-digit' | '8-digit' | '10-digit'): void {
    this.isLoading = true;
    this.itemTypeService.getItemTypesByTariffCode(code, type).subscribe(
      (types) => {
        this.itemTypes = types;
        this.isLoading = false;
      },
      (error) => {
        console.error('Error loading item types:', error);
        this.toastrService.error('Failed to load Item Types', 'Error');
        this.isLoading = false;
      }
    );
  }

  deleteItemType(itemTypeId: number): void {
    if (confirm('Are you sure you want to delete this Item Type?')) {
      this.itemTypeService.deleteItemType(itemTypeId).subscribe(
        () => {
          this.itemTypes = this.itemTypes.filter((item) => item.id !== itemTypeId);
          this.toastrService.success('Item Type deleted successfully', 'Success');
        },
        (error) => {
          console.error('Error deleting item type:', error);
          this.toastrService.error('Failed to delete Item Type', 'Error');
        }
      );
    }
  }
}
