import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ISizeCategory } from '../models/ISizeCategory';
import { IDefaultSize } from '../models/IDefaultSize';
import { IGenderMapping } from '../models/IGenderMapping';

@Injectable({
  providedIn: 'root'
})
export class SizeService {
  clientUrl = environment.apiUrl;

  constructor(private http: HttpClient) { }

  getSizes(): Observable<IDefaultSize[]> {
    return this.http.get<IDefaultSize[]>(`${this.clientUrl}/api/defaultsize`);
  }

  getSizeCategories(): Observable<ISizeCategory[]> {
    return this.http.get<ISizeCategory[]>(`${this.clientUrl}/api/sizecategory`);
  }

  // New method to get size categories by ItemTypeGroup ID
  getSizeCategoriesByGroup(itemTypeGroupId: number): Observable<ISizeCategory[]> {
    return this.http.get<ISizeCategory[]>(`${this.clientUrl}/api/sizecategory/group/${itemTypeGroupId}`);
  }

  // Create a new size
  createSize(size: IDefaultSize): Observable<IDefaultSize> {
    return this.http.post<IDefaultSize>(`${this.clientUrl}/api/defaultsize`, size, {
      headers: { 'Content-Type': 'application/json' }
    });
  }

  // Update an existing size
  updateSize(size: IDefaultSize): Observable<IDefaultSize> {
    return this.http.put<IDefaultSize>(`${this.clientUrl}/api/defaultsize/${size.id}`, size, {
      headers: { 'Content-Type': 'application/json' }
    });
  }

  deleteSize(sizeId: number): Observable<void> {
    return this.http.delete<void>(`${this.clientUrl}/api/defaultsize/${sizeId}`);
  }

  createCategory(category: ISizeCategory): Observable<ISizeCategory> {
    return this.http.post<ISizeCategory>(`${this.clientUrl}/api/sizecategory`, category, {
      headers: { 'Content-Type': 'application/json' }
    });
  }

  updateCategory(category: ISizeCategory): Observable<ISizeCategory> {
    console.log("Category Id should be: ", category.id);
    return this.http.put<ISizeCategory>(`${this.clientUrl}/api/sizecategory/${category.id}`, category, {
      headers: { 'Content-Type': 'application/json' }
    });
  }

  deleteCategory(categoryId: number): Observable<void> {
    return this.http.delete<void>(`${this.clientUrl}/api/sizecategory/${categoryId}`);
  }

  // New method to get gender mappings by ItemTypeGroup ID
  getGenderMappingsByGroup(itemTypeGroupId: number): Observable<IGenderMapping[]> {
    return this.http.get<IGenderMapping[]>(`${this.clientUrl}/api/gendermapping/group/${itemTypeGroupId}`);
  }

  // Get all gender mappings (for cases when you want all mappings)
  getAllGenderMappings(): Observable<IGenderMapping[]> {
    return this.http.get<IGenderMapping[]>(`${this.clientUrl}/api/gendermapping`);
  }

  // Update a specific gender mapping
  updateGenderMapping(genderMapping: Partial<IGenderMapping>): Observable<IGenderMapping> {
    return this.http.put<IGenderMapping>(`${this.clientUrl}/api/gendermapping/${genderMapping.sizeCategoryId}`, genderMapping);
  }

  // Optionally, create a new gender mapping
  createGenderMapping(genderMapping: IGenderMapping): Observable<IGenderMapping> {
    return this.http.post<IGenderMapping>(`${this.clientUrl}/api/gendermapping`, genderMapping);
  }

  // Optionally, delete a gender mapping by ID
  deleteGenderMapping(sizeCategoryId: number): Observable<void> {
    return this.http.delete<void>(`${this.clientUrl}/api/gendermapping/${sizeCategoryId}`);
  }
}
