import { Component, effect, input, output } from '@angular/core';
import { IInboxItemsFilter } from '../../../core/models/IInboxItemsFilter';
import { ReactiveFormsModule, FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'app-inbox-items-filter',
  standalone: true,
  imports: [ReactiveFormsModule],
  templateUrl: './inbox-items-filter.component.html',
  styleUrl: './inbox-items-filter.component.scss'
})
export class InboxItemsFilterComponent {
  onOnlyCheckboxesChange = output<Partial<IInboxItemsFilter>>();
  onQueryChange = output<string>();

  query = new FormControl('');

  onlyCheckboxes = new FormGroup({
    unreadOnly: new FormControl(false),
    unresolvedOnly: new FormControl(false)
  });
  
  ngOnInit() {
    this.query.valueChanges.subscribe(value => this.onQueryChange.emit(value ?? ""));

    this.onlyCheckboxes.valueChanges.subscribe(value => {
      if (value && value.unreadOnly != null && value.unresolvedOnly != null)
        this.onOnlyCheckboxesChange.emit({ unresolvedOnly: value.unresolvedOnly, unreadOnly: value.unreadOnly });
    });
  }
}
