@if (loading()) {
<div class="d-flex justify-content-center align-items-center h-100 ">
    <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
    </div>
</div>
}
@if (pane() && !loading()) {
<div id="reading-pane" class="d-flex flex-column h-100 m-1">
    <div id="pane-head" class="border border-bottom-0 rounded-top-4">
        <div class="row mt-1">
            <div class="col ms-3 my-2">
                <span>
                    Question to <strong>{{ pane()?.questionTarget }}</strong> about Order {{
                    pane()?.orderNumber }}
                    @if (pane()?.orderLineCode) {
                    Line {{ pane()?.orderLineCode }}
                    }
                </span>
                <h5 class="my-2">{{ pane()?.subject }}</h5>
            </div>
            <div class="col-auto ms-auto">
                <button mat-icon-button [matMenuTriggerFor]="menu">
                    <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                    <button mat-menu-item [routerLink]="['/orders/order-details/', pane()?.orderId]">
                        <mat-icon>
                            local_shipping
                        </mat-icon>
                        <span>Go to Order {{ pane()?.orderNumber }}</span>
                    </button>
                    @if (pane()?.orderLineCode) {
                    <button mat-menu-item
                        [routerLink]="['/orders/order-details/', pane()?.orderId, '', pane()?.orderLineCode]">
                        <mat-icon>
                            local_shipping
                        </mat-icon>
                        <span>Go to Line {{ pane()?.orderLineCode }}</span>
                    </button>
                    }
                    @if (!pane()?.askCustomsTeamEnabled) {
                    <button mat-menu-item>
                        <mat-icon>person_raised_hand</mat-icon>
                        <span>Ask Customs Team for help</span>
                    </button>
                    }
                </mat-menu>
            </div>
        </div>
    </div>
    <div id="pane-msgs" class="flex-grow-1 overflow-y-hidden align-content-end bg-body border">
        <app-inbox-message-list [messages]="pane()?.messages || []"></app-inbox-message-list>
    </div>
    <div id="pane-reply">
        @if (pane()?.responseEnabled) {
        <form class="px-2 py-1 border border-top-0">
            <div class="input-group">
                @if (pane()?.resolveEnabled) {
                <div class="input-group-text text-bg-success rounded-start-5">
                    <div class="form-check">
                        <input id="resolve-check" class="form-check-input" type="checkbox" value="">
                        <label class="form-check-label" for="resolve-check">
                            Mark as 'Resolved'
                        </label>
                    </div>
                </div>
                }
                <textarea id="response-textarea" class="form-control"></textarea>
                <button id="respond-button" class="btn btn-primary btn-lg rounded-end-5" type="submit">
                    <i class="fa-solid fa-play"></i>
                </button>
            </div>
        </form>
        }
        @else {
        <p class="p-3 m-0 text-center bg-warning-subtle rounded-bottom border border-top-0">
            <i class="fa-solid fa-ban"></i> This question has been closed.
        </p>
        }
    </div>
</div>
}