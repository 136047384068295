<lib-secondary-navbar
  [title]="'sidebar.itemtypemanagement' | translate"
></lib-secondary-navbar>

<div class="containerdiv">
  <tabset type="pills">
    <tab [heading]="'itemtypemanagement.menuitems.itemtypes' | translate">
      <app-item-type-mgmt></app-item-type-mgmt>
    </tab>

    <tab [heading]="'itemtypemanagement.menuitems.attributesvalues' | translate">
      <app-attribute-values-mgmt></app-attribute-values-mgmt>
    </tab>

    <tab [heading]="'itemtypemanagement.menuitems.materials' | translate">
      <app-material-mgmt></app-material-mgmt>
    </tab>

    <tab [heading]="'itemtypemanagement.menuitems.construction' | translate">
      <app-construction-mgmt></app-construction-mgmt>
    </tab>

    <tab [heading]="'itemtypemanagement.menuitems.sizemanagement' | translate">
      <app-size-mgmt></app-size-mgmt>
    </tab>

    <tab [heading]="'itemtypemanagement.menuitems.sectionschaptersheadings' | translate">
      <app-section-chapter-mgmt></app-section-chapter-mgmt>
    </tab>

    <tab [heading]="'itemtypemanagement.menuitems.itemtypegroups' | translate">
      <app-item-type-groups></app-item-type-groups>
    </tab>

    <tab [heading]="'itemtypemanagement.menuitems.regions' | translate">
      <app-regions></app-regions>
    </tab>
  </tabset>
</div>
