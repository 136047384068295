<h2 mat-dialog-title>{{ editMode ? 'Edit Material' : 'Create Material' }}</h2>
<hr />
<form [formGroup]="materialForm" (ngSubmit)="onSave()">
  <mat-dialog-content>
    <div class="form-group">
      <!-- Description Field -->
      <mat-form-field appearance="fill" class="full-width">
        <mat-label>Description</mat-label>
        <input matInput formControlName="description" placeholder="Enter material description" maxlength="750" />
        <mat-hint align="start">
          Specifies the material type or name within the selected category.
        </mat-hint>
        <mat-hint align="end">{{ materialForm.get('description')?.value?.length || 0 }}/750</mat-hint>
        <mat-error *ngIf="materialForm.get('description')?.hasError('required')">
          Description is required
        </mat-error>
        <mat-error *ngIf="materialForm.get('description')?.hasError('maxlength')">
          Description cannot exceed 750 characters
        </mat-error>
      </mat-form-field>

      <!-- Category Dropdown -->
      <mat-form-field appearance="fill" class="full-width">
        <mat-label>Category</mat-label>
        <mat-select formControlName="category">
          <mat-option *ngFor="let category of categories" [value]="category.id">
            {{ category.description }}
          </mat-option>
        </mat-select>
        <mat-hint align="start">
          Select the material category this item belongs to.
        </mat-hint>
      </mat-form-field>

      <!-- Clarifying Information -->
      <mat-form-field appearance="fill" class="full-width">
        <mat-label>Clarifying Information</mat-label>
        <textarea
          matInput
          formControlName="clarifyingInformation"
          placeholder="Enter additional details"
          maxlength="2000"
          rows="5"
          style="resize: none;"
        ></textarea>
        <mat-hint align="start">
          Provides additional classification details or special considerations.
        </mat-hint>
        <mat-hint align="end">{{ materialForm.get('clarifyingInformation')?.value?.length || 0 }}/2000</mat-hint>
      </mat-form-field>

      <!-- Checkboxes Section -->
      <div class="checkbox-group">
        <mat-checkbox formControlName="manMade">
          Man Made
          <mat-icon
            matTooltip="Indicates if the material is synthetic or artificially produced."
            class="help-icon">
            help_outline
          </mat-icon>
        </mat-checkbox>

        <mat-checkbox formControlName="metallizedYarn">
          Metallized Yarn
          <mat-icon
            matTooltip="Specifies if the material contains metallic fibers or coatings."
            class="help-icon">
            help_outline
          </mat-icon>
        </mat-checkbox>
      </div>

      <mat-checkbox formControlName="includeInCustomsDescription" class="full-width">
        Include in Customs Description
        <mat-icon
          matTooltip="Determines if this material should be included in the customs description for classification."
          class="help-icon">
          help_outline
        </mat-icon>
      </mat-checkbox>

    </div>
  </mat-dialog-content>
  <hr />
  <div class="action-buttons">
    <mat-dialog-actions>
      <button mat-flat-button color="warn" type="button" *ngIf="editMode" (click)="onDelete()">
        Delete
      </button>
    </mat-dialog-actions>
    <mat-dialog-actions>
      <button mat-button type="button" (click)="onCancel()">Cancel</button>
      <button mat-flat-button color="primary" type="submit" [disabled]="materialForm.invalid  || isSaving">
        {{ editMode ? 'Update' : 'Create' }}
      </button>
    </mat-dialog-actions>
  </div>
</form>
