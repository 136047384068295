<hr />
<div class="grid-container">
  <div class="left-column">
    <div class="container-fluid">
      <h6>Select an Option Below:</h6>
      <ul class="list-group">
        <li
          class="list-group-item"
          [class.active]="selectedList() === 'worldwidebook'"
          (click)="selectList('worldwide')"
        >
          <i class="fas fa-globe"></i>
          <span class="ms-2">Worldwide (HS) Tariff Book(s)</span>
        </li>
        <li
          class="list-group-item"
          [class.active]="selectedList() === 'tarifftelbook'"
          (click)="selectList('tarifftel')"
        >
          <i class="fas fa-book-open"></i>
          <span class="ms-2">TariffTel (HS) Tariff Book</span>
        </li>
      </ul>

      <hr />

      <div class="sections">
        <div class="form-group">
          <h6>Select Section/Chapter/Heading:</h6>

          <!-- Section Dropdown -->
          <div class="dropdown">
            <button
              class="btn btn-secondary dropdown-toggle form-control"
              type="button"
              (click)="toggleDropdown('section')"
            >
              {{ selectedSectionTitle }}
            </button>
            <ul
              class="dropdown-menu scrollable-dropdown wide-dropdown"
              [class.show]="sectionDropdownOpen"
            >
              <li *ngFor="let section of sections()">
                <button
                  class="dropdown-item"
                  type="button"
                  (click)="selectSection(section)"
                >
                  {{ section.numeral }} -
                  {{
                    section.title.length > 60
                      ? (section.title | slice: 0:60) + '...'
                      : section.title
                  }}
                </button>
              </li>
            </ul>
          </div>

          <!-- Chapter Dropdown -->
          <div class="dropdown mt-2" *ngIf="selectedSectionId() !== null">
            <button
              class="btn btn-secondary dropdown-toggle form-control"
              type="button"
              (click)="toggleDropdown('chapter')"
            >
              {{ selectedChapterDescription }}
            </button>
            <ul
              class="dropdown-menu scrollable-dropdown wide-dropdown"
              [class.show]="chapterDropdownOpen"
            >
              <li *ngFor="let chapter of chapters()">
                <button
                  class="dropdown-item"
                  type="button"
                  (click)="selectChapter(chapter)"
                >
                  Chapter {{ chapter.chapterNumber }}:
                  {{
                    chapter.description.length > 60
                      ? (chapter.description | slice: 0:60) + '...'
                      : chapter.description
                  }}
                </button>
              </li>
            </ul>
          </div>

          <!-- Heading Dropdown -->
          <div class="dropdown mt-2" *ngIf="selectedChapterId() !== null">
            <button
              class="btn btn-secondary dropdown-toggle form-control"
              type="button"
              (click)="toggleDropdown('heading')"
            >
              {{ selectedHeadingDescription }}
            </button>
            <ul
              class="dropdown-menu scrollable-dropdown wide-dropdown"
              [class.show]="headingDropdownOpen"
            >
              <li *ngFor="let heading of headings()">
                <button
                  class="dropdown-item"
                  type="button"
                  (click)="selectHeading(heading)"
                >
                  {{ heading.headingCode }} -
                  {{
                    heading.description.length > 60
                      ? (heading.description | slice: 0:60) + '...'
                      : heading.description
                  }}
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <hr />
      <button class="btn btn-primary me-2" (click)="classify()" aria-label="Create Group">
        Classification Dialog
      </button>
    </div>
  </div>

  <div class="right-column">
    <div *ngIf="selectedHeadingId() !== null; else noSelection">
      <ng-container
        *ngIf="selectedList() === 'worldwidebook'; else tariffTelContent"
      >
        <!-- Worldwide Tariff Book Content -->
        <app-harmonized-codes-by-heading
          (selectedCode)="onCodeSelected($event)"
          [headingCode]="selectedHeadingDescription"
        >
        </app-harmonized-codes-by-heading>

        <app-item-types-table
          [code]="selectedCode()"
          [codeType]="codeType()"
        >
        </app-item-types-table>
      </ng-container>
    </div>

    <!-- TariffTel Book Content -->
    <ng-template #tariffTelContent>
      <app-tarifftel-codes-by-heading></app-tarifftel-codes-by-heading>
    </ng-template>

    <ng-template #noSelection>
      <div class="alert-container">
        <div class="alert alert-info text-center" role="alert">
          Please select a Section, Chapter, and Heading to view the codes.
        </div>
      </div>
    </ng-template>
  </div>
</div>

