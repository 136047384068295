<lib-secondary-navbar
  [title]="'Department Management'"
></lib-secondary-navbar>

<div class="containerdiv">
  <div class="d-flex justify-content-between align-items-center mb-3 mt-2">
    <div class="col-lg-6">
      <input
        type="text"
        class="form-control"
        placeholder="Search by Department Description"
        [(ngModel)]="searchTerm"
        (ngModelChange)="onSearchTermChange($event)"
      />
    </div>

    <!-- Filters and Buttons -->
    <div class="col-lg-6 text-end">
      <div class="btn-group me-2" role="group" aria-label="Filter Buttons">
        <button
          [ngClass]="store.filter() === 'all' ? 'btn btn-primary' : 'btn btn-outline-primary'"
          (click)="store.changeFilter('all')"
          aria-label="Filter to show all departments"
        >
          All
        </button>
        <button
          [ngClass]="store.filter() === 'active' ? 'btn btn-primary' : 'btn btn-outline-primary'"
          (click)="store.changeFilter('active')"
          aria-label="Filter to show active departments"
        >
          Active
        </button>
        <button
          [ngClass]="store.filter() === 'inactive' ? 'btn btn-primary' : 'btn btn-outline-primary'"
          (click)="store.changeFilter('inactive')"
          aria-label="Filter to show inactive departments"
        >
          Not Active
        </button>
      </div>

      <button class="btn btn-primary me-2" (click)="createDepartment()" aria-label="Create Department">
        Create Department
      </button>

      <button class="btn btn-warning me-2" (click)="resetFilters()" aria-label="Reset filters and search">
        Reset
      </button>

      <button class="btn btn-secondary" (click)="forceRefresh()" aria-label="Refresh data">
        Refresh Data
      </button>
    </div>

  </div>
  <hr />
  <div class="container">
    <div class="item-type-tab-set">
      <div>
        <div *ngIf="store.filteredDepartments().length; else noDepartments">
          <table class="table table-bordered table-striped" style="width: 100%">
            <thead>
              <tr>
                <th scope="col" style="width: 75%">Description (Click to Edit)</th>
                <th scope="col" style="width: 15%" class="text-center">Department Code</th>
                <th scope="col" style="width: 10%" class="text-center">Active</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let department of store.filteredDepartments()">
                <td>
                  <div class="d-flex justify-content-between align-items-center">
                    <span class="clickable-description" (click)="editDepartment(department)">
                      {{ department.description }}
                    </span>
                  </div>
                </td>
                <td style="text-align: center">{{ department.code  }}</td>
                <td style="text-align: center;">
                  <ui-switch
                    [checked]="department.active"
                    (change)="onToggleActive(department)"
                  ></ui-switch>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <ng-template #noDepartments>
          <div class="alert alert-warning" role="alert">
            No Departments found.
          </div>
        </ng-template>
      </div>
    </div>
  </div>

</div>



