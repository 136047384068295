import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { IOrderLine } from '../../../../core/models/IOrderLine';

@Component({
  selector: 'app-product-classification-dialog',
  standalone: true,
  imports: [
    MatDialogModule,
    MatButtonModule
  ],
  templateUrl: './product-classification-dialog.component.html',
  styleUrl: './product-classification-dialog.component.scss'
})
export class ProductClassificationDialogComponent {
  title: string = "Classification Dialog";
  message: string = "";
  orderLine:IOrderLine | undefined

  constructor(public dialogRef: MatDialogRef<ProductClassificationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {orderLine:IOrderLine,message:string}) {
      this.orderLine = data.orderLine;
  }

   
  onConfirm(): void {
    this.dialogRef.close(true);
  }

  onDismiss(): void {
    this.dialogRef.close(false);
  }
}
