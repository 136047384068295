import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, Observable, of } from 'rxjs';
import { IItemAttribute } from '../models/IItemAttribute';
import { IAttributeValue } from '../models/IAttributeValue';
import { ISubAttribute } from '../models/ISubAttribute';
import { IItemTypeAttributeValue } from '../models/IItemTypeAttributeValue';

@Injectable({
  providedIn: 'root'
})
export class ItemTypeAttributeService {

  clientUrl = environment.apiUrl;

  constructor(private http: HttpClient) { }

  getItemTypeAttributes(): Observable<IItemAttribute[]> {
    return this.http.get<IItemAttribute[]>(this.clientUrl + "/api/ItemTypeAttribute");
  }

  getItemTypeAttributeValuesByItemTypeAttributeId(ItemAttributeId: number): Observable<IAttributeValue[]> {
    return this.http.get<IAttributeValue[]>(`${this.clientUrl}/api/AttributeValue/${ItemAttributeId}/attributevalues`);
  }

  getItemTypeAttributeValuesByItemTypeGroupId(ItemTypeGroupId: number): Observable<IItemTypeAttributeValue[]> {
    return this.http.get<IItemTypeAttributeValue[]>(`${this.clientUrl}/api/ItemTypeAttributeValue/GetByItemTypeGroupId/${ItemTypeGroupId}`);
  }

  addItemTypeAttribute(attributeName: string): Observable<any> {
      const headers = new HttpHeaders().set('Content-Type', 'application/json');
      const url = `${this.clientUrl}/api/ItemTypeAttribute?attributeName=${encodeURIComponent(attributeName)}`;
      return this.http.post<any>(url, null, { headers });
  }

  deleteItemTypeAttributeAndValues(attributeId: number): Observable<any> {
    const url = `${this.clientUrl}/api/ItemTypeAttributeValues/${attributeId}`;
    return this.http.delete<any>(url);
  }

  getItemTypeSubAttributesByAttributeValueId(attributeValueId: number): Observable<ISubAttribute[]> {
    return this.http.get<ISubAttribute[]>(`${this.clientUrl}/api/AttributeValue/${attributeValueId}/subattributes`);
  }


  getItemTypeSubAttributeValuesBySubAttributeId(subAttributeId: number): Observable<any> {
    return this.http.get<any>(`${this.clientUrl}/api/SubAttributeValue/${subAttributeId}/subattributevalues`);
  }

    // Create a new attribute
  createAttribute(attribute: IItemAttribute): Observable<IItemAttribute> {
    return this.http.post<IItemAttribute>(`${this.clientUrl}/api/itemattribute`, attribute, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }

  // Update an existing Attribute
  updateAttribute(attribute: IItemAttribute): Observable<IItemAttribute> {
    return this.http.put<IItemAttribute>(`${this.clientUrl}/api/itemattribute/${attribute.id}`, attribute, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }

    // Create a new attribute
  createAttributeValue(attributeValue: IAttributeValue, imageFile?: File): Observable<IAttributeValue> {
    const formData = new FormData();

    // Append basic properties
    formData.append('valueName', attributeValue.valueName);
    formData.append('description', attributeValue.description || '');
    formData.append('itemAttributeId', attributeValue.itemAttributeId.toString());

    // Append aliases
    if (attributeValue.attributeValueAliases) {
      attributeValue.attributeValueAliases.forEach((alias, index) => {
        formData.append(`aliases[${index}]`, alias.alias);
      });
    }

    // Append sub-attributes
    if (attributeValue.subAttributes) {
      attributeValue.subAttributes.forEach((subAttribute, subIndex) => {
        formData.append(`subAttributes[${subIndex}][id]`, subAttribute.id.toString());
        formData.append(`subAttributes[${subIndex}][subAttributeName]`, subAttribute.subAttributeName);

        // Ensure subAttributeValues is defined
        (subAttribute.subAttributeValues ?? []).forEach((value, valueIndex) => {
          formData.append(`subAttributes[${subIndex}][subAttributeValues][${valueIndex}][id]`, value.id.toString());
          formData.append(`subAttributes[${subIndex}][subAttributeValues][${valueIndex}][valueName]`, value.valueName);
        });
      });
    }

    // Append image if provided
    if (imageFile) {
      formData.append('imageFile', imageFile, imageFile.name);
    }

    return this.http.post<IAttributeValue>(
      `${this.clientUrl}/api/AttributeValue/attributevalue`,
      formData,
      {
        headers: { 'enctype': 'multipart/form-data' }, // Ensure correct header for form data
      }
    );
  }


  updateAttributeValue(attributeValue: IAttributeValue, imageFile?: File): Observable<IAttributeValue | null> {
    const formData = new FormData();

    formData.append('id', attributeValue.id.toString());
    formData.append('valueName', attributeValue.valueName);
    formData.append('description', attributeValue.description || '');
    formData.append('itemAttributeId', attributeValue.itemAttributeId.toString());

    attributeValue.attributeValueAliases?.forEach((alias, index) => {
        formData.append(`attributeValueAliases[${index}].id`, alias.id.toString());
        formData.append(`attributeValueAliases[${index}].alias`, alias.alias);
    });

    attributeValue.subAttributes?.forEach((subAttr, subIndex) => {
        formData.append(`subAttributes[${subIndex}].id`, subAttr.id.toString());
        formData.append(`subAttributes[${subIndex}].subAttributeName`, subAttr.subAttributeName);

        subAttr.subAttributeValues?.forEach((value, valueIndex) => {
            formData.append(`subAttributes[${subIndex}].subAttributeValues[${valueIndex}].id`, value.id.toString());
            formData.append(`subAttributes[${subIndex}].subAttributeValues[${valueIndex}].valueName`, value.valueName);
        });
    });

    if (imageFile) {
        formData.append('imageFile', imageFile, imageFile.name);
    }

    return this.http.put<IAttributeValue | null>(
        `${this.clientUrl}/api/attributevalue/${attributeValue.id}/attributeValue`,
        formData
    );
  }

  // Create a new Shared Attribute
  createSharedAttribute(attribute: IItemAttribute): Observable<IItemAttribute> {
    return this.http.post<IItemAttribute>(`${this.clientUrl}/api/sharedattribute`, attribute, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }

  // Update an existing Shared Attribute
  updateSharedAttribute(attribute: IItemAttribute): Observable<IItemAttribute> {
    return this.http.put<IItemAttribute>(`${this.clientUrl}/api/sharedattribute/${attribute.id}`, attribute, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }


  getAttributesByItemTypeGroupId(itemTypeGroupId: number): Observable<IItemAttribute[]> {
    return this.http.get<IItemAttribute[]>(`${this.clientUrl}/api/ItemAttribute/${itemTypeGroupId}/attributes`);
  }

  uploadImage(formData: FormData): Observable<string> {
    return this.http.post<string>(`${this.clientUrl}/api/attributevalue/uploadimage`, formData);
  }

  getSubAttributesByAttributeValueId(attributeValueId: number): Observable<ISubAttribute[]> {
    return this.http.get<ISubAttribute[]>(`${this.clientUrl}/api/SubAttribute/attributeValue/${attributeValueId}`);
  }

  createSubAttributeWithValues(subAttribute: ISubAttribute): Observable<ISubAttribute> {
    return this.http.post<ISubAttribute>(`${this.clientUrl}/api/SubAttribute/with-values`, subAttribute);
  }

  deleteAttributeValue(attributeId: number, attributeValueId: number): Observable<void> {
    return this.http.delete<void>(`${this.clientUrl}/api/attributevalue/${attributeId}/values/${attributeValueId}`);
  }

  deleteAttribute(attributeId: number): Observable<void> {
    return this.http.delete<void>(`${this.clientUrl}/api/ItemAttribute/remove/${attributeId}`);
  }
}
