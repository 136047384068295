import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { IAggridTopToolbarBtn } from './IAggridTopToolbarBtn';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-aggrid-top-btn-toolbar',
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule
  ],
  templateUrl: './aggrid-top-btn-toolbar.component.html',
  styleUrl: './aggrid-top-btn-toolbar.component.scss'
})
export class AggridTopBtnToolbarComponent {
  @Input() aggridTopToolbarBtns:IAggridTopToolbarBtn[] | undefined;
  @Output() aggridTopToolbarBtnClicked = new EventEmitter<IAggridTopToolbarBtn>();
  
  onAggridTopToolbarBtnClicked(aggridTopToolbarBtn:IAggridTopToolbarBtn) {
    this.aggridTopToolbarBtnClicked.emit(aggridTopToolbarBtn);
  }
}
