<h2 mat-dialog-title>{{ editMode ? 'Edit Construction' : 'Create Construction' }}</h2>
<hr />
<form [formGroup]="constructionForm" (ngSubmit)="onSave()">
  <mat-dialog-content>
    <div class="form-group">
      <!-- Description Field -->
      <mat-form-field appearance="fill" class="full-width">
        <mat-label>Description</mat-label>
        <input matInput formControlName="description" placeholder="Enter construction description" maxlength="750" />

        <!-- Hint for guidance -->
        <mat-hint align="start">
          Specifies the construction name or type within the selected category.
        </mat-hint>

        <mat-hint align="end">{{ constructionForm.get('description')?.value?.length || 0 }}/750</mat-hint>

         <!-- Required error message -->
        <mat-error *ngIf="constructionForm.get('description')?.hasError('required')">
          Description is required
        </mat-error>

        <!-- Max length error message -->
        <mat-error *ngIf="constructionForm.get('description')?.hasError('maxlength')">
          Description cannot exceed 750 characters
        </mat-error>
      </mat-form-field>

      <!-- Category Dropdown -->
      <mat-form-field appearance="fill" class="full-width">
        <mat-label>Category</mat-label>
        <mat-select formControlName="category">
          <mat-option *ngFor="let category of categories" [value]="category.id">
            {{ category.description }}
          </mat-option>
        </mat-select>
        <mat-hint align="start">
          Select the construction category this item belongs to.
        </mat-hint>
      </mat-form-field>

      <mat-form-field appearance="fill" class="full-width">
        <mat-label>Clarifying Information</mat-label>
        <textarea
          matInput
          formControlName="clarifyingInformation"
          placeholder="Enter additional details"
          maxlength="2000"
          rows="5"
          style="resize: none;"
        ></textarea>

        <!-- Hint for guidance -->
        <mat-hint align="start">
          Provides additional classification details or special considerations.
        </mat-hint>

        <mat-hint align="end">{{ constructionForm.get('clarifyingInformation')?.value?.length || 0 }}/2000</mat-hint>
      </mat-form-field>

      <!-- Include in Customs Desc Checkbox -->
      <mat-checkbox formControlName="includeInCustomsDescription">
        Include in Customs Description
        <mat-icon
          matTooltip="Determines if this construction should be included in the customs description for classification."
          class="help-icon">
          help_outline
        </mat-icon>
      </mat-checkbox>

    </div>
  </mat-dialog-content>
  <hr />
  <div class="action-buttons">
    <mat-dialog-actions>
      <button mat-flat-button color="warn" type="button" *ngIf="editMode" (click)="onDelete()">
        Delete
      </button>
    </mat-dialog-actions>
    <mat-dialog-actions>
      <button mat-button type="button" (click)="onCancel()">Cancel</button>
      <button mat-flat-button color="primary" type="submit" [disabled]="constructionForm.invalid || isSaving">
        {{ editMode ? 'Update' : 'Create' }}
      </button>
    </mat-dialog-actions>
  </div>
</form>

