import { Component } from '@angular/core';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { RegionsComponent } from './regions/regions.component';
import { SecondaryNavbarComponent } from '@tarifftel/components-library';
import { ItemTypeGroupsComponent } from "./item-type-groups/item-type-groups.component";
import { ConstructionMgmtComponent } from "./construction-mgmt/construction-mgmt.component";
import { MaterialMgmtComponent } from './material-mgmt/material-mgmt.component';
import { SectionChapterMgmtComponent } from "./section-chapter-mgmt/section-chapter-mgmt.component";
import { ItemTypeMgmtComponent } from "./item-type-mgmt/item-type-mgmt.component";
import { AttributeValuesMgmtComponent } from "./attribute-values-mgmt/attribute-values-mgmt.component";
import { SizeMgmtComponent } from "./size-mgmt/size-mgmt.component";
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-item-type-management',
  standalone: true,
  imports: [
    SecondaryNavbarComponent,
    TabsModule,
    RegionsComponent,
    ItemTypeGroupsComponent,
    MaterialMgmtComponent,
    ConstructionMgmtComponent,
    SectionChapterMgmtComponent,
    ItemTypeMgmtComponent,
    AttributeValuesMgmtComponent,
    SizeMgmtComponent,
    TranslateModule
],
  templateUrl: './item-type-management.component.html',
  styleUrl: './item-type-management.component.scss'
})
export class ItemTypeManagementComponent {

}
