import { CommonModule, DatePipe, KeyValue, TitleCasePipe } from '@angular/common';
import { Component, effect, input, OnInit } from '@angular/core';
import { IOrder } from '../../../../../core/models/IOrder';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

@Component({
  selector: 'app-tab-detail-list-group',
  standalone: true,
  imports: [
    CommonModule,
    MatSlideToggleModule
  ],
  templateUrl: './tab-detail-list-group.component.html',
  styleUrl: './tab-detail-list-group.component.scss',
  providers:[TitleCasePipe]
})
export class TabDetailListGroupComponent {
  readonly DATETIMEFORMAT = "dd MMM yyyy HH:mm";
  objectToDisplay = input<any|undefined>(undefined)
  objectPropertyIndexStart = input<number|undefined>(undefined)
  objectPropertyIndexEnd = input<number|undefined>(undefined)
  hideIds = input<boolean>(false)

  constructor(private titlecasePipe:TitleCasePipe ) {
  }

  excludeProperty(propertyKey:string) {
    if(this.hideIds() && propertyKey.toUpperCase().includes('ID')) {
      return true;
    }
    else {
      return false;
    }
  }

  convertPropNameToTitleCaseWithSpaces(propName:string):string {
    return this.titlecasePipe.transform(propName.replace(/([a-z])([A-Z])/g, '$1 $2'));
  }

  sliceOrderObjProps(order:IOrder,start:number,end:number) {
    return Object.fromEntries(
      Object.entries(order).slice(start, end)
    )
  }

  preserveOriginalOrder = (a: KeyValue<string,any>, b: KeyValue<string,any>): number => {
    return 0;
  }

  formatDateTime(propertyValue:any){
    return new DatePipe('en-US').transform(propertyValue,this.DATETIMEFORMAT)
  }

  isBoolean(propertyValue:any) {
    if(typeof propertyValue == 'boolean' || ( propertyValue instanceof Boolean)) {
      return true;
    }
    else {
      return false;
    }
  }
}
