<hr />
<div class="container-fluid">
  <div class="search-container">
    <div class="d-flex justify-content-end align-items-center mb-3 mt-2">
      <button class="btn btn-secondary" (click)="forceRefresh()" aria-label="Refresh data">Refresh Data</button>
    </div>
  </div>

  <hr />
  <div class="grid-container">
    <div class="left-column">
      <h6>Select an Option Below:</h6>
      <ul class="list-group">
        <li
          class="list-group-item d-flex justify-content-between align-items-center"
          [ngClass]="{ 'active': store.selectedList() === 'sizes' }"
          (click)="showTable('sizes')"
        >
          Default Sizes
        </li>
        <li
          class="list-group-item d-flex justify-content-between align-items-center"
          [ngClass]="{ 'active': store.selectedList() === 'sizecategories' }"
          (click)="showTable('sizecategories')"
        >
          Size Categories
        </li>
        <!-- <li
        class="list-group-item d-flex justify-content-between align-items-center"
        [ngClass]="{ 'active': store.selectedList() === 'gendermapping' }"
        (click)="showTable('gendermapping')"
      >
        Gender Mapping
      </li> -->
      </ul>

      <hr />

      <div>
        <h6>Item Type Group (Size Management):</h6>
        <div class="scrollable-item-group-list">
          <ul class="list-group">
            <li
              class="list-group-item d-flex justify-content-between align-items-center"
              *ngFor="let group of store.itemTypeGroups()"
              [ngClass]="{ 'active': group.id === store.selectedItemTypeGroupId() }"
              (click)="store.handleGroupClick(group)"
            >
              {{ group.description }}
            </li>
          </ul>
        </div>
      </div>

      <div *ngIf="store.selectedList() === 'sizes'">
        <hr />
        <h6>Active Region:</h6>
        <div class="scrollable-item-group-list">
          <ul class="list-group">
            <li
              class="list-group-item d-flex justify-content-between align-items-center"
              *ngFor="let region of store.regions()"
              [ngClass]="{ 'active': region.id === store.selectedRegionId() }"
              (click)="store.handleRegionClick(region)"
            >
              {{ region.description }}
              <span [class]="region.class" style="font-size: 23px"></span>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="right-column">
      <app-size-list *ngIf="store.selectedList() === 'sizes'"></app-size-list>
      <app-size-category-list *ngIf="store.selectedList() === 'sizecategories'"></app-size-category-list>
      <!-- <app-gender-mapping-list *ngIf="store.selectedList() === 'gendermapping'"></app-gender-mapping-list> -->
    </div>
  </div>
</div>
