import { Component, effect, inject, input, signal, Signal } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import {Subject, Subscription } from 'rxjs';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { CommonModule, DatePipe } from '@angular/common';
import { AgGridModule } from 'ag-grid-angular';
import { ColDef, GetContextMenuItemsParams, GridApi, GridReadyEvent, MenuItemDef, SideBarDef, FirstDataRenderedEvent, Column, ToolPanelDef } from 'ag-grid-enterprise';
import { MatDialog } from '@angular/material/dialog';
import { AggridFurtherOptionsToolPanelComponent } from '../aggrid-further-options-tool-panel/aggrid-further-options-tool-panel.component';
import { ConfirmModalComponent } from '../../../../core/components/confirm-modal/confirm-modal.component';
import { Router, RouterModule } from '@angular/router';
import { IAggridTopToolbarBtn } from '../aggrid-top-btn-toolbar/IAggridTopToolbarBtn';
import { AggridTopToolbarBtn, AggridTopToolbarBtnsEnum } from '../aggrid-top-btn-toolbar/AggridTopToolbarBtn';
import { AggridTopBtnToolbarComponent } from "../aggrid-top-btn-toolbar/aggrid-top-btn-toolbar.component";
import { UserFriendlyHttpErrorMessageDialogComponent } from '../user-friendly-http-error-message-dialog/user-friendly-http-error-message-dialog.component';
import { Vendor } from '../../../../core/models/vendor.model';
import { VendorManagementService } from '../../../../core/services/vendor-management.service';
import { AggridFurtherOptions } from '../aggrid-further-options-tool-panel/AggridFurtherOptions';
import 'ag-grid-enterprise';
import { VendorStore } from '../store/vendors.store';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-aggrid-vendor-management',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatIconModule,
    MatButtonModule,
    MatTooltipModule,
    MatCheckboxModule,
    MatRadioModule,
    AgGridModule,
    AggridTopBtnToolbarComponent,
    RouterModule
],
  templateUrl: './aggrid-vendor-management.component.html',
  styleUrl: './aggrid-vendor-management.component.scss'
})
export class AggridVendorManagementComponent {
  readonly DATETIMEFORMAT = "dd MMM yyyy HH:mm";
  readonly VENDORDETAILROUTEPREFIX = "settings/vendor-management/vendor-detail/";
  readonly EXCELEXPORTFILENAME = "Tarifftel Vendors"
  readonly EXCELEXPORTWORKSHEETNAME = "Tarifftel Vendors Worksheet"
  gridApi: GridApi|undefined;
  deleteVendorsBtnDisabled:boolean = true;
  refreshVendorDataBtnDisabled:boolean = false;
  manageVendorUsersBtnDisabled:boolean = true;
  //vendorManagementServiceRequestError$:Subscription | undefined
  //vendorManagementServiceRequestError:boolean = false;
  vendorsSubscription:Subscription|undefined;


  defaultColDef: ColDef = {
    flex:1,
    // allow every column to be aggregated
    enableValue: false,
    // allow every column to be grouped
    enableRowGroup: false,
    // allow every column to be pivoted
    enablePivot: false,
    filter: true,
    sortable:true,
    suppressMovable:true,
    editable: false,
    suppressHeaderMenuButton: true
  };
  sideBarDef = input<SideBarDef>({
        toolPanels: [
            {
              id: 'columns',
              labelDefault: 'Columns',
              labelKey: 'columns',
              iconKey: 'columns',
              toolPanel: 'agColumnsToolPanel',
              toolPanelParams: {
                suppressRowGroups: true,
                suppressValues: true,
                suppressPivots: true,
                suppressPivotMode: true,
                suppressColumnFilter: true,
                suppressColumnSelectAll: true,
                suppressColumnExpandAll: true,
              }
          },
          {
              id: 'filters',
              labelDefault: 'Filters',
              labelKey: 'filters',
              iconKey: 'filter',
              toolPanel: 'agFiltersToolPanel'
          },
          {
            id: 'furtherOptions',
            labelDefault: 'Further Options',
            labelKey: 'furtherOptions',
            iconKey: 'columns',
            toolPanel: AggridFurtherOptionsToolPanelComponent,
            toolPanelParams: {
              furtherOptionsToDisplay:[
                AggridFurtherOptions.ACTIVATEVENDORSCONTEXTMENUITEMNAME,
                AggridFurtherOptions.DEACTIVATEVENDORSCONTEXTMENUITEMNAME,
                AggridFurtherOptions.COPYMENUITEMNAME,
                AggridFurtherOptions.COPYWITHHEADERSMENUITEMNAME,
                AggridFurtherOptions.EXCELEXPORTSELECTEDROWSMENUITEMNAME,
                AggridFurtherOptions.EXCELEXPORTMENUITEMNAME,
              ],
              excelExportParams:{
                columnKeys:[],
                fileName: this.EXCELEXPORTFILENAME,
                sheetName:this.EXCELEXPORTWORKSHEETNAME,
              }
            }
          }
      ],
      position: 'right',
      defaultToolPanel: ''
  });
  rowData!: Vendor[]|null;
  themeClass: string = "ag-theme-quartz";
  columnDefs: ColDef[] = [
    {
      headerName: '',
      maxWidth:60,
      resizable: false,
      sortable:false,
      suppressFiltersToolPanel: true,
      suppressFloatingFilterButton:true,
      suppressColumnsToolPanel:true,
      checkboxSelection:true,
      headerCheckboxSelection:true,
      cellRenderer: "agGroupCellRenderer"
    },
    {
      field: 'vendorIdentifier',
    },
    {
      field: 'companyName',
    },
    {
      field: 'email',

    },
    {
      field: 'status',
      headerName: 'Active'
    },
    {
      field: 'lastOrderDate',
      cellRenderer: (data: any) => {
        return new DatePipe('en-US').transform(data.value,this.DATETIMEFORMAT);
      }
    },
    {
      field: 'administratorName',
      hide:true,
    },
    {
      field: 'phoneNumber',
      hide:true,
    },
    {
      field: 'streetAddress',
      hide:true
    },
    {
      field: 'city',
      hide:true
    },
    {
      field: 'state',
      hide:true
    },
    {
      field: 'postalCode',
      hide:true
    },
    {
      field: 'country',
      hide:true
    },
    {
      field: 'region',
      valueGetter: (params: any) => {
        if(params.data && params.data.region && params.data.region.description) {
          return params.data.region.description;
        }
        else {
          return "";
        }
      }
    },
    {
      headerName: '',
      cellRenderer:() => {
        return `<a href="javascript:void(0)" style="text-decoration:underline" >Select</a>`;
      },
      onCellClicked:(params) => {
        this.routeToVendorDetail(false,params.data.id);
      },
      maxWidth:80,
      minWidth:80,
      resizable: false,
      sortable:false,
      suppressFiltersToolPanel: true,
      suppressFloatingFilterButton:true,
      suppressColumnsToolPanel:true,
      filter: false
    }
  ];
  aggridTopToolbarBtns:IAggridTopToolbarBtn[] = [
    new AggridTopToolbarBtn(AggridTopToolbarBtnsEnum.Delete,"btn btn-danger","Delete Selected Vendors",this.deleteVendorsBtnDisabled,""),
    new AggridTopToolbarBtn(AggridTopToolbarBtnsEnum.Refresh,"btn btn-secondary","Refresh Vendor Data",this.refreshVendorDataBtnDisabled,"")
  ];
  aggridContextMenuItems: (string | MenuItemDef)[] = [
    {
      // custom item
      name: AggridFurtherOptions.ACTIVATEVENDORSCONTEXTMENUITEMNAME,
      action: () => {
        this.bulkChgStatusOfVendor(true);
      },
      cssClasses: ['bold'],
    },
    {
      // custom item
      name: AggridFurtherOptions.DEACTIVATEVENDORSCONTEXTMENUITEMNAME,
      action: () => {
        this.bulkChgStatusOfVendor(false);
      },
      cssClasses: ['bold'],
    },
    'copy',
    'copyWithHeaders',
    {
      // custom item
      name: AggridFurtherOptions.EXCELEXPORTSELECTEDROWSMENUITEMNAME,
      action: () => {

        let exportExcelParams = {
          columnKeys: this.gridApi?.getColumns()?.slice(1).map((col: Column) => col.getColId()), // exclude the first column which is a checkbox column
          onlySelected:true,
          fileName: this.EXCELEXPORTFILENAME,
          sheetName:this.EXCELEXPORTWORKSHEETNAME,
        }

        if(this.chkRowsAreSelectedForBulks("",`Please select atleast one row to export selected rows to an excel file.`)) {
          this.gridApi?.exportDataAsExcel(exportExcelParams);
        }
      },
      cssClasses: ['bold'],
    },
    {
      // custom item
      name: AggridFurtherOptions.EXCELEXPORTMENUITEMNAME,
      action: () => {

        let exportExcelParams = {
          columnKeys: this.gridApi?.getColumns()?.slice(1).map((col: Column) => col.getColId()), // exclude the first column which is a checkbox column
          onlySelected:false,
          fileName: this.EXCELEXPORTFILENAME,
          sheetName:this.EXCELEXPORTWORKSHEETNAME
        };

        this.gridApi?.exportDataAsExcel(exportExcelParams);
      },
      cssClasses: ['bold'],
    }
  ];
  vendorStore = inject(VendorStore);
  toastrService = inject(ToastrService)


  constructor(public dialog: MatDialog,
              private vendorManagementService:VendorManagementService,
              private router: Router) {

    effect(() => {
      this.rowData = this.vendorStore.vendors();
    });
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    this.vendorsSubscription?.unsubscribe();
  }

  onGridReady(params: GridReadyEvent<Vendor>) {
    this.gridApi = params.api;
    //this.aggridSaveGridStateService.aggridGridReadySignal.set(this.gridApi);
    this.gridApi.setGridOption("loading", true);

    this.vendorStore.loadVendors(false).subscribe({
      next: (vendors) => {
        this.gridApi?.setGridOption("loading", false);
      },
      error: (error) => {
        console.error('Failed to load vendors', error);
        this.dialog.open(UserFriendlyHttpErrorMessageDialogComponent);
      }
    });



    this.sideBarDef().toolPanels?.forEach((toolPanel:ToolPanelDef|string) => {
      if((toolPanel as ToolPanelDef).id == "furtherOptions") {
        // need to exclude the first column which is the checkbox from the columns to export to excel
        (toolPanel as ToolPanelDef).toolPanelParams.excelExportParams.columnKeys = this.gridApi?.getColumns()?.slice(1).map((col: Column) => col.getColId());
      }
    })
    this.gridApi.refreshToolPanel();

    (this.gridApi as any).furtherOptionsToolPanelBulkUpdateVendorsStatus = new Subject<boolean>();
    (this.gridApi as any).furtherOptionsToolPanelBulkUpdateVendorsStatus.subscribe((activateVendorStatus:boolean) => {
      if (activateVendorStatus != undefined && this.gridApi) {
        this.vendorStore.updateVendorStatus(this.gridApi.getSelectedRows(),activateVendorStatus).subscribe({
          next:() => {
            this.toastrService.success("Vendor(s) active field successfully updated!");
            this.gridApi?.setGridOption("loading", false);
          },
          error:(any: any) => {
            this.dialog.open(UserFriendlyHttpErrorMessageDialogComponent);
          }
        });
      }
    });

  }

  getContextMenuItems= (params:GetContextMenuItemsParams) => {
    return this.aggridContextMenuItems;
  }

  onSelectionChanged(event:any) {
    if(this.gridApi) {
      let selectedRowsLength = this.gridApi.getSelectedRows().length;
      this.deleteVendorsBtnDisabled = (selectedRowsLength > 0) ? false:true;
      this.manageVendorUsersBtnDisabled = (selectedRowsLength > 0) ? false:true;

      this.aggridTopToolbarBtns.forEach(aggridTopToolbarBtn => {
        if (aggridTopToolbarBtn.btnEnumVal == AggridTopToolbarBtnsEnum.Delete) {
          aggridTopToolbarBtn.disabled = this.deleteVendorsBtnDisabled;
        }
      });

    }
  }

  deleteVendorsBtnEvent() {
    let confirmDialogResult = false;
    if(this.deleteVendorsBtnDisabled) {
      return;
    }

    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      data: {
        title:"",
        message:"Are you sure you would like to delete the selected vendors?"
      }
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if(dialogResult && this.gridApi) {
        this.gridApi.setGridOption("loading", true);
        var selectedData = this.gridApi.getSelectedRows();

        this.vendorStore.deleteVendors(selectedData).subscribe({
          next:() => {
            this.toastrService.success("Selected Vendor(s) were deleted successfully!");
            this.gridApi?.setGridOption("loading", false);
          },
          error:() => {
            this.dialog.open(UserFriendlyHttpErrorMessageDialogComponent);
          }
        });
      }
    });
  }

  chkRowsAreSelectedForBulks(confirmModalTitle:string,confirmModalMsg:string) {
    if(!this.gridApi) {
      return
    }

    let selectedVendorRows:Vendor[] = this.gridApi.getSelectedRows();
    if(selectedVendorRows.length < 1) {
        this.dialog.open(ConfirmModalComponent, {
          data: {
            title:confirmModalTitle,
            message:confirmModalMsg
          }
        });
        return false;
    }
    else  {
      return true;
    }
  }

  bulkChgStatusOfVendor(activateVendor:boolean) {
    if(this.chkRowsAreSelectedForBulks("",`Please select atleast one vendor to bulk ${activateVendor ? 'activate':'deactivate'} selected vendors.`)) {
      const dialogRef = this.dialog.open(ConfirmModalComponent, {
        data: {
          title:"",
          message:`Are you sure you would like proceed to ${activateVendor ? 'activate':'deactivate'} selected vendors?`
        }
      });

      dialogRef.afterClosed().subscribe(dialogResult => {
        if(dialogResult && this.gridApi) {
          this.gridApi.setGridOption("loading", true);
          this.vendorStore.updateVendorStatus(this.gridApi.getSelectedRows(),activateVendor).subscribe({
            next:() => {
              this.gridApi?.setGridOption("loading", false);
              this.toastrService.success("Vendor(s) active field successfully updated!");
            },
            error:(any: any) => {
              this.dialog.open(UserFriendlyHttpErrorMessageDialogComponent);
            }
          });
        }
      });
    }
  }

  aggridTopToolbarBtnClicked(aggridTopToolbarBtn:IAggridTopToolbarBtn) {
    if(aggridTopToolbarBtn.btnEnumVal == AggridTopToolbarBtnsEnum.Delete) {
      this.deleteVendorsBtnEvent()
    }
    if(aggridTopToolbarBtn.btnEnumVal == AggridTopToolbarBtnsEnum.Refresh) {
      this.refreshVendorStore()
    }
  }

  routeToVendorDetail(addVendor:boolean,vendorId?:number) {

    let vendorIdToRouteWith = vendorId ? vendorId:0
    let createOrEditVendorModeQueryParam = addVendor ? "create":"edit"
    this.router.navigate([this.VENDORDETAILROUTEPREFIX,createOrEditVendorModeQueryParam,vendorIdToRouteWith])
  }

  refreshVendorStore() {
    this.gridApi?.setGridOption("loading", true);
    this.vendorStore.loadVendors(true).subscribe({
      next: (vendors) => {
        this.gridApi?.setGridOption("loading", false);
      },
      error: (error) => {
        console.error('Failed to load vendors', error);
        this.dialog.open(UserFriendlyHttpErrorMessageDialogComponent);
      }
    });
  }
}
