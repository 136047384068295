<!-- src/app/features/item-type-management/item-types-table/item-types-table.component.html -->

<div class="container">
  <!-- Loading Indicator -->
  <div *ngIf="isLoading" class="text-center my-4">
    <div class="spinner-border text-primary" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>

  <!-- Item Types Table -->
  <div *ngIf="!isLoading && itemTypes.length > 0">
    <table class="table table-bordered" style="width: 100%">
      <thead>
        <tr>
          <th style="width:60%">Description (click to edit)</th>
          <th style="text-align: center; width: 15%">Delete</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let itemType of itemTypes">
          <td>{{ itemType.description }}</td>
          <td style="text-align: center;">
            <button class="btn btn-danger btn-sm" (click)="deleteItemType(itemType.id)">Delete</button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <!-- Handle No Item Types Found -->
  <div *ngIf="!isLoading && itemTypes.length === 0">
    <p *ngIf="codeType !== 'unknown'">No Item Types found for the selected {{ codeType }} code.</p>
    <p *ngIf="codeType === 'unknown'">No Item Types available.</p>
  </div>
</div>
