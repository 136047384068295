import { inject, Injectable } from '@angular/core';
import { OrderService } from './order.service';
import { IQuestionForm } from '../models/IQuestionForm';
import { EMPTY, forkJoin, map, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { IKeyValuePair } from '../models/IKeyValuePair';
import { IQuestion } from '../models/IQuestion';

@Injectable({
  providedIn: 'root'
})
export class QuestionService {
  orderService = inject(OrderService);
  http = inject(HttpClient);
  clientUrl = environment.apiUrl;

  private getCategories(): Observable<IKeyValuePair[]> {
    return this.http.get<IKeyValuePair[]>(`${this.clientUrl}/api/question/categories`);
  }

  private getOrderNumber(orderId: number): Observable<string> {
    return this.orderService.getOrderById(orderId).pipe(
      map((order: any) => order.orderNumber)
    );
  }

  getHeaderQuestionForm(orderId: number): Observable<IQuestionForm> {
    return forkJoin({
      orderNumber: this.getOrderNumber(orderId),
      categories: this.getCategories()
    }).pipe(
      map(({ orderNumber, categories }) => {
        return {
          orderNumber,
          orderLineCode: null,
          categories
        }
      })
    )
  }

  getLineQuestionForm(orderId: number, orderLineId: number): Observable<IQuestionForm> {
    const getOrderLineCode: Observable<string> = !orderLineId ? EMPTY : this.orderService.getOrderLineById(orderLineId).pipe(
      map((order: any) => order.uniqueProductCode)
    );

    return forkJoin({
      orderNumber: this.getOrderNumber(orderId),
      orderLineCode: getOrderLineCode,
      categories: this.getCategories()
    }).pipe(
      map(({ orderNumber, orderLineCode, categories }) => {
        return {
          orderNumber,
          orderLineCode,
          categories
        }
      })
    );
  }

  ask(question: Partial<IQuestion>) {
    return this.http.post(`${this.clientUrl}/api/question/`, question);
  }
}
